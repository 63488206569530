import Framework7 from "framework7";
import FullscreenModal from "../fullscreen-modal/f7.fullscreen-modal.class";
import "./f7.dimbar.css";

export default function(Framework7Class) {
  return class Dimbar extends FullscreenModal(Framework7Class) {

    private params;

    public min: number = 0;
    public max: number = 100;
    public releaseOnly: boolean = false;
    public stopOnly: boolean = true;
    public value: number = -1;
    public reverse: boolean = false;
    public frequency: number = 0;
    private targetValue: number = -1;
    public targetValueSet: number = 20;

    private _startValue: number = this.value;
    private _stopTimeout: any;
    private _blocked: boolean = false;

    private _buttonReleaseStopEventBlocked: boolean = true;
    private _buttonReleaseStopEventTimeout: any;
    private _buttonReleaseStopEventDelay: number = 1000;
    private _beforeButtonPressTimeout: any;
    private _buttonSteadyDelay: number = 10;

    private _buttons: boolean = false;
    private _targetValueMode: boolean = false;
    private _targetValueIncrement: number = 1;
    private _targetValueAppendix: string = "";
    private _type: "updown" | "plusminus" | "slider" = "slider";
    private _lastButtonState: string | undefined;

    private _beforeOpenEvent: (ev: Event) => void;
    private _closeEvent: (ev: Event) => void;


    constructor(app: Framework7, params)
    {

      super(app, params);

      this.params = params;


      //-- Set parameters

      this.min = this.params.min ?? this.min;
      this.value = this.params.value ?? this._mapPercentageToMinMax(this.value);
      this.releaseOnly = this.params.releaseOnly ?? this.params.releaseonly ?? this.releaseOnly;
      this.stopOnly = this.params.stopOnly ?? this.params.stoponly ?? this.stopOnly;
      this.max = this.params.max ?? this.max;
      this.reverse = this.params.reverse ?? this.reverse;
      this._buttons = this.params.buttons ?? this._buttons;
      this._type = this.params.type ?? this._type;
      this.frequency = this.params.frequency ?? this.frequency;
      this.targetValue = this.params.targetValue ?? this.targetValue;
      this._targetValueMode = this.params.targetValueMode ?? this._targetValueMode;
      this._targetValueIncrement = this.params.targetValueIncrement ?? this._targetValueIncrement;
      this._targetValueAppendix = this.params.targetValueAppendix ?? this._targetValueAppendix;

      //-- Add backwards compatibility for old buttons parameter

      if (this.params.type === undefined && this.params.buttons === true)
      {
        this._type = "updown";
      }


      //-- Listen to fullscreenmodal init event

      this._beforeOpenEvent = this._beforeOpen.bind(this);
      this.on("beforeOpen", this._beforeOpenEvent);

      this._closeEvent = this._close.bind(this);
      this.on("close", this._closeEvent);

      //@ts-expect-error
      this.$targetEl[0].f7Dimbar = this;

      //@ts-expect-error
      this.$targetEl[0].dimbar = this;

    }


    private _close(ev)
    {

    }


    private _beforeOpen(ev)
    {

      //@ts-expect-error
      this.$contentEl[0].dimbar = this;
      //@ts-expect-error
      this.$targetEl[0].dimbar = this;
      //@ts-expect-error
      this.$contentEl[0].f7Dimbar = this;

      this._updateSliderPosition(this._mapMinMaxValueToPercentage(this.value));

    }


    public pointerDown(ev): void
    {
      this._startValue = this.value;

      if (this._targetValueMode === true && this.releaseOnly === true)
      {
        if (this.$contentEl === undefined)
        {
          return;
        }
        //New Mode for increment a targetvalue by just click
        const dimbarElement = this.$contentEl.find(".dimbar-element");
        const dimbarHeight = dimbarElement.height();
        const dimbarWidth = dimbarElement.width();
        const dimbarPosY = dimbarElement.offset().top;
        const dimbarPosX = dimbarElement.offset().left;
        let regionY: number;
        let regionX: number;

        regionY = Math.round(100 / dimbarHeight * (this.currentY - dimbarPosY));
        regionY = (regionY > 100 ? 100 : regionY && regionY < 0 ? 0 : regionY);
        regionX = Math.round(100 / dimbarWidth * (this.currentX - dimbarPosX));
        regionX = (regionX > 100 ? 100 : regionX && regionX < 0 ? 0 : regionX);
        //debugger;

        if (regionY >= 50 && regionX > 0 && regionX < 100)
        {
          this.emit("local::change buttonChange", "down");
          dimbarElement.trigger("change buttonChange", "down");
          this._lastButtonState = "down";
          this.changeTargetValue(-1);
        } else if (regionY < 50 && regionX > 0 && regionX < 100)
        {
          this.emit("local::change buttonChange", "up");
          dimbarElement.trigger("change buttonChange", "up");
          this._lastButtonState = "up";
          this.changeTargetValue(1);
        }
      }
    }


    public pointerUp(ev): void
    {


      //-- Slider

      if (this._type === "slider")
      {

        if (this.releaseOnly === true)
        {


          if (this.$contentEl === undefined)
          {
            return;
          }

          const slider = this.$contentEl.find(".dimbar-slider-value");

          if (slider.length > 0)
          {
            this.emit("local::change dimbarChange", this.value);
            slider.trigger("change dimbarChange", this.value);
          }

        }

      }


      //-- Buttons

      if (this._type !== "slider")
      {

        if (this.$contentEl === undefined)
        {
          return;
        }
        const dimbarElement = this.$contentEl.find(".dimbar-element");
        const buttonUp = this.$contentEl.find(".dimbar-element .button.up");
        const buttonDown = this.$contentEl.find(".dimbar-element .button.down");
        buttonUp.removeClass("active");
        buttonDown.removeClass("active");

        if (dimbarElement.length > 0)
        {

          if (this.releaseOnly === true)
          {
            if (this.value >= 50)
            {
              this.emit("local::change buttonChange", "down");
              dimbarElement.trigger("change buttonChange", "down");
              this._lastButtonState = "down";
            } else
            {
              this.emit("local::change buttonChange", "up");
              dimbarElement.trigger("change buttonChange", "up");
              this._lastButtonState = "up";
            }
          } else {

            if(this._buttonReleaseStopEventBlocked !== true){
              this.emit("local::change buttonChange", "release");
              dimbarElement.trigger("change buttonChange", "release");
            }

            this._lastButtonState = "release";

          }

        }

      }


    }


    public pointerMove(ev): void {

      if(this.$contentEl === undefined){
        return;
      }


      //-- Calculate percentage

      const dimbarElement = this.$contentEl.find(".dimbar-element");
      const dimbarHeight = dimbarElement.height();
      const pixelPerPercent = dimbarHeight / 100;


      //-- Slider

      if(this._type === "slider"){

        let percent: number;

        if(this.reverse !== true){
          percent = this._mapMinMaxValueToPercentage(this._startValue) + ((this.startY - this.currentY) / pixelPerPercent);
        } else {
          percent = this._mapMinMaxValueToPercentage(this._startValue) - ((this.startY - this.currentY) / pixelPerPercent);
        }


        //-- Normalize percentage

        percent = percent > 100 ? 100 : percent && percent < 0 ? 0 : percent;

        const value = this._mapPercentageToMinMax(percent);


        //-- Enable immediate direction change when percent reaches maximum or minimum

        if(percent === 100 || percent === 0){
          this._startValue = value;
          this.startY = this.currentY;
        }


        //-- Only continue when value has changed

        if(value === this.value){
          return;
        }


        //-- Update value

        this.value = value;


        //-- Slider

        const slider = this.$contentEl.find(".dimbar-slider-value");

        if(slider.length > 0){


          //-- Update slider position

          // this._disableSliderAnimations();
          this._updateSliderPosition(percent);


          //-- Emit slider events

          if(this.releaseOnly === false && this.stopOnly === false){


            //-- Emit events only if not blocked

            if(this.frequency > 0){

              if(this._blocked === true){
                return;
              }

              this._blocked = true;

              setTimeout(() => {
                this._blocked = false;
              }, 1000 / this.frequency);

            }

            this.emit("local::change dimbarChange", this.value);
            slider.trigger("change dimbarChange", this.value);

          }

          if(this.stopOnly === true && this.releaseOnly === false){

            if(this._stopTimeout !== undefined){
              clearTimeout(this._stopTimeout);
            }

            this._stopTimeout = setTimeout(() => {

              this.emit("local::change dimbarChange", this.value);
              slider.trigger("change dimbarChange", this.value);

            }, 150);

          }
        }

      }


      //-- Buttons

      if(this._type !== "slider"){
        if(this._beforeButtonPressTimeout !== undefined){
          clearTimeout(this._beforeButtonPressTimeout);
        }

        this._beforeButtonPressTimeout = setTimeout(() => {
          this._buttonPressed(ev);
        }, this._buttonSteadyDelay);

      }

    }

    private _buttonPressed(ev) {

      if(this.$contentEl === undefined){
        return;
      }

      if(this._type === "slider"){
        return;
      }

      //-- Calculate percentage

      const dimbarElement = this.$contentEl.find(".dimbar-element");
      const dimbarHeight = dimbarElement.height();
      const dimbarPosY = dimbarElement.offset().top;

      let percent = Math.round(100 / dimbarHeight * (this.currentY - dimbarPosY));


      //-- Normalize percentage
      percent = (percent > 100 ? 100 : percent && percent < 0 ? 0 : percent);

      if(percent === this.value){
        return;
      }

      //-- Button

      if(this._lastButtonState === "down" && percent >= 50){
        return;
      } else if(this._lastButtonState === "up" && percent < 50){
        return;
      }

      //-- Block release event for this._buttonReleaseStopEventDelay ms

      this._buttonReleaseStopEventBlocked = true;

      if(this._buttonReleaseStopEventTimeout !== undefined){
        clearTimeout(this._buttonReleaseStopEventTimeout);
      }

      this._buttonReleaseStopEventTimeout = setTimeout(() => {
        this._buttonReleaseStopEventBlocked = false;
      }, this._buttonReleaseStopEventDelay);


      //-- Trigger button actions

      const buttonUp = this.$contentEl.find(".dimbar-element .button.up");
      const buttonDown = this.$contentEl.find(".dimbar-element .button.down");

      //-- Emit events

      if(percent >= 50){
        buttonUp.removeClass("active");
        buttonDown.addClass("active");
        this.emit("local::change buttonChange", "down");
        dimbarElement.trigger("change buttonChange", "down");
        this._lastButtonState = "down";
      }
      if(percent < 50){
        buttonDown.removeClass("active");
        buttonUp.addClass("active");
        this.emit("local::change buttonChange", "up");
        dimbarElement.trigger("change buttonChange", "up");
        this._lastButtonState = "up";
      }
    }


    private _updateSliderPosition(percent: number): void {

      if(this.$contentEl === undefined){
        return;
      }

      const slider = this.$contentEl.find(".dimbar-slider-value");

      if(slider.length <= 0){
        return;
      }

      percent = Math.round(percent);

      if(this.reverse !== true){
        slider.css("background-position", "center " + percent + "%");
      } else {
        slider.css("background-position", "center " + (0 - percent) + "%");
      }

    }


    public setValue(value: number): void
    {
      //this.targetValue = value;
      this.value = value;
      this._updateSliderPosition(this._mapMinMaxValueToPercentage(value));
    }
    public setTargetValue(value: number): void
    {
      //console.log("Set TargetValue from outside to: " + value);
      this.targetValue = value;
      this.targetValueSet = this.targetValue;
      this.displayTargetValue();
      this.cutTargetValue();
    }
    private changeTargetValue(direction: number)
    {
      this.targetValueSet = this.targetValue + (this._targetValueIncrement * direction);
      this.displayTargetValue();
      this.cutTargetValue();
      //console.log("targetvalue changed by " + this._targetValueIncrement * direction);
      //console.log("targetvalue set is now " + this.targetValueSet);
    }
    private cutTargetValue()
    {
      if (this.targetValueSet >= this.max)
      {
        this.targetValueSet = this.max
      }
      if (this.targetValueSet <= this.min)
      {
        this.targetValueSet = this.min
      }
    }
    private displayTargetValue()
    {
      //console.log("targetvalue display refresh");
      //console.log(this.targetValueSet);
      if (this.$contentEl === undefined)
      {
        return;
      }
      const targetValueDisplay = this.$contentEl.find("#target-value-display");
      //console.log(this.targetValueSet);
      //console.log(this.min);
      //console.log(this.max);
      if (this.targetValueSet > this.max || this.targetValueSet < this.min)
      {
        targetValueDisplay[0].innerHTML = "N/A";
      }
      else
      {
        targetValueDisplay[0].innerHTML = `${this.targetValueSet}&#8451`;
      }

    }
    public render(): string {

      let container = "";
      let icon = "";
      let title = "";
      let content = "";
      let value = this.value;

      if(this.params.icon !== undefined){
        icon = this.params.icon;
      }

      if(this.params.title !== undefined){
        title = this.params.title;
      }

      if(this.params.content !== undefined){
        content = this.params.content;
      }

      if(this.params.value !== undefined){
        value = this.params.value;
      }

      if (this._type !== "slider")
      {
        const upLabel = this._type === "updown" ? "arrowtriangle_up" : "plus";
        const downLabel = this._type === "updown" ? "arrowtriangle_down" : "minus";

        if (this._targetValueMode === true)
        {
          container = `
          <div class="dimbar-container">
            <i class="f7-icons">${icon}</i>
            <div class="fullscreen-modal-title">${title}</div>
            <div class="fullscreen-modal-content dimbar-element touch-area">
              <div class="dimbar-buttons">
                <button class="button target-value-enabled-button up">
                  <span class="f7-icons">${upLabel}</span>
                </button>
                <div id="target-value-display">${this.targetValue}${this._targetValueAppendix}</div>
                <button class="button target-value-enabled-button down">
                  <span class="f7-icons">${downLabel}</span>
                </button>
              </div>
            </div>
            <div class="dimbar-content fullscreen-modal-content flexbox">
              ${content}
            </div>
          </div>
          `;
        }
        else
        {
          container = `
          <div class="dimbar-container">
            <i class="f7-icons">${icon}</i>
            <div class="fullscreen-modal-title">${title}</div>
            <div class="fullscreen-modal-content dimbar-element touch-area">
              <div class="dimbar-buttons">
                <button class="button up">
                  <span class="f7-icons">${upLabel}</span>
                </button>
                <button class="button down">
                  <span class="f7-icons">${downLabel}</span>
                </button>
              </div>
            </div>
            <div class="dimbar-content fullscreen-modal-content flexbox">
              ${content}
            </div>
          </div>
          `;
        }
      } else {
        container = `
          <div class="dimbar-container">
            ${icon}
            <div class="dimbar-title">${title}</div>
            <div class="fullscreen-modal-content dimbar-element touch-area">
              <div class="dimbar-slider-value">

              </div>
            </div>
            <div class="dimbar-content fullscreen-modal-content flexbox">
              ${content}
            </div>
          </div>
        `;
      }

      return container;

    }


    private _mapPercentageToMinMax(value: number): number {
      return Math.round(value / 100 * (this.max - this.min));
    }


    private _mapMinMaxValueToPercentage(value: number): number {
      return Math.round(value / (this.max - this.min) * 100);
    }

  };
}
