
export class Zoom {

  static maxZoomLevel: number = 7;
  static minZoomLevel: number = 1;
  static doubleTapZoomLevel: number = 3;
  static zoomStep: number = .3;

  public container: HTMLElement;

  private _eventCache: Array<PointerEvent> = [];
  private _width: number = 0;
  private _height: number = 0;
  private _animationFrameRequested: boolean = false;
  private _renderOnPointerUp?: { x: number, y: number, zoomLevel: number } = undefined;
  private _renderedOffsetY: number = 0;
  private _renderedOffsetX: number = 0;
  private _zoomLevel: number = 1;
  private _scaleZoomLevel: number = 1;
  private _initialPinchOriginX: number | undefined;
  private _initialPinchOriginY: number | undefined;
  private _previousPanOriginX: number | undefined;
  private _previousPanOriginY: number | undefined;
  private _initialPinchDiff: number | undefined;
  private _lastTap: number = 0;
  private _tapCount: number = 0;

  private _dragStartEvent: (ev: DragEvent) => void;
  private _pointerDownEvent: (ev: PointerEvent) => void;
  private _pointerMoveEvent: (ev: PointerEvent) => void;
  private _touchMoveEvent: (ev: TouchEvent) => void;
  private _wheelEvent: (ev: WheelEvent) => void;
  private _pointerUpEvent: (ev: PointerEvent) => void;


  constructor(container: HTMLElement) {

    this.container = container;
    this.container.classList.add("zoom");
    this.container.style.setProperty("--zoom-level", this._zoomLevel + "");
    this.container.style.setProperty("width", "calc(var(--zoom-level) * 100%)");
    this.container.style.setProperty("height", "calc(var(--zoom-level) * 100%)");
    this.container.style.setProperty("transition-origin", "0 0");


    //-- Update dimensions

    this._updateDimensions();


    //-- Register eventListeners

    this._pointerDownEvent = this._pointerDown.bind(this);
    this._pointerMoveEvent = this._pointerMove.bind(this);
    this._touchMoveEvent = this._touchMove.bind(this);
    this._wheelEvent = this._wheel.bind(this);
    this._pointerUpEvent = this._pointerUp.bind(this);
    this._dragStartEvent = this._dragStart.bind(this);

    this.container.addEventListener("dragstart", this._dragStartEvent);
    this.container.addEventListener("pointerdown", this._pointerDownEvent);
    this.container.addEventListener("pointermove", this._pointerMoveEvent);
    this.container.addEventListener("touchmove", this._touchMoveEvent);
    this.container.addEventListener("wheel", this._wheelEvent);
    this.container.addEventListener("pointerup", this._pointerUpEvent);
    this.container.addEventListener("pointercancel", this._pointerUpEvent);
    this.container.addEventListener("pointerout", this._pointerUpEvent);
    this.container.addEventListener("pointerleave", this._pointerUpEvent);


    //-- Register resize observer

    const resizeObserver = new ResizeObserver(entries => {
      this._updateDimensions();
    });

    resizeObserver.observe(this.container.parentElement ?? this.container);

  }


  public destroy() {


    //-- Remove event listeners

    this.container.removeEventListener("dragstart", this._dragStartEvent);
    this.container.removeEventListener("pointerdown", this._pointerDownEvent);
    this.container.removeEventListener("pointermove", this._pointerMoveEvent);
    this.container.removeEventListener("touchmove", this._touchMoveEvent);
    this.container.removeEventListener("wheel", this._wheelEvent);
    this.container.removeEventListener("pointerup", this._pointerUpEvent);
    this.container.removeEventListener("pointercancel", this._pointerUpEvent);
    this.container.removeEventListener("pointerout", this._pointerUpEvent);
    this.container.removeEventListener("pointerleave", this._pointerUpEvent);

  }


  private _pointerDown(ev: PointerEvent): void {

    if(ev.target === null || ev.target !== this.container){
      return;
    }

    this._eventCache.push(ev);
    this._tapCount++;

  }


  private async _requestAnimationFrame(): Promise<boolean> {
    return new Promise(resolve => {
      if(this._animationFrameRequested === true){
        resolve(false);
      } else {
        window.requestAnimationFrame(() => {
          resolve(true);
          this._animationFrameRequested = false;
        });
      }
    });
  }


  private async _pointerMove(ev: PointerEvent) {

    if(ev.target === null || ev.target !== this.container){
      return;
    }

    if(this._scaleZoomLevel !== 1){
      ev.stopPropagation();
    }

    if(this._eventCache.length === 0){
      return;
    }

    if(await this._requestAnimationFrame() === false){
      return;
    }

    for(let e = 0; e < this._eventCache.length; e++){
      if(ev.pointerId === this._eventCache[e].pointerId){
        this._eventCache[e] = ev;
        break;
      }
    }


    //-- Get origin

    let originX: number | undefined;
    let originY: number | undefined;

    if(this._eventCache.length === 1){
      const { x, y } = this._getLayerCoordinates(this._eventCache[0]);
      originX = x;
      originY = y;
    } else if(this._eventCache.length >= 2){
      const { x: x1, y: y1 } = this._getLayerCoordinates(this._eventCache[0]);
      const { x: x2, y: y2 } = this._getLayerCoordinates(this._eventCache[1]);
      originX = Math.abs(x1 + x2) / 2;
      originY = Math.abs(y1 + y2) / 2;
    }

    if(originX === undefined || originY === undefined){
      return;
    }


    //-- Pan

    if(this._eventCache.length === 1){

      if(this._previousPanOriginX !== undefined && this._previousPanOriginY !== undefined){


        //-- Reset tapCount

        if(Math.abs(this._previousPanOriginX - originX) > 5 || Math.abs(this._previousPanOriginY - originY) > 5){
          this._tapCount = 0;
        }

        this._render(originX, originY, this._zoomLevel);

      }

      this._previousPanOriginX = originX;
      this._previousPanOriginY = originY;

    }


    //-- Pinch to zoom

    if(this._eventCache.length === 2){

      ev.preventDefault();

      const currentPinchDiff = Math.hypot(this._eventCache[0].clientX - this._eventCache[1].clientX,
        this._eventCache[0].clientY - this._eventCache[1].clientY);

      if(this._initialPinchDiff !== undefined && this._initialPinchOriginX !== undefined && this._initialPinchOriginY !== undefined){


        //-- Calculate new zoom level

        let newZoomLevel: number | undefined;

        if(currentPinchDiff > this._initialPinchDiff){
          newZoomLevel = this._zoomLevel - 1 + currentPinchDiff / this._initialPinchDiff;
        } else if(currentPinchDiff < this._initialPinchDiff){
          newZoomLevel = this._zoomLevel + 1 - this._initialPinchDiff / currentPinchDiff;
        }

        if(newZoomLevel === undefined){
          return;
        }

        newZoomLevel = this._normalizeZoomLevel(newZoomLevel);

        this._scale(originX, originY, newZoomLevel);

      } else {
        this._initialPinchOriginX = originX;
        this._initialPinchOriginY = originY;
        this._initialPinchDiff = currentPinchDiff;
      }

    }

  }


  private _normalizeZoomLevel(zoomLevel: number): number {
    return zoomLevel > Zoom.maxZoomLevel ? Zoom.maxZoomLevel : zoomLevel < Zoom.minZoomLevel ? Zoom.minZoomLevel : zoomLevel;
  }


  private gotoCoordinates(x: number, y: number, newZoomLevel: number, render?: boolean): void;
  private gotoCoordinates(x: number, y: number, newZoomLevel: number, duration?: number): void;
  private gotoCoordinates(x: number, y: number, newZoomLevel: number, renderOrDuration?: boolean | number): void {

    newZoomLevel = this._normalizeZoomLevel(newZoomLevel);

    const render = typeof renderOrDuration === "boolean" ? renderOrDuration : false;
    const duration = typeof renderOrDuration === "number" ? renderOrDuration : undefined;

    if(this._zoomLevel === newZoomLevel){
      this._pan(x, y);
    } else {
      if(render === true){
        this._render(x, y, newZoomLevel);
      } else {
        this._scale(x, y, newZoomLevel, duration);
      }
    }

  }


  private _scale(x: number, y: number, newZoomLevel: number, duration?: number): void {

    if(this._zoomLevel === newZoomLevel){
      return this._pan(x, y);
    }

    this._scaleZoomLevel = newZoomLevel;

    if(this._initialPinchOriginX !== undefined && this._initialPinchOriginY !== undefined){
      if(newZoomLevel > this._zoomLevel){
        x = this._initialPinchOriginX - (x - this._initialPinchOriginX) / newZoomLevel * this._zoomLevel;
        y = this._initialPinchOriginY - (y - this._initialPinchOriginY) / newZoomLevel * this._zoomLevel;
      } else {
        x = this._initialPinchOriginX + (x - this._initialPinchOriginX) / newZoomLevel * this._zoomLevel;
        y = this._initialPinchOriginY + (y - this._initialPinchOriginY) / newZoomLevel * this._zoomLevel;
      }
    }

    const originalX = x;
    const originalY = y;


    //-- Convert x and y from global to rendered coordinates

    x = x / this._zoomLevel - this._renderedOffsetX / this._zoomLevel;
    y = y / this._zoomLevel - this._renderedOffsetY / this._zoomLevel;

    const beforeZoomX = x * this._zoomLevel;
    const beforeZoomY = y * this._zoomLevel;

    const afterZoomX = x * newZoomLevel;
    const afterZoomY = y * newZoomLevel;

    const offsetX = this._renderedOffsetX + beforeZoomX - afterZoomX;
    const offsetY = this._renderedOffsetY + beforeZoomY - afterZoomY;

    const { x: finalOffsetX, y: finalOffsetY } = this._limitOffset(offsetX, offsetY, newZoomLevel);

    const scale = newZoomLevel / this._zoomLevel;


    //-- Enable animation

    if(duration !== undefined){
      this.container.style.transitionDuration = duration / 1000 + "s";
      this.container.style.transitionProperty = "transform";
    }


    //-- Set transform

    this.container.style.setProperty("transform", "translate3D(" + finalOffsetX + "px, " + finalOffsetY + "px, 0) scale(" + scale + ")");


    //-- Disable animation

    if(duration !== undefined){
      setTimeout(() => {

        this.container.style.transitionDuration = "0s";
        this.container.style.transitionProperty = "";

        this._render(originalX, originalY, newZoomLevel);
        this._reset();

      }, duration);
    } else {
      this._renderOnPointerUp = { x: originalX, y: originalY, zoomLevel: newZoomLevel };
    }

  }


  private _render(x: number, y: number, newZoomLevel: number) {

    if(this._zoomLevel === newZoomLevel){
      return this._pan(x, y);
    }

    this._scaleZoomLevel = newZoomLevel;


    //-- Convert x and y from global to rendered coordinates

    x = x / this._zoomLevel - this._renderedOffsetX / this._zoomLevel;
    y = y / this._zoomLevel - this._renderedOffsetY / this._zoomLevel;

    const beforeZoomX = x * this._zoomLevel;
    const beforeZoomY = y * this._zoomLevel;

    const afterZoomX = x * newZoomLevel;
    const afterZoomY = y * newZoomLevel;

    const offsetX = this._renderedOffsetX + beforeZoomX - afterZoomX;
    const offsetY = this._renderedOffsetY + beforeZoomY - afterZoomY;

    const { x: finalOffsetX, y: finalOffsetY } = this._limitOffset(offsetX, offsetY, newZoomLevel);

    this._renderedOffsetX = finalOffsetX;
    this._renderedOffsetY = finalOffsetY;


    //-- Update zoomLevel

    this._zoomLevel = newZoomLevel;


    //-- Set transform

    this.container.style.setProperty("--zoom-level", newZoomLevel + "");
    this.container.style.setProperty("transform", "translate3D(" + finalOffsetX + "px, " + finalOffsetY + "px, 0) scale(1)");

  }


  private _pan(x: number, y: number) {

    if(this._previousPanOriginX === undefined || this._previousPanOriginY === undefined){
      return;
    }


    //-- Pan

    const offsetX = this._renderedOffsetX + (x - this._previousPanOriginX);
    const offsetY = this._renderedOffsetY + (y - this._previousPanOriginY);

    const { x: finalOffsetX, y: finalOffsetY } = this._limitOffset(offsetX, offsetY, this._zoomLevel);

    this._renderedOffsetX = finalOffsetX;
    this._renderedOffsetY = finalOffsetY;

    this._previousPanOriginX = x;
    this._previousPanOriginY = y;

    this.container.style.transform = "translate3D(" + (finalOffsetX) + "px, " + (finalOffsetY) + "px, 0)";

  }


  private async _wheel(ev: WheelEvent) {

    if(ev.target === null || ev.target !== this.container){
      return;
    }

    ev.preventDefault();
    ev.stopPropagation();

    if(await this._requestAnimationFrame() === false){
      return;
    }

    const { x, y } = this._getLayerCoordinates(ev);

    const zoomLevel = ev.deltaY > 0 ? this._zoomLevel - Zoom.zoomStep : this._zoomLevel + Zoom.zoomStep;

    this.gotoCoordinates(x, y, zoomLevel, true);

  }


  private _dragStart(ev: DragEvent): void {
    ev.preventDefault();
  }


  private _touchMove(ev: TouchEvent): void {

    if(ev.target === null || ev.target !== this.container){
      return;
    }


    //-- Prevent parent from moving

    if(this._scaleZoomLevel !== 1){
      ev.stopPropagation();
    }
  }


  private _pointerUp(ev: PointerEvent): void {

    if(this._eventCache.length === 0){
      return;
    }

    let zoomOperationStarted = false;


    //-- Doubletap to zoom

    if(ev.type === "pointerup"){
      if(this._eventCache.length === 1){

        if(ev.button === 0){
          if(this._tapCount >= 1){

            if(this._lastTap > Date.now() - 300){

              zoomOperationStarted = true;

              const { x, y } = this._getLayerCoordinates(this._eventCache[0]);

              if(this._zoomLevel > 1){
                this.gotoCoordinates(x, y, 1, 300);
              } else {
                this.gotoCoordinates(x, y, Zoom.doubleTapZoomLevel, 300);
              }

            }

            this._tapCount = 0;

          }

          this._lastTap = Date.now();

        }

      }
    }

    if(this._renderOnPointerUp !== undefined){
      this._render(this._renderOnPointerUp.x, this._renderOnPointerUp.y, this._renderOnPointerUp.zoomLevel);
      this._renderOnPointerUp = undefined;
    }

    if(zoomOperationStarted === false){
      this._reset();
    }

    this._removeEvent(ev);

  }


  private _removeEvent(ev: PointerEvent) {

    for(let e = 0; e < this._eventCache.length; e++){
      if(this._eventCache[e].pointerId === ev.pointerId){
        this._eventCache.splice(e, 1);
        break;
      }
    }

  }


  private _getLayerCoordinates(ev: PointerEvent | WheelEvent): { x: number, y: number } {

    let x: number;
    let y: number;

    //@ts-expect-error
    if(ev.layerX !== undefined && ev.layerY !== undefined && navigator.userAgent.toLowerCase().indexOf("mozilla") === -1){
      //@ts-expect-error
      x = ev.layerX!;
      //@ts-expect-error
      y = ev.layerY!;
    } else {
      ({ x, y } = this._calculateLayerCoordinates(ev));
    }

    return { x, y };
  }


  private _calculateLayerCoordinates(ev: PointerEvent | WheelEvent): { x: number, y: number } {

    let el: HTMLElement | null = ev.target as HTMLElement;
    let x = 0;
    let y = 0;

    while(el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)){
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent as HTMLElement;
    }

    x = ev.clientX - x;
    y = ev.clientY - y;

    x += document.documentElement.scrollLeft;
    y += document.documentElement.scrollTop;

    return { x: x, y: y };
  }


  private _limitOffset(x: number, y: number, zoomLevel: number) {

    if(this._width === undefined || this._height === undefined){
      return { x, y };
    }

    const newWidth = this._width * zoomLevel;
    const newHeight = this._height * zoomLevel;

    if(x < this._width - newWidth){
      x = this._width - newWidth;
    } else if(x > 0){
      x = 0;
    }
    if(y < this._height - newHeight){
      y = this._height - newHeight;
    } else if(y > 0){
      y = 0;
    }

    return { x, y };

  }


  private _updateDimensions() {
    this._width = this.container.clientWidth;
    this._height = this.container.clientHeight;
  }


  private _reset() {
    this._initialPinchOriginX = undefined;
    this._initialPinchOriginY = undefined;
    this._initialPinchDiff = undefined;
    this._previousPanOriginX = undefined;
    this._previousPanOriginY = undefined;
  }

}