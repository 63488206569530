import { types } from "../../interfaces/types";
import * as functions from "../../functions/functions";
import { Storage } from "../../../js/modules/storage";
import { CloudStorage } from "../cloud-storage";
import TinyEventEmitter from "../tiny-event-emitter";
import semver from "semver";


class Database_ extends TinyEventEmitter {

  private _PDB: types.PDB | undefined;
  private _MDB: Array<types.Module> | undefined;
  private _settings: types.ClientSettings | undefined;

  private _identifier: string | undefined;
  private _code: string | undefined;

  constructor() {
    super();
  }


  public async initializeDB() {
    await Storage.initializeDB();
    await this._readCode();
    await this._readIdentifier();
    await this._readPDB();
    await this._readMDB();
    await this._readSettings();
  }


  private async _readIdentifier(): Promise<string | undefined> {

    const code = await Storage.getCode();

    if(code === undefined){
      return;
    }

    const identifier = await Storage.getData(code + "__IDENTIFIER__");

    if(typeof identifier !== "string"){
      return;
    }

    this._identifier = identifier;
    return this._identifier;

  }


  private async _readCode(): Promise<string | undefined> {

    const code = await Storage.getCode();

    if(code === undefined){
      return;
    }

    this._code = code;
    return this._code;

  }


  private async _readPDB(): Promise<types.PDB | undefined> {

    const code = this.getCode();

    if(code === undefined){
      console.warn("Database: _readPDB warning: code is undefined");
      return;
    }

    const data = await Storage.getData(code + "__PDB__");

    if(typeof data !== "string"){
      return;
    }

    if(functions.isParseableJSON(data)){
      this._PDB = JSON.parse(data);
      return this._PDB;
    }

    return;

  }


  public getIdentifier(): Readonly<string | undefined> {
    if(this._identifier === undefined){
      return;
    }
    return this._identifier;
  }


  public getCode(): Readonly<string | undefined> {
    if(this._code === undefined){
      return;
    }
    return this._code;
  }


  public getPDB(): Readonly<types.PDB> | undefined {
    if(this._PDB === undefined){
      return;
    }
    return this._PDB;
  }


  public getMDB(): Readonly<Array<types.Module>> | undefined {
    if(this._MDB === undefined){
      return;
    }
    return this._MDB;
  }


  public getSettings(): types.ClientSettings | undefined {
    if(this._settings === undefined){
      return;
    }
    return this._settings;
  }


  private async _readMDB(): Promise<Array<types.Module> | undefined> {

    const code = this.getCode();

    if(code === undefined){
      console.warn("Database: _readMDB warning: code is undefined");
      return;
    }

    const data = await Storage.getData(code + "__MDB__");

    if(typeof data !== "string"){
      return;
    }

    if(functions.isParseableJSON(data)){
      this._MDB = JSON.parse(data);
      return this._MDB;
    }

    return;

  }


  private async _readSettings(): Promise<types.ClientSettings | undefined> {

    const code = this.getCode();

    if(code === undefined){
      console.warn("Database: _readSettings warning: code is undefined");
      return;
    }

    const data = await Storage.getData(code + "__ClientSettings__");

    if(typeof data !== "string"){
      return;
    }

    if(functions.isParseableJSON(data)){
      this._settings = JSON.parse(data);
      return this._settings;
    }

    return;

  }


  public get cloudStorage() {

    return {
      _storeSDB: async(sdb: string) => {

        if(!functions.isParseableJSON(sdb)){
          console.warn("Database: storeSDB warning: sdb is no valid json: ", sdb);
          return;
        }

        const code = this.getCode();

        if(code === undefined){
          console.warn("Database: _storeSDB warning: code is undefined");
          return;
        }

        await Storage.storeData(code + "_SDB", sdb);

      },
      getValue: (name: string, deviceIdentifier: string): string | number | boolean | undefined => {
        return CloudStorage.getValue(name, deviceIdentifier);
      },
      storeValue: async(name: string, value: string | number | boolean, deviceIdentifier: string, time: number, emitEvent = false) => {
        CloudStorage.storeValue(name, value, deviceIdentifier, time);
        await this.cloudStorage._storeSDB(JSON.stringify(CloudStorage.SDB));
        if(emitEvent === true){
          this.emit("cloudValueChanged", name, value, deviceIdentifier, time);
        }
      },
      deleteValue: async(name: string, deviceIdentifier: string, time: number) => {
        CloudStorage.deleteValue(name, deviceIdentifier, time);
        await this.cloudStorage._storeSDB(JSON.stringify(CloudStorage.SDB));
      }
    };

  }


  public async storePDB(pdb: string): Promise<boolean> {

    if(!functions.isParseableJSON(pdb)){
      console.warn("Database: storePDB warning: pdb is no valid json");
      return false;
    }

    const code = this.getCode();

    if(code === undefined){
      console.warn("Database: storePDB warning: code is undefined");
      return false;
    }

    const changed = pdb !== JSON.stringify(this.getPDB());

    if(changed === true){
      this._PDB = JSON.parse(pdb);
      await Storage.storeData(code + "__PDB__", pdb);
    }

    return changed;

  }


  public async storeIdentifier(identifier: string): Promise<boolean> {

    const code = this.getCode();

    if(code === undefined){
      console.warn("Database: storeIdentifier warning: code is undefined");
      return false;
    }

    const changed = identifier !== this.getIdentifier();

    if(changed === true){
      this._identifier = identifier;
      await Storage.storeData(code + "__IDENTIFIER__", identifier);
    }

    return changed;

  }


  public async storeCode(code: string): Promise<boolean> {

    const previousCode = this.getCode();

    const changed = previousCode !== code;

    if(changed === true){
      this._code = code;
      await Storage.storeCode(code);
    }

    return changed;

  }


  public async storeMDB(mdb: string): Promise<boolean> {

    if(!functions.isParseableJSON(mdb)){
      return false;
    }

    const code = this.getCode();

    if(code === undefined){
      console.warn("Database: storeMDB warning: code is undefined");
      return false;
    }

    const changed = mdb !== JSON.stringify(this.getMDB());

    if(changed === true){
      this._MDB = JSON.parse(mdb);
      await Storage.storeData(code + "__MDB__", mdb);
    }

    return changed;

  }


  public async storeSettings(settings: string) {

    if(!functions.isParseableJSON(settings)){
      return;
    }

    const code = this.getCode();

    if(code === undefined){
      console.warn("Database: storeSettings warning: code is undefined");
      return;
    }

    const newSettings: types.ClientSettings = JSON.parse(settings);

    const changed = settings !== JSON.stringify(this._settings);

    if(changed === true){
      this._settings = newSettings;
      await Storage.storeData(code + "__ClientSettings__", settings);
    }

  }


  public getCommandByIdentifier(commandIdentifier: string, deviceIdentifier: string): Readonly<types.Command> | undefined {

    const pdb = this.getPDB();
    const mdb = this.getMDB();

    if(pdb === undefined){
      return;
    }

    for(let a = 0; a < pdb.automa.length; a++){
      deviceLoop: for(let d = 0; d < pdb.automa[a].devices.length; d++){

        if(pdb.automa[a].devices[d].identifier !== deviceIdentifier){
          continue deviceLoop;
        }

        for(let c = 0; c < pdb.automa[a].devices[d].overrides.commands.length; c++){
          if(commandIdentifier === pdb.automa[a].devices[d].overrides.commands[c].identifier){
            return pdb.automa[a].devices[d].overrides.commands[c];
          }
        }


        //-- Search in modules

        if(mdb === undefined){
          continue;
        }

        if(pdb.automa[a].devices[d].module !== undefined){

          for(let m = 0; m < mdb.length; m++){
            if(mdb[m].identifier === pdb.automa[a].devices[d].module && (pdb.automa[a].devices[d].version === undefined || semver.satisfies(mdb[m].version, pdb.automa[a].devices[d].version!))){
              for(let c = 0; c < mdb[m].commands.length; c++){
                if(mdb[m].commands[c].identifier === commandIdentifier){
                  return mdb[m].commands[c];
                }
              }
            }
          }
        }
      }
    }

    return;
  }


  public getAutomaByIdentifier(identifier: string): Readonly<types.Automa> | undefined {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    for(let a = 0; a < pdb.automa.length; a++){
      if(identifier === pdb.automa[a].identifier){
        return pdb.automa[a];
      }
    }

    return;

  }


  public getAutomaByDeviceIdentifier(identifier: string): Readonly<types.Automa> | undefined {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    for(let p = 0; p < pdb.automa.length; p++){
      for(let d = 0; d < pdb.automa[p].devices.length; d++){
        if(identifier === pdb.automa[p].devices[d].identifier){
          return pdb.automa[p];
        }
      }
    }

    return;

  }


  public getAutomaByFeedbackIdentifier(feedbackIdentifier: string, deviceIdentifier?: string): Readonly<types.Automa> | undefined {

    const pdb = this.getPDB();
    const mdb = this.getMDB();

    if(pdb === undefined){
      return;
    }

    for(let a = 0; a < pdb.automa.length; a++){

      for(let af = 0; af < pdb.automa[a].feedbacks.length; af++){
        if(pdb.automa[a].feedbacks[af].identifier === feedbackIdentifier){
          return pdb.automa[a];
        }
      }

      if(deviceIdentifier !== undefined){

        deviceLoop: for(let d = 0; d < pdb.automa[a].devices.length; d++){

          if(deviceIdentifier !== pdb.automa[a].devices[d].identifier){
            continue deviceLoop;
          }

          for(let f = 0; f < pdb.automa[a].devices[d].overrides.feedbacks.length; f++){
            if(feedbackIdentifier === pdb.automa[a].devices[d].overrides.feedbacks[f].identifier){
              return pdb.automa[a];
            }
          }


          //-- Search in modules

          if(mdb === undefined){
            continue;
          }

          if(pdb.automa[a].devices[d].module !== undefined){

            for(let m = 0; m < mdb.length; m++){
              if(mdb[m].identifier === pdb.automa[a].devices[d].module && (pdb.automa[a].devices[d].version === undefined || semver.satisfies(mdb[m].version, pdb.automa[a].devices[d].version!))){
                for(let f = 0; f < mdb[m].feedbacks.length; f++){
                  if(mdb[m].feedbacks[f].identifier === feedbackIdentifier){
                    return pdb.automa[a];
                  }
                }
              }
            }
          }
        }

      }

    }

    return;

  }


  public getModuleByIdentifier(identifier: string, version: string | undefined = "*"): Readonly<types.Module> | undefined {

    const mdb = this.getMDB();

    const availableVersions: Array<string> = [];

    if(mdb !== undefined){
      for(const mod of mdb){
        if(mod.identifier === identifier){
          availableVersions.push(mod.version);
        }
      }
    }

    const maxSatisfyingVersion = semver.maxSatisfying(availableVersions, version) ?? undefined;

    if(mdb !== undefined){
      for(const mod of mdb){

        if(mod.identifier !== identifier){
          continue;
        }

        if(mod.version === maxSatisfyingVersion){
          return mod;
        }

      }
    }

    return;

  }


  public getDeviceName(device: types.Device): string {

    if(device.overrides.name !== undefined){
      return device.overrides.name;
    }

    if(device.module !== undefined){

      const mod = this.getModuleByIdentifier(device.module, device.version);

      if(mod !== undefined){
        return mod.name;
      }

    }

    return "";

  }


  public isAutomaFeedback(feedbackIdentifier: string, deviceIdentifier?: string) {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    for(const automa of pdb.automa){
      for(const automaFeedback of automa.feedbacks){
        if(automaFeedback.identifier === feedbackIdentifier){
          return true;
        }
      }
    }

    return false;

  }


  public getDeviceFeedbackByName(feedbackName: string, deviceIdentifier: string): Readonly<types.Feedback> | undefined {

    const pdb = this.getPDB();
    const mdb = this.getMDB();

    if(pdb === undefined){
      return;
    }

    for(const automa of pdb.automa){

      for(const device of automa.devices){

        if(device.identifier !== deviceIdentifier){
          continue;
        }

        for(const feedback of device.overrides.feedbacks){
          if(feedback.name === feedbackName){
            return feedback;
          }
        }


        //-- Search in modules

        if(mdb === undefined){
          continue;
        }

        if(device.module !== undefined){

          for(let m = 0; m < mdb.length; m++){
            if(mdb[m].identifier === device.module && (device.version === undefined || semver.satisfies(mdb[m].version, device.version!))){
              for(let f = 0; f < mdb[m].feedbacks.length; f++){
                if(mdb[m].feedbacks[f].name === feedbackName){
                  return mdb[m].feedbacks[f];
                }
              }
            }
          }

        }

      }
    }

    return;

  }


  public getAutomaFeedbackByName(feedbackName: string, automaIdentifier: string): Readonly<types.Feedback> | undefined {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    for(const automa of pdb.automa){

      if(automa.identifier !== automaIdentifier){
        continue;
      }

      for(const automaFeedback of automa.feedbacks){
        if(automaFeedback.name === feedbackName){
          return automaFeedback;
        }
      }

    }

    return;

  }


  public getFeedbackByIdentifier(feedbackIdentifier: string, deviceIdentifier?: string): Readonly<types.Feedback> | undefined {

    const pdb = this.getPDB();
    const mdb = this.getMDB();

    if(pdb === undefined){
      return;
    }

    for(let a = 0; a < pdb.automa.length; a++){


      //-- Search automa feedbacks

      for(let f = 0; f < pdb.automa[a].feedbacks.length; f++){
        if(feedbackIdentifier === pdb.automa[a].feedbacks[f].identifier){
          return pdb.automa[a].feedbacks[f];
        }
      }

      if(deviceIdentifier !== undefined){


        //-- Search device feedbacks

        deviceLoop: for(let d = 0; d < pdb.automa[a].devices.length; d++){

          if(pdb.automa[a].devices[d].identifier !== deviceIdentifier){
            continue deviceLoop;
          }

          for(let f = 0; f < pdb.automa[a].devices[d].overrides.feedbacks.length; f++){
            if(feedbackIdentifier === pdb.automa[a].devices[d].overrides.feedbacks[f].identifier){
              return pdb.automa[a].devices[d].overrides.feedbacks[f];
            }
          }


          //-- Search in modules

          if(mdb === undefined){
            continue;
          }

          if(pdb.automa[a].devices[d].module !== undefined){

            for(let m = 0; m < mdb.length; m++){
              if(mdb[m].identifier === pdb.automa[a].devices[d].module && (pdb.automa[a].devices[d].version === undefined || semver.satisfies(mdb[m].version, pdb.automa[a].devices[d].version!))){
                for(let f = 0; f < mdb[m].feedbacks.length; f++){
                  if(mdb[m].feedbacks[f].identifier === feedbackIdentifier){
                    return mdb[m].feedbacks[f];
                  }
                }
              }
            }

          }

        }
      }

    }
    return;
  }


  public getPowerStatusFeedbackByDeviceIdentifier(deviceIdentifier: string): Readonly<string | false | types.PowerStatusFeedback | undefined> {

    const pdb = this.getPDB();
    const mdb = this.getMDB();

    if(pdb === undefined){
      return;
    }

    for(let a = 0; a < pdb.automa.length; a++){

      for(let d = 0; d < pdb.automa[a].devices.length; d++){

        if(pdb.automa[a].devices[d].identifier !== deviceIdentifier){
          continue;
        }

        if(pdb.automa[a].devices[d].overrides.powerStatusFeedback !== undefined){
          return pdb.automa[a].devices[d].overrides.powerStatusFeedback;
        }


        //-- Search in modules

        if(mdb === undefined){
          continue;
        }

        if(pdb.automa[a].devices[d].module !== undefined){

          for(let m = 0; m < mdb.length; m++){
            if(mdb[m].identifier === pdb.automa[a].devices[d].module && (pdb.automa[a].devices[d].version === undefined || semver.satisfies(mdb[m].version, pdb.automa[a].devices[d].version!))){
              if(mdb[m].powerStatusFeedback !== undefined){
                return mdb[m].powerStatusFeedback;
              }
            }
          }

        }
      }

    }

    return;

  }


  public getScriptEventByIdentifier(identifier: string): Readonly<types.Event> | undefined {

    const pdb = this.getPDB();
    const mdb = this.getMDB();

    if(pdb === undefined){
      return;
    }

    for(let a = 0; a < pdb.automa.length; a++){
      for(let d = 0; d < pdb.automa[a].devices.length; d++){

        for(let e = 0; e < pdb.automa[a].devices[d].overrides.events.length; e++){
          if(identifier === pdb.automa[a].devices[d].overrides.events[e].identifier){
            return pdb.automa[a].devices[d].overrides.events[e];
          }
        }


        //-- Search in modules

        if(mdb === undefined){
          continue;
        }

        if(pdb.automa[a].devices[d].module !== undefined){

          for(let m = 0; m < mdb.length; m++){
            if(mdb[m].identifier === pdb.automa[a].devices[d].module && (pdb.automa[a].devices[d].version === undefined || semver.satisfies(mdb[m].version, pdb.automa[a].devices[d].version!))){
              for(let e = 0; e < mdb[m].events.length; e++){
                if(mdb[m].events[e].identifier === identifier){
                  return mdb[m].events[e];
                }
              }
            }
          }
        }

      }
    }

    return;

  }


  public getDeviceByIdentifier(identifier: string): Readonly<types.Device> | undefined {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    for(let p = 0; p < pdb.automa.length; p++){
      for(let d = 0; d < pdb.automa[p].devices.length; d++){
        if(identifier === pdb.automa[p].devices[d].identifier){
          return pdb.automa[p].devices[d];
        }
      }
    }

    return;

  }


  public getDeviceEvents(deviceIdentifier: string): Readonly<Array<types.Event>> | undefined {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    const device = this.getDeviceByIdentifier(deviceIdentifier);

    if(device === undefined){
      return;
    }


    const events: Array<types.Event> = [];

    if(device.module !== undefined){
      const mod = this.getModuleByIdentifier(device.module, device.version);
      if(mod !== undefined){
        for(const event of mod.events){
          if(event.deleted !== true){
            events.push(event);
          }
        }
      }
    }


    //-- Override events

    eventLoop: for(const event of device.overrides.events){
      for(let m = events.length - 1; m >= 0; m--){
        if(events[m].identifier === event.identifier){
          if(event.deleted === true){
            events.splice(m, 1);
            continue eventLoop;
          } else {
            events[m] = event;
            continue eventLoop;
          }
        }
      }
      events.push(event);
    }

    return events;

  }


  public getRoomByIdentifier(identifier: string): Readonly<types.Room> | undefined {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    for(let r = 0; r < pdb.rooms.length; r++){
      if(identifier == pdb.rooms[r].identifier){
        return pdb.rooms[r];
      }
    }

    return;

  }


  public getPageByIdentifier(identifier: string): Readonly<types.Page> | undefined {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    for(let r = 0; r < pdb.rooms.length; r++){
      for(let p = 0; p < pdb.rooms[r].pages.length; p++){
        if(identifier === pdb.rooms[r].pages[p].identifier){
          return pdb.rooms[r].pages[p];
        }
      }
    }

    return;

  }


  public getFloorplanStoryByIdentifier(identifier: string): Readonly<types.FloorplanStory> | undefined {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    for(let r = 0; r < pdb.rooms.length; r++){
      pageLoop: for(let p = 0; p < pdb.rooms[r].pages.length; p++){

        if((pdb.rooms[r].pages[p] as types.FloorplanPage).stories === undefined){
          continue pageLoop;
        }

        const stories = (pdb.rooms[r].pages[p] as types.FloorplanPage).stories || [];

        for(let s = 0; s < stories.length; s++){
          if(stories[s].identifier === identifier){
            return stories[s];
          }
        }
      }
    }

    return;

  }


  public getPageByFloorplanStoryIdentifier(identifier: string): Readonly<types.Page> | undefined {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    for(let r = 0; r < pdb.rooms.length; r++){
      pageLoop: for(let p = 0; p < pdb.rooms[r].pages.length; p++){

        if((pdb.rooms[r].pages[p] as types.FloorplanPage).stories === undefined){
          continue pageLoop;
        }

        const stories = (pdb.rooms[r].pages[p] as types.FloorplanPage).stories || [];

        for(let s = 0; s < stories.length; s++){
          if(stories[s].identifier === identifier){
            return pdb.rooms[r].pages[p];
          }
        }
      }
    }

    return;

  }


  public getFloorplanStoryIndexByIdentifier(identifier: string): number | undefined {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    for(let r = 0; r < pdb.rooms.length; r++){
      pageLoop: for(let p = 0; p < pdb.rooms[r].pages.length; p++){

        if((pdb.rooms[r].pages[p] as types.FloorplanPage).stories === undefined){
          continue pageLoop;
        }

        const stories = (pdb.rooms[r].pages[p] as types.FloorplanPage).stories || [];

        for(let s = 0; s < stories.length; s++){
          if(stories[s].identifier === identifier){
            return s;
          }
        }
      }
    }

    return;

  }


  public getRoomByPageIdentifier(identifier: string): Readonly<types.Room> | undefined {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    for(let r = 0; r < pdb.rooms.length; r++){
      for(let p = 0; p < pdb.rooms[r].pages.length; p++){
        if(identifier === pdb.rooms[r].pages[p].identifier){
          return pdb.rooms[r];
        }
      }
    }

    return;

  }


  public getMacroByIdentifier(identifier: string): Readonly<types.Macro> | undefined {

    const pdb = this.getPDB();

    if(pdb === undefined){
      return;
    }

    for(let m = 0; m < pdb.macros.length; m++){
      if(identifier === pdb.macros[m].identifier){
        return pdb.macros[m];
      }
    }

    return;

  }


}

export const Database = new Database_();