import TinyEventEmitter from "../tiny-event-emitter";

export default class Websocket extends TinyEventEmitter {

  public ws: any | undefined;
  public platform: "web" | "node" | undefined;
  public url: string;

  constructor(url: string) {

    super();

    this.url = url;

    this.connect();

  }


  private _getWebsocketInterface(): Promise<any> {

    return new Promise(async(resolve, reject) => {

      //@ts-ignore
      if(globalThis.WebSocket !== null && globalThis.WebSocket !== undefined){
        this.platform = "web";
        //@ts-ignore
        resolve(globalThis.WebSocket);
      } else {
        this.platform = "node";
        const mod = await import("ws") as any;
        resolve(mod.default);
      }
      reject("No WebSocket module installed.");

    });

  }


  public async connect() {

    if(this.ws === undefined || this.ws.readyState === 3){

      const webSocketInterface = await this._getWebsocketInterface();

      this.ws = new webSocketInterface(this.url);


      //-- Initialize events

      this.ws.onopen = this._onopen.bind(this);
      this.ws.onclose = this._onclose.bind(this);
      this.ws.onerror = this._onerror.bind(this);
      this.ws.onmessage = this._onmessage.bind(this);

    }

  }


  public close() {
    this.ws.close();
  }


  public async send(data: string) {

    if(this.ws !== undefined && this.ws.readyState === 3){
      await this.connect();
    }

    if(this.ws === undefined){
      return;
    }

    this.ws.send(data);

  }


  private _onopen() {
    this.emit("open", null);
  }


  private _onclose() {
    this.emit("close", null);
  }


  private _onerror(err) {
    this.emit("error", err);
  }


  private _onmessage(data) {
    this.emit("message", data.data);
  }

}