import DimbarClassConstructor from "./f7.dimbar.class";

let Dimbar;
export default {
  name: "dimbar",

  install() {
    const Framework7 = this;
    Dimbar = DimbarClassConstructor(Framework7.Class);
    Framework7.Dimbar = Dimbar;
  },
  create: function(instance) {

    const app = this;
    const $ = app.$;

    app.dimbar = {
      create: function(params) {
        return new Dimbar(app, params);
      },
      get: function(el = ".dimbar") {
        if(el instanceof Dimbar){
          return el;
        }
        const $el = $(el);
        if($el.length === 0){
          return undefined;
        }
        return $el[0].f7Dimbar;
      },
      destroy: function(el = ".dimbar") {
        const instance = app.dimbar.get(el);
        if(instance && instance.destroy){
          return instance.destroy();
        }
        return undefined;
      }

    };

    return () => {};
  },
  on: {
    init() {

    },
    pageInit(page) {
      try {
        const $ = page.app.$;
        const app = page.app;

        page.$el.find(".dimbar-init").each((dimbarEl, index) => {
          const $dimbarEl = $(dimbarEl);
          const params = { dimbarEl: dimbarEl };
          app.dimbar.create(app.utils.extend(params, $dimbarEl.dataset()));
        });
      } catch (err){
        console.error("dimbar error: ", err);
      }
    }
  }
};
