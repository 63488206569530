import ColorTemperatureWheelClassConstructor from "./f7.color-temperature-wheel.class";

let ColorTemperatureWheel;
export default {
  name: "color-temperature-wheel",

  install() {
    const Framework7 = this;
    ColorTemperatureWheel = ColorTemperatureWheelClassConstructor(Framework7.Class);
    Framework7.ColorTemperatureWheel = ColorTemperatureWheel;
  },
  create: function(instance) {

    const app = this;
    const $ = app.$;

    app.ColorTemperatureWheel = {
      create: function(params) {
        return new ColorTemperatureWheel(app, params);
      },
      get: function(el = ".color-temperature-wheel") {
        if(el instanceof ColorTemperatureWheel){
          return el;
        }
        const $el = $(el);
        if($el.length === 0){
          return undefined;
        }
        return $el[0].f7ColorTemperatureWheel;
      },
      destroy: function(el = ".color-temperature-wheel") {
        const instance = app.ColorTemperatureWheel.get(el);
        if(instance && instance.destroy){
          return instance.destroy();
        }
        return undefined;
      }

    };

    return () => {};
  },
  on: {
    init() {

    },
    pageInit(page) {
      try {

        const $ = page.app.$;
        const app = page.app;

        page.$el.find(".color-temperature-wheel-init").each((colorTemperatureWheelEl, index) => {
          const $colorTemperatureWheelEl = $(colorTemperatureWheelEl);
          const params = { colorTemperatureWheelEl: colorTemperatureWheelEl };
          app.ColorTemperatureWheel.create(app.utils.extend(params, $colorTemperatureWheelEl.dataset()));
        });

      } catch (err){
        console.error("colorTemperatureWheel error: ", err);
      }
    }
  }
};
