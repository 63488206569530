import FullscreenModalClassConstructor from "./f7.fullscreen-modal.class";

let FullscreenModal;
export default {
  name: "fullscreenModal",

  install() {
    const Framework7 = this;
    FullscreenModal = FullscreenModalClassConstructor(Framework7.Class);
    Framework7.FullscreenModal = FullscreenModal;
  },
  create: function(instance) {

    const app = this;
    const $ = app.$;

    app.FullscreenModal = {
      create: function(params) {
        return new FullscreenModal(app, params);
      },
      get: function(el = ".fullscreen-modal") {
        if(el instanceof FullscreenModal){
          return el;
        }
        const $el = $(el);
        if($el.length === 0){
          return undefined;
        }
        return $el[0].f7FullscreenModal;
      },
      destroy: function(el = ".fullscreen-modal") {
        const instance = app.FullscreenModal.get(el);
        if(instance && instance.destroy){
          return instance.destroy();
        }
        return undefined;
      }

    };

    return () => {};
  },
  on: {
    init() {

    },
    pageInit(page) {
      try {

        const $ = page.app.$;
        const app = page.app;

        page.$el.find(".fullscreen-modal-init").each((fullscreenModalEl, index) => {
          const $fullscreenModalEl = $(fullscreenModalEl);
          const params = { fullscreenModalEl: fullscreenModalEl };
          app.FullscreenModal.create(app.utils.extend(params, $fullscreenModalEl.dataset()));
        });

      } catch (err){
        console.error("fullscreenModal error: ", err);
      }
    }
  }
};
