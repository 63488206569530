import NATIVE from "../native/native";

export function reload(force: boolean = false) {
  if(force === false){
    location.reload();
  } else {
    changeLocation();
  }
}

export function changeLocation(url?: string) {
  NATIVE.runCommand("clearCache");
  const newLocation = new URL(url ?? location.href);
  const params = newLocation.searchParams;
  params.set("r", Date.now() + "");
  location.href = newLocation.protocol + "//" + newLocation.host + newLocation.pathname + "?" + params.toString();
}
