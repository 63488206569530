/* eslint-disable @typescript-eslint/naming-convention */
export default [
  {
    "language": ["en"],
    "fallback": "en",
    "text": {
      "weekday-0-0": "Monday",
      "weekday-0-1": "Tuesday",
      "weekday-0-2": "Wednesday",
      "weekday-0-3": "Thursday",
      "weekday-0-4": "Friday",
      "weekday-0-5": "Saturday",
      "weekday-0-6": "Sunday",
      "weekday-1-0": "Sunday",
      "weekday-1-1": "Monday",
      "weekday-1-2": "Tuesday",
      "weekday-1-3": "Wednesday",
      "weekday-1-4": "Thursday",
      "weekday-1-5": "Friday",
      "weekday-1-6": "Saturday",
      "weekday-0-0-s": "Mon",
      "weekday-0-1-s": "Tue",
      "weekday-0-2-s": "Wed",
      "weekday-0-3-s": "Thu",
      "weekday-0-4-s": "Fri",
      "weekday-0-5-s": "Sat",
      "weekday-0-6-s": "Sun",
      "weekday-1-0-s": "Sun",
      "weekday-1-1-s": "Mon",
      "weekday-1-2-s": "Tue",
      "weekday-1-3-s": "Wed",
      "weekday-1-4-s": "Thu",
      "weekday-1-5-s": "Fri",
      "weekday-1-6-s": "Sat",
      "app-close": "Close",
      "app-ok": "OK",
      "app-save": "Save",
      "app-cancel": "Cancel",
      "app-back": "Back",
      "app-home": "Home",
      "app-on": "on",
      "app-off": "off",
      "sleeptimer-off": "off",
      "app-installed": "installed",
      "app-app": "App",
      "app-automa": "Controller",
      "app-search": "Search",
      "app-modules": "Modules",
      "app-floorplan-upload": "Upload floorplan image",
      "app-floorplan-layer-lamps": "Light",
      "app-floorplan-layer-windows": "Windows",
      "app-floorplan-layer-blinds": "Blinds",
      "app-floorplan-layer-thermostats": "Temperature",
      "dashboard-rooms": "Rooms",
      "dashboard-devices": "Devices",
      "dashboard-powermanagement": "Power control",
      "dashboard-roomoff": "Power off",
      "dashboard-pageoff": "Power off",
      "dashboard-device-power-off": "Power off",
      "dashboard-device-power-off-all": "Power off all",
      "dashboard-device-power-on": "Power on",
      "dashboard-device-power-toggle": "Power toggle",
      "dashboard-all-off": "ALL OFF",
      "dashboard-sleeptimer": "Sleeptimer",
      "template-remote-remote": "Remote",
      "template-remote-channels": "Channels",
      "login-screen-login-title": "Welcome to controlHome",
      "login-screen-code-description": "Enter your 6-digit download code to connect to your smart Home.",
      "login-screen-set-up": "Set up",
      "statusbar-connecting": "Connecting",
      "statusbar-connected": "Connected",
      "statusbar-not-connected-to-all": "Connection with controller Failed",
      "statusbar-not-connected-to-some": "Not connected to every controller",
      "statusbar-disconnected": "Disconnected",
      "statusbar-updating": "Updating controller",
      "settings-title": "Settings",
      "settings-installer": "Installer",
      "settings-developer": "Developer",
      "settings-about": "About",
      "settings-subscription": "Subscription",
      "settings-update": "Update",
      "settings-automation": "Automation",
      "settings-automations": "Automations",
      "settings-automation-time": "Time",
      "settings-automation-sunrise": "Sunrise",
      "settings-automation-sunset": "Sunset",
      "settings-automation-random": "Random",
      "settings-automation-coming-home": "Coming Home",
      "settings-automation-leaving-home": "Leaving Home",
      "settings-automation-time-from": "Between",
      "settings-automation-time-to": "and",
      "settings-automation-save": "Save",
      "settings-automation-add": "Add Automation",
      "settings-automation-edit": "Edit Automation",
      "settings-automation-event": "Event",
      "settings-automation-action": "Action",
      "settings-automation-delete": "Delete",
      "settings-automation-repeat": "Repeat",
      "settings-automation-random-probability": "Probability",
      "settings-delete": "Delete",
      "settings-automation-no-timer": "No Automations are set up, click on the plus sign to add one.",
      "settings-channel-icons-label": "Show channel icons",
      "settings-channel-numbers-label": "Show channel numbers",
      "settings-auto-code-detection": "Automatic code detection",
      "settings-code-history": "Code History",
      "settings-rename-codes": "Rename this project",
      "settings-connected-clients": "Connected devices",
      "settings-this-device": "this device",
      "settings-2fa-activated": "activated",
      "settings-2fa-not-activated": "2FA not allowed",
      "settings-last-seen": "Last seen",
      "settings-online-since": "Online since",
      "settings-2fa-label": "Enable two factor authentication",
      "settings-dark-theme-label": "Use dark theme",
      "settings-reset-label": "Delete user data",
      "settings-logout-label": "Logout",
      "settings-clients-activate": "Activate",
      "settings-clients-block": "Block",
      "settings-clients-signout": "Signout",
      "settings-clients-reboot": "Reboot",
      "settings-clients-manage-access": "Manage access for this device.",
      "settings-clients-manage-device": "Manage this device.",
      "settings-reset-confirm-text": "You are about to reset the app. Are you sure you want to delete the settings?",
      "two-factor-popup-title": "2FA confirmation",
      "two-factor-popup-heading": "signed in.",
      "two-factor-popup-text": "has successfully signed in. If you do not know this device, you can block access to your controlHome system for this device.",
      "two-factor-popup-allow": "Allow",
      "two-factor-popup-block": "Block",
      "two-factor-popup-blocked-title": "Device blocked.",
      "two-factor-popup-blocked-text": "Access to the controlHome system was blocked for this device. Only the owner can authenticate devices for control.",
      "two-factor-popup-not-activated-title": "Device not authenticated.",
      "two-factor-popup-not-activated-text": "Access to the controlHome system was not yet authenticated for this device. Only the owner can authenticate devices for control.",
      "update-installed-title": "App updated",
      "update-installed-version": "Installed",
      "update-unkown-version": "Unknown",
      "update-new-version": "New",
      "update-installed-changelog": "Changelog",
      "update-error": "Could not retrieve update.",
      "update-click-to-try-again": "Try again",
      "macro-page-on": "starting",
      "macro-room-off": "shutting down",
      "creator-homescreen-add-room": "Add Room",
      "creator-homescreen-start-by-adding-a-room": "The App looks empty right now. Start by adding a room."
    }
  }, {
    "language": ["de", "de-de", "de-ch"],
    "fallback": "en",
    "text": {
      "weekday-0-0": "Montag",
      "weekday-0-1": "Dienstag",
      "weekday-0-2": "Mittwoch",
      "weekday-0-3": "Donnerstag",
      "weekday-0-4": "Freitag",
      "weekday-0-5": "Samstag",
      "weekday-0-6": "Sonntag",
      "weekday-1-0": "Sonntag",
      "weekday-1-1": "Montag",
      "weekday-1-2": "Dienstag",
      "weekday-1-3": "Mittwoch",
      "weekday-1-4": "Donnerstag",
      "weekday-1-5": "Freitag",
      "weekday-1-6": "Samstag",
      "weekday-0-0-s": "Mo",
      "weekday-0-1-s": "Di",
      "weekday-0-2-s": "Mi",
      "weekday-0-3-s": "Do",
      "weekday-0-4-s": "Fr",
      "weekday-0-5-s": "Sa",
      "weekday-0-6-s": "So",
      "weekday-1-0-s": "So",
      "weekday-1-1-s": "Mo",
      "weekday-1-2-s": "Di",
      "weekday-1-3-s": "Mi",
      "weekday-1-4-s": "Do",
      "weekday-1-5-s": "Fr",
      "weekday-1-6-s": "Sa",
      "app-close": "Schliessen",
      "app-ok": "OK",
      "app-save": "Speichern",
      "app-cancel": "Abbrechen",
      "app-back": "Zurück",
      "app-home": "Home",
      "app-on": "einschalten",
      "app-off": "ausschalten",
      "sleeptimer-off": "deaktiviert",
      "app-installed": "installiert",
      "app-app": "App",
      "app-automa": "Controller",
      "app-search": "Suchen",
      "app-modules": "Module",
      "app-floorplan-upload": "Grundriss hochladen",
      "app-floorplan-layer-lamps": "Licht",
      "app-floorplan-layer-windows": "Fenster",
      "app-floorplan-layer-blinds": "Storen",
      "app-floorplan-layer-thermostats": "Temperatur",
      "dashboard-rooms": "Räume",
      "dashboard-devices": "Geräte",
      "dashboard-powermanagement": "Standbysteuerung",
      "dashboard-roomoff": "Ausschalten",
      "dashboard-pageoff": "Ausschalten",
      "dashboard-device-power-off": "Ausschalten",
      "dashboard-device-power-off-all": "Alle ausschalten",
      "dashboard-device-power-on": "Einschalten",
      "dashboard-device-power-toggle": "Umschalten",
      "dashboard-all-off": "Alles ausschalten",
      "dashboard-sleeptimer": "Sleep Timer",
      "template-remote-remote": "Fernbedienung",
      "template-remote-channels": "Sender",
      "login-screen-login-title": "Anmeldung",
      "login-screen-code-description": "Geben Sie Ihren 6-Stelligen Code ein um sich mit Ihrem smart Home zu verbinden.",
      "login-screen-set-up": "Los",
      "statusbar-connecting": "Verbinden",
      "statusbar-connected": "Verbunden",
      "statusbar-not-connected-to-all": "Verbindung mit Controllern fehlgeschlagen",
      "statusbar-not-connected-to-some": "Es sind nicht alle Controller verbunden",
      "statusbar-disconnected": "Keine Verbindung",
      "statusbar-updating": "Controller wird aktualisiert",
      "settings-title": "Einstellungen",
      "settings-installer": "Installiert von",
      "settings-developer": "Entwickelt von",
      "settings-about": "Informationen",
      "settings-subscription": "Abonnement",
      "settings-update": "Update",
      "settings-automation": "Automatisierung",
      "settings-automations": "Automatisierungen",
      "settings-automation-time": "Zeit",
      "settings-automation-sunrise": "Sonnenaufgang",
      "settings-automation-sunset": "Sonnenuntergang",
      "settings-automation-random": "Zufall",
      "settings-automation-coming-home": "Coming Home",
      "settings-automation-leaving-home": "Leaving Home",
      "settings-automation-time-from": "Zwischen",
      "settings-automation-time-to": "und",
      "settings-automation-save": "Sichern",
      "settings-automation-add": "Automatisierung erstellen",
      "settings-automation-edit": "Automatisierung bearbeiten",
      "settings-automation-event": "Auslöser",
      "settings-automation-action": "Aktion",
      "settings-automation-delete": "Löschen",
      "settings-automation-repeat": "Wiederholen",
      "settings-automation-random-probability": "Häufigkeit",
      "settings-delete": "Löschen",
      "settings-automation-no-timer": "Keine Automatisierungen eingerichtet, Klicke auf das Plus Symbol um einen Automatisierung zu erstellen.",
      "settings-channel-icons-label": "Senderlogos anzeigen",
      "settings-channel-numbers-label": "Sendernummern anzeigen",
      "settings-auto-code-detection": "Automatische Code erkennung",
      "settings-code-history": "Code Verlauf",
      "settings-rename-codes": "Projekt umbenennen",
      "settings-connected-clients": "Verbundene Geräte",
      "settings-this-device": "dieses Gerät",
      "settings-2fa-activated": "aktiviert",
      "settings-2fa-not-activated": "2FA nicht erlaubt",
      "settings-last-seen": "Zuletzt online",
      "settings-online-since": "Online seit",
      "settings-2fa-label": "Zweifaktor Authentifizierung aktivieren",
      "settings-dark-theme-label": "Dunkles Design verwenden",
      "settings-reset-label": "Benutzerdaten löschen",
      "settings-logout-label": "Abmelden",
      "settings-clients-activate": "Aktivieren",
      "settings-clients-block": "Blockieren",
      "settings-clients-signout": "Abmelden",
      "settings-clients-reboot": "Neustarten",
      "settings-clients-manage-access": "Zugriff für dieses Gerät verwalten.",
      "settings-clients-manage-device": "Dieses Gerät verwalten.",
      "settings-reset-confirm-text": "Du bist dabei die App zurückzusetzen. Bist du sicher dass du die Einstellungen löschen möchtest?",
      "two-factor-popup-title": "2FA Bestätigung",
      "two-factor-popup-heading": "angemeldet.",
      "two-factor-popup-text": "hat sich erfolgreich angemeldet. Wenn Sie dieses Gerät nicht kennen, können Sie den Zugriff auf Ihr controlHome System für dieses Gerät blockieren.",
      "two-factor-popup-allow": "Erlauben",
      "two-factor-popup-block": "Blockieren",
      "two-factor-popup-blocked-title": "Gerät blockiert.",
      "two-factor-popup-blocked-text": "Der Zugriff auf das controlHome System wurde für dieses Gerät blockiert. Nur der Besitzer kann Geräte zur Steuerung freigeben.",
      "two-factor-popup-not-activated-title": "Gerät nicht authentifiziert.",
      "two-factor-popup-not-activated-text": "Der Zugriff auf das controlHome System wurde für dieses Gerät noch nicht authentifiziert. Nur der Besitzer kann Geräte zur Steuerung freigeben.",
      "update-installed-title": "App aktualisiert",
      "update-installed-version": "Installiert",
      "update-unkown-version": "Unbekannt",
      "update-new-version": "Neu",
      "update-installed-changelog": "Änderungen",
      "update-error": "Update konnte nicht abgerufen werden",
      "update-click-to-try-again": "Erneut versuchen",
      "macro-page-on": "wird gestartet",
      "macro-room-off": "wird ausgeschalten",
      "creator-homescreen-add-room": "Raum hinzufügen",
      "creator-homescreen-start-by-adding-a-room": "Die App sieht noch ziemlich leer aus. Beginne indem du einen Raum hinzufügst."
    }
  }, {
    "language": ["gsw", "ch"],
    "fallback": "de",
    "text": {
      "weekday-0-0": "Määntig",
      "weekday-0-1": "Dsischtig",
      "weekday-0-2": "Mittwuch",
      "weekday-0-3": "Donnschtig",
      "weekday-0-4": "Friitig",
      "weekday-0-5": "Samschtig",
      "weekday-0-6": "Sunntig",
      "weekday-1-0": "Sunntig",
      "weekday-1-1": "Määntig",
      "weekday-1-2": "Dsischtig",
      "weekday-1-3": "Mittwuch",
      "weekday-1-4": "Donnschtig",
      "weekday-1-5": "Friitig",
      "weekday-1-6": "Samschtig",
      "weekday-0-0-s": "Mä",
      "weekday-0-1-s": "Zi",
      "weekday-0-2-s": "Mi",
      "weekday-0-3-s": "Do",
      "weekday-0-4-s": "Fr",
      "weekday-0-5-s": "Sa",
      "weekday-0-6-s": "So",
      "weekday-1-0-s": "So",
      "weekday-1-1-s": "Mä",
      "weekday-1-2-s": "Zi",
      "weekday-1-3-s": "Mi",
      "weekday-1-4-s": "Do",
      "weekday-1-5-s": "Fr",
      "weekday-1-6-s": "Sa",
      "app-close": "Schlüsse",
      "app-ok": "Okay",
      "app-save": "Speichere",
      "app-cancel": "Abbreche",
      "app-back": "Zrug",
      "app-home": "Home",
      "app-on": "ischalte",
      "app-off": "usschalte",
      "sleeptimer-off": "deaktiviert",
      "app-installed": "installiert",
      "app-app": "App",
      "app-automa": "Controller",
      "app-search": "Sueche",
      "app-modules": "Modul",
      "app-floorplan-upload": "Grundriss ufelade",
      "app-floorplan-layer-lamps": "Liecht",
      "app-floorplan-layer-windows": "Fenster",
      "app-floorplan-layer-blinds": "Store",
      "app-floorplan-layer-thermostats": "Temperature",
      "dashboard-rooms": "Rüüm",
      "dashboard-devices": "Grät",
      "dashboard-powermanagement": "Standbystüürig",
      "dashboard-roomoff": "Usschalte",
      "dashboard-pageoff": "Usschalte",
      "dashboard-device-power-off": "Usschalte",
      "dashboard-device-power-off-all": "Alli usschalte",
      "dashboard-device-power-on": "Ihschalte",
      "dashboard-device-power-toggle": "Umschalte",
      "dashboard-all-off": "Alles usschalte",
      "dashboard-sleeptimer": "Sleep Timer",
      "template-remote-remote": "Fernbedienig",
      "template-remote-channels": "Sender",
      "login-screen-login-title": "Ahmeldig",
      "login-screen-code-description": "Gib din 6-Stellig Code ih, zum dich mit Dim smart Home z verbinde.",
      "login-screen-set-up": "Abelade",
      "statusbar-connecting": "Verbinde",
      "statusbar-connected": "Verbunde",
      "statusbar-not-connected-to-all": "Verbindig mit de Controller fehlgschlage",
      "statusbar-not-connected-to-some": "Es sind nöd alli Controller verbunde",
      "statusbar-disconnected": "Verbindig trennt",
      "statusbar-updating": "Controller wird aktualisiert",
      "settings-title": "Istellige",
      "settings-installer": "Installiert vo",
      "settings-developer": "Entwicklet vo",
      "settings-about": "Informatione",
      "settings-subscription": "Abo",
      "settings-automation": "Automatisierig",
      "settings-automations": "Automatisierige",
      "settings-automation-time": "Ziit",
      "settings-automation-sunrise": "Sunneufgang",
      "settings-automation-sunset": "Sunneuntergang",
      "settings-automation-random": "Zuefall",
      "settings-automation-coming-home": "Coming Home",
      "settings-automation-leaving-home": "Leaving Home",
      "settings-automation-time-from": "Zwüsche",
      "settings-automation-time-to": "und",
      "settings-automation-save": "Sichere",
      "settings-automation-add": "Automatisierig erstelle",
      "settings-automation-edit": "Automatisierig bearbeite",
      "settings-automation-event": "Uslöser",
      "settings-automation-action": "Aktion",
      "settings-automation-delete": "Lösche",
      "settings-automation-repeat": "Wiederhole",
      "settings-automation-no-timer": "Kei Automatisierige iihgrichtet, klick ufs Plus Symbol zum en Automatisierig erstelle.",
      "settings-automation-random-probability": "Hüüfigkeit",
      "settings-delete": "Lösche",
      "settings-channel-icons-label": "Sänderlogos ahzeige",
      "settings-channel-numbers-label": "Sändernummere ahzeige",
      "settings-auto-code-detection": "Automatischi Code erkennig",
      "settings-code-history": "Verlauf",
      "settings-rename-codes": "Projekt umbenänne",
      "settings-connected-clients": "Verbundeni Grät",
      "settings-this-device": "das Grät",
      "settings-2fa-activated": "aktiviert",
      "settings-2fa-not-activated": "2FA nöd erlaubt",
      "settings-last-seen": "Zletscht online",
      "settings-online-since": "Online sit",
      "settings-2fa-label": "Zweifaktor Authentifizierig aktiviere",
      "settings-dark-theme-label": "Dunkels Design verwende",
      "settings-reset-label": "Benutzerdate lösche",
      "settings-logout-label": "Abmelde",
      "settings-clients-activate": "Aktiviere",
      "settings-clients-block": "Blockiere",
      "settings-clients-signout": "Abmelde",
      "settings-clients-reboot": "Neustarte",
      "settings-clients-manage-access": "Zuegriff für das Grät verwalte.",
      "settings-clients-manage-device": "Das Grät verwalte.",
      "settings-reset-confirm-text": "Du bisch grad d App am zrugsetze. Bisch sicher dass alli Ihstellige wetsch lösche?",
      "two-factor-popup-title": "2FA Bestätigung",
      "two-factor-popup-heading": "ahgmeldet.",
      "two-factor-popup-text": "het sich erfolgriich ahgmeldet. Wenn du das Grät nöd kennsch, chasch de Zuegriff uf dis controlHome System für das Grät blockiere.",
      "two-factor-popup-allow": "Erlaube",
      "two-factor-popup-block": "Blockiere",
      "two-factor-popup-blocked-title": "Grät blockiert.",
      "two-factor-popup-blocked-text": "De Zuegriff auf das controlHome System isch für das Grät blockiert worde. Nur de Bsitzer cha Grät zur Stüürig freigeh.",
      "two-factor-popup-not-activated-title": "Grät no nöd authentifiziert.",
      "two-factor-popup-not-activated-text": "De Zuegriff auf das controlHome System isch für das Grät no nöd authentifiziert worde. Nur de Bsitzer cha Grät zur Stüürig freigeh.",
      "update-installed-title": "App aktualisiert",
      "update-installed-version": "Installiert",
      "update-unkown-version": "Unbekannt",
      "update-new-version": "Neu",
      "update-installed-changelog": "Änderige",
      "update-error": "Aktualisierige hend nöd chönne abgrüeft werde.",
      "update-click-to-try-again": "Nomal probiere",
      "macro-page-on": "wird gstartet",
      "macro-room-off": "wird usgschalte"
    }
  }
];