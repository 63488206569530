import { CH_API } from "../../js/modules/ch-api";
import { types } from "../interfaces/types";
import { Database } from "../modules/database";

export function checkCondition(condition: types.MacroCommand | types.CustomEvent | types.PowerStatusFeedback, feedbackValue?: string | number | boolean | Array<types.Object>): boolean | undefined {


  //-- Check condition

  if(condition.parameters === undefined || condition.parameters.length < 1){
    return;
  }

  const parameters = condition.parameters as [types.MacroCommandIfStatementParameter, ...types.MacroAndButtonCommandOrFeedbackParameter[]];

  const feedbackIdentifier = parameters[0].template;
  const deviceIdentifier = parameters[0].device;

  if(feedbackIdentifier === undefined){
    return;
  }

  const feedback = Database.getFeedbackByIdentifier(feedbackIdentifier, deviceIdentifier);
  const automa = Database.getAutomaByFeedbackIdentifier(feedbackIdentifier, deviceIdentifier);


  //-- Set feedback value from event parameters

  if(feedback !== undefined && feedbackValue === undefined){

    if(automa === undefined){
      return;
    }


    //-- Set feedback value from actual feedback

    let feedbackParameters: types.Object | undefined;

    if(condition.parameters.length > 1){
      feedbackParameters = {};
    }


    //-- Reconstruct parameters

    if(feedback.parameters !== undefined && feedbackParameters !== undefined){
      for(const feedbackParameter of feedback.parameters){
        for(const macroParameter of condition.parameters){
          if(feedbackParameter.identifier === macroParameter.template){
            feedbackParameters[feedbackParameter.name] = macroParameter.value;
          }
        }
      }
    }

    if(deviceIdentifier !== undefined){
      feedbackValue = CH_API.getFeedback(feedback.name, automa.identifier, deviceIdentifier, feedbackParameters);
    } else {
      feedbackValue = CH_API.getFeedback(feedback.name, automa.identifier);
    }

  }

  if(feedbackValue === undefined){
    return;
  }

  if(parameters[0].operator === undefined){
    return;
  }

  if(parameters[0].value === undefined){
    return;
  }

  let parameterValue = parameters[0].value;

  if(typeof parameterValue === "string"){
    if(parameterValue === "true"){
      parameterValue = true;
    }
    if(parameterValue === "false"){
      parameterValue = false;
    }
  }


  //-- Check condition

  if(parameters[0].operator === "<"){
    if(!(feedbackValue < parameterValue)){
      return false;
    }
  }
  if(parameters[0].operator === "<="){
    if(!(feedbackValue <= parameterValue)){
      return false;
    }
  }
  if(parameters[0].operator === "=="){
    if(!(feedbackValue == parameterValue)){
      return false;
    }
  }
  if(parameters[0].operator === "!="){
    if(!(feedbackValue != parameterValue)){
      return false;
    }
  }
  if(parameters[0].operator === ">"){
    if(!(feedbackValue > parameterValue)){
      return false;
    }
  }
  if(parameters[0].operator === ">="){
    if(!(feedbackValue >= parameterValue)){
      return false;
    }
  }

  return true;

}