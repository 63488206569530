import Framework7 from "framework7";

import Dom7 from "../../../shared/modules/dom7";
import { Dom7Array } from "dom7";
import { pointerdown, pointermove, pointerup } from "../../../js/modules/event-names";

const $$ = Dom7;
export default function(Framework7Class) {
  return class Dimable extends Framework7Class {

    public percentage: number | undefined;
    public min: number;
    public max: number;
    public releaseOnly: boolean;
    public stopOnly: boolean;
    public frequency: number;
    public $el: Dom7Array;

    private _pointerIsDown: boolean = false;
    private _startX: number = 0;
    private _startY: number = 0;
    private _startValue: number | undefined;
    private _app: Framework7;
    private _stopTimeout: any;
    private _delayTimeout: any;
    private _blocked: boolean = false;
    private _valueHasChangedFromStartValue: boolean = false;

    private _pointerDownEvent: (event) => void;


    constructor(app: Framework7, params) {

      super(params, [app]);

      this.releaseOnly = params.releaseOnly ?? params.releaseonly ?? false;
      this.stopOnly = params.stopOnly ?? params.stoponly ?? false;
      this.frequency = params.frequency ?? 0;
      this.max = params.max ?? 100;
      this.min = params.min ?? 0;
      this.$el = $$(params.dimableEl);
      this._startValue = +this.$el.attr("value") ?? 0;
      this.percentage = this._mapMinMaxValueToPercentage(this._startValue);
      this._app = app;
      this._range = this.max - this.min;

      this._pointerDownEvent = this._pointerDown.bind(this);
      this._pointerMoveEvent = this._pointerMove.bind(this);
      this._pointerUpEvent = this._pointerUp.bind(this);

      //@ts-expect-error
      this.$el[0].dimable = this;
      //@ts-expect-error
      this.$el[0].f7Dimable = this;

      this._updateSliderPosition(this.percentage);


      //-- Add event listeners

      this.$el.on(pointerdown, this._pointerDownEvent);
      this.$el.on(pointermove, this._pointerMoveEvent);
      this.$el.on(pointerup, this._pointerUpEvent);

    }


    private _pointerDown(ev): void {

      ev.stopPropagation();

      if(ev.touches !== undefined){

        if(ev.touches.length > 1){
          return;
        }

        this._pointerIsDown = true;

      } else {
        if(ev.which === 1){
          this._pointerIsDown = true;
        }
      }

      if(ev.touches !== undefined){
        this._startX = ev.touches[0].screenX;
        this._startY = ev.touches[0].screenY;
      } else {
        this._startX = ev.pageX;
        this._startY = ev.pageY;
      }

      this._startValue = +this.$el.attr("value");
      this._valueHasChangedFromStartValue = false;

    }


    private _pointerMove(ev): void {

      ev.stopPropagation();

      if(this._pointerIsDown !== true){
        return;
      }

      if(this._animationFrameAwaiting === true){
        return;
      }

      this._animationFrameAwaiting = true;

      window.requestAnimationFrame(() => {

        this._animationFrameAwaiting = false;

        let currentX = 0;
        let currentY = 0;

        if(ev.touches !== undefined){
          currentX = ev.touches[0].screenX;
          currentY = ev.touches[0].screenY;
        } else {
          currentX = ev.pageX;
          currentY = ev.pageY;
        }

        if(this.$el !== undefined && this._startValue !== undefined){

          let percent = this._mapMinMaxValueToPercentage(this._startValue) + (100 / this.$el.width() * (currentX - this._startX));

          percent = (percent > 100 ? 100 : percent && percent < 0 ? 0 : percent);


          //-- Only emit change event when value has changed

          if(this.percentage === percent){
            return;
          }

          this.percentage = percent;

          this._disableAnimations();

          this._updateSliderPosition(percent);


          //-- Only emit change event when value has changed from startValue

          if(this._startValue === this._mapPercentageToMinMax(this.percentage)){
            return;
          }

          this._valueHasChangedFromStartValue = true;

          if(this.releaseOnly === false && this.stopOnly === false){
            if(this.frequency > 0){

              if(this._blocked === true){
                return;
              }

              this._blocked = true;

              setTimeout(() => {
                this._blocked = false;
              }, 1000 / this.frequency);
            }

            this.emit("local::change dimableChange", this._mapPercentageToMinMax(this.percentage));
            this.$el.trigger("change dimableChange", this._mapPercentageToMinMax(this.percentage));

          }

          if(this.stopOnly === true && this.releaseOnly === false){

            if(this._stopTimeout !== undefined){
              clearTimeout(this._stopTimeout);
            }

            this._stopTimeout = setTimeout(() => {

              if(this.percentage === undefined){
                return;
              }

              this.emit("local::change dimableChange", this._mapPercentageToMinMax(this.percentage));
              this.$el.trigger("change dimableChange", this._mapPercentageToMinMax(this.percentage));

            }, 100);

          }
        }

      });

    }


    private _pointerUp(ev): void {

      if(this._delayTimeout !== undefined){
        clearTimeout(this._delayTimeout);
      }

      if(this._pointerIsDown === false){
        return;
      }

      this._pointerIsDown = false;

      if(this.percentage === undefined){
        return;
      }

      if(this._valueHasChangedFromStartValue === true){
        this.emit("local::change dimableChange", this._mapPercentageToMinMax(this.percentage));
        this.$el.trigger("change dimableChange", this._mapPercentageToMinMax(this.percentage));
      }

    }


    private _enableAnimations(): void {
      this.$el.addClass("animated");
    }


    private _disableAnimations(): void {
      this.$el.removeClass("animated");
    }


    public setValue(value: number) {

      if(this._pointerIsDown === true){
        return;
      }

      const startValue = +this.$el.attr("value");
      const endValue = value;

      if(this.$el !== undefined){
        this.$el.attr("value", value + "");
      }

      this._enableAnimations();
      this._updateSliderPosition(this._mapMinMaxValueToPercentage(value));

    }


    private _updateSliderPosition(percent: number): void {

      percent = Math.round(percent);

      this.$el.css("background-position", 100 - percent + "%");
      // this.$el.css("background", `linear-gradient(to right, rgba(var(--dimable-slider-color), 1) 0%, rgba(var(--dimable-slider-color), 1) ${percent}%, rgba(var(--dimable-slider-color), 0) ${percent}%, rgba(var(--dimable-slider-color), 0) 100%)`);
      this.percentage = percent;
      this.$el.attr("value", this._mapPercentageToMinMax(this.percentage) + "");

    }


    private _mapPercentageToMinMax(value: number): number {
      return Math.round(value / 100 * (this.max - this.min));
    }


    private _mapMinMaxValueToPercentage(value: number): number {
      return Math.round(value / (this.max - this.min) * 100);
    }


    public destroy(): void {


      //-- Remove events

      this.$el.off(pointerdown, this._pointerDownEvent);
      this.$el.off(pointermove, this._pointerMoveEvent);
      this.$el.off(pointerup, this._pointerUpEvent);

      if(this.tapHold === true){
        if(Framework7.support.touch){
          this.$el.off("taphold", this._pointerDownEvent);
        }
      }

    }

  };
}
