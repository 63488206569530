import Dom7 from "../../../shared/modules/dom7";
import { Dom7Array } from "dom7";

import TinyEventEmitter from "../../../shared/modules/tiny-event-emitter";

const $$ = Dom7;

import "./style.css";

export default class VolumeSlider extends TinyEventEmitter {

  public selector: string;

  public isHolding: boolean;
  public lastValue: number;

  private _startX: number;
  private _startY: number;

  private _startValue: number;

  private _lastX: number;
  private _lastY: number;

  private _currentX: number;
  private _currentY: number;

  private _currentSlider: Dom7Array | undefined;

  private _fadeAnimationTimeout: any;

  private _supressionTimeout: any;
  private _stopTimeout: any;


  constructor(selector: string) {

    super();

    this.selector = selector;

    this._startX = 0;
    this._startY = 0;

    this._startValue = 0;

    this._lastX = 0;
    this._lastY = 0;
    this.lastValue = 0;

    this._currentX = 0;
    this._currentY = 0;

    this.isHolding = false;


    //-- Initialize events

    $$(document).on("mousedown touchstart", selector, this._startEvent.bind(this));
    $$(document).on("mousemove touchmove", this._moveEvent.bind(this));
    $$(document).on("mouseup touchend", this._endEvent.bind(this));

    $$(document).on("mouseleave", this._endEvent.bind(this));

  }


  private _startEvent(ev: any): void {

    ev.preventDefault();


    if(ev.button !== undefined){
      if(ev.button !== 0){
        return;
      }
    }

    const target = ev.target;


    //-- Set touch positions

    this._startX = (ev.touches !== undefined ? ev.touches[0].screenX : ev.pageX);
    this._startY = (ev.touches !== undefined ? ev.touches[0].screenY : ev.pageY);

    this._currentX = this._startX;
    this._currentY = this._startY;

    this.isHolding = true;


    //-- Get slider

    if($$(target).hasClass("volume-slider")){
      this._currentSlider = $$(target).find("input.slider[type='range']");
    } else if($$(target).hasClass("slider")){
      this._currentSlider = $$(target);
    } else {
      this._currentSlider = $$(target).parents(".volume-slider").find("input.slider[type='range']");
    }

    this._startValue = parseInt(this._currentSlider.val(), 10);
    this.lastValue = this._startValue;


    //-- Show slider

    this._currentSlider.removeClass("fade-out");
    this._currentSlider.show();
    this.emit("show");

  }


  private _moveEvent(ev: any): void {

    if(this.isHolding === false){
      return;
    }

    ev.preventDefault();

    this._currentX = (ev.touches !== undefined ? ev.touches[0].screenX : ev.pageX);
    this._currentY = (ev.touches !== undefined ? ev.touches[0].screenY : ev.pageY);

    let percentMoved = 0;
    let baseDistance = 80;
    let sliderValue = this._startValue + percentMoved;

    if(this._currentSlider !== undefined){
      baseDistance = this._currentSlider.width();
    }

    let fullDistance = baseDistance;


    //-- Calculate required distance when move down

    if(this._currentY > this._startY){

      const newDistance = this._currentY - this._startY;
      if(newDistance > 1){
        fullDistance = newDistance + baseDistance;
      }

    }


    //-- Calculate value

    if(this._currentX > this._startX){

      percentMoved = (((this._currentX - this._startX) / fullDistance) * 100);
      sliderValue = this._startValue + percentMoved;

      if(sliderValue >= 100){
        this._startX = this._currentX;
        sliderValue = 100;
        this._startValue = sliderValue;
      }

    } else {

      percentMoved = (((this._startX - this._currentX) / fullDistance) * 100);
      sliderValue = this._startValue - percentMoved;

      if(sliderValue <= 0){
        this._startX = this._currentX;
        sliderValue = 0;
        this._startValue = sliderValue;
      }

    }

    sliderValue = Math.round(sliderValue);


    //-- Clear animation timeout

    if(this._fadeAnimationTimeout !== undefined){
      clearTimeout(this._fadeAnimationTimeout);
      this._fadeAnimationTimeout = undefined;
    }


    if(this.lastValue === sliderValue){
      return;
    }


    //-- Update slider position

    this.setValue(sliderValue, true);


    //-- Start animation

    if(this._currentSlider === undefined){
      return;
    }

    this._currentSlider.removeClass("fade-out");


    //-- Limit amount of commands

    if(this._supressionTimeout === undefined){

      this._supressionTimeout = setTimeout(() => {
        this._supressionTimeout = undefined;
      }, 100);

      this.emit("change", sliderValue);

    }


    //-- stop Timeout

    if(this._stopTimeout !== undefined){
      clearTimeout(this._stopTimeout);
      this._stopTimeout = undefined;
    }

    this._stopTimeout = setTimeout(() => {

      this.emit("change", sliderValue);

    }, 200);



  }


  private _endEvent(ev: any): void {

    this.isHolding = false;

    if(this._currentSlider === undefined){
      return;
    }

    this.emit("release", this.lastValue);


    if(this._currentSlider.parents(".auto-hide").length === 0){
      return;
    }


    //-- Set animation timeout

    if(this._fadeAnimationTimeout === undefined){
      this._fadeAnimationTimeout = setTimeout(() => {

        if(this._currentSlider === undefined){
          return;
        }

        this._currentSlider.addClass("fade-out");

        setTimeout(() => {

          if(this._currentSlider === undefined){
            return;
          }

          this._currentSlider.hide();

          this.emit("hide");

        }, 300);

      }, 5000);
    }

  }


  public setValue(value: number, force = false) {

    if(this.isHolding === true && force === false){
      return;
    }

    $$(this.selector + " input.slider[type='range']").val(value);
    $$(this.selector + " input.slider[type='range']").css("background", "linear-gradient(to right,var(--f7-theme-color), var(--f7-theme-color) " + value + "%, var(--ch-gray-color-7) " + value + "%, var(--ch-gray-color-7)");
    this.lastValue = value;

  }

}