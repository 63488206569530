
export function getUserScriptByDeviceIdentifier(identifier: string) {

  for(let u = 0; u < globalThis.USERSCRIPTS.length; u++){
    if(globalThis.USERSCRIPTS[u].identifier === identifier){
      return globalThis.USERSCRIPTS[u];
    }
  }

  return;

}


export function getCSSVariable(variable: string, element: Element = document.documentElement): string {
  variable = variable.startsWith("--") ? variable : "--" + variable;
  return window.getComputedStyle(element).getPropertyValue(variable).trim();
}