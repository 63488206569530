import Framework7 from "framework7";
import { isParseableJSON, mapNumber } from "../../../shared/functions/functions";
import FullscreenModal from "../fullscreen-modal/f7.fullscreen-modal.class";
import "./f7.color-temperature-wheel.css";

export default function(Framework7Class) {
  return class ColorTemperatureWheel extends FullscreenModal(Framework7Class) {

    private params;

    public min: number = 0;
    public radius: number = 100;
    public releaseOnly: boolean = false;
    public stopOnly: boolean = true;
    public value: { r: number, g: number, b: number } = { r: 223, g: 249, b: 253 };

    public colors: Array<string> = ["ff8c00", "fffafe"];

    private _stopTimeout: any;


    constructor(app: Framework7, params) {

      super(app, params);

      this.params = params;


      //-- Set parameters

      this.radius = this.params.radius || this.radius;
      this.releaseOnly = this.params.releaseOnly || this.params.releaseonly || this.releaseOnly;
      this.stopOnly = this.params.stopOnly || this.params.stoponly || this.stopOnly;
      this.colors = this.params.colors || this.colors;


      //-- Set initial value

      let value = this.params.value || this.value;

      if(typeof value === "string"){
        value = decodeURIComponent(value);
        if(isParseableJSON(value)){
          value = JSON.parse(value);
        }
      }

      this.value = value;


      //-- Set radius

      const phoneRadius = this.params.phoneRadius || this.params.phoneradius || this.radius;

      if(app.device.ios === true && app.device.iphone === true){
        this.radius = phoneRadius;
      }
      if(app.width < 768 && (app.device.desktop !== true && app.theme !== "aurora")){
        this.radius = phoneRadius;
      }


      this.init();


      if(this.$contentEl === undefined){
        return;
      }

      //@ts-expect-error
      this.$contentEl[0].f7ColorTemperatureWheel = this;

    }


    public pointerDown(ev): void {

    }


    public pointerUp(ev): void {
      if(this.releaseOnly === true){
        this.emit("local::change colorTemperatureWheelChange", this.value);

        if(this.$contentEl === undefined){
          return;
        }

        this.$contentEl.trigger("change colorTemperatureWheelChange", this.value);

      }
    }


    public pointerMove(ev): void {

      if(this.$contentEl === undefined){
        return;
      }

      const svg = this.$contentEl.find(".color-temperature-wheel-svg-container");

      if(svg.length <= 0){
        return;
      }

      const svgRect = svg[0].getBoundingClientRect();

      const y = ev.clientY - svgRect.top;
      const p = 100 / (this.radius * 2) * y;

      for(let c = 0; c < this.colors.length - 1; c++){

        if(!(p > (100 / (this.colors.length - 1) * c) && p < (100 / (this.colors.length - 1) * (c + 1)))){
          continue;
        }

        const steps = 100 / (this.colors.length - 1);
        const p2 = 100 / steps * (p - (steps * c));

        const colorArray = this.colors[c].match(/.{1,2}/g);

        if(colorArray === null){
          continue;
        }

        const r = parseInt(colorArray[0], 16);
        const g = parseInt(colorArray[1], 16);
        const b = parseInt(colorArray[2], 16);

        const colorArray2 = this.colors[c + 1].match(/.{1,2}/g);

        if(colorArray2 === null){
          continue;
        }

        const r2 = parseInt(colorArray2[0], 16);
        const g2 = parseInt(colorArray2[1], 16);
        const b2 = parseInt(colorArray2[2], 16);

        const red =  Math.round(mapNumber(p2, 0, 100, r, r2));
        const green = Math.round(mapNumber(p2, 0, 100, g, g2));
        const blue = Math.round(mapNumber(p2, 0, 100, b, b2));

        this.value = { r: red, g: green, b: blue };

        this._updateCurrentColorCircle(red, green, blue);

      }


      //-- Emit colorTemperatureWheelChange events

      if(this.releaseOnly === false && this.stopOnly === false){

        this.emit("local::change colorTemperatureWheelChange", this.value);

        if(this.$contentEl !== undefined){
          this.$contentEl.trigger("change colorTemperatureWheelChange", this.value);
        }

      }

      if(this.stopOnly === true && this.releaseOnly === false){

        if(this._stopTimeout !== undefined){
          clearTimeout(this._stopTimeout);
        }

        this._stopTimeout = setTimeout(() => {

          this.emit("local::change colorTemperatureWheelChange", this.value);

          if(this.$contentEl !== undefined){
            this.$contentEl.trigger("change colorTemperatureWheelChange", this.value);
          }

        }, 150);
      }

    }


    private _updateCurrentColorCircle(r: number, g: number, b: number) {

      if(this.$contentEl === undefined){
        return;
      }


      //-- Update current color circle

      const svg = this.$contentEl.find(".color-temperature-wheel-svg-container");
      const currentColorCircle = svg[0].getElementsByClassName("current-color-circle")[0];

      if(currentColorCircle !== undefined){


        const coordinates = this._getCoordinatesFromRGB(r, g, b);

        if(coordinates === undefined){
          return;
        }

        r = Math.round(r);
        g = Math.round(g);
        b = Math.round(b);

        const rgb = `rgb(${r}, ${g}, ${b})`;

        currentColorCircle.setAttribute("fill", rgb);
        currentColorCircle.setAttribute("cx", coordinates.x + "");
        currentColorCircle.setAttribute("cy", coordinates.y + "");
        currentColorCircle.classList.remove("not-set");

      }
    }


    private _getCoordinatesFromRGB(r: number, g: number, b: number): { x: number, y: number } | undefined {

      for(let c = 0; c < this.colors.length - 1; c++){

        const steps = 100 / (this.colors.length - 1);

        const colorArray = this.colors[c].match(/.{1,2}/g);

        if(colorArray === null){
          continue;
        }

        const r1 = parseInt(colorArray[0], 16);
        const g1 = parseInt(colorArray[1], 16);
        const b1 = parseInt(colorArray[2], 16);

        const colorArray2 = this.colors[c + 1].match(/.{1,2}/g);

        if(colorArray2 === null){
          continue;
        }

        const r2 = parseInt(colorArray2[0], 16);
        const g2 = parseInt(colorArray2[1], 16);
        const b2 = parseInt(colorArray2[2], 16);

        let rp = -1;
        let gp = -1;
        let bp = -1;

        let rd = 0;
        let gd = 0;
        let bd = 0;

        let rnc = false;
        let gnc = false;
        let bnc = false;

        r = Math.round(r);
        g = Math.round(g);
        b = Math.round(b);

        if(r >= r1 && r <= r2){
          rp = mapNumber(r, r1, r2, 0, 100);
          rd = (100 / (r2 - r1)) / 2;
        } else if(r >= r2 && r <= r1){
          rp = 100 - mapNumber(r, r2, r1, 0, 100);
          rd = (100 / (r1 - r2)) / 2;
        }if(r === r1 && r1 === r2){
          rnc = true;
          rp = 100;
          rd = 100;
        }
        if(g >= g1 && g <= g2){
          gp = mapNumber(g, g1, g2, 0, 100);
          gd = (100 / (g2 - g1)) / 2;
        } else if(g >= g2 && g <= g1){
          gp = 100 - mapNumber(g, g2, g1, 0, 100);
          gd = (100 / (g1 - g2)) / 2;
        }if(g === g1 && g1 === g2){
          gnc = true;
          gp = 100;
          gd = 100;
        }
        if(b >= b1 && b <= b2){
          bp = mapNumber(b, b1, b2, 0, 100);
          bd = (100 / (b2 - b1)) / 2;
        } else if(b >= b2 && b <= b1){
          bp = 100 - mapNumber(b, b2, b1, 0, 100);
          bd = (100 / (b1 - b2)) / 2;
        }if(b === b1 && b1 === b2){
          bnc = true;
          bp = 100;
          bd = 100;
        }


        if(rp === -1 || gp === -1 || bp === -1){
          continue;
        }


        //-- find highestLowest value

        const rl = rp - rd;
        const gl = gp - gd;
        const bl = bp - bd;

        const rh = rp + rd;
        const gh = gp + gd;
        const bh = bp + bd;


        //-- Check if all colors are within deltas

        if(((rl > gh && gnc !== true) || (rl > bh && bnc !== true)) && rnc !== true){
          continue;
        }
        if(((gl > rh && rnc !== true) || (gl > bh && bnc !== true)) && gnc !== true){
          continue;
        }
        if(((bl > rh && rnc !== true) || (bl > gh && gnc !== true)) && bnc !== true){
          continue;
        }

        let highestLowest: number | undefined = undefined;

        if(highestLowest === undefined || (rl > highestLowest && rnc !== true)){
          highestLowest = rl;
        }
        if(highestLowest === undefined || (gl > highestLowest && gnc !== true)){
          highestLowest = gl;
        }
        if(highestLowest === undefined || (bl > highestLowest && bnc !== true)){
          highestLowest = bl;
        }


        //-- Check if all colors are within deltas

        if(((rh < gl && gnc !== true) || (rh < bl && bnc !== true)) && rnc !== true){
          continue;
        }
        if(((gh < rl && rnc !== true) || (gh < bl && bnc !== true)) && gnc !== true){
          continue;
        }
        if(((bh < rl && rnc !== true) || (bh < gl && gnc !== true)) && bnc !== true){
          continue;
        }


        //-- find lowestHighest value

        let lowestHighest: number | undefined = undefined;

        if(lowestHighest === undefined || (rh < lowestHighest && rnc !== true)){
          lowestHighest = rh;
        }
        if(lowestHighest === undefined || (gh < lowestHighest && gnc !== true)){
          lowestHighest = gh;
        }
        if(lowestHighest === undefined || (bh < lowestHighest && bnc !== true)){
          lowestHighest = bh;
        }


        //-- Find y coordinate

        const average = (lowestHighest + highestLowest) / 2;

        const p = average / 100 * steps + (c * steps);

        const y = (this.radius * 2) / 100 * p;

        return { x: this.radius, y };

      }

      return;

    }


    public render() {

      let container = "";
      let icon = "";
      let title = "";
      let content = "";

      if(this.params.icon !== undefined){
        icon = this.params.icon;
      }

      if(this.params.title !== undefined){
        title = this.params.title;
      }

      if(this.params.content !== undefined){
        content = this.params.content;
      }


      //-- Create SVG

      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.style.setProperty("width", this.radius * 2 + "px");
      svg.style.setProperty("height", this.radius * 2 + "px");
      svg.style.setProperty("display", "block");
      svg.style.setProperty("overflow", "visible");
      svg.setAttribute("width", this.radius * 2 + "");
      svg.setAttribute("height", this.radius * 2 + "");


      //-- Create defs

      const defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");


      //-- Create radial gradient

      const colorTemperatureGradient = document.createElementNS("http://www.w3.org/2000/svg", "linearGradient");

      colorTemperatureGradient.setAttribute("id", "colorTemperature");
      colorTemperatureGradient.setAttribute("x2", "0%");
      colorTemperatureGradient.setAttribute("y2", "100%");

      for(let c = 0; c < this.colors.length; c++){
        const stop = document.createElementNS("http://www.w3.org/2000/svg", "stop");
        stop.setAttribute("offset", (100 / (this.colors.length - 1) * c) + "%");
        stop.setAttribute("stop-color", "#" + this.colors[c]);
        colorTemperatureGradient.appendChild(stop);
      }


      defs.appendChild(colorTemperatureGradient);


      //-- Add defs

      svg.appendChild(defs);


      const svgRect = svg.getBoundingClientRect();


      //-- Add saturation overlay

      const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      circle.setAttribute("fill", "url(#colorTemperature)");
      circle.setAttribute("r", this.radius + "");
      circle.setAttribute("cx", this.radius + "");
      circle.setAttribute("cy", this.radius + "");

      svg.appendChild(circle);



      //-- Add currentColor circle

      const currentColorCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      currentColorCircle.setAttribute("class", "current-color-circle not-set");
      currentColorCircle.setAttribute("stroke", "#000");
      currentColorCircle.setAttribute("stroke-width", "1px");
      currentColorCircle.setAttribute("r", 12 + "");
      currentColorCircle.setAttribute("cx", 0 + "");
      currentColorCircle.setAttribute("cy", 0 + "");


      //-- Set initial value

      if(typeof this.value === "object"){
        if(typeof this.value.r !== "undefined" && !isNaN(this.value.r) &&
            typeof this.value.g !== "undefined" && !isNaN(this.value.g) &&
            typeof this.value.b !== "undefined" && !isNaN(this.value.b)){

          const coordinates = this._getCoordinatesFromRGB(this.value.r, this.value.g, this.value.b);

          if(coordinates !== undefined){

            currentColorCircle.setAttribute("fill", `rgb(${this.value.r}, ${this.value.g}, ${this.value.b})`);
            currentColorCircle.setAttribute("cx", coordinates.x + "");
            currentColorCircle.setAttribute("cy", coordinates.y + "");
            currentColorCircle.classList.remove("not-set");

          }
        }
      }

      svg.appendChild(currentColorCircle);

      const additionalContent = content === undefined ? "" : `
        <div class="color-temperature-wheel-content fullscreen-modal-content flexbox">
          ${content}
        </div>
      `;

      container = `
        <div class="color-temperature-wheel-container">
          <i class="f7-icons">${icon}</i>
          <div class="fullscreen-modal-title">${title}</div>
          <div class="color-temperature-wheel-svg-container touch-area">
            ${svg.outerHTML}
          </div>
          ${additionalContent}
        </div>
      `;

      return container;

    }

  };
}
