/* eslint-disable @typescript-eslint/naming-convention */
export default [
  {
    "language": ["en"],
    "fallback": "en",
    "text": {
      "weekday-0-0": "Monday",
      "weekday-0-1": "Tuesday",
      "weekday-0-2": "Wednesday",
      "weekday-0-3": "Thursday",
      "weekday-0-4": "Friday",
      "weekday-0-5": "Saturday",
      "weekday-0-6": "Sunday",
      "weekday-1-0": "Sunday",
      "weekday-1-1": "Monday",
      "weekday-1-2": "Tuesday",
      "weekday-1-3": "Wednesday",
      "weekday-1-4": "Thursday",
      "weekday-1-5": "Friday",
      "weekday-1-6": "Saturday",
      "creator-signedin": "Signed in",
      "creator-welcome-message": "Welcome back",
      "creator-import-or-update-device": "Do you want to create a new instance of this device or do you want to update the existing device",
      "creator-upload-icon": "Upload icon",
      "creator-upload-image": "Upload image",
      "creator-import": "Import",
      "creator-update": "Update",
      "creator-signin": "Login",
      "creator-register": "Register",
      "creator-forgot-pw": "Forgot password",
      "creator-signout": "Logout",
      "creator-reboot": "Reboot",
      "creator-enabled": "Enabled",
      "creator-disabled": "Disabled",
      "creator-reset": "Reset?",
      "creator-confirm-license": "Activate license?",
      "creator-confirm-removal": "Confirm removal",
      "creator-confirm-reboot": "Are you sure you want to reboot the controller?",
      "creator-search": "Search",
      "creator-filter": "Filter",
      "creator-delete": "Delete",
      "creator-deleted": "Deleted",
      "creator-devices": "Devices",
      "creator-pages": "Pages",
      "creator-page": "Page",
      "creator-page-name": "Page name",
      "creator-page-template-remote": "Remote buttons",
      "creator-page-template-channellist-remote": "Channel list with remote buttons",
      "creator-page-template-mediaplayer": "Simple remote with fewer buttons",
      "creator-page-template-floorplan": "Floorplan",
      "creator-page-template-scheme": "Switch app",
      "creator-page-template-page-link": "Link to an existing page",
      "creator-page-template-other": "Empty page that must filled programmatically",
      "creator-page-icons": "Page icons",
      "creator-scheme-icons": "App Icons",
      "creator-page-url-scheme": "URL scheme",
      "creator-page-url-scheme-placeholder": "controlhome://",
      "creator-linked-page": "Linked page",
      "creator-no-page-selected": "No page selected",
      "creator-no-macro-selected": "No macro selected",
      "creator-live-preview": "Live Preview",
      "creator-app": "App",
      "creator-remote": "Remote",
      "creator-automa": "Controller",
      "creator-rooms": "Rooms",
      "creator-room": "Room",
      "creator-device": "Device",
      "creator-feedback": "Feedback",
      "creator-no-room-selected": "No room selected",
      "creator-buttons": "Buttons",
      "creator-slider": "Slider",
      "creator-sequences": "Sequences",
      "creator-command": "Command",
      "creator-commands": "Commands",
      "creator-label": "Label",
      "creator-warning": "Warning",
      "creator-no-device-selected": "No device selected",
      "creator-no-device-found": "No devices found in the database, but you can create your own",
      "creator-module-free-label": "Free",
      "creator-no-page-or-device-selected": "No page or event selected",
      "creator-add-function": "Add Function",
      "creator-add-event": "Add Event",
      "creator-add-feedback": "Add Feedback",
      "creator-settings": "Settings",
      "creator-timezone": "Timezone",
      "creator-ethernet-settings": "Ethernet settings",
      "creator-wlan-settings": "WLAN settings",
      "creator-wlan-network": "WLAN Network",
      "creator-wlan-disabled": "Disable WLAN",
      "creator-wlan-hidden": "Hidden",
      "creator-wlan-scan-active": "Scanning...",
      "creator-wlan-connect-by-ssid": "Connect by ssid",
      "creator-wlan-connect-by-bssid": "Connect by bssid",
      "creator-wlan-connecting-dialog": "Connecting to <b>{1}</b>...",
      "creator-wlan-connecting-dialog-success": "Connection <b>{1}</b> has successfully been established.",
      "creator-wlan-connecting-dialog-failed": "Connection failed with network <b>{1}</b>. The controller will connect to the previous network...",
      "creator-sensing-threshold-settings": "Sensor threshold",
      "creator-sensing-values": "Sensor values (0 - 100)",
      "creator-sensing-threshold-settings-description": "The measured values can be influenced by various factors, such as manufacturing tolerances, connection cables used and the connected device. Therefore, the threshold values for the integrated sensors can be set below. The set threshold values are only used for the event trigger. For the query in macros, the original values must be used",
      "creator-updating-client-settings": "Updating settings...",
      "creator-updating-client-settings-success": "Settings have been applied successfully.",
      "creator-updating-client-settings-failed": "Settings could not be applied. Please check the informations",
      "creator-undeployed-changed-warning": "<b>Warning</b>: You might need to <span class='deploy'>deploy</span> your changes before functions work!",
      "creator-undeployed-changed-warning-medium": "Undeployed changes",
      "creator-undeployed-changed-warning-short": "Undeployed",
      "creator-module-documentation": "Documentation",
      "creator-module-documentation-not-available": "Documentation not available",
      "creator-module-outdated-warning-short": "Outdated",
      "creator-version-installed": "Installed",
      "creator-version-latest": "Latest",
      "creator-automa-settings-change-warning": "Settings will take effect on next deployment!",
      "creator-automa-adopt": "Adopt",
      "creator-new-automa-found": "New controller found.",
      "creator-new-automa-found-click-to-add": "Click here to adopt it to this project.",
      "creator-console": "Debug-console",
      "creator-run-macro": "Run macro",
      "creator-run-command": "Run command",
      "creator-command-delay": "Delay",
      "creator-command-if": "IF",
      "creator-command-push-notification": "Push Notification",
      "creator-command-set-feedback": "Set Feedback",
      "creator-command-run-macro": "Run macro",
      "creator-set-feedback": "Set feedback",
      "creator-action": "Action",
      "creator-macro": "Macro",
      "creator-value": "Value",
      "creator-app-initialize-warning-title": "App could not be initialized",
      "creator-app-initialize-warning-message": "Try <span class='page-reload'>refreshing</span> the page.",
      "creator-if-statement": "if statement",
      "creator-command-parameters-none": "None",
      "creator-command-parameters-feedback": "Feedback",
      "creator-command-parameters-event-parameter": "Event parameter",
      "creator-command-parameters": "Command parameters",
      "creator-feedback-parameters": "Feedback parameters",
      "creator-holding-pattern": "Holding pattern",
      "creator-holding-pattern-once": "Once",
      "creator-holding-pattern-pulses": "Pulses",
      "creator-holding-pattern-hold": "Hold",
      "creator-events": "Events",
      "creator-triggers": "Triggers",
      "creator-feedbacks": "Feedbacks",
      "creator-timers": "Macros",
      "creator-on": "on",
      "creator-on-short": "on",
      "creator-off": "off",
      "creator-off-short": "off",
      "creator-time": "Time",
      "creator-add-timer": "Add macro",
      "creator-new-timer": "New macro",
      "creator-add-page": "Add Page",
      "creator-add-device": "Add Device",
      "creator-add-room": "Add Room",
      "creator-add-command": "+ Add Command",
      "creator-new-room": "New room",
      "creator-new-command": "New Command",
      "creator-edit-page": "Edit page",
      "creator-edit-list": "Edit list",
      "creator-infrared": "Infrared",
      "creator-device-other": "Other",
      "creator-serial": "Serial",
      "creator-choose-template": "Choose template",
      "creator-choose-template-description": "Select one of the available templates. The template determines how the page is built, and which buttons and features are available.",
      "creator-choose-icon": "Choose icon",
      "creator-programmable-templates": "Programmable templates",
      "creator-tab-layout": "Tab Layout",
      "creator-list-layout": "List Layout",
      "creator-button-templates": "Button Layouts",
      "creator-tv-remote-buttons-layout": "TV",
      "creator-store-error-title": "Error while storing the project.",
      "creator-store-statuscode-0": "Tried to update the project with outdated project data. Please refresh the tab to updated the project data.",
      "creator-store-statuscode-1": "Tried to update the project with corrupt project data. Please refresh the tab to updated the project data.",
      "creator-script-functions": "Functions",
      "creator-script-events": "Triggers",
      "creator-script-feedbacks": "Feedbacks",
      "creator-script-add-function": "Add Function",
      "creator-script-add-event": "Add Event",
      "creator-script-add-feedback": "Add Feedback",
      "creator-script-add-parameter": "Add Parameter",
      "creator-script-new_function": "NEW_FUNCTION",
      "creator-script-new_event": "NEW_EVENT",
      "creator-script-new_feedback": "NEW_FEEDBACK",
      "creator-script-change-parameters": "Change parameters",
      "creator-script-multiple-parameters": "Multiple",
      "creator-script-optional-parameter": "Optional",
      "creator-script-confirm-reset-to-original": "You will lose any changes you have made when you restore this file to its original state. Do you really want to continue?",
      "creator-infrared-protocol": "Protocol",
      "creator-infrared-device": "Device",
      "creator-infrared-subdevice": "Subdevice",
      "creator-infrared-system": "System",
      "creator-infrared-command": "Command",
      "creator-infrared-hold": "Long press",
      "creator-infrared-trailer": "Trailer",
      "creator-infrared-frequency": "Frequency",
      "creator-infrared-waiting-for-data": "Waiting for infrared data",
      "creator-infrared-statuscode-0": "Error: Neither could decode protocol, nor frequency. Decrease distance to about 1 - 2cm.",
      "creator-infrared-statuscode-4": "Warning: Multiple possible protocols detected but could not detect frequency. Decrease distance to about 1 - 2cm.",
      "creator-network-ip": "IP-Address",
      "creator-network-dhcp": "Enable DHCP",
      "creator-network-subnet": "Subnet",
      "creator-network-dns1": "DNS 1",
      "creator-network-dns2": "DNS 2",
      "creator-network-gateway": "Gateway",
      "creator-network-password": "Password",
      "creator-network-wifi-disabled": "Disabled",
      "creator-run": "Run",
      "creator-macro-from-device": "from device",
      "creator-macro-from-automa": "from controller",
      "creator-macro-device": "Device",
      "creator-macro-automa": "Controller",
      "creator-macro-parameters": "Parameters",
      "creator-macro-feedback": "Feedback",
      "creator-macro-to": "to",
      "creator-macro-condition": "Condition",
      "creator-macro-edit": "Edit",
      "creator-clear-macro-confirmation-title": "Clear macro",
      "creator-clear-macro-confirmation-text": "Are you sure you want to remove all commands from the current macro?",
      "creator-parameters": "Parameters",
      "creator-change-parameters": "Change parameters",
      "creator-no-parameters-defined": "No parameters defined",
      "creator-clear": "Clear",
      "creator-room-volume-type": "Type",
      "creator-room-volume-none": "None",
      "creator-room-volume-device": "Device for room volume",
      "creator-room-settings": "Room settings",
      "creator-emable-http-https-server": "Enable HTTP and HTTPS server",
      "creator-http-https-server": "HTTP and HTTPS server",
      "creator-add-entry": "Add Entry",
      "creator-edit-module-confirmation": "Editing this module may keep the module from updating to the latest version to avoid compatibility problems. Are you sure you want to continue?",
      "creator-add-scheme": "Add scheme",
      "contextmenu-rename": "Rename",
      "contextmenu-edit": "Edit",
      "contextmenu-edit-module": "Edit module",
      "contextmenu-unlink-module": "Unlink from module",
      "contextmenu-delete": "Delete",
      "contextmenu-restore": "Restore",
      "contextmenu-reset-to-original": "Reset to original",
      "contextmenu-change-parameters": "Change parameters",
      "contextmenu-change-feedback-parameters": "Change feedback parameters",
      "contextmenu-change-command-parameters": "Change command parameters",
      "contextmenu-settings": "Settings",
      "contextmenu-change-list": "Change List",
      "contextmenu-change-icon": "Change Icon",
      "contextmenu-change-scheme": "Change app",
      "contextmenu-change-page-link": "Change page link",
      "contextmenu-change-template": "Change template",
      "contextmenu-change-room-volume": "Change room volume",
      "contextmenu-edit-script": "Edit script",
      "contextmenu-run-command": "Run Command",
      "contextmenu-update-module": "Update module",
      "contextmenu-update-module-confirmation": "Are you sure you want to update the module '<b>{1}</b>'? Updating the module will reset all customizations.",
      "contextmenu-delete-project-confirmation": "Are you sure you want to delete the project '<b>{1}</b>'? This cannot be undone! <br>Type <span class='selectable-text'>{2}</span> for confirmation.",
      "contextmenu-delete-device-confirmation": "Are you sure you want to delete the device '<b>{1}</b>'?",
      "contextmenu-delete-page-confirmation": "Are you sure you want to delete the page '<b>{1}</b>'?",
      "contextmenu-delete-pages-confirmation": "Are you sure you want to delete all selected pages?",
      "contextmenu-delete-room-confirmation": "Are you sure you want to delete the room '<b>{1}</b>'?",
      "contextmenu-delete-timer-confirmation": "Are you sure you want to delete the timer '<b>{1}</b>'?",
      "contextmenu-delete-module-confirmation": "Are you sure you want to delete the module?",
      "contextmenu-delete-release-confirmation": "Are you sure you want to delete the release?",
      "contextmenu-delete-automa-confirmation": "Are you sure you want to delete the controller and all associated devices? <br>Type <span class='selectable-text'>{1}</span> for confirmation.",
      "contextmenu-export-as-module": "Export as module",
      "contextmenu-edit-as-module": "Edit as module",
      "contextmenu-add-page-to-module": "Add page to module",
      "contextmenu-remove-page-from-module": "Remove page from modules",
      "contextmenu-assign-feedback-to-module": "Power status feedback",
      "contextmenu-remove-feedback-from-module": "Remove power status feedback",
      "overview-overview": "Overview",
      "overview-projects": "Projects",
      "overview-projects-getting-loaded": "Loading projects",
      "overview-order-history": "Order History",
      "overview-channel-lists": "Channel Lists",
      "overview-modules": "Modules",
      "overview-search": "Search",
      "overview-search-name-or-address": "Name or address",
      "overview-add-project": "+ Add project",
      "overview-add-channellist": "+ Add channellist",
      "overview-new-project": "New project",
      "overview-change-address": "Change address",
      "overview-edit-project": "Edit project",
      "overview-delete-project": "Delete project",
      "overview-project-added": "Project successfully created",
      "overview-project-added-action": "Click here to edit the project.",
      "overview-label-first-name": "First Name",
      "overview-label-last-name": "Last Name",
      "overview-label-location": "Location",
      "overview-label-location-placeholder": "Home / holiday flat / office",
      "overview-label-email": "Email",
      "overview-label-street": "Street",
      "overview-label-housenumber": "Building Number",
      "overview-label-zip": "Zip",
      "overview-label-city": "City",
      "overview-registration-customer-information-text": "Please provide us some information about the customer.",
      "overview-warning-delete-project-while-clients-connected": "You can not delete any projects that have clients connected.",
      "creator-overview": "Overview",
      "creator-project-overview": "Project Overview",
      "creator-website": "Website",
      "creator-channellist-delay": "Delay",
      "creator-channellist-leading-zeros": "Leading zeros",
      "creator-channellist-cursor-select": "Confirmation command",
      "creator-infrared-send": "Send",
      "creator-infrared-test": "Test Commands",
      "creator-infrared-test-running": "Test running",
      "creator-infrared-sending-command": "Sending command...",
      "creator-infrared-learn": "Learn",
      "creator-infrared-extended": "Extended",
      "creator-infrared-mode": "Mode",
      "creator-infrared-toggle": "Toggle",
      "creator-infrared-port": "Port",
      "creator-infrared-pulse-count": "Pulse count",
      "creator-infrared-prontohex": "Pronto Hex",
      "creator-serial-send": "Send",
      "creator-serial-sending-command": "Sending command...",
      "creator-serial-learn": "Learn",
      "creator-serial-databits": "Datanbits",
      "creator-serial-stopbits": "Stopbits",
      "creator-serial-baudrate": "Baudrate",
      "creator-serial-port": "Port",
      "creator-http-port": "Port",
      "creator-http-send": "Send",
      "creator-http-url": "URL",
      "creator-http-body": "Body",
      "creator-http-method": "Method",
      "creator-tcp-ip": "IP-address",
      "creator-tcp-port": "Port",
      "creator-tcp-send": "Send",
      "creator-tcp-keepalive": "Keepalive",
      "creator-tcp-keepalive-enabled": "Enabled",
      "creator-tcp-keepalive-disabled": "Disabled",
      "creator-tcp-body": "Payload",
      "creator-license": "License",
      "creator-licenses": "Licenses",
      "creator-activate-license-confirmation": "Are you sure you want to activate the license?",
      "creator-available": "available",
      "creator-get-license": "Get license",
      "creator-floorplan-change-floorplan": "Change floorplan",
      "creator-floorplan-add-lamp": "Add lamp",
      "creator-floorplan-add-window": "Add window",
      "creator-floorplan-add-speaker": "Add speaker",
      "creator-floorplan-add-blinds": "Add blinds",
      "creator-floorplan-add-power": "Add power outlet",
      "creator-floorplan-add-thermostat": "Add thermostat",
      "creator-floorplan-add-device": "Add device",
      "creator-floorplan-add-camera": "Add camera",
      "creator-floorplan-add-other": "Add other",
      "creator-floorplan-add-story": "Add story",
      "creator-floorplan-new-story": "New story",
      "creator-floorplan-change-dimensions": "Change dimensions",
      "creator-floorplan-circular": "Circular",
      "creator-floorplan-rectangular": "Rectangular",
      "creator-floorplan-width": "Width",
      "creator-floorplan-height": "Height",
      "creator-floorplan-radius": "Radius",
      "creator-floorplan-diameter": "Diameter",
      "creator-floorplan-size": "Size",
      "creator-macro-selection": "Macro",
      "creator-option-selection": "Select an option",
      "creator-power-status-feedback": "Status",
      "creator-power-status-current-value": "Current value",
      "creator-optical-1": "Optical 1",
      "creator-optical-2": "Optical 2",
      "creator-usb-1": "USB 1",
      "creator-usb-2": "USB 2",
      "popup-save": "Save",
      "popup-apply": "Apply",
      "popup-back": "Back",
      "popup-next": "Next",
      "popup-parameters": "Parameters",
      "popup-close": "Close",
      "popup-cancel": "Cancel",
      "popup-delete": "Delete",
      "popup-reset": "Reset",
      "popup-confirm": "Confirm",
      "popup-refresh": "Refresh",
      "popup-login": "Login",
      "dev-add-module": "Add Module",
      "dev-add-or-update-module": "Add/update Module",
      "dev-add-release": "Add release",
      "dev-released": "Released",
      "dev-release-scheduled": "Scheduled",
      "dev-release-scheduled-on": "Release planned on",
      "dev-create-release": "Create release",
      "dev-schedule-release": "Schedule release",
      "dev-version": "Version",
      "dev-creator": "Creator",
      "dev-automa": "Controller",
      "dev-app": "App",
      "dev-changelog": "Changelog",
      "dev-modules": "Modules",
      "dev-releases": "Releases",
      "dev-module-name": "Module name",
      "dev-module-version": "Version",
      "dev-module-description": "Description",
      "dev-module-changelog": "Changelog",
      "dev-module-short-description": "Short description",
      "dev-module-tags": "Tags",
      "dev-module-price": "Price",
      "dev-add-image": "Add image"
    }
  }, {
    "language": ["de", "de-de", "de-ch"],
    "fallback": "en",
    "text": {
      "weekday-0-0": "Montag",
      "weekday-0-1": "Dienstag",
      "weekday-0-2": "Mittwoch",
      "weekday-0-3": "Donnerstag",
      "weekday-0-4": "Freitag",
      "weekday-0-5": "Samstag",
      "weekday-0-6": "Sonntag",
      "weekday-1-0": "Sonntag",
      "weekday-1-1": "Montag",
      "weekday-1-2": "Dienstag",
      "weekday-1-3": "Mittwoch",
      "weekday-1-4": "Donnerstag",
      "weekday-1-5": "Freitag",
      "weekday-1-6": "Samstag",
      "creator-signedin": "Anmeldung erfolgreich",
      "creator-welcome-message": "Willkommen zurück",
      "creator-import-or-update-device": "Möchten Sie eine neue Instanz des importierten Gerätes hinzufügen oder möchten Sie die bestehenden aktualisieren?",
      "creator-import": "Importieren",
      "creator-upload-icon": "Icon hochladen",
      "creator-upload-image": "Bild hochladen",
      "creator-update": "Aktualisieren",
      "creator-signin": "Login",
      "creator-register": "Registrieren",
      "creator-forgot-pw": "Passwort vergessen",
      "creator-signout": "Logout",
      "creator-reboot": "Neustarten",
      "creator-enabled": "Aktiviert",
      "creator-disabled": "Deaktiviert",
      "creator-reset": "Zurücksetzen?",
      "creator-confirm-license": "Lizenz aktivieren?",
      "creator-confirm-removal": "Löschen bestätigen",
      "creator-confirm-reboot": "Sind Sie sicher dass Sie den controller neustarten möchten?",
      "creator-search": "Suchen",
      "creator-filter": "Filtern",
      "creator-delete": "Löschen",
      "creator-deleted": "Gelöscht",
      "creator-devices": "Geräte",
      "creator-pages": "Seiten",
      "creator-page": "Seite",
      "creator-page-name": "Name der Seite",
      "creator-page-template-remote": "Fernbedienungstasten",
      "creator-page-template-channellist-remote": "Senderliste mit Fernbedienungstasten",
      "creator-page-template-mediaplayer": "Einfache Fernbedienung mit weniger Tasten",
      "creator-page-template-floorplan": "Grundriss",
      "creator-page-template-scheme": "App wechseln",
      "creator-page-template-page-link": "Verknüpfung zu bestehender Seite",
      "creator-page-template-other": "Leere Seite die durch programmierung gefüllt werden kann",
      "creator-page-icons": "Geräte Icons",
      "creator-scheme-icons": "App Icons",
      "creator-page-url-scheme": "URL Schema",
      "creator-page-url-scheme-placeholder": "controlhome://",
      "creator-linked-page": "Verknüpfte Seite",
      "creator-no-page-selected": "Keine Seite ausgewählt",
      "creator-no-macro-selected": "Keine Makro ausgewählt",
      "creator-live-preview": "Live Vorschau",
      "creator-app": "App",
      "creator-remote": "Fernbedienung",
      "creator-automa": "Controller",
      "creator-rooms": "Räume",
      "creator-room": "Raum",
      "creator-device": "Gerät",
      "creator-feedback": "Feedback",
      "creator-no-room-selected": "Kein Raum ausgewählt",
      "creator-buttons": "Tasten",
      "creator-slider": "Slider",
      "creator-sequences": "Sequenzen",
      "creator-command": "Befehl",
      "creator-commands": "Befehle",
      "creator-label": "Beschriftung",
      "creator-warning": "Warnung",
      "creator-no-page-or-device-selected": "Wählen Sie ein Event oder eine Seite aus um die Sequenz bearbeiten zu können.",
      "creator-add-function": "Funktion hinzufügen",
      "creator-add-event": "Event hinzufügen",
      "creator-add-feedback": "Feedback hinzufügen",
      "creator-settings": "Einstellungen",
      "creator-console": "Debug-Konsole",
      "creator-run-macro": "Makro ausführen",
      "creator-run-command": "Befehl ausführen",
      "creator-command-delay": "Verzögerung",
      "creator-command-if": "Logische Abfrage",
      "creator-command-push-notification": "Push Benachrichtigung",
      "creator-command-set-feedback": "Setze Feedback",
      "creator-command-run-macro": "Makro ausführen",
      "creator-set-feedback": "Feedback setzen",
      "creator-action": "Aktion",
      "creator-macro": "Makro",
      "creator-value": "Wert",
      "creator-app-initialize-warning-title": "App konnte nicht geladen werden",
      "creator-app-initialize-warning-message": "Versuchen sie die Seite <span class='page-reload'>neu zu laden</span>",
      "creator-if-statement": "if statement",
      "creator-command-parameters-none": "Keine",
      "creator-command-parameters-feedback": "Feedback",
      "creator-command-parameters-event-parameter": "Event parameter",
      "creator-command-parameters": "Befehlsparameter",
      "creator-feedback-parameters": "Feedbackparameter",
      "creator-holding-pattern": "Halte modus",
      "creator-holding-pattern-once": "Einmalig",
      "creator-holding-pattern-pulses": "Mehrere Pulse",
      "creator-holding-pattern-hold": "Gedrückt halten",
      "creator-timezone": "Zeitzone",
      "creator-ethernet-settings": "Ethernet Einstellungen",
      "creator-wlan-settings": "WLAN Einstellungen",
      "creator-wlan-network": "WLAN Netzwerk",
      "creator-wlan-disabled": "WLAN deaktivieren",
      "creator-wlan-hidden": "Versteckt",
      "creator-wlan-scan-active": "Suche nach Wifi Netzwerken...",
      "creator-wlan-connect-by-ssid": "Verbinde mit SSID",
      "creator-wlan-connect-by-bssid": "Verbinde mit BSSID",
      "creator-wlan-connecting-dialog": "Verbindung mit dem Netzwerk <b>{1}</b> wird hergestellt...",
      "creator-wlan-connecting-dialog-success": "Verbindung mit <b>{1}</b> wurde erfolgreich hergestellt.",
      "creator-wlan-connecting-dialog-failed": "Verbindung mit <b>{1}</b> konnte nicht hergestellt werden. Der controller verbindet sich erneut mit dem Vorherigen Netzwerk...",
      "creator-sensing-threshold-settings": "Sensor Schwellwert",
      "creator-sensing-values": "Sensorwerte (0-100)",
      "creator-sensing-threshold-settings-description": "Die Messwerte können durch verschiedene Faktoren, wie Fertigungstoleranzen, verwendete Anschlusskabel und das angeschlossene Gerät, beeinflusst werden. Deshalb können die Schwellenwerte für die integrierten Sensoren unten eingestellt werden. Die eingestellten Schwellenwerte werden nur für die Ereignisauslösung verwendet. Für die Abfrage in Makros müssen die Originalwerte verwendet werden",
      "creator-updating-client-settings": "Einstellungen werden übernommen...",
      "creator-updating-client-settings-success": "Einstellungen wurden erfolgreich übernommen.",
      "creator-updating-client-settings-failed": "Einstellungen konnten nicht übernommen werden. Bitte überprüfen Sie die Angaben.",
      "creator-undeployed-changed-warning": "<b>Warnung</b>: Sie müssen unter Umständen zuerst die <span class='deploy'>Änderungen hochladen</span>, bevor die Funktionen funktionieren!",
      "creator-undeployed-changed-warning-medium": "Nicht angewante Änderungen",
      "creator-undeployed-changed-warning-short": "Geändert",
      "creator-module-documentation": "Dokumentation",
      "creator-module-documentation-not-available": "Dokumentation nicht verfügbar",
      "creator-module-outdated-warning-short": "Veraltet",
      "creator-version-installed": "Installiert",
      "creator-version-latest": "Verfügbar",
      "creator-automa-settings-change-warning": "Die Einstellungen werden erst beim nächsten upload wirksam!",
      "creator-automa-adopt": "Einbinden",
      "creator-new-automa-found": "Neuer Controller gefunden.",
      "creator-new-automa-found-click-to-add": "Hier klicken um den Controller einzubinden.",
      "creator-events": "Events",
      "creator-triggers": "Trigger",
      "creator-feedbacks": "Feedbacks",
      "creator-timers": "Makros",
      "creator-on": "einschalten",
      "creator-on-short": "ein",
      "creator-off": "ausschalten",
      "creator-off-short": "aus",
      "creator-time": "Zeit",
      "creator-add-timer": "Makro hinzufügen",
      "creator-new-timer": "Neues Makro",
      "creator-add-page": "Seite hinzufügen",
      "creator-add-device": "Gerät hinzufügen",
      "creator-add-room": "Raum hinzufügen",
      "creator-add-command": "+ Befehl hinzufügen",
      "creator-new-room": "Neuer Raum",
      "creator-new-command": "Neuer Befehl",
      "creator-edit-page": "Seite bearbeiten",
      "creator-edit-list": "Liste bearbeiten",
      "creator-infrared": "Infrarot",
      "creator-serial": "Seriell",
      "creator-device-other": "Other",
      "creator-choose-template": "Vorlage auswählen",
      "creator-choose-template-description": "Wähle eine der verfügbaren Vorlagen aus. Die Vorlage bestimmt wie die Seite Aufgebaut ist, und welche Tasten und Features verfügbar sind.",
      "creator-choose-icon": "Icon auswählen",
      "creator-programmable-templates": "Programmierbare Vorlagen",
      "creator-tab-layout": "Tab Ansicht",
      "creator-list-layout": "Listen Ansicht",
      "creator-button-templates": "Tasten Vorlagen",
      "creator-tv-remote-buttons-layout": "TV",
      "creator-store-error-title": "Es ist ein Fehler aufgetreten während des Speicherns.",
      "creator-store-statuscode-0": "Es wurde versucht das Projekt mit einem veralteten Projekt zu überschreiben, bitte aktualisieren Sie die Seite um die aktuellen Projektdaten abzurufen.",
      "creator-store-statuscode-1": "Es wurde versucht das Projekt mit einem korrupten Projekt zu überschreiben, bitte aktualisieren Sie die Seite um die aktuellen Projektdaten abzurufen.",
      "creator-no-device-selected": "Kein Gerät ausgewählt",
      "creator-no-device-found": "Kein Gerät in der Datenbank gefunden, aber du kannst es selbst erstellen.",
      "creator-module-free-label": "Kostenlos",
      "creator-script-functions": "Funktionen",
      "creator-script-events": "Triggers",
      "creator-script-feedbacks": "Feedbacks",
      "creator-script-add-function": "Funktion hinzufügen",
      "creator-script-add-event": "Event hinzufügen",
      "creator-script-add-feedback": "Feedback hinzufügen",
      "creator-script-add-parameter": "Parameter hinzufügen",
      "creator-script-new_function": "NEUE_FUNKTION",
      "creator-script-new_event": "NEUES_EVENT",
      "creator-script-new_feedback": "NEUES_FEEDBACK",
      "creator-script-change-parameters": "Parameter ändern",
      "creator-script-multiple-parameters": "Mehrfachauswahl",
      "creator-script-optional-parameter": "Optional",
      "creator-script-confirm-reset-to-original": "Sie verlieren alle Änderungen, die Sie vorgenommen haben, wenn Sie diese Datei in ihren ursprünglichen Zustand zurücksetzen. Wollen Sie wirklich weitermachen?",
      "creator-no-parameters-defined": "Keine Parameter definiert",
      "creator-infrared-protocol": "Protokoll",
      "creator-infrared-device": "Device",
      "creator-infrared-subdevice": "Subdevice",
      "creator-infrared-system": "System",
      "creator-infrared-command": "Befehl",
      "creator-infrared-hold": "Langer Tastendruck",
      "creator-infrared-trailer": "Trailer",
      "creator-infrared-frequency": "Frequenz",
      "creator-infrared-waiting-for-data": "Warte auf Infrarot Daten",
      "creator-infrared-statuscode-0": "Fehler: Konnte weder die Frequenz noch das Protokoll erkennen. Verringern Sie die distanz auf etwa 1 - 2cm",
      "creator-infrared-statuscode-4": "Warnung: Mehrere mögliche Protokolle aber keine Frequenz erkannt. Verringern Sie die distanz auf etwa 1 - 2cm",
      "creator-network-ip": "IP-Adresse",
      "creator-network-dhcp": "DHCP aktivieren",
      "creator-network-subnet": "Subnetz",
      "creator-network-dns1": "DNS 1",
      "creator-network-dns2": "DNS 2",
      "creator-network-gateway": "Gateway",
      "creator-network-password": "Passwort",
      "creator-network-wifi-disabled": "Deaktiviert",
      "creator-run": "Ausführen",
      "creator-macro-from-device": "von Gerät",
      "creator-macro-from-automa": "von Controller",
      "creator-macro-device": "Gerät",
      "creator-macro-automa": "Controller",
      "creator-macro-parameters": "Parameter",
      "creator-macro-feedback": "Feedback",
      "creator-macro-to": "zu",
      "creator-macro-condition": "Bedingung",
      "creator-macro-edit": "Bearbeiten",
      "creator-clear-macro-confirmation-title": "Makro zurücksetzen",
      "creator-clear-macro-confirmation-text": "Sind Sie sicher dass Sie alle Befehle aus dem aktuellen Makro entfernen möchten?",
      "creator-parameters": "Parameter",
      "creator-change-parameters": "Parameter ändern",
      "creator-clear": "Zurücksetzen",
      "creator-room-volume-type": "Art",
      "creator-room-volume-none": "Keine",
      "creator-room-volume-device": "Gerät für Raum Lautstärke",
      "creator-room-settings": "Raum Einstellungen",
      "creator-enable-http-https-server": "HTTP und HTTPS Server aktivieren",
      "creator-http-https-server": "HTTP und HTTPS Server",
      "creator-add-entry": "Eintrag hinzufügen",
      "creator-edit-module-confirmation": "Wenn Sie das Modul bearbeiten, wird es möglicherweise nicht mehr aktualisiert, um kompatibilitätsprobleme zu vermeiden. Sind Sie sicher dass Sie fortfahren möchten?",
      "creator-add-scheme": "URL Schema hinzufügen",
      "contextmenu-rename": "Umbenennen",
      "contextmenu-edit": "Bearbeiten",
      "contextmenu-edit-module": "Modul bearbeiten",
      "contextmenu-delete": "Löschen",
      "contextmenu-unlink-module": "Verknüpfung mit Modul trennen",
      "contextmenu-restore": "Wiederherstellen",
      "contextmenu-reset-to-original": "Original wiederherstellen",
      "contextmenu-change-parameters": "Parameter ändern",
      "contextmenu-change-feedback-parameters": "Feedback Parameter ändern",
      "contextmenu-change-command-parameters": "Befehl Parameter ändern",
      "contextmenu-settings": "Einstellungen",
      "contextmenu-change-list": "Liste anpassen",
      "contextmenu-change-icon": "Icon ändern",
      "contextmenu-change-scheme": "App ändern",
      "contextmenu-change-page-link": "Verknüpfte Seite ändern",
      "contextmenu-change-template": "Vorlage ändern",
      "contextmenu-change-room-volume": "Gerät für Raumlautstärke ändern",
      "contextmenu-edit-script": "Script bearbeiten",
      "contextmenu-run-command": "Funktion ausführen",
      "contextmenu-update-module": "Modul aktualisieren",
      "contextmenu-update-module-confirmation": "Sind Sie sicher, dass Sie das Modul '<b>{1}</b>' aktualisieren möchten? Die aktualisierung setzt alle Anpassungen zurück.",
      "contextmenu-delete-project-confirmation": "Sind Sie sicher, dass Sie das Projekt '<b>{1}</b>' löschen möchten? Dieser Schritt kann nicht rückgängig gemacht werden! <br>Schreiben Sie zur Bestätigung <span class='selectable-text'>{2}</span>",
      "contextmenu-delete-device-confirmation": "Sind Sie sicher, dass Sie das Gerät '<b>{1}</b>' löschen möchten?",
      "contextmenu-delete-page-confirmation": "Sind Sie sicher, dass Sie die Seite '<b>{1}</b>' löschen möchten?",
      "contextmenu-delete-pages-confirmation": "Sind Sie sicher, dass Sie die ausgewählten Seiten löschen möchten?",
      "contextmenu-delete-room-confirmation": "Sind Sie sicher, dass Sie den Raum '<b>{1}</b>' löschen möchten?",
      "contextmenu-delete-timer-confirmation": "Sind Sie sicher, dass Sie das Makro '<b>{1}</b>' löschen möchten?",
      "contextmenu-delete-module-confirmation": "Sind Sie sicher, dass Sie das Modul löschen möchten?",
      "contextmenu-delete-release-confirmation": "Sind Sie sicher, dass Sie das Release löschen möchten?",
      "contextmenu-delete-automa-confirmation": "Sind Sie sicher, dass Sie den Controller und alle dazugehörigen Geräte löschen möchten? <br>Schreiben Sie zur Bestätigung <span class='selectable-text'>{1}</span>",
      "contextmenu-export-as-module": "Als Modul exportieren",
      "contextmenu-edit-as-module": "Als Modul bearbeiten",
      "contextmenu-add-page-to-module": "Seite zu Modul hinzufügen",
      "contextmenu-remove-page-from-module": "Seite von Modul entfernen",
      "contextmenu-assign-feedback-to-module": "Status Feedback",
      "contextmenu-remove-feedback-from-module": "Status Feedback entfernen",
      "overview-overview": "Übersicht",
      "overview-projects": "Projekte",
      "overview-projects-getting-loaded": "Projekte werden geladen",
      "overview-order-history": "Bestellverlauf",
      "overview-channel-lists": "Senderlisten",
      "overview-modules": "Module",
      "overview-search": "Suche",
      "overview-search-name-or-address": "Name oder Adresse",
      "overview-add-project": "+ Projekt hinzufügen",
      "overview-add-channellist": "+ Senderliste hinzufügen",
      "overview-new-project": "Neues Projekt",
      "overview-change-address": "Adresse ändern",
      "overview-edit-project": "Projekt bearbeiten",
      "overview-delete-project": "Projekt löschen",
      "overview-project-added": "Projekt wurde erfolgreich erstellt",
      "overview-project-added-action": "Klicken Sie hier um das Projekt zu bearbeiten.",
      "overview-label-first-name": "Vorname",
      "overview-label-last-name": "Nachname",
      "overview-label-location": "Standort",
      "overview-label-location-placeholder": "Wohnung / Ferienhaus / Büro",
      "overview-label-email": "Email",
      "overview-label-street": "Strasse",
      "overview-label-housenumber": "Hausnummer",
      "overview-label-zip": "Plz",
      "overview-label-city": "Ort",
      "overview-registration-customer-information-text": "Bitte geben Sie uns ein paar Informationen über den Kunden.",
      "overview-warning-delete-project-while-clients-connected": "Sie können ein Projekt erst löschen wenn keine Geräte mehr angemeldet sind.",
      "creator-overview": "Übersicht",
      "creator-project-overview": "Projektübersicht",
      "creator-website": "Webseite",
      "creator-channellist-delay": "Verzögerung",
      "creator-channellist-leading-zeros": "Führende Nullen",
      "creator-channellist-cursor-select": "Bestätigungsbefehl",
      "creator-infrared-send": "Senden",
      "creator-infrared-test": "Teste Befehle",
      "creator-infrared-test-running": "Test läuft",
      "creator-infrared-sending-command": "Senden Befehl...",
      "creator-infrared-learn": "Lernen",
      "creator-infrared-extended": "Erweitert",
      "creator-infrared-modus": "Modus",
      "creator-infrared-toggle": "Umschaltbit",
      "creator-infrared-port": "Port",
      "creator-infrared-pulse-count": "Anzahl Impulse",
      "creator-infrared-prontohex": "Pronto Hex",
      "creator-serial-send": "Senden",
      "creator-serial-sending-command": "Sende Befehl...",
      "creator-serial-learn": "Lernen",
      "creator-serial-databits": "Datenbits",
      "creator-serial-stopbits": "Stopbits",
      "creator-serial-baudrate": "Baudrate",
      "creator-serial-port": "Port",
      "creator-http-send": "Senden",
      "creator-http-port": "Port",
      "creator-http-url": "URL",
      "creator-http-body": "Inhalt",
      "creator-http-method": "Methode",
      "creator-tcp-ip": "IP-Adresse",
      "creator-tcp-port": "Port",
      "creator-tcp-send": "Senden",
      "creator-tcp-keepalive": "Keepalive",
      "creator-tcp-keepalive-enabled": "Aktiviert",
      "creator-tcp-keepalive-disabled": "Deaktiviert",
      "creator-tcp-body": "Inhalt",
      "creator-udp-ip": "IP-Adresse",
      "creator-udp-port": "Port",
      "creator-udp-send": "Senden",
      "creator-udp-body": "Inhalt",
      "creator-license": "Lizenz",
      "creator-licenses": "Lizenzen",
      "creator-activate-license-confirmation": "Sind Sie sicher dass Sie die Lizenz aktivieren möchten?",
      "creator-available": "verfügbar",
      "creator-get-license": "Lizenz erwerben",
      "creator-floorplan-change-floorplan": "Grundriss ändern",
      "creator-floorplan-add-lamp": "Lampe hinzufügen",
      "creator-floorplan-add-window": "Fenster hinzufügen",
      "creator-floorplan-add-speaker": "Lautsprecher hinzufügen",
      "creator-floorplan-add-blinds": "Storen hinzufügen",
      "creator-floorplan-add-power": "Steckdose hinzufügen",
      "creator-floorplan-add-thermostat": "Temperaturregler hinzufügen",
      "creator-floorplan-add-device": "Gerät hinzufügen",
      "creator-floorplan-add-camera": "Kamera hinzufügen",
      "creator-floorplan-add-other": "Anderes hinzufügen",
      "creator-floorplan-add-story": "Stockwerk hinzufügen",
      "creator-floorplan-new-story": "Neues Stockwerk",
      "creator-floorplan-change-dimensions": "Grösse ändern",
      "creator-floorplan-circular": "Rund",
      "creator-floorplan-rectangular": "Rechteckig",
      "creator-floorplan-width": "Breite",
      "creator-floorplan-height": "Höhe",
      "creator-floorplan-radius": "Radius",
      "creator-floorplan-diameter": "Durchmesser",
      "creator-floorplan-size": "Grösse",
      "creator-macro-selection": "Makro",
      "creator-option-selection": "Wählen Sie eine option",
      "creator-power-status-feedback": "Status",
      "creator-power-status-current-value": "Aktueller Wert",
      "creator-optical-1": "Optical 1",
      "creator-optical-2": "Optical 2",
      "creator-usb-1": "USB 1",
      "creator-usb-2": "USB 2",
      "popup-save": "Speichern",
      "popup-apply": "Anwenden",
      "popup-back": "Zurück",
      "popup-next": "Weiter",
      "popup-parameters": "Parameter",
      "popup-close": "Schliessen",
      "popup-cancel": "Abbrechen",
      "popup-delete": "Löschen",
      "popup-reset": "Zurücksetzen",
      "popup-confirm": "Bestätigen",
      "popup-refresh": "Aktualisieren",
      "popup-login": "Anmelden",
      "dev-add-module": "Modul hinzufügen",
      "dev-add-or-update-module": "Modul hinzufügen/aktualisieren",
      "dev-add-release": "Release hinzufügen",
      "dev-released": "Released",
      "dev-release-scheduled": "Geplant",
      "dev-release-scheduled-on": "Release geplant am",
      "dev-create-release": "Release erstellen",
      "dev-schedule-release": "Release planen",
      "dev-version": "Version",
      "dev-creator": "Creator",
      "dev-automa": "Controller",
      "dev-app": "App",
      "dev-changelog": "Changelog",
      "dev-modules": "Module",
      "dev-releases": "Releases",
      "dev-module-name": "Module Name",
      "dev-module-version": "Version",
      "dev-module-description": "Beschreibung",
      "dev-module-changelog": "Changelog",
      "dev-module-short-description": "Kurzbeschreibung",
      "dev-module-tags": "Schlagworte",
      "dev-module-price": "Preis",
      "dev-add-image": "Bild hinzufügen"
    }
  }
];