import { types } from "../../interfaces/types";
import * as functions from "../../functions/functions";


export class Feedbacks_ {

  public FEEDBACKS: types.FeedbackValues;

  constructor() {

    this.FEEDBACKS = {
      "automa": [],
      "time": 0
    };

  }


  //-- Returns true if the feedback was changed, otherwise false

  public setFeedback(name: string, value: string | number | boolean | types.Object, automaIdentifier: string): boolean;
  public setFeedback(name: string, value: string | number | boolean | types.Object, automaIdentifier: string, deviceIdentifier?: string): boolean;
  public setFeedback(name: string, value: string | number | boolean | types.Object, automaIdentifier: string, deviceIdentifierOrUndefined?: string): boolean {

    let deviceIdentifier: string | undefined;
    let hasChanged = false;

    const now = new Date().getTime();

    if(deviceIdentifierOrUndefined !== undefined){
      deviceIdentifier = deviceIdentifierOrUndefined as string;
    }

    if(typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean" ||
        typeof value === "bigint"){
      value = {
        "value": value
      };
    }

    value.time = now;

    let automaFound = false;

    for(let a = 0; a < this.FEEDBACKS.automa.length; a++){
      if(automaIdentifier === this.FEEDBACKS.automa[a].identifier){
        automaFound = true;
      }
    }

    if(automaFound === false){
      this.FEEDBACKS.automa.push({
        "identifier": automaIdentifier,
        "feedbacks": [],
        "devices": []
      });
    }

    for(let a = 0; a < this.FEEDBACKS.automa.length; a++){

      if(automaIdentifier !== this.FEEDBACKS.automa[a].identifier){
        continue;
      }

      if(deviceIdentifier === undefined){


        //-- Set automa feedback

        feedbackLoop: for(let af = 0; af < this.FEEDBACKS.automa[a].feedbacks.length; af++){

          if(this.FEEDBACKS.automa[a].feedbacks[af].name !== name){
            continue feedbackLoop;
          }

          if(typeof value === "object"){

            valueLoop: for(let v = this.FEEDBACKS.automa[a].feedbacks[af].values.length - 1; v >= 0; v--){

              const objectKeys = Object.keys(this.FEEDBACKS.automa[a].feedbacks[af].values[v]);


              //-- Make sure parameters match

              keyLoop: for(let ek = 0; ek < objectKeys.length; ek++){

                if(objectKeys[ek] === "time"){
                  continue keyLoop;
                }

                if(objectKeys[ek] === "value"){
                  continue keyLoop;
                }

                for(const newKey in value){
                  if(objectKeys[ek] === newKey && JSON.stringify(this.FEEDBACKS.automa[a].feedbacks[af].values[v][objectKeys[ek]]) !== JSON.stringify(value[newKey])){


                    //-- Delete old feedbacks

                    if(this.FEEDBACKS.automa[a].feedbacks[af].values[v].time < (now - 5.184e+9)){
                      this.FEEDBACKS.time = now;
                      this.FEEDBACKS.automa[a].feedbacks[af].values.splice(v, 1);
                    }

                    continue valueLoop;
                  }
                }

              }

              if(JSON.stringify(this.FEEDBACKS.automa[a].feedbacks[af].values[v].value) !== JSON.stringify(value.value)){
                hasChanged = true;
              }

              this.FEEDBACKS.automa[a].feedbacks[af].values[v].value = value.value;
              this.FEEDBACKS.automa[a].feedbacks[af].values[v].time = now;
              this.FEEDBACKS.time = now;
              return hasChanged;

            }

            this.FEEDBACKS.automa[a].feedbacks[af].values.push(value);
            this.FEEDBACKS.time = now;

            hasChanged = true;
            return hasChanged;

          }

        }


        //-- Insert new feedback

        this.FEEDBACKS.automa[a].feedbacks.push({
          "name": name,
          "values": [value]
        });
        this.FEEDBACKS.time = now;

        hasChanged = true;

      } else {


        //-- Set device feedback

        deviceLoop: for(let d = 0; d < this.FEEDBACKS.automa[a].devices.length; d++){

          if(deviceIdentifier !== this.FEEDBACKS.automa[a].devices[d].identifier){
            continue deviceLoop;
          }

          feedbackLoop: for(let df = 0; df < this.FEEDBACKS.automa[a].devices[d].feedbacks.length; df++){

            if(this.FEEDBACKS.automa[a].devices[d].feedbacks[df].name !== name){
              continue feedbackLoop;
            }

            if(typeof value === "object"){
              valueLoop: for(let v = this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values.length - 1; v >= 0; v--){

                const objectKeys = Object.keys(this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values[v]);


                //-- Update feedback

                keyLoop: for(let ek = 0; ek < objectKeys.length; ek++){

                  if(objectKeys[ek] === "time"){
                    continue keyLoop;
                  }

                  if(objectKeys[ek] === "value"){
                    continue keyLoop;
                  }

                  for(const newKey in value){
                    if(objectKeys[ek] === newKey && JSON.stringify(this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values[v][objectKeys[ek]]) !== JSON.stringify(value[newKey])){


                      //-- Delete old feedbacks

                      if(this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values[v].time < (now - 5.184e+9)){
                        this.FEEDBACKS.time = now;
                        this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values.splice(v, 1);
                      }

                      continue valueLoop;
                    }
                  }

                }

                if(JSON.stringify(this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values[v].value) !== JSON.stringify(value.value)){
                  hasChanged = true;
                }

                this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values[v].value = value.value;
                this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values[v].time = now;
                this.FEEDBACKS.time = now;
                return hasChanged;

              }

              this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values.push(value);
              this.FEEDBACKS.time = now;
              hasChanged = true;
              return hasChanged;
            }

          }


          //-- Insert new feedback

          this.FEEDBACKS.automa[a].devices[d].feedbacks.push({
            "name": name,
            "values": [value]
          });
          this.FEEDBACKS.time = now;

          hasChanged = true;
          return hasChanged;

        }


        //-- Insert new device

        this.FEEDBACKS.automa[a].devices.push({
          "identifier": deviceIdentifier,
          "feedbacks": [
            {
              "name": name,
              "values": [value]
            }
          ]
        });
        this.FEEDBACKS.time = now;

        hasChanged = true;
        return hasChanged;

      }

    }

    return hasChanged;

  }


  //-- Returns the whole values array if no parameters where provided. If the parameters match, returns the actual value. If nothing was found, returns undefined

  public getFeedback(name: string, automaIdentifier: string, parameters?: types.Object): string | number | boolean | undefined | Array<types.Object>;
  public getFeedback(name: string, automaIdentifier: string, deviceIdentifier: string, parameters?: types.Object): string | number | boolean | undefined | Array<types.Object>;
  public getFeedback(name: string, automaIdentifier: string, deviceIdentifierOrParameters?: string | types.Object, parametersOrUndefined?: types.Object): string | number | boolean | undefined | Array<types.Object> {

    let deviceIdentifier: string | undefined;
    let parameters: types.Object = {};

    if(parametersOrUndefined !== undefined){
      parameters = parametersOrUndefined;
      deviceIdentifier = deviceIdentifierOrParameters as string;
    } else {
      if(deviceIdentifierOrParameters !== undefined){
        if(typeof deviceIdentifierOrParameters === "string"){
          deviceIdentifier = deviceIdentifierOrParameters;
        } else if(typeof deviceIdentifierOrParameters === "object"){
          parameters = deviceIdentifierOrParameters;
        }
      } else {
        parameters = {};
      }
    }

    if(typeof parameters === "string" ||
        typeof parameters === "number" ||
        typeof parameters === "boolean" ||
        typeof parameters === "bigint"){
      parameters = {
        "value": parameters
      };
    }

    for(let a = 0; a < this.FEEDBACKS.automa.length; a++){

      if(automaIdentifier !== this.FEEDBACKS.automa[a].identifier){
        continue;
      }

      if(deviceIdentifier === undefined){


        //-- Get automa feedback

        feedbackLoop: for(let af = 0; af < this.FEEDBACKS.automa[a].feedbacks.length; af++){

          if(this.FEEDBACKS.automa[a].feedbacks[af].name !== name){
            continue feedbackLoop;
          }

          for(let v = 0; v < this.FEEDBACKS.automa[a].feedbacks[af].values.length; v++){

            const objectKeys = Object.keys(this.FEEDBACKS.automa[a].feedbacks[af].values[v]);

            keyLoop: for(let ek = 0; ek < objectKeys.length; ek++){

              if(objectKeys[ek] === "value"){

                if(ek == objectKeys.length - 1){
                  return this.FEEDBACKS.automa[a].feedbacks[af].values[v][objectKeys[ek]].value;
                }

                continue keyLoop;
              }

              if(ek == objectKeys.length - 1){
                return this.FEEDBACKS.automa[a].feedbacks[af].values[v].value;
              }

              return;
            }

          }
        }

      } else {


        //-- Get device feedback

        deviceLoop: for(let d = 0; d < this.FEEDBACKS.automa[a].devices.length; d++){

          if(deviceIdentifier !== this.FEEDBACKS.automa[a].devices[d].identifier){
            continue deviceLoop;
          }

          feedbackLoop: for(let df = 0; df < this.FEEDBACKS.automa[a].devices[d].feedbacks.length; df++){

            if(this.FEEDBACKS.automa[a].devices[d].feedbacks[df].name !== name){
              continue feedbackLoop;
            }

            if(typeof parameters === "object"){

              valueLoop: for(let v = 0; v < this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values.length; v++){

                const objectKeys = Object.keys(this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values[v]);

                keyLoop: for(let ek = 0; ek < objectKeys.length; ek++){

                  if(objectKeys[ek] === "time"){
                    continue keyLoop;
                  }
                  if(objectKeys[ek] === "value"){
                    continue keyLoop;
                  }

                  let keyFound = false;

                  for(const newKey in parameters){
                    if(objectKeys[ek] === newKey){

                      keyFound = true;

                      if(typeof this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values[v][objectKeys[ek]] === "object" || typeof parameters[newKey] === "object"){
                        if(JSON.stringify(this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values[v][objectKeys[ek]]) != JSON.stringify(parameters[newKey])){
                          continue valueLoop;
                        }
                      } else {
                        if(this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values[v][objectKeys[ek]] != parameters[newKey]){
                          continue valueLoop;
                        }
                      }
                    }
                  }


                  //-- Return whole object if specific value was not found

                  if(keyFound === false){
                    return this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values;
                  }

                }

                return this.FEEDBACKS.automa[a].devices[d].feedbacks[df].values[v].value;

              }
            }

          }

          return;

        }

        return;

      }

    }

    return;

  }


  public storeFeedbacks(feedbacks: string) {
    if(functions.isParseableJSON(feedbacks)){
      const feedbackObject = JSON.parse(feedbacks);
      if(feedbackObject.automa !== undefined){
        this.FEEDBACKS = feedbackObject;
      }
    }
  }

}

export const Feedbacks = new Feedbacks_();
