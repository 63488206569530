import Cloud from "../../../shared/modules/cloud";
import TinyEventEmitter from "../../../shared/modules/tiny-event-emitter";
class NATIVE_ extends TinyEventEmitter {

  static os: string;

  private _deviceName: string = "";
  private _language: string = "";
  private _pushToken: string = "";
  private _UUID: string = "";


  constructor() {

    super();

    //@ts-ignore
    if(window.webkit !== null && window.webkit !== undefined){
      NATIVE_.os = "iOS";
    }
    //@ts-ignore
    if(window.JSInterface !== null && window.JSInterface !== undefined){
      NATIVE_.os = "android";
    }

  }


  public set name(name: string) {
    this._deviceName = decodeURIComponent(name);
  }


  public get name(): string {
    return this._deviceName;
  }


  public set language(language: string) {
    this._language = language;
  }


  public get language(): string {
    return this._language;
  }


  public set pushToken(token: string) {
    this._pushToken = token;
    Cloud.send({ "func": "update-push-token", "params": { "payload": { "token": this._pushToken }}});
  }


  public get pushToken() {
    return this._pushToken;
  }


  public set UUID(uuid: string) {
    this._UUID = uuid;
  }


  public get UUID() {
    return this._UUID;
  }


  public runCommand(command: string): void;
  public runCommand(command: string, value: string): void
  public runCommand(command: string, valueOrUndefined?: string): void {

    let value: string;

    if(valueOrUndefined === undefined){
      value = command; // Fix crash on iOS when value is undefined, will be fixed in iOS App > v1.0.2
    } else {
      value = valueOrUndefined;
    }

    if(NATIVE_.os === "iOS"){
      //@ts-ignore
      webkit.messageHandlers.jscmd.postMessage(JSON.stringify({ command, value }));
    }

    if(NATIVE_.os === "android"){
      try {
        if(command !== undefined){
          //@ts-ignore
          window.JSInterface[command](value);
        } else {
          //@ts-ignore
          window.JSInterface[command]();
        }
      } catch (err){
        console.warn("Android command " + command + " not existing: ", err);
      }
    }

  }


  public onCommand(command: string, value: string | number): any {

    this.emit("command", { "command": command, "value": value, "os": NATIVE_.os});

    if(command === "validatejs"){
      return "valid";
    }

    if(command === "back"){


      //-- If active view is main view

      if(globalThis.APP.views.current === globalThis.APP.views.main){


        //-- If view is on homescreen

        if(globalThis.APP.views.current.router.currentRoute.path === "/index.html" ||
          globalThis.APP.views.current.router.currentRoute.path === "/" ||
          globalThis.APP.views.current.router.currentRoute.path === "/app/index.html" ||
          globalThis.APP.views.current.router.currentRoute.path === "/app/"){
          return "close";
        } else {
          globalThis.APP.views.current.router.back();
          return "abort";
        }
      } else {


        //-- Start on subview

        if(globalThis.APP.views.current.router.currentRoute.path === "/index.html" ||
          globalThis.APP.views.current.router.currentRoute.path === "/" ||
          globalThis.APP.views.current.router.currentRoute.path === "/app/index.html" ||
          globalThis.APP.views.current.router.currentRoute.path === "/app/"){


          //-- If subview is in popup

          if(globalThis.APP.views.current.$el.parents(".popup").length > 0){
            const popup = globalThis.APP.popup.get(globalThis.APP.views.current.$el.parents(".popup")[0]);
            popup.close();
          }

        } else {
          globalThis.APP.views.current.router.back();
        }
      }

    }

  }

}

const NATIVE = new NATIVE_();
export default NATIVE;