import { publicTypes } from "./public";

export namespace types {


  //-- Public types

  export type SerialPort = publicTypes.SerialPort;
  export type SerialBaudRates = publicTypes.SerialBaudRates;
  export type SerialDataBits = publicTypes.SerialDataBits;
  export type SerialStopBits = publicTypes.SerialStopBits;
  export type HTTPMethod = publicTypes.HTTPMethod;
  export type IRPort = publicTypes.IRPort;

  export type AnalogPins = publicTypes.AnalogPins;
  export type DigitalPins = publicTypes.DigitalPins;
  export type GPIOEdges = publicTypes.GPIOEdges;
  export type GPIOPinModes = publicTypes.GPIOPinModes;


  //-- Private types

  export type CommandTypes = "infrared" | "bluetooth" | "other" | "tcp" | "udp" | "http" | "serial" | "macro";
  export type MacroCommandTypes = "pn" | "if" | "delay" | "macro" | "fb" | "infrared" | "bluetooth" | "other" | "tcp" | "udp" | "http" | "serial";
  export type RemoteActivityTag = "REMOTE_M1" | "REMOTE_M2" | "REMOTE_M3" | "REMOTE_M4" | "REMOTE_M5" | "REMOTE_M6";


  export enum SID {
    Error = 0,
    ServerRequestReceived = 1,
    AutomaRequestReceived = 2,
    AutomaProcessing = 3,
    ServerResponseReceived = 4,
    ClientResponseReceived = 5
  }

  export enum ProductTypes {
    Controller = 1,
    Accessory
  }


  //-- Object

  export interface Object {
    [key: string]: any;
  }


  //-- SDB

  export type SDB = Array<SDBDevice>;

  export interface SDBDevice {
    identifier: string;
    variables: Array<SDBModuleVariables>;
  }

  export interface SDBModuleVariables {
    name: string;
    value: string | boolean | number;
    time: number;
  }


  //-- PDB

  export interface PDB {
    code: string;
    time: number;
    automa: Array<Automa>;
    rooms: Array<Room>;
    customEvents: Array<CustomEvent>;
    macros: Array<Macro>;
    timers: Array<PDBTimer>;
    remotes: Array<Remote>;
    customHomescreenImages?: Array<string>;
  }


  //-- Automa

  export interface Automa {
    name: string;
    identifier: string;
    feedbacks: Array<Feedback>;
    /** @deprecated */
    events: Array<Event>;
    devices: Array<Device>;
    model: string;
  }


  //-- Modules and Devices

  export interface Device {
    identifier: string;
    type: CommandTypes;
    overrides: Overrides;
    module?: string;
    version?: string;
    page?: DevicePage;
    lastEdited?: string;
    undeployedChanges?: boolean;
  }


  export interface Overrides {
    name?: string;
    events: Array<Event>;
    commands: Array<Command | InfraredCommand>;
    feedbacks: Array<Feedback>;
    /**
     * string is deprecated.
     * false is to override/disable the value from the module.
     */
    powerStatusFeedback?: string | PowerStatusFeedback | false;
    main?: string;
    mainSource?: string;
    mainScriptEditorViewState?: any;
    gui?: string;
    guiSource?: string;
    guiScriptEditorViewState?: any;
    shared?: string;
    sharedSource?: string;
    sharedScriptEditorViewState?: any;
    css?: string;
    cssSource?: string;
    cssScriptEditorViewState?: any;
    settings?: Object;
    settingsScriptEditorViewState?: any;
    translations?: Object;
    translationsScriptEditorViewState?: any;
    creator?: Array<Command>;
    template?: string;
    files?: Array<string>;
  }


  export interface PowerStatusFeedback {
    parameters: Array<MacroAndButtonCommandOrFeedbackParameter | MacroCommandIfStatementParameter>;
  }


  export interface Module {
    identifier: string;
    name: string;
    powerStatusFeedback?: string | PowerStatusFeedback | false;
    type: string;
    events: Array<Event>;
    commands: Array<Command | InfraredCommand>;
    feedbacks: Array<Feedback>;
    version: string;
    latest?: string;
    main?: string;
    mainSource?: string;
    gui?: string;
    guiSource?: string;
    shared?: string;
    sharedSource?: string;
    css?: string;
    cssSource?: string;
    settings?: Object;
    translations?: Object;
    creator?: Array<Command>;
    template?: string;
    page?: DevicePage;
    files?: Array<string>;
    _deviceIdentifier?: string; //only used for dev upload (to know the old image path)
  }


  //-- Timers

  export interface PDBTimer {
    name: string;
    identifier: string;
    macro: string;
    undeployedChanges?: boolean;
  }


  export interface Timer {
    name: string;
    identifier: string;
    active: boolean;
    event: "t" | "r" | "ch" | "lh" | "ss" | "sr";
    days: Array<boolean>;
    actions: Array<string | { command: string; device: string; }>;
    time?: string;
    timestamp?: number;
    from?: string;
    to?: string;
    selfDestruct?: boolean;
    probability?: number;
    hidden?: boolean;
  }


  //-- Commands

  export enum HoldingPatterns {
    once,
    pulses,
    hold
  }

  export interface Command {
    name: string;
    identifier: string;
    command: any;
    commandSource?: string;
    scriptEditorViewState?: any;
    deleted?: boolean;
    parameters?: Array<CommandParameters>;
  }

  export interface CommandParameters {
    name: string;
    path: string;
    identifier: string;
    multiple?: boolean;
    optional?: boolean;
  }

  export interface InfraredProtocolCommand {
    command: number;
    protocol: string;
    frequency: number;
    device?: number;
    subdevice?: number;
    trailer?: number;
    extended?: number;
    toggle?: number;
    system?: number;
    mode?: number;
    cleaned?: Array<Array<number>>;
    longpress: boolean;
    raw?: Array<Array<number>>;
    prontoHex?: string;
  }

  export interface InfraredCommand extends Command, InfraredProtocolCommand {
    command: number;
  }


  //-- Feedbacks

  export interface Feedback {
    name: string;
    identifier: string;
    parameters?: Array<FeedbackParameters> | undefined;
    deleted?: boolean;
  }

  export interface FeedbackValues {
    automa: Array<FeedbackAutoma>;
    time: number;
  }

  export interface FeedbackAutoma {
    identifier: string;
    feedbacks: Array<FeedbackFeedbacks>;
    devices: Array<FeedbackDevice>;
  }

  export interface FeedbackDevice {
    identifier: string;
    feedbacks: Array<FeedbackFeedbacks>;
  }

  export interface FeedbackFeedbacks {
    name: string;
    values: Array<any>;
  }

  export interface FeedbackParameters {
    name: string;
    path: string;
    identifier: string;
    multiple?: boolean;
    optional?: boolean;
  }


  //-- CUstom Event

  export interface CustomEvent {
    name: string;
    identifier: string;
    parameters: Array<MacroCommandIfStatementParameter>;
  }


  //-- Events

  export interface Event {
    name: string;
    identifier: string;
    parameters?: Array<EventParameters> | undefined;
    deleted?: boolean;
  }

  export interface EventMacro {
    macro: string;
    value: any;
  }

  export interface EventParameters {
    name: string;
    path: string;
    identifier: string;
  }


  //-- Rooms

  export interface Room {
    name: string;
    identifier: string;
    on: string;
    off: string;
    pages: Array<Page> | Array<FloorplanPage>;
    volume?: RoomVolume;
  }

  export interface RoomVolume {
    device: string;
    hold: HoldingPatterns;
    type: "slider" | "buttons";
  }


  //-- Pages

  export interface Page {
    name: string;
    identifier: string;
    template: string;
    schemes?: Array<string>;
    link?: string;
    icon: string;
    on: string;
    off: string;
    buttons: Array<Button>;
    channellist?: PageChannellist;
    device?: string;
  }

  export interface PageChannellist {
    leadingZeros: number;
    cursorSelect: boolean;
    delay: number;
    identifier: string;
    channels?: Array<string>;
    customChannels?: Array<string>;
  }

  export interface DevicePage extends Page {
    disabled: boolean;
  }


  //-- Floorplan

  export interface FloorplanPage extends Page {
    stories?: Array<FloorplanStory>;
  }

  export interface FloorplanStory {
    identifier: string;
    backgroundImage: string;
    backgroundWidth: number;
    backgroundHeight: number;
    layers: Array<FloorplanLayer>;
    name: string;
  }

  export type FloorplanLayerCategory = "lamps" | "speakers" | "blinds" | "power" | "thermostats" | "cameras" | "devices" | "windows" |"other";

  export interface FloorplanLayer {
    category: FloorplanLayerCategory;
    items: Array<FloorplanItem>;
  }

  export interface FloorplanItem {
    left: number;
    top: number;
    width: number;
    height: number;
    feedback?: string;
    feedbackParameters?: Array<MacroAndButtonCommandOrFeedbackParameter>;
    feedbackDevice?: string;
    actionIdentifier?: string;
    actionDevice?: string;
    identifier: string;
    icon: string;
    parameters?: Array<MacroAndButtonCommandOrFeedbackParameter>;
    hold: HoldingPatterns;
  }


  //-- GUI Commands

  export interface Button {
    actionIdentifier?: string;
    actionDevice?: string;
    tag: string;
    feedback?: string;
    feedbackParameters?: Array<MacroAndButtonCommandOrFeedbackParameter>;
    feedbackDevice?: string;
    parameters?: Array<MacroAndButtonCommandOrFeedbackParameter>;
    hold?: HoldingPatterns;
    decorations?: ButtonTextDecorationObject;
  }

  export interface ButtonTextDecorationObject {
    heavy?: boolean;
    italic?: boolean;
    underline?: boolean;
    color?: string;
    label?: string;
    icon?: boolean;
    size?: number;
  }


  //-- Macros

  export interface Macro {
    identifier: string;
    commands: Array<MacroCommand>;
    type: "page-on" | "page-off" | "event" | "timer" | "room-off" | "room-on" | "custom-event";
    parameters?: Array<MacroAndButtonCommandOrFeedbackParameter>;
    room?: string;
    page?: string;
    timer?: string;
    event?: string;
    customEvent?: string;
  }

  export interface MacroCommand {
    actionIdentifier?: string;
    type: MacroCommandTypes;
    identifier: string;
    parameters: Array<MacroAndButtonCommandOrFeedbackParameter | MacroCommandIfStatementParameter>;
    actionDevice?: string;
    actionAutoma?: string;
    subcommands?: Array<MacroCommand>;
    hold?: HoldingPatterns;
  }

  export interface MacroAndButtonCommandOrFeedbackParameter {
    value: string | number | boolean | Object | Array<string> | Array<number>;
    device?: string;
    template?: string;
  }

  export interface MacroCommandIfStatementParameter extends MacroAndButtonCommandOrFeedbackParameter {
    operator: string;
  }


  //-- Remotes

  export interface Remote {
    name: string;
    identifier: string;
    activities: Array<RemoteActivity>;
  }

  export interface RemoteActivity {
    page: string | undefined;
    tag: RemoteActivityTag;
    buttons: Array<Button>;
  }

  export interface HTTPRequestOptions {
    url?: string;
    port?: number;
    method?: HTTPMethod;
    keepAlive?: boolean;
    body?: string;
    timeout?: number;
    headers?: object;
    cache?: boolean;
    rejectUnauthorized?: boolean;
  }

  export interface HTTPResponseObject {
    sid: SID;
    ip: string;
    response: string;
  }


  //-- Clients

  export interface Client {
    online: boolean;
    device: "app" | "automa" | "iframe" | "shell";
    version: string | undefined;
    name: string;
    identifier: string;
    isUpdating: boolean;
    lastseen: number;
    remoteIP: string;
    activated: boolean;
    blocked: boolean;
    code: string;
    settings: ClientSettings;
    pushToken?: string;
    internalIP?: string;
  }


  export interface ClientSettings {
    network?: {
      eth0?: NetworkEth0;
      wlan0?: NetworkWlan0;
    };
    timezone?: string;
    thresholds?: {
      optical1?: number;
      optical2?: number;
      usb1?: number;
      usb2?: number;
    };
  }

  export interface NetworkEth0 {
    dhcp: boolean;
    ip?: string;
    subnet?: string;
    gateway?: string;
    dns?: string;
    dns2?: string;
  }

  export interface NetworkWlan0 extends NetworkEth0 {
    password?: string;
    ssid?: string;
    bssid?: string;
  }

}