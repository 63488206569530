import TabNavigationClassConstructor from "./f7.tab-navigation.class";

let TabNavigation;
export default {
  name: "tab-navigation",

  install() {
    const Framework7 = this;
    TabNavigation = TabNavigationClassConstructor(Framework7.Class);
    Framework7.TabNavigation = TabNavigation;
  },
  create: function(instance) {

    const app = this;
    const $ = app.$;

    app.TabNavigation = {
      create: function(params) {
        return new TabNavigation(app, params);
      },
      get: function(el = ".tab-navigation") {
        if(el instanceof TabNavigation){
          return el;
        }
        const $el = $(el);
        if($el.length === 0){
          return undefined;
        }
        return $el[0].f7TabNavigation;
      },
      destroy: function(el = ".tab-navigation") {
        const instance = app.TabNavigation.get(el);
        if(instance && instance.destroy){
          return instance.destroy();
        }
        return undefined;
      }

    };

    return () => {};
  },
  on: {
    init() {

    },
    pageInit(page) {
      try {

        const $ = page.app.$;
        const app = page.app;

        page.$el.find(".tab-navigation-init").each((tabNavigationEl, index) => {
          const $tabNavigationEl = $(tabNavigationEl);
          const params = { el: tabNavigationEl };
          app.TabNavigation.create(app.utils.extend(params, $tabNavigationEl.dataset()));
        });

      } catch (err){
        console.error("tabNavigation error: ", err);
      }
    }
  }
};
