import Framework7 from "framework7";

import Dom7 from "../../../shared/modules/dom7";
import { Dom7Array } from "dom7";

import "./f7.tab-navigation.css";

const $$ = Dom7;

//https://jsfiddle.net/n3uevyqh/10/

export default function(Framework7Class) {
  return class TabNavigation extends Framework7Class {

    public $el: Dom7Array | undefined;

    private _init: boolean = true;
    private _height: number | undefined;
    private _fullWidth: boolean = false;
    private _clickEvent: (event) => void;

    constructor(app: Framework7, params) {

      super(params, [app]);

      this._clickEvent = this._onClick.bind(this);

      if(params.el !== undefined){

        this.$el = $$(params.el);
        this._init = params.init || this._init;

        if(this._init !== false){
          this.init();
        }

      }

    }


    public init() {


      //-- Make sure content is rendered

      requestAnimationFrame(() => {

        if(this.$el === undefined){
          return;
        }


        //-- Set container height

        const height = this.$el.height();
        this.$el.css("height", height + "px");

        if(this.$el.hasClass("tab-navigation-full-width")){
          this._fullWidth = true;
        }

        const children = this.$el.children();


        //-- Set initial active state

        for(let c = 0; c < children.length; c++){

          if(this._fullWidth === true){

            const marginLeft = +getComputedStyle((children[c] as HTMLElement)).marginLeft.replace("px", "");
            const marginRight = +getComputedStyle((children[c] as HTMLElement)).marginRight.replace("px", "");
            const paddingLeft = +getComputedStyle((children[c] as HTMLElement)).paddingLeft.replace("px", "");
            const paddingRight = +getComputedStyle((children[c] as HTMLElement)).paddingRight.replace("px", "");

            (children[c] as HTMLElement).style.setProperty("width", "calc(" + (100 / children.length) + "% - " + (marginLeft + marginLeft) + "px)");
          }

          this._updateElement(children[c] as HTMLElement);

        }


        //-- Add mutation observer

        const observer = new MutationObserver(ev => {
          for(let e = 0; e < ev.length; e ++){
            const target = ev[e].target as HTMLElement;
            this._updateElement(target);
          }
        });

        for(let c = 0; c < children.length; c++){
          observer.observe(children[c], {
            attributes: true,
            attributeFilter: ["class"],
            childList: false,
            characterData: false
          });
        }


        //-- Add event listener

        this.$el.on("click", ".tab", this._clickEvent);

      });

    }


    private _updateElement(el: HTMLElement) {

      if(this.$el === undefined){
        return;
      }

      if(el.classList.contains("active") || el.classList.contains("tab-link-active")){

        const marginTop = +getComputedStyle(el).marginTop.replace("px", "");
        const height = el.offsetHeight;
        const width = el.offsetWidth;
        const posLeft = el.offsetLeft;

        const containerMarginLeft = +getComputedStyle(this.$el[0]).marginLeft.replace("px", "");

        let containerConatainerPaddingLeft = +getComputedStyle(this.$el.parent()[0]).paddingLeft.replace("px", "");

        if(containerConatainerPaddingLeft !== 0){
          containerConatainerPaddingLeft = containerConatainerPaddingLeft + containerMarginLeft;
        }

        (this.$el[0] as HTMLElement).style.setProperty("--tab-navigation-element-pos-top", (height + marginTop) + "px");
        (this.$el[0] as HTMLElement).style.setProperty("--tab-navigation-element-pos-left", (posLeft - containerConatainerPaddingLeft) + "px");
        (this.$el[0] as HTMLElement).style.setProperty("--tab-navigation-element-width", width + "px");
        (this.$el[0] as HTMLElement).style.setProperty("--tab-navigation-element-height", height + "px");


        //-- Add animation class on next eventloop cycle

        requestAnimationFrame(() => {

          if(this.$el === undefined){
            return;
          }

          this.$el.addClass("animated");

        });


      }

    }


    private _onClick(ev) {

      if(this.$el === undefined){
        return;
      }

      const children = this.$el.children();

      for(let c = 0; c < children.length; c++){
        if(ev.target === children[c]){
          children[c].classList.add("active");
        } else {
          children[c].classList.remove("active");
        }
      }

    }


    public destroy() {
      if(this.$el !== undefined){
        this.$el.off("click", ".tab", this._clickEvent);
      }
    }

  };


}