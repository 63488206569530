
class Splitview {

  private _resizeEvent: (ev: UIEvent) => void;

  private _lastMode: "phone" | "tablet" | undefined;

  constructor() {
    this._resizeEvent = this._resize.bind(this);
    window.addEventListener("resize", this._resizeEvent);
  }


  private _resize(ev: UIEvent) {
    window.requestAnimationFrame(this.update.bind(this));
  }


  public update() {

    const splitviews = document.querySelectorAll(".splitview");

    if(splitviews.length <= 0){
      return;
    }

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const breakpoint = 768;
    const currentMode = vw < breakpoint ? "phone" : "tablet";

    if(this._lastMode === currentMode){
      // return; // Uncommented because this fails if the user goes from splitview back to the homescreen and into the splitview again
    }

    for(let s = 0; s < splitviews.length; s++){

      const splitview = splitviews[s] as HTMLElement;

      const swiper: HTMLElement | null = splitview.classList.contains("swiper-container") ? splitview : splitview.querySelector(".swiper-container");
      const tabs = splitview.querySelectorAll(".tab");
      const activeTab = this._getActiveTab(tabs);
      const tabLinks = splitview.querySelectorAll(".tab-link");
      const tabbars = splitview.querySelectorAll(".tabbar");

      for(let t = 0; t < tabs.length; t++){
        if(tabs[t] !== activeTab){
          globalThis.APP.tab.show(tabs[t]);
          break;
        }
      }

      globalThis.APP.tab.show(activeTab);


      if(vw < breakpoint){


        //-- Switch to next tab if tab is hidden on phone

        if(activeTab?.classList.contains("tablet")){
          for(let t = 0; t < tabs.length; t++){
            if(!tabs[t].classList.contains("tablet")){
              globalThis.APP.tab.show(tabs[t], false);
              break;
            }
          }
        }


        //-- Update tabbar highlight

        for(let t = 0; t < tabbars.length; t++){
          if(tabbars[t].classList.contains("phone")){
            globalThis.APP.toolbar.setHighlight(tabbars[t]);
          }
        }


        //@ts-expect-error
        if(swiper !== null && swiper.swiper !== undefined){

          if(splitview.classList.contains("phone-no-tabs")){
            this._detachEvents(swiper);
          } else {
            this._attachEvents(swiper);
          }

        }

      } else {


        //-- Switch to next tab if tab is hidden on tablet

        if(activeTab?.classList.contains("phone")){
          for(let t = 0; t < tabs.length; t++){
            if(!tabs[t].classList.contains("phone")){
              globalThis.APP.tab.show(tabs[t], false);
              break;
            }
          }
        }


        //-- Update tabbar highlight

        for(let t = 0; t < tabbars.length; t++){
          if(tabbars[t].classList.contains("tablet")){
            globalThis.APP.toolbar.setHighlight(tabbars[t]);
          }
        }


        //@ts-expect-error
        if(swiper !== null && swiper.swiper !== undefined){

          if(splitview.classList.contains("tablet-no-tabs")){
            this._detachEvents(swiper);
          } else {
            this._attachEvents(swiper);
          }

        }

      }

    }

    this._lastMode = currentMode;

  }


  private _getActiveTab(tabs: NodeListOf<Element>): Element | undefined {
    for(let t = 0; t < tabs.length; t++){
      if(tabs[t].classList.contains("tab-active")){
        return tabs[t];
      }
    }
    return;
  }


  private _getActiveTabLink(tabLinks: NodeListOf<Element>): Element | undefined {
    for(let t = 0; t < tabLinks.length; t++){
      if(tabLinks[t].classList.contains("tab-link-active")){
        return tabLinks[t];
      }
    }
    return;
  }


  private _attachEvents(swiper: HTMLElement) {

    //@ts-expect-error
    if(swiper === null || swiper.swiper === undefined){
      return;
    }

    if(swiper.getAttribute("swiper-events") === "false"){
      //@ts-expect-error
      swiper.swiper.attachEvents();
      swiper.setAttribute("swiper-events", "true");
    }

  }


  private _detachEvents(swiper: HTMLElement) {

    //@ts-expect-error
    if(swiper === null || swiper.swiper === undefined){
      return;
    }

    const swiperWrapper: HTMLElement | null = swiper.querySelector(".swiper-wrapper");

    if(swiperWrapper !== null){
      swiperWrapper.style.removeProperty("transform");
    }

    if(swiper.getAttribute("swiper-events") === "true" || swiper.getAttribute("swiper-events") === null){
      //@ts-expect-error
      swiper.swiper.detachEvents();
      swiper.setAttribute("swiper-events", "false");
    }

  }

}

const splitview = new Splitview();
export default splitview;