
import { types } from "../../../shared/interfaces/types";

import * as functions from "../../../shared/functions/functions";

import Cloud from "../../../shared/modules/cloud";

import { Storage } from "../storage";
import { Database } from "../../../shared/modules/database";
import { Feedbacks } from "../../../shared/modules/feedbacks";
import { changeLocation } from "../location/index";
import { checkCondition } from "../../../shared/functions/condition";

import HTTPS_REQUEST from "../https-request";

import Dom7 from "../../../shared/modules/dom7";
import TinyEventEmitter from "../../../shared/modules/tiny-event-emitter";

import NATIVE from "../native/native";
import * as common from "../../../common/js/common";
import { XMLParser } from "fast-xml-parser";
import { pointerdown } from "../event-names";


const $$ = Dom7;

let CH_CLIENTS: Array<types.Client> = [];


class CH_API_ extends TinyEventEmitter {

  static pendingFeedbacks: Array<types.Object> = [];
  static feedbackSuppressionTimeout: NodeJS.Timeout | number | undefined;

  constructor() {

    super();

    Database.on("cloudValueChanged", (name, value, deviceIdentifier, time) => {

      const userscript = common.getUserScriptByDeviceIdentifier(deviceIdentifier);

      if(userscript !== undefined){
        userscript.emit("cloudvaluechanged", { name, value });
      }

    });

  }


  public isParseableJSON(json: string) {
    return functions.isParseableJSON(json);
  }


  public isStringifiableJSON(json: string) {
    return functions.isStringifyableJSON(json);
  }


  public XML2JSON(xml: string): types.Object {
    return this.xml2json(xml);
  }


  public xml2json(xml: string): types.Object {
    return new XMLParser().parse(xml);
  }


  public getIdentifier(): string | undefined {
    return Database.getIdentifier();
  }


  public getDeviceName(): string {
    return NATIVE.name === "" ? globalThis.APP.device.os : NATIVE.name;
  }


  public getCode(): string | undefined {
    return Database.getCode();
  }


  public async getValue(name: string, deviceIdentifier: string) {
    return this.getData(name, deviceIdentifier);
  }

  public async getData(dataname: string, deviceIdentifier?: string) {

    const code = this.getCode();

    if(code === undefined){
      console.warn("CH_API.getData warning: code is undefined");
      return;
    }

    return await Storage.getData(code + "-" + (deviceIdentifier ?? "") + "-" + dataname);

  }


  public async storeValue(dataname: string, data: string | number | boolean, deviceIdentifier: string) {
    this.storeData(dataname, data, deviceIdentifier);
  }


  public async storeData(dataname: string, data: string | number | boolean, deviceIdentifier?: string) {

    const code = this.getCode();

    if(code === undefined){
      console.warn("CH_API.storeDataSync warning: code is undefined");
      return;
    }

    await Storage.storeData(code + "-" + (deviceIdentifier ?? "") + "-" + dataname, data);

  }


  public async deleteValue(dataname: string, deviceIdentifier: string) {
    this.deleteData(dataname, deviceIdentifier);
  }


  public async deleteData(dataname: string, deviceIdentifier?: string) {

    const code = this.getCode();

    if(code === undefined){
      return;
    }

    return await Storage.deleteData(code + "-" + (deviceIdentifier ?? "") + "-" + dataname);

  }


  public httpRequest(options: types.HTTPRequestOptions, automaIdentifier: string);
  public httpRequest(options: types.HTTPRequestOptions, callback: Function, automaIdentifier: string);
  public httpRequest(url: string, automaIdentifier: string);
  public httpRequest(url: string, options: types.HTTPRequestOptions, automaIdentifier: string);
  public httpRequest(url: string, options: types.HTTPRequestOptions, callback: Function, automaIdentifier: string);
  public httpRequest(urlOrOptions: string | types.HTTPRequestOptions, optionsOrCallbackOrAutomaIdentifier?: types.HTTPRequestOptions | Function | string, callbackOrAutomaIdentifierOrUndefined?: Function | string | undefined, automaIdentiferOrUndefinedOrUndefined?: string | undefined) {

    let url: string;
    let options: types.HTTPRequestOptions = {};
    let callback: Function | undefined;
    let automaIdentifier: string;

    if(typeof urlOrOptions === "string"){
      options.url = urlOrOptions;
    } else if(typeof urlOrOptions === "object"){
      options = urlOrOptions;
    }

    if(typeof optionsOrCallbackOrAutomaIdentifier === "object"){
      options = optionsOrCallbackOrAutomaIdentifier;
    } else if(typeof optionsOrCallbackOrAutomaIdentifier === "function"){
      callback = optionsOrCallbackOrAutomaIdentifier;
    } else if(typeof optionsOrCallbackOrAutomaIdentifier === "string"){
      automaIdentifier = optionsOrCallbackOrAutomaIdentifier;
    }

    if(typeof callbackOrAutomaIdentifierOrUndefined === "function"){
      callback = callbackOrAutomaIdentifierOrUndefined;
    } else if(typeof callbackOrAutomaIdentifierOrUndefined === "string"){
      automaIdentifier = callbackOrAutomaIdentifierOrUndefined;
    }

    if(typeof automaIdentiferOrUndefinedOrUndefined === "string"){
      automaIdentifier = automaIdentiferOrUndefinedOrUndefined;
    }


    //-- Check if device is activated and not iframe

    if(CH_PRIVATE.getDevice() === "iframe"){
      return Promise.reject("Device is not activated");
    }

    return new Promise((resolve, reject) => {

      if(options.url === undefined){
        reject("No url provided.");
        if(callback !== undefined){
          callback("No url provided.");
        }
        return;
      }

      Cloud.send({
        "func": "http-request",
        "params": {
          "target": automaIdentifier,
          "targetDevice": "automa",
          "payload": {
            "request": options
          }
        }
      }, data => {

        if(data.params.payload === undefined){
          return;
        }

        const httpRequest = new HTTPS_REQUEST(data.params.payload);

        if(callback !== undefined){
          callback(httpRequest);
        }

        if(data.params.payload.sid === 0){
          reject(httpRequest);
        }

        if(data.params.payload.sid === 5){
          resolve(httpRequest);
        }

      });

    });

  }


  public async runFunctionOnApps(deviceIdentifier: string, functionName: string, data?: string | number | boolean | types.Object): Promise<any> {

    for(const client of this.getClients()){
      if(client.device === "app" && client.online === true && client.identifier !== this.getIdentifier()){
        Cloud.send({
          "func": "run-function-on-app",
          "params": {
            "target": client.identifier,
            "targetDevice": "app",
            "payload": {
              "deviceIdentifier": deviceIdentifier,
              "functionName": functionName,
              "argument": data
            }
          }
        });
      }
    }

    return this.runFunctionOnApp(deviceIdentifier, functionName, data);

  }


  public async runFunctionOnApp(deviceIdentifier: string, functionName: string, data?: string | number | boolean | types.Object): Promise<any> {


    //-- Check if device is activated and not iframe

    if(CH_PRIVATE.getDevice() === "iframe"){
      return Promise.reject("Device is not activated");
    }

    const userscript = common.getUserScriptByDeviceIdentifier(deviceIdentifier);

    if(typeof userscript[functionName] === "function"){
      return await userscript[functionName](data);
    }

  }


  public runFunctionOnController(automaIdentifier: string, deviceIdentifier: string, functionName: string, data: string | number | boolean | types.Object, callback?: Function): Promise<any>;
  public runFunctionOnController(automaIdentifier: string, deviceIdentifier: string, functionName: string, callback?: Function): Promise<any>;
  public runFunctionOnController(automaIdentifier: string, deviceIdentifier: string, functionName: string, dataOrCallbackOrUndefined?: string | number | boolean | types.Object | Function | undefined, callbackOrUndefined?: Function | undefined): Promise<any> {

    let data: string | number | boolean | types.Object | undefined;
    let callback: Function | undefined;

    if(typeof dataOrCallbackOrUndefined === "string" || typeof dataOrCallbackOrUndefined === "number" || typeof dataOrCallbackOrUndefined === "boolean" || typeof dataOrCallbackOrUndefined === "object"){
      data = dataOrCallbackOrUndefined;
    } else if(typeof dataOrCallbackOrUndefined === "function"){
      callback = dataOrCallbackOrUndefined;
    }
    if(typeof callbackOrUndefined === "function"){
      callback = callbackOrUndefined;
    }


    //-- Check if device is activated and not iframe

    if(CH_PRIVATE.getDevice() === "iframe"){
      return Promise.reject("Device is not activated");
    }

    return new Promise((resolve, reject) => {
      Cloud.send({
        "func": "run-function-on-controller",
        "params": {
          "target": automaIdentifier,
          "targetDevice": "automa",
          "payload": {
            "deviceIdentifier": deviceIdentifier,
            "functionName": functionName,
            "argument": data
          }
        }
      }, data => {

        if(data.params.payload === undefined){
          return;
        }

        if(callback !== undefined){
          callback(data.params.payload);
        }

        if(data.params.payload.sid === 0){
          reject(data.params.payload.statustext);
        }

        if(data.params.payload.sid === 5){
          resolve(data.params.payload.response);
        }

      });

    });

  }


  public async triggerEvent(automaIdentifier: string, deviceIdentifier: string, eventName: string, parameters?: types.Object) {

    const PDB = CH_PRIVATE.getPDB();

    if(PDB === undefined){
      return;
    }

    automaLoop: for(const automa of PDB.automa){

      if(automa.identifier !== automaIdentifier){
        continue automaLoop;
      }

      let event: types.Event | undefined;

      if(automa.events !== undefined){
        for(let e = 0; e < automa.events.length; e++){
          if(automa.events[e].name === eventName){
            event = automa.events[e];
          }
        }
      }

      deviceLoop: for(const device of automa.devices){

        if(device.identifier !== deviceIdentifier){
          continue deviceLoop;
        }

        if(device.module !== undefined){

          const mod = Database.getModuleByIdentifier(device.module, device.version);

          if(mod !== undefined){
            for(let e = 0; e < mod.events.length; e++){
              if(mod.events[e].name === eventName){
                event = mod.events[e];
              }
            }
          }

        }

        if(device.overrides.events !== undefined){
          for(let e = 0; e < device.overrides.events.length; e++){
            if(device.overrides.events[e].name === eventName){
              event = device.overrides.events[e];
            }
          }
        }

      }

      if(event === undefined){
        return;
      }

      macroLoop: for(const macro of PDB.macros){

        if(macro.event === undefined){
          continue macroLoop;
        }

        if(macro.event !== event.identifier){
          continue macroLoop;
        }

        if(event.parameters === undefined){
          CH_API.runMacro(macro.identifier);
          return;
        } else {

          if(macro.parameters === undefined){
            continue macroLoop;
          }

          if(parameters === undefined){
            continue macroLoop;
          }

          let allParametersFound = true;

          if(macro.parameters.length !== Object.keys(parameters).length){
            continue macroLoop;
          }

          parameterLoop: for(const macroParameter of macro.parameters){
            for(const key in parameters){
              for(const eventParameter of event.parameters){


                //-- Keypair found

                if(macroParameter.template === eventParameter.identifier && eventParameter.name === key){

                  if(typeof parameters[key] === "string"){


                    //-- if parameters was directly the identifier

                    if(macroParameter.value != parameters[key]){
                      continue macroLoop;
                    }

                  } else if(typeof parameters[key] === "object"){


                    //-- if parameters was directly the whole object

                    if(macroParameter.value != parameters[key].identifier && macroParameter.value != parameters[key][".identifier"]){
                      continue macroLoop;
                    }

                  }

                  continue parameterLoop;

                }

              }
            }

            allParametersFound = false;

          }

          if(allParametersFound === false){
            continue macroLoop;
          }

          CH_API.runMacro(macro.identifier);

        }

      }

    }

  }


  public setFeedback(name: string, value: number | string | types.Object, automaIdentifier: string, broadcast?: boolean): void;
  public setFeedback(name: string, value: number | string | types.Object, automaIdentifier: string, deviceIdentifier?: string, broadcast?: boolean): void;
  public setFeedback(name: string, value: number | string | types.Object, automaIdentifier: string, deviceIdentifierOrBroadcast?: string | boolean, broadcast?: boolean): void {

    try {


      //-- Check if device is activated and not iframe

      if(CH_PRIVATE.getDevice() === "iframe"){
        return;
      }

      let deviceIdentifier: string | undefined;

      if(broadcast !== undefined){
        deviceIdentifier = deviceIdentifierOrBroadcast as string;
      } else {
        if(typeof deviceIdentifierOrBroadcast === "boolean"){
          broadcast = deviceIdentifierOrBroadcast;
        } else {
          deviceIdentifier = deviceIdentifierOrBroadcast;
          broadcast = false;
        }
      }

      const feedbackChanged = Feedbacks.setFeedback(name, value, automaIdentifier, deviceIdentifier);

      if(feedbackChanged === true){
        if(broadcast === true){

          let needsPush = true;

          for(const pendingFeedback of CH_API_.pendingFeedbacks){
            if(pendingFeedback.automaIdentifier === automaIdentifier
              && pendingFeedback.deviceIdentifier === deviceIdentifier
              && pendingFeedback.name === name
              && functions.isObject(pendingFeedback.value)
              && functions.deepEqual(pendingFeedback.value, value, ["value", "time"])){
              pendingFeedback.value = value;
              needsPush = false;
            }
          }

          if(needsPush === true){
            CH_API_.pendingFeedbacks.push({ automaIdentifier, deviceIdentifier, name, value });
          }

          if(CH_API_.feedbackSuppressionTimeout === undefined){

            CH_API_.feedbackSuppressionTimeout = setTimeout(() => {

              while(CH_API_.pendingFeedbacks.length > 0){

                const pendingFeedback = CH_API_.pendingFeedbacks.shift();

                if(pendingFeedback === undefined){
                  return;
                }

                Cloud.send({
                  "func": "set-feedback",
                  "params": {
                    "payload": {
                      "automaIdentifier": pendingFeedback.automaIdentifier,
                      "deviceIdentifier": pendingFeedback.deviceIdentifier,
                      "name": pendingFeedback.name,
                      "value": pendingFeedback.value
                    }
                  }
                });

              }

              CH_API_.feedbackSuppressionTimeout = undefined;

            }, 100);

          }

        }

      }

      this.updateFeedback({ name, value, automaIdentifier, deviceIdentifier });


      //-- Emit event

      this.emit("feedbackChanged", { name, value, automaIdentifier, deviceIdentifier });

    } catch (err){
      console.error("CH_API.setFeedback error: ", err);
    }

  }


  public updateFeedback(obj?: { name: string; automaIdentifier: string; deviceIdentifier?: string; value?: number | string | boolean | types.Object; }): void {

    try {

      const { name, automaIdentifier, deviceIdentifier, value } = obj ?? {};


      //-- Update all feedbacks

      if(obj === undefined || value === undefined){

        for(const automa of Feedbacks.FEEDBACKS.automa){
          if(automaIdentifier !== undefined && automaIdentifier !== automa.identifier){
            continue;
          }
          for(const device of automa.devices){
            if(deviceIdentifier !== undefined && deviceIdentifier !== device.identifier){
              continue;
            }
            for(const deviceFeedback of device.feedbacks){
              if(name !== undefined && name !== deviceFeedback.name){
                continue;
              }
              for(const feedbackValue of deviceFeedback.values){
                CH_API.updateFeedback({ name: deviceFeedback.name, automaIdentifier: automa.identifier, deviceIdentifier: device.identifier, value: feedbackValue });
              }
            }
          }
          for(const automaFeedback of automa.feedbacks){
            if(name !== undefined && name !== automaFeedback.name){
              continue;
            }
            for(const feedbackValue of automaFeedback.values){
              CH_API.updateFeedback({ name: automaFeedback.name, automaIdentifier: automa.identifier, value: feedbackValue });
            }
          }
        }

        return;

      }


      //-- Update gui

      let feedbackStatusSelectorString: string | undefined;
      let feedbackTextSelectorString: string | undefined;
      let valueContent: string | number | boolean | undefined;


      //-- Get real value content

      if(typeof value === "object"){
        if(value.value !== undefined){
          valueContent = value.value;
        }
      } else {
        valueContent = value;
      }

      if(valueContent === undefined){
        return;
      }


      //-- Get feedback DOM elements

      if(typeof value === "object"){

        let parameterSelectors = "";

        keyLoop: for(const key in value){
          if(key === "value" || key === "time"){
            continue keyLoop;
          }

          if(typeof value[key] === "object"){
            const identifier = value[key].identifier || value[key][".identifier"];
            parameterSelectors += `[feedback-parameter-${key}="${identifier}"]`;
          } else {
            parameterSelectors += `[feedback-parameter-${key}="${value[key]}"]`;
          }
        }


        //-- Set feedback-status selector

        feedbackStatusSelectorString = `
          [device="${deviceIdentifier}"] ${parameterSelectors}[feedback-status="${name}"]:not([feedback-device]),
          [device="${deviceIdentifier}"] ${parameterSelectors}:not([feedback-device]) [feedback-status="${name}"]:not([feedback-device]),
          [feedback-device="${deviceIdentifier}"]${parameterSelectors}[feedback-status="${name}"],
          [feedback-device="${deviceIdentifier}"]${parameterSelectors} [feedback-status="${name}"],
          [feedback-device="${deviceIdentifier}"] ${parameterSelectors}[feedback-status="${name}"],
          [feedback-device="${deviceIdentifier}"] ${parameterSelectors} [feedback-status="${name}"],
          [feedback-controller="${automaIdentifier}"]${parameterSelectors}[feedback-status="${name}"],
          [feedback-controller="${automaIdentifier}"]${parameterSelectors} [feedback-status="${name}"],
          [feedback-controller="${automaIdentifier}"] ${parameterSelectors} [feedback-status="${name}"],
          [feedback-controller="${automaIdentifier}"] ${parameterSelectors}[feedback-status="${name}"]
        `;


        //-- Set feedback-text selector

        feedbackTextSelectorString = `
          [device="${deviceIdentifier}"] ${parameterSelectors}[feedback-text="${name}"]:not([feedback-device]),
          [device="${deviceIdentifier}"] ${parameterSelectors}:not([feedback-device]) [feedback-text="${name}"]:not([feedback-device]),
          [feedback-device="${deviceIdentifier}"]${parameterSelectors}[feedback-text="${name}"],
          [feedback-device="${deviceIdentifier}"]${parameterSelectors} [feedback-text="${name}"],
          [feedback-device="${deviceIdentifier}"] ${parameterSelectors}[feedback-text="${name}"],
          [feedback-device="${deviceIdentifier}"] ${parameterSelectors} [feedback-text="${name}"],
          [feedback-controller="${automaIdentifier}"]${parameterSelectors}[feedback-text="${name}"],
          [feedback-controller="${automaIdentifier}"]${parameterSelectors} [feedback-text="${name}"],
          [feedback-controller="${automaIdentifier}"] ${parameterSelectors}[feedback-text="${name}"],
          [feedback-controller="${automaIdentifier}"] ${parameterSelectors} [feedback-text="${name}"]
        `;

      } else {


        //-- Set feedback-status selector

        feedbackStatusSelectorString = `
          [feedback-device="${deviceIdentifier}"][feedback-status="${name}"],
          [feedback-device="${deviceIdentifier}"] [feedback-status="${name}"],
          [feedback-controller="${automaIdentifier}"][feedback-status="${name}"],
          [feedback-controller="${automaIdentifier}"] [feedback-status="${name}"],
          [feedback-controller="${automaIdentifier}"][feedback-status="${name}"],
          [feedback-controller="${automaIdentifier}"] [feedback-status="${name}"]
        `;


        //-- Set feedback-text selector

        feedbackTextSelectorString = `
          [feedback-device="${deviceIdentifier}"][feedback-text="${name}"],
          [feedback-device="${deviceIdentifier}"] [feedback-text="${name}"],
          [feedback-controller="${automaIdentifier}"][feedback-text="${name}"],
          [feedback-controller="${automaIdentifier}"] [feedback-text="${name}"],
          [feedback-controller="${automaIdentifier}"][feedback-text="${name}"],
          [feedback-controller="${automaIdentifier}"] [feedback-text="${name}"]
        `;

      }


      //-- Status feedback

      if(feedbackStatusSelectorString !== undefined){

        const feedbackStatusElements = document.querySelectorAll(feedbackStatusSelectorString);

        if(feedbackStatusElements.length > 0){


          //-- Normal feedbacks

          let valueTrue = false;

          if(typeof valueContent === "boolean"){
            valueTrue = valueContent == true;
          } else if(!isNaN(+valueContent)){
            valueTrue = +valueContent >= 1;
          } else if(typeof valueContent === "string"){
            valueTrue = valueContent == "true";
          }

          feedbackStatusElements.forEach(feedbackStatusElement => {


            //-- Conditioned feedbacks

            const conditionOperator = feedbackStatusElement.getAttribute("feedback-condition-operator") ?? undefined;
            const conditionValue = feedbackStatusElement.getAttribute("feedback-condition-value") ?? undefined;

            if(conditionOperator !== undefined && conditionValue !== undefined){

              const feedbackName = feedbackStatusElement.getAttribute("feedback-status") ?? undefined;
              const feedbackDevice = feedbackStatusElement.getAttribute("feedback-device") ?? undefined;
              const feedbackAutoma = feedbackStatusElement.getAttribute("feedback-controller") ?? undefined;

              if(feedbackName !== undefined && (feedbackDevice !== undefined || feedbackAutoma !== undefined)){

                const feedback = feedbackDevice !== undefined ? Database.getDeviceFeedbackByName(feedbackName, feedbackDevice) : Database.getAutomaFeedbackByName(feedbackName, feedbackAutoma!);

                if(feedback !== undefined){


                  //-- Reconstruct parameters

                  const parameters: types.PowerStatusFeedback["parameters"] = [
                    {
                      template: feedback.identifier,
                      "device": feedbackDevice,
                      "operator": conditionOperator,
                      "value": conditionValue
                    }
                  ];

                  if(feedback.parameters !== undefined){
                    for(const parameter of feedback.parameters){
                      const value = feedbackStatusElement.getAttribute(`feedback-parameter-${parameter.name}`) ?? undefined;
                      if(value !== undefined){
                        parameters.push({
                          template: parameter.identifier,
                          value: value
                        });
                      }
                    }
                  }

                  const powerStatusFeedback: types.PowerStatusFeedback = {
                    parameters
                  };


                  //-- Create fake power status feedback

                  valueTrue = checkCondition(powerStatusFeedback) ?? false;

                }
              }

            }

            if(feedbackStatusElement.classList.contains("floorplan-item")){

              const identifier = feedbackStatusElement.getAttribute("identifier");

              if(identifier === null){
                return;
              }


              //-- Extract colors and brightness

              let rollerPosition: number | undefined;
              let brightness: number | undefined;
              let red: number | undefined;
              let green: number | undefined;
              let blue: number | undefined;

              if(typeof valueContent === "string"){

                const rgba = valueContent.match(/#([0-F]{2})([0-F]{2})([0-F]{2})([0-F]{2})?/i);

                if(rgba !== null){

                  red = parseInt(rgba[1], 16);
                  green = parseInt(rgba[2], 16);
                  blue = parseInt(rgba[3], 16);

                  if(rgba[4] !== undefined){
                    brightness = parseInt(rgba[4], 16);
                  }

                }

              } else if(typeof valueContent === "number"){
                brightness = valueContent;
                rollerPosition = valueContent;
              } else if(typeof valueContent === "boolean"){
                brightness = valueContent ? 255 : 0;
                rollerPosition = valueContent === true ? 100 : 0;
              }

              if(brightness !== undefined){

                if(brightness > 0){
                  valueTrue = true;
                } else {
                  valueTrue = false;
                }


                //-- Map value between 0...1

                brightness = functions.mapNumber(brightness, 0, 255, 0, 1);


                //-- Limit max brightness to 0.9 opacity for better visibility

                brightness = brightness * 0.9;

                const $glow = $$(".glow-effect[identifier='" + identifier + "']");

                if($glow.length > 0){

                  const $stop0 = $glow.find("stop[offset='0']");
                  const fill = $stop0.attr("stop-color");

                  $stop0.attr("stop-color", fill.replace(/[\d.]+\)$/g, brightness + ")"));

                }


                //-- Set color

                if(red !== undefined && green !== undefined && blue !== undefined){

                  const $glow = $$(".glow-effect[identifier='" + identifier + "']");
                  const $lamp = $$(".floorplan-item.icon[identifier='" + identifier + "']");

                  $lamp.attr("fill", `rgb(${red}, ${green}, ${blue})`);

                  if($glow.length > 0){

                    $glow.each((element, index) => {

                      const $stop0 = $$(element).find("stop[offset='0']");
                      const $stop1 = $$(element).find("stop[offset='1']");

                      // const opacityAndBracket = $stop0.attr("stop-color").match(/[\d.]+\)$/g);

                      $stop0.attr("stop-color", `rgba(${red}, ${green}, ${blue}, ${brightness}`);
                      $stop1.attr("stop-color", `rgba(${red}, ${green}, ${blue}, 0)`);

                    });

                  }

                }


                //-- Set blinds

                if(rollerPosition !== undefined){

                  const $blinds = $$(".blinds .floorplan-item.icon[identifier='" + identifier + "']");

                  if($blinds.length > 0){

                    const $fins = $blinds.find(".fins path");

                    for(let f = 0; f < $fins.length; f++){
                      if(rollerPosition >= Math.round((f + 1) * (100 / 6))){
                        ($fins[f] as SVGElement).style.removeProperty("fill");
                      } else {
                        ($fins[f] as SVGElement).style.setProperty("fill", "transparent");
                      }
                    }

                  }

                }
              }
            }


            //-- Set normal feedback-status

            if(valueTrue === true){
              feedbackStatusElement.classList.add("active");
              if(feedbackStatusElement.hasAttribute("src") === true){
                const on = feedbackStatusElement.getAttribute("active-src");
                if(on !== null){
                  feedbackStatusElement.setAttribute("src", on);
                }
              }
            } else {
              feedbackStatusElement.classList.remove("active");
              if(feedbackStatusElement.hasAttribute("src") === true){
                const off = feedbackStatusElement.getAttribute("inactive-src");
                if(off !== null){
                  feedbackStatusElement.setAttribute("src", off);
                }
              }
            }

          });

        }

      }


      //-- Normal feedback

      if(feedbackTextSelectorString !== undefined){

        if(typeof value === "object"){
          document.querySelectorAll(feedbackTextSelectorString).forEach(element => {
            element.innerHTML = value.value;
          });
        } else {
          document.querySelectorAll(feedbackTextSelectorString).forEach(element => {
            element.innerHTML = value + "";
          });
        }

      }

    } catch (err){
      console.error("CH_API.updateFeedback error: " + err);
    }

  }


  public getFeedback(name: string, automaIdentifier: string, parameters?: types.Object): string | number | boolean | undefined | Array<types.Object>;
  public getFeedback(name: string, automaIdentifier: string, deviceIdentifier: string, parameters?: types.Object): string | number | boolean | undefined | Array<types.Object>;
  public getFeedback(name: string, automaIdentifier: string, deviceIdentifierOrParameters?: string | types.Object, parametersOrUndefined?: types.Object): string | number | boolean | undefined | Array<types.Object> {

    try {

      let deviceIdentifier: string | undefined;
      let parameters: types.Object = {};

      if(parametersOrUndefined !== undefined){
        parameters = parametersOrUndefined;
        deviceIdentifier = deviceIdentifierOrParameters as string;
      } else {
        if(deviceIdentifierOrParameters !== undefined){
          if(typeof deviceIdentifierOrParameters === "string"){
            deviceIdentifier = deviceIdentifierOrParameters;
          } else if(typeof deviceIdentifierOrParameters === "object"){
            parameters = deviceIdentifierOrParameters;
          }
        } else {
          parameters = {};
        }
      }

      if(deviceIdentifier !== undefined){
        return Feedbacks.getFeedback(name, automaIdentifier, deviceIdentifier, parameters);
      } else {
        return Feedbacks.getFeedback(name, automaIdentifier, parameters);
      }

    } catch (err){
      console.error("CH_API.getFeedback error: " + err);
      return;
    }

  }


  public get cloudStorage() {

    return {
      getValue: (name: string, deviceIdentifier: string) => {
        return Promise.resolve(Database.cloudStorage.getValue(name, deviceIdentifier));
      },
      storeValue: (name: string, value: string | boolean | number, deviceIdentifier: string): void => {


        //-- Check if device is activated and not iframe

        if(CH_PRIVATE.getDevice() === "iframe"){
          return;
        }

        const time = new Date().getTime();

        Cloud.send({
          "func": "cloud-store",
          "params": {
            "payload": {
              "deviceIdentifier": deviceIdentifier,
              "name": name,
              "value": value,
              "time": time
            }
          }
        });

        Database.cloudStorage.storeValue(name, value, deviceIdentifier, time);

      },
      deleteValue: (name: string, deviceIdentifier: string): void => {


        //-- Check if device is activated and not iframe

        if(CH_PRIVATE.getDevice() === "iframe"){
          return;
        }

        const time = new Date().getTime();

        Cloud.send({
          "func": "cloud-delete",
          "params": {
            "payload": {
              "deviceIdentifier": deviceIdentifier,
              "name": name,
              "time": time
            }
          }
        });

        Database.cloudStorage.deleteValue(name, deviceIdentifier, time);

      }
    };

  }


  public get floorplan() {

    return {
      lamps: {
        enable: (identifier: string) => {
          $$(".floorplan-item.icon[identifier='" + identifier + "']").addClass("active");
        },
        disable: (identifier: string) => {
          $$(".floorplan-item.icon[identifier='" + identifier + "']").removeClass("active");
        },
        setBrightness: (identifier: string, brightness: number) => {

          if(brightness > 0){
            this.floorplan.lamps.enable(identifier);
          } else {
            this.floorplan.lamps.disable(identifier);
          }


          //-- Limit max brighntess to 0.9 opacity

          brightness = brightness * 0.9;

          const $glow = $$(".glow-effect[identifier='" + identifier + "']");

          if($glow.length > 0){

            const $stop0 = $glow.find("stop[offset='0']");

            const fill = $stop0.attr("stop-color");

            $stop0.attr("stop-color", fill.replace(/[\d.]+\)$/g, brightness / 100 + ")"));

            return;

          }
        },
        setColor: (identifier: string, red: number, green: number, blue: number) => {

          const $glow = $$(".glow-effect[identifier='" + identifier + "']");
          const $lamp = $$(".floorplan-item.icon[identifier='" + identifier + "']");

          $lamp.attr("fill", `rgb(${red}, ${green}, ${blue})`);

          if($glow.length > 0){

            $glow.each((element, index) => {

              const $stop0 = $$(element).find("stop[offset='0']");
              const $stop1 = $$(element).find("stop[offset='1']");

              const opacityAndBracket = $stop0.attr("stop-color").match(/[\d.]+\)$/g);

              $stop0.attr("stop-color", `rgba(${red}, ${green}, ${blue}, ${opacityAndBracket}`);
              $stop1.attr("stop-color", `rgba(${red}, ${green}, ${blue}, 0)`);

            });

          }

        }

      }, blinds: {
        setPosition: (identifier: string, position: number) => {

          const $blinds = $$(".blinds .floorplan-item.icon[identifier='" + identifier + "']");

          if($blinds.length > 0){

            const $fins = $blinds.find(".fins path");

            $fins.forEach((element, index) => {

              if(position >= Math.round((index + 1) * (100 / 6))){
                (element as SVGElement).style.removeProperty("fill");
              } else {
                (element as SVGElement).style.setProperty("fill", "transparent");
              }
            });

          }
        }
      }

    };

  }


  public runMacro(identifier: string): void {


    //-- Check if device is activated and not iframe

    if(CH_PRIVATE.getDevice() === "iframe"){
      return;
    }

    Cloud.send({
      "func": "macro",
      "params": {
        "payload": {
          "identifier": identifier
        }
      }
    });

  }


  public runCommand(commandIdentifier: string, deviceIdentifier: string, hold: types.HoldingPatterns, parameters?: Array<string | number | types.Object>): void {


    //-- Check if device is activated and not iframe

    if(CH_PRIVATE.getDevice() === "iframe"){
      return;
    }

    Cloud.send({
      "func": "command",
      "params": {
        "payload": {
          "command": commandIdentifier,
          "device": deviceIdentifier,
          "hold": hold,
          "parameters": parameters
        }
      }
    });

  }


  public cancelCommand(commandIdentifier: string, deviceIdentifier: string): void {


    //-- Check if device is activated and not iframe

    if(CH_PRIVATE.getDevice() === "iframe"){
      return;
    }

    Cloud.send({
      "func": "cancel-command",
      "params": {
        "payload": {
          "command": commandIdentifier,
          "device": deviceIdentifier
        }
      }
    });

  }


  public getClients(): Array<types.Client> {
    return CH_CLIENTS;
  }


  public openURLScheme(urlscheme: string): void {
    NATIVE.runCommand("scheme", urlscheme);
  }


  public get debug() {

    return {
      log: function(deviceIdentifier: string | undefined, name: string, args: string | number | boolean | Object | Array<string | number | boolean | Object>) {
        this._send(deviceIdentifier, name, "log", args);
      },
      warn: function(deviceIdentifier: string | undefined, name: string, args: string | number | boolean | Object | Array<string | number | boolean | Object>) {
        this._send(deviceIdentifier, name, "warning", args);
      },
      error: function(deviceIdentifier: string | undefined, name: string, args: string | number | boolean | Object | Array<string | number | boolean | Object>) {
        this._send(deviceIdentifier, name, "error", args);
      },
      _send: async function(deviceIdentifier: string | undefined, name: string, mode: string, args: string | number | boolean | Object | Array<string | number | boolean | Object>) {

        if(typeof args === "string" || typeof args === "number" || typeof args === "boolean"){
          args = [args];
        }

        if(mode === "log"){
          console.log(...args as any[]);
        } else if(mode === "warn"){
          console.warn(...args as any[]);
        } else if(mode === "error"){
          console.error(...args as any[]);
        }

        const debugEnabled = await CH_API.getData("DEBUG_ENABLED");

        if(debugEnabled !== true){
          return;
        }

        Cloud.send({ "func": "debug", "params": { "payload": { args, deviceIdentifier, name, mode } } });

      }

    };

  }

}

export const CH_API = new CH_API_();


class CH_PRIVATE_ extends TinyEventEmitter {

  private _serverstatusToast: any;

  constructor() {
    super();
  }


  public getDevice(): "app" | "iframe" {

    const url = new URL(location.href);
    const params = url.searchParams;
    const urlCreator = params.get("creator");

    if(urlCreator == "true"){ return "iframe"; }
    if(window.self !== window.top){ return "iframe"; }
    if(window.location !== window.parent.location){ return "iframe"; }
    if(top !== self){ return "iframe"; }

    return "app";

  }


  public getPDB(): Readonly<types.PDB> | undefined {
    return Database.getPDB();
  }


  public getMDB(): Readonly<Array<types.Module>> | undefined {
    return Database.getMDB();
  }


  public async storePDB(pdb: string) {
    return await Database.storePDB(pdb);
  }


  public async storeMDB(mdb: string) {
    return await Database.storeMDB(mdb);
  }


  public get TapticEngine() {
    return {
      notification: {
        success: () => {
          NATIVE.runCommand("taptic-engine", "notification-success");
        },
        warning: () => {
          NATIVE.runCommand("taptic-engine", "notification-warning");
        },
        error: () => {
          NATIVE.runCommand("taptic-engine", "notification-error");
        }
      },
      impact: {
        light: () => {
          NATIVE.runCommand("taptic-engine", "impact-light");
        },
        medium: () => {
          NATIVE.runCommand("taptic-engine", "impact-medium");
        },
        heavy: () => {
          NATIVE.runCommand("taptic-engine", "impact-heavy");
        }
      },
      selection: () => {
        NATIVE.runCommand("taptic-engine", "selection");
      }
    };
  }


  public async updateMDB() {

    try {

      const pdb = CH_PRIVATE.getPDB();
      const moduleList: Array<{ identifier: string; version?: string; }> = [];

      if(pdb === undefined){
        return;
      }

      for(const automa of pdb.automa){
        for(const device of automa.devices){
          if(device.module !== undefined){
            moduleList.push({ identifier: device.module, version: device.version });
          }
        }
      }

      const mdb = await Cloud.get({
        "func": "get-mdb",
        "params": {
          "payload": {
            "modules": moduleList
          }
        }
      });

      Database.storeMDB(JSON.stringify(mdb.modules));

    } catch (err){
      console.error("updateMDB error: ", err);
    }

  }


  public async storeCode(code: string) {

    if(code === undefined){
      console.warn("CH_PRIVATE.storeCode warning: code is undefined");
      return;
    }

    await Database.storeCode(code);

  }


  public async signout() {
    await Storage.storeData("AUTO_CODE_DETECTION_ENABLED", "false");
    Cloud.send({ "func": "signout" });
    this.deleteCode();
  }


  public async changeCode(code: string, disableCodeDetection: boolean = true) {

    if(code === undefined){
      console.warn("CH_PRIVATE.storeCode warning: code is undefined");
      return;
    }

    CH_PRIVATE.storeCode(code);

    if(disableCodeDetection === true){
      await Storage.storeData("AUTO_CODE_DETECTION_ENABLED", "false");
    }

    const url = new URL(location.href);
    const params = url.searchParams;
    params.set("code", code);

    const newLocation = url.protocol + "//" + url.host + url.pathname + "?" + params.toString();

    changeLocation(newLocation);

  }


  public deleteCode(): void {

    Storage.deleteCode();

    const url = new URL(location.href);
    const params = url.searchParams;
    params.delete("code");

    const newLocation = url.protocol + "//" + url.host + url.pathname + "?" + params.toString();

    changeLocation(newLocation);

  }


  public async storeIdentifier(identifier: string) {

    const code = CH_API.getCode();

    if(code === undefined){
      console.warn("CH_PRIVATE.storeIdentifier warning: code is undefined");
      return;
    }

    Database.storeIdentifier(identifier);

  }


  public getFeedbacks() {
    return Feedbacks.FEEDBACKS;
  }


  public storeFeedbacks(feedbacks: string) {
    if(functions.isParseableJSON(feedbacks)){
      Feedbacks.storeFeedbacks(feedbacks);
      this.emit("feedbacksChanged", Feedbacks.FEEDBACKS);
    }
  }


  public async updateClients(clients: Array<types.Client>) {

    CH_CLIENTS = clients;

    for(const client of CH_CLIENTS){
      if(client.device !== "app" && client.device !== "iframe"){


        //-- Set statusbar based on connection to automa

        let allAutomasFound = true;
        let someAutomasFound = false;
        let someAutomasUpdating = false;

        const CH_PDB = CH_PRIVATE.getPDB();

        if(CH_PDB === undefined){
          return;
        }

        for(const client of CH_CLIENTS){
          if(client.online === true){
            $$("[identifier='" + client.identifier + "'] .online-indicator").addClass("online");
          } else {
            $$("[identifier='" + client.identifier + "'] .online-indicator").removeClass("online");
          }
        }

        for(let p = 0; p < CH_PDB.automa.length; p++){
          for(let c = 0; c < CH_CLIENTS.length; c++){

            if(CH_PDB.automa[p].identifier !== CH_CLIENTS[c].identifier){
              continue;
            }

            if(CH_CLIENTS[c].online !== true){
              allAutomasFound = false;
            }
            if(CH_CLIENTS[c].online === true){
              someAutomasFound = true;
            }
            if(CH_CLIENTS[c].isUpdating === true){
              someAutomasUpdating = true;
            }

          }
        }

        if(allAutomasFound === true){
          this.setServerStatus("success");
        } else {
          if(someAutomasUpdating === true){
            this.setServerStatus("updating");
          } else {
            if(someAutomasFound === true){
              this.setServerStatus("warning");
            } else {
              this.setServerStatus("error");
            }
          }
        }

        break;

      }
    }

    this.emit("clientsupdated", CH_CLIENTS);

  }


  public setServerStatus(type: ServerStatus): void {


    //-- Check if status has actually changed

    if($$(document.documentElement).hasClass("server-connecting") && type === "connecting"){
      return;
    }
    if($$(document.documentElement).hasClass("server-disconnected") && type === "error"){
      return;
    }
    if($$(document.documentElement).hasClass("server-connected") && type === "warning"){
      return;
    }
    if($$(document.documentElement).hasClass("server-automa-connected") && type === "success"){
      return;
    }
    if($$(document.documentElement).hasClass("server-automa-updating") && type === "updating"){
      return;
    }

    $$(document.documentElement).removeClass("server-connecting");
    $$(document.documentElement).removeClass("server-disconnected");
    $$(document.documentElement).removeClass("server-connected");
    $$(document.documentElement).removeClass("server-automa-connected");
    $$(document.documentElement).removeClass("server-automa-updating");

    let text = "";

    if(type === "connecting"){

      $$(document.documentElement).addClass("server-connecting");

      text = `
        <div class="preloader color-white if-ios">
          <span class="preloader-inner">
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
            <span class="preloader-inner-line"></span>
          </span>
        </div>
        <div class="preloader color-white if-md" >
          <span class="preloader-inner">
            <span class="preloader-inner-gap"></span>
            <span class="preloader-inner-left">
                <span class="preloader-inner-half-circle"></span>
            </span>
            <span class="preloader-inner-right">
                <span class="preloader-inner-half-circle"></span>
            </span>
          </span>
        </div>
        <div class="preloader color-white if-aurora">
          <span class="preloader-inner">
            <span class="preloader-inner-circle"></span>
          </span>
        </div>
        ${globalThis.TRANSLATIONS.getText("statusbar-connecting")}
      `;

    }

    if(type === "error"){
      $$(document.documentElement).addClass("server-disconnected");
      text = globalThis.TRANSLATIONS.getText("statusbar-disconnected");
    }
    if(type === "warning"){
      $$(document.documentElement).addClass("server-connected");
      text = globalThis.TRANSLATIONS.getText("statusbar-not-connected-to-some");
    }
    if(type === "success"){
      $$(document.documentElement).addClass("server-automa-connected");
      text = globalThis.TRANSLATIONS.getText("statusbar-connected");
    }
    if(type === "updating"){
      $$(document.documentElement).addClass("server-automa-updating");
      text = globalThis.TRANSLATIONS.getText("statusbar-updating");
    }

    if(this._serverstatusToast === undefined){

      this._serverstatusToast = globalThis.APP.toast.create({
        text: text,
        position: "top",
        horizontalPosition: "left",
        cssClass: "serverstatus"
      });

      this._serverstatusToast.open();

      this._serverstatusToast.$el.on(pointerdown, () => {
        this._serverstatusToast.close();
      });

    } else {
      this._serverstatusToast.$el.find(".toast-text").html(text);
      this._serverstatusToast.open();
    }

    if(type !== "error" && type !== "updating" && type !== "connecting"){
      setTimeout(() => {
        this._serverstatusToast.close();
      }, 2000);
    }

  }

}

export const CH_PRIVATE = new CH_PRIVATE_();


type ServerStatus = "connecting" | "error" | "success" | "warning" | "updating";