import { CH_API } from "../ch-api/index";
import { types } from "../../../shared/interfaces/types";
import { isParseableJSON } from "../../../shared/functions/functions";

export class Timer_ {

  constructor() {

  }


  public async setSleepTimer(macrosAndCommands: Array<string | { command: string, device: string }>, timerIdentifier: string, timestamp: number) {

    const timer: types.Timer = {
      identifier: timerIdentifier,
      active: true,
      event: "t",
      name: timerIdentifier,
      hidden: true,
      selfDestruct: true,
      timestamp: timestamp,
      days: [],
      actions: macrosAndCommands
    };

    await this.saveTimer(timer);

  }


  public async enableTimer(identifier: string): Promise<Array<types.Timer>> {

    const timers = await this.getTimers();

    for(let t = 0; t < timers.length; t++){
      if(timers[t].identifier === identifier){
        timers[t].active = true;
        await this.saveTimer(timers[t]);
        break;
      }
    }

    return timers;

  }


  public async disableTimer(identifier: string): Promise<Array<types.Timer>> {

    const timers = await this.getTimers();

    for(let t = 0; t < timers.length; t++){
      if(timers[t].identifier === identifier){
        timers[t].active = false;
        await this.saveTimer(timers[t]);
        break;
      }
    }

    return timers;

  }


  public async toggleTimer(identifier: string): Promise<Array<types.Timer>> {

    const timers = await this.getTimers();

    for(let t = 0; t < timers.length; t++){
      if(timers[t].identifier === identifier){
        timers[t].active = !timers[t].active;
        await this.saveTimer(timers[t]);
        break;
      }
    }

    return timers;

  }


  public async deleteTimer(identifier: string): Promise<Array<types.Timer>> {

    const timers = await this.getTimers();

    for(let t = 0; t < timers.length; t++){
      if(timers[t].identifier === identifier){
        timers.splice(t, 1);
        break;
      }
    }

    CH_API.cloudStorage.storeValue("timers", JSON.stringify(timers), "__TIMER__");

    return timers;

  }


  public async getTimer(identifier: string): Promise<types.Timer | undefined> {

    const timers = await this.getTimers();

    for(let t = 0; t < timers.length; t++){
      if(timers[t].identifier === identifier){
        return timers[t];
      }
    }

    return;

  }


  public async saveTimer(timer: types.Timer): Promise<Array<types.Timer>> {

    const timers = await this.getTimers();
    let timerFound = false;

    for(let t = 0; t < timers.length; t++){
      if(timers[t].identifier === timer.identifier){
        timers[t] = timer;
        timerFound = true;
        break;
      }
    }

    if(timerFound === false){
      timers.push(timer);
    }

    CH_API.cloudStorage.storeValue("timers", JSON.stringify(timers), "__TIMER__");

    return timers;

  }


  public async getTimers(): Promise<Array<types.Timer>> {

    const value = await CH_API.cloudStorage.getValue("timers", "__TIMER__");
    let timers: Array<types.Timer> = [];

    timerBlock: if(value !== undefined && typeof value === "string"){

      if(isParseableJSON(value) !== true){
        break timerBlock;
      }

      timers = JSON.parse(value) as Array<types.Timer>;

    }

    return timers;

  }

}

export const Timer = new Timer_();