export const FLOORPLAN_ITEM_PADDING = 350;
export const FLOORPLAN_ITEM_SCALE_FACTOR = 300;
export const HEARTBEAT_TIMEOUT = 10000;
export const HEARTBEAT_MAX_TRIES = 3;

export const URLS = {
  "CREATOR_IFRAME_APP_URL": "https://creator.controlhome.ch/app/index.html?theme=ios&creator=true",
  "CREATOR_BASE_PATH": "https://creator.controlhome.ch/",
  "CREATOR_BETA_BASE_PATH": "https://beta.creator.controlhome.ch/",
  "CREATOR_PAGE_ICONS_PATH": "https://creator.controlhome.ch/common/assets/page-icons/",
  "CREATOR_DEVICE_TYPES_PATH": "https://creator.controlhome.ch/common/assets/device-types/",
  "CREATOR_PLACEHOLDERS_ASSET_PATH": "https://creator.controlhome.ch/common/assets/placeholders/",
  "CREATOR_ASSET_PATH": "https://creator.controlhome.ch/common/assets/",
  "WEBSITE_BASE_URL": "https://controlhome.ch/",
  "WEBSITE_LOGIN_URL": "https://controlhome.ch/login",
  "WEBSITE_LOGOUT_URL": "https://controlhome.ch/logout",
  "WEBSITE_STORE_MODULE_URL": "https://controlhome.ch/store/module/",
  "WEBSITE_ASSET_PATH": "https://controlhome.ch/assets/",
  "WEBSITE_RESET_PASSWORD_BASE_URL": "https://controlhome.ch/password-reset",
  "DOWNLOADS_MODULES_BASE_PATH": "https://downloads.controlhome.ch/modules/",
  "DOWNLOADS_MODULE_BASE_PATH": "https://downloads.controlhome.ch/modules/",
  "DOWNLOADS_PRODUCTS_BASE_PATH": "https://downloads.controlhome.ch/products/",
  "DOWNLOADS_PROJECTS_BASE_PATH": "https://downloads.controlhome.ch/projects/",
  "DOWNLOADS_DEVICES_BASE_PATH": "https://downloads.controlhome.ch/devices/",
  "DOWNLOADS_COMPANIES_BASE_PATH": "https://downloads.controlhome.ch/companies/",
  "UPLOAD_PATH": "https://downloads.controlhome.ch/uploads/upload.php",
  "SERVER_ADDRESS": "server.controlhome.ch:7088",
  "HTTP_SERVER_ADDRESS": "server.controlhome.ch:7090"
};

export const MWST = 7.7;

export const DEV = false;
export const PROD = true;