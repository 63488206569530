export default class TinyEventEmitter {

  private _handlers: Array<{ type: string, "handler": Function, "once": boolean }> = [];

  constructor() {

  }


  //-- EventEmitter

  public on = (type: string, handler: Function) => {
    this._handlers.unshift({ "type": type.toLowerCase(), "handler": handler, "once": false });
  }


  public once = (type: string, handler: Function) => {
    this._handlers.unshift({ "type": type.toLowerCase(), "handler": handler, "once": true });
  }


  public off = (type: string, handler: Function) => {
    if(handler === undefined){
      for(let h = this._handlers.length - 1; h >= 0; h--){
        if(this._handlers[h].type === type.toLowerCase()){
          this._handlers.splice(h, 1);
        }
      }
    } else {
      for(let h = this._handlers.length - 1; h >= 0; h--){
        if(this._handlers[h].type === type && this._handlers[h].handler === handler){
          this._handlers.splice(h, 1);
        }
      }
    }
  }


  public emit = (type: string, ...data: any) => {
    for(let h = this._handlers.length - 1; h >= 0; h--){
      if(this._handlers[h].type === type.toLowerCase()){
        if(typeof this._handlers[h].handler === "function"){
          this._handlers[h].handler(...data);
          if(this._handlers[h].once === true){
            this._handlers.splice(h, 1);
          }
        }
      }
    }
  }

}