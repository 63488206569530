//-- Framework7

import Framework7 from "framework7";

import Dom7 from "../shared/modules/dom7";
import { Dom7Array } from "dom7";

import "framework7-icons/package/css/framework7-icons.css";
import "framework7/framework7-bundle.min.css";


//-- Framework7 components

import SearchbarComponent from "framework7/components/searchbar";
import SwiperComponent from "framework7/components/swiper";
import LoginScreenComponent from "framework7/components/login-screen";
import TabsComponent from "framework7/components/tabs";
import AccordionComponent from "framework7/components/accordion";
import PopupComponent, { Popup } from "framework7/components/popup";
import ViewComponent, { View } from "framework7/components/view";
import FormComponent from "framework7/components/form";
import SmartSelectComponent from "framework7/components/smart-select";
import SwipeoutComponent from "framework7/components/swipeout";
import VirtualListComponent from "framework7/components/virtual-list";
import DialogComponent from "framework7/components/dialog";
import ToggleComponent from "framework7/components/toggle";
import ToolbarComponent from "framework7/components/toolbar";
import ToastComponent from "framework7/components/toast";
import SheetComponent from "framework7/components/sheet";
import PopoverComponent from "framework7/components/popover";
import SkeletonComponent from "framework7/components/skeleton";
import FabComponent from "framework7/components/fab";
import RangeComponent from "framework7/components/range";
import LazyComponent from "framework7/components/lazy";
import ActionsComponent from "framework7/components/actions";
import PullToRefreshComponent from "framework7/components/pull-to-refresh";
import PreloaderComponent from "framework7/components/preloader";
import InputComponent from "framework7/components/input";
import SortableComponent from "framework7/components/sortable";
import GridComponent from "framework7/components/grid";

import Dimbar from "../common/f7-plugins/dimbar/f7.dimbar";
import Dimable from "../common/f7-plugins/dimable/f7.dimable";
import HueSaturationWheel from "../common/f7-plugins/hue-saturation-wheel/f7.hue-saturation-wheel";
import ColorTemperatureWheel from "../common/f7-plugins/color-temperature-wheel/f7.color-temperature-wheel";
import FullscreenModal from "../common/f7-plugins/fullscreen-modal/f7.fullscreen-modal";
import TabNavigation from "../common/f7-plugins/tab-navigation/f7.tab-navigation";


//-- controlHome modules

import Cloud from "../shared/modules/cloud";
import Translations from "../shared/modules/translations";
import NATIVE from "./modules/native/native";
import { reload } from "./modules/location";
import VolumeSlider from "./modules/volume-slider";
import { CH_API, CH_PRIVATE } from "./modules/ch-api";
import { Zoom } from "./modules/zoom/src/index";
import splitview from "./modules/splitview";
import TinyEventEmitter from "../shared/modules/tiny-event-emitter";
import { pointerdown, pointerup } from "./modules/event-names";
import { Timer } from "./modules/timer";


//-- Other modules

import { marked } from "marked";
import svgpath from "svgpath";


//-- Import package.json

import * as packagejson from "../package.json";


//-- CSS classes

import "../shared/styles/shared.css";
import "../css/style.css";
import "../css/f7-styling.css";
import "../css/settings.css";
import "../css/dashboard.css";
import "../css/serverstatus.css";
import "../css/homescreen.css";
import "../css/grid.css";
import "../css/page-templates.css";
import "../shared/styles/theme.css";
import "../shared/styles/creator-styles.css";
import "../shared/styles/classes.css";
import "../css/theme.css";
import "../js/modules/zoom/src/style.css";


//-- Import shared

import { Database } from "../shared/modules/database";
import { Storage } from "./modules/storage";
import { types } from "../shared/interfaces/types";
import * as functions from "../shared/functions/functions";
import * as common from "../common/js/common";
import * as constants from "shared-constants";

//process.env["NODE_TLS_REJECT_UNAUTHORIZED"] = "0";

//-- Initialize framework7 modules

//@ts-expect-error
Framework7.use([
  ActionsComponent,
  SearchbarComponent,
  SkeletonComponent,
  PopupComponent,
  PopoverComponent,
  LoginScreenComponent,
  SwiperComponent,
  TabsComponent,
  AccordionComponent,
  GridComponent,
  ViewComponent,
  FormComponent,
  InputComponent,
  SmartSelectComponent,
  SwipeoutComponent,
  ToggleComponent,
  VirtualListComponent,
  SortableComponent,
  DialogComponent,
  Dimbar,
  Dimable,
  FullscreenModal,
  HueSaturationWheel,
  ColorTemperatureWheel,
  ToolbarComponent,
  ToastComponent,
  SheetComponent,
  TabNavigation,
  FabComponent,
  RangeComponent,
  LazyComponent,
  PullToRefreshComponent,
  PreloaderComponent
]);

const $$ = Dom7;


let APP: Framework7;
let PAGE_ROUTER: PageRouter;
let HOMESCREEN: Homescreen;
let DASHBOARD: Dashboard;
let TRANSLATIONS: Translations;


//-- Setup globalThis

globalThis.$$ = $$;
globalThis.CH_API = CH_API;
globalThis.API = globalThis.CH_API;
globalThis.CH_PRIVATE = CH_PRIVATE;
globalThis.NATIVE = NATIVE;
globalThis.USERSCRIPTS = [];


//-- Initialize

async function initialize() {


  //-- Theme

  let theme = "auto";
  let darkTheme = "auto";
  let autoDarkTheme = true;

  if(document.location.search.indexOf("theme=") >= 0 && theme === "auto"){
    theme = document.location.search.split("theme=")[1].split("&")[0];
  }

  if(document.location.search.indexOf("darktheme=") >= 0 && darkTheme === "auto"){
    darkTheme = document.location.search.split("darktheme=")[1].split("&")[0];
  }

  if(CH_PRIVATE.getDevice() === "iframe"){
    autoDarkTheme = false;
  }

  await Database.initializeDB();


  //-- Initialize app

  APP = new Framework7({
    el: "#app",
    name: "controlHome",
    autoDarkTheme: autoDarkTheme,
    theme: theme,
    iosTranslucentBars: false,
    touch: {
      tapHold: true
    },
    view: {
      xhrCacheDuration: 1000 * 60 * 10080,
      animate: Framework7.device.ios && Framework7.device.osVersion !== undefined ? +Framework7.device.osVersion.split(".")[0] >= 13 : true
    },
    id: "io.controlhome.controlhome",
    routes: [
      {
        name: "home",
        path: "/",
        url: "./pages/home.html"
      },
      {
        name: "remote",
        path: "/remote/",
        url: "./pages/remote.html"
      },
      {
        name: "channellist-remote",
        path: "/channellist-remote/",
        url: "./pages/channellist-remote.html"
      },
      {
        name: "mediaplayer",
        path: "/mediaplayer/",
        url: "./pages/mediaplayer.html"
      },
      {
        name: "floorplan",
        path: "/floorplan/",
        url: "./pages/floorplan.html"
      },
      {
        name: "script",
        path: "/script/",
        url: "./pages/script.html"
      }
    ]
  });


  //-- Initialize PAGE_ROUTER

  PAGE_ROUTER = new PageRouter();
  globalThis.PAGE_ROUTER = PAGE_ROUTER;

  APP.views.create(".view-main", {
    url: "/"
  });

  globalThis.APP = APP;


  //-- Translate existing html

  TRANSLATIONS = new Translations("app");
  globalThis.TRANSLATIONS = TRANSLATIONS;
  TRANSLATIONS.translatePage();


  //-- Get code

  let code = CH_API.getCode();

  const url = new URL(location.href);
  const params = url.searchParams;
  const urlCode = params.get("code");

  if(typeof urlCode === "string" && urlCode.length === 6){
    code = urlCode;
    await CH_PRIVATE.storeCode(urlCode);
  }


  //-- Check login

  if(code === undefined){
    showLoginScreen();
    return;
  }


  //-- Disable contextmenu

  $$(document).on("contextmenu", ev => {
    ev.preventDefault();
  });


  //-- Initialize cloud connection

  await initializeCloud();


  //-- Start VM

  await initializeVM();


  //-- Create Dashboard

  if(DASHBOARD === undefined){
    DASHBOARD = new Dashboard();
    globalThis.DASHBOARD = DASHBOARD;
  }

  $$(document).on("visibilitychange", visibilityChanged);


  //-- Preload pages

  for(const route of APP.routes){

    if(route.url === undefined || route.url === "/index.html"){
      continue;
    }

    APP.request.get(route.url, data => {});

  }


  //-- Add theme styles to body

  for(const className of document.documentElement.classList){
    document.body.classList.add(className);
  }


  //-- Sometimes the app does not load the initial page for some unkown reasons. Catch it if this happens and reload the page

  setTimeout(() => {
    if(APP.views.main.el.childElementCount === 0){
      reload(true);
    }
  }, 3000);

}


function visibilityChanged(ev): void {

  if(ev.target.visibilityState === "visible"){
    Cloud.connect();
  }

}


//-- VM --//

async function initializeVM() {
  try {

    const CH_PDB = CH_PRIVATE.getPDB();

    if(CH_PDB === undefined || CH_PDB.automa === undefined){
      console.warn("VM: startVM error, CH_PDB is " + typeof CH_PDB, CH_PDB);
      return;
    }

    let script = `
      const $$ = globalThis.$$;
      const APP = globalThis.APP;
      const API = globalThis.API;

      const isParseableJSON = API.isParseableJSON;
      const isStringifiableJSON = API.isStringifiableJSON;
      const xml2json = API.xml2json;
      const openURLScheme = API.openURLScheme;

      const require = (moduleName) => {
        scriptLoop: for(let u = 0; u < globalThis.USERSCRIPTS.length; u++){
          if(globalThis.USERSCRIPTS[u].name.replace(/ /g, "") !== moduleName){
            continue scriptLoop;
          }
          return globalThis.USERSCRIPTS[u];
        }
      };


      class EventEmitter {
        constructor() {
          this._handlers = [];
          //-- EventEmitter
          this.on = (type, handler) => {
            this._handlers.push({ "type": type.toLowerCase(), "handler": handler, "once": false });
          };
          this.once = (type, handler) => {
            this._handlers.push({ "type": type.toLowerCase(), "handler": handler, "once": true });
          };
          this.off = (type, handler) => {
            if(handler === undefined){
              for(let h = this._handlers.length - 1; h >= 0; h--){
                if(this._handlers[h].type === type.toLowerCase()){
                  this._handlers.splice(h, 1);
                }
              }
            } else {
              for(let h = this._handlers.length - 1; h >= 0; h--){
                if(this._handlers[h].type === type && this._handlers[h].handler === handler){
                  this._handlers.splice(h, 1);
                }
              }
            }
          };
          this.emit = (type, ...data) => {
            for(let h = this._handlers.length - 1; h >= 0; h--){
              if(this._handlers[h].type === type.toLowerCase()){
                if(typeof this._handlers[h].handler === "function"){
                  this._handlers[h].handler(...data);
                  if(this._handlers[h].once === true){
                    this._handlers.splice(h, 1);
                  }
                }
              }
            }
          };
        }
      }

    `;

    let style = "";

    for(const automa of CH_PDB.automa){

      if(automa.devices === undefined){
        continue;
      }

      for(const device of automa.devices){

        let settings = {};
        let translations = {};
        let main = "";
        let gui = "";
        let shared = "";
        let css = "";

        let name = "";

        let commands: Array<types.Command> = [];
        let feedbacks: Array<types.Feedback> = [];
        let events: Array<types.Event> = [];

        if(device.module !== undefined){

          const mod = Database.getModuleByIdentifier(device.module, device.version);

          if(mod === undefined){
            console.warn("VM warning: Could not get module by identifier " + device.module);
          } else {

            if(mod.main !== undefined){
              main = mod.main;
            }
            if(mod.gui !== undefined){
              gui = mod.gui;
            }
            if(mod.shared !== undefined){
              shared = mod.shared;
            }
            if(mod.css !== undefined){
              css = mod.css;
            }
            if(mod.settings !== undefined){
              settings = mod.settings;
            }
            if(mod.translations !== undefined){
              translations = mod.translations;
            }
            if(mod.name !== undefined){
              name = mod.name;
            }

            commands = [...mod.commands];
            feedbacks = [...mod.feedbacks];
            events = [...mod.events];

          }

        }


        //-- Override

        if(device.overrides.main !== undefined){
          main = device.overrides.main;
        }
        if(device.overrides.gui !== undefined){
          gui = device.overrides.gui;
        }
        if(device.overrides.shared !== undefined){
          shared = device.overrides.shared;
        }
        if(device.overrides.css !== undefined){
          css = device.overrides.css;
        }
        if(device.overrides.settings !== undefined){
          settings = device.overrides.settings;
        }
        if(device.overrides.translations !== undefined){
          translations = device.overrides.translations;
        }
        if(device.overrides.name !== undefined){
          name = device.overrides.name;
        }


        //-- Override commands

        commandLoop: for(const command of device.overrides.commands){
          for(let c = 0; c < commands.length; c++){
            if(commands[c].identifier === command.identifier){
              commands[c] = command;
              continue commandLoop;
            }
          }
          commands.push(command);
        }


        //-- Override feedbacks

        feedbackLoop: for(const feedback of device.overrides.feedbacks){
          for(let f = 0; f < feedbacks.length; f++){
            if(feedbacks[f].identifier === feedback.identifier){
              feedbacks[f] = feedback;
              continue feedbackLoop;
            }
          }
          feedbacks.push(feedback);
        }


        //-- Override events

        eventLoop: for(const event of device.overrides.events){
          for(let e = 0; e < events.length; e++){
            if(events[e].identifier === event.identifier){
              events[e] = event;
              continue eventLoop;
            }
          }
          events.push(event);
        }

        if(device.type === "other"){


          //-- Add stylesheet

          style += css;


          //-- Add Script

          script += `
            function ${device.identifier}(){

              (async () => {

                  try {
                    
                    const MODULE = this;

                    this.name = "${name}";
                    this.identifier = "${device.identifier}";
                    this.automa = "${automa.identifier}";
                    this.module = "${device.module}";
                    this.controller = { identifier: "${automa.identifier}" };
                    this.settings = ${JSON.stringify(settings)};
                    this.translations = ${JSON.stringify(translations)};

                    this.translate = (key) => {
                      
                      let language = navigator.languages && navigator.languages[0] ||
                        navigator.language ||
                        navigator.userLanguage ||
                        navigator.systemLanguage ||
                        navigator.browserLanguage || "en";

                      language = language.split("-")[0];

                      if(this.translations[language] === undefined){
                        language = "en";
                      }

                      if(this.translations[language] === undefined){
                      return "text not found";
                      }
                      
                      const keys = key.split(".");

                      let currentPath = this.translations[language];

                      for(let k = 0; k < keys.length; k++){
                        currentPath = currentPath[keys[k]];
                        if(k === keys.length - 1){
                          return currentPath;
                        }
                      }

                      return "text not found";

                    }


                    const console = {
                      log: function(){
                        CH_API.debug.log("${device.identifier}", "${name}", arguments);
                      },
                      warn: function(){
                        CH_API.debug.warn("${device.identifier}", "${name}", arguments);
                      },
                      error: function(){
                        CH_API.debug.error("${device.identifier}", "${name}", arguments);
                      },
                    };


                    this.runFunctionOnController = async (functionName, data, callback) => {
                      return await CH_API.runFunctionOnController("${automa.identifier}", "${device.identifier}", functionName, data, callback);
                    }


                    this.runFunctionOnApps = async (functionName, data, callback) => {
                      return await CH_API.runFunctionOnApps("${device.identifier}", functionName, data, callback, true);
                    }

                    this.runFunctionOnApp = this.runFunctionOnApps;


                    this.httpRequest = function(){
                      return CH_API.httpRequest(...arguments, "${automa.identifier}");
                    }


                    this.triggerEvent = (eventName, parameters) => {
                      CH_API.triggerEvent("${automa.identifier}", "${device.identifier}", eventName, parameters);
                    }

                    this.runTrigger = this.triggerEvent;


                    this.setFeedback = (feedbackName, value) => {
                      CH_API.setFeedback(feedbackName, value, "${automa.identifier}", "${device.identifier}", true);
                    }


                    this.getFeedback = (feedbackName, parameters) => {
                      return CH_API.getFeedback(feedbackName, "${automa.identifier}", "${device.identifier}", parameters, true);
                    }


                    this.storeValue = (name, value, local) => {
                      if(local !== true){
                        CH_API.cloudStorage.storeValue(name, value, "${device.identifier}");
                      } else {
                        CH_API.storeValue(name, value, "${device.identifier}");
                      }
                    }


                    this.deleteValue = (name, local) => {
                      if(local !== true){
                        CH_API.cloudStorage.deleteValue(name,"${device.identifier}");
                      } else {
                        CH_API.deleteValue(name, "${device.identifier}");
                      }
                    }


                    this.getValue = (name, local) => {
                      if(local !== true){
                        return CH_API.cloudStorage.getValue(name, "${device.identifier}");
                      } else {
                        return CH_API.getValue(name, "${device.identifier}");
                      }
                    }


                    //-- EventEmitter

                    this._handlers = [];

                    this.on = (type, handler) => {
                      this._handlers.unshift({ "type": type.toLowerCase(), "handler": handler, "once": false });
                    }


                    this.once = (type, handler) => {
                      this._handlers.unshift({ "type": type.toLowerCase(), "handler": handler, "once": true });
                    }


                    this.off = (type, handler) => {
                      if(handler === undefined){
                        for(let h = this._handlers.length - 1; h >= 0; h--){
                          if(this._handlers[h].type === type.toLowerCase()){
                            this._handlers.splice(h, 1);
                          }
                        }
                      } else {
                        for(let h = this._handlers.length - 1; h >= 0; h--){
                          if(this._handlers[h].type === type && this._handlers[h].handler === handler){
                            this._handlers.splice(h, 1);
                          }
                        }
                      }
                    }


                    this.emit = (type, data) => {
                      for(let h = this._handlers.length - 1; h >= 0; h--){
                        if(this._handlers[h].type === type.toLowerCase()){
                          if(typeof this._handlers[h].handler === "function"){
                            this._handlers[h].handler(data);
                            if(this._handlers[h].once === true){
                              this._handlers.splice(h, 1);
                            }
                          }
                        }
                      }
                    }

          `;


          //-- Insert commands

          for(const command of commands){

            const parameterNameArray: Array<string> = [];

            if(command.parameters !== undefined){
              for(const parameter of command.parameters){
                parameterNameArray.push(parameter.name);
              }
            }

            script += `

                this.${command.identifier} = async function(${parameterNameArray.join()}){
                  try {
                    
                    const parameters = [];

                    const commandParameters = ${JSON.stringify(command.parameters)};

                    if(commandParameters !== undefined){
                      commandParameters.forEach((parameter, index) => {
                        if(arguments[index] !== undefined){

                          const valueIdentifier = arguments[index].identifier !== undefined ? arguments[index].identifier : arguments[index][".identifier"];

                          if(valueIdentifier !== undefined){

                            parameters.push({
                              template: parameter.identifier,
                              value: valueIdentifier
                            });

                          } else {
                            parameters.push(arguments[index]);
                          }

                        }
                      });
                    }

                    CH_API.runCommand("${command.identifier}", "${device.identifier}", ${types.HoldingPatterns.once}, parameters);

                  } catch(err){
                    console.error("VM: Error in script function ${Database.getCommandByIdentifier(command.identifier, device.identifier)?.name}: " + err);
                  }
                }

                this.${command.name} = async function(${parameterNameArray.join()}){
                  return this.${command.identifier}.apply(MODULE, arguments);
                }

           `;

          }


          //-- Insert Feedbacks

          for(const feedback of feedbacks){

            const parameters = feedback.parameters || [];
            const parameterNameArray: Array<string> = [];

            for(const parameter of parameters){
              parameterNameArray.push(parameter.name);
            }

            script += `


                //-- Add getters and setters

                Object.defineProperty(MODULE, "${feedback.name}", {
                  enumerable: false,
                  configurable: false,
                  get: () => {
                    return MODULE.getFeedback("${feedback.name}");
                  },
                  set: (value) => {
                    return MODULE.setFeedback("${feedback.name}", value);
                  }
                });

            `;

          }

          script += `


                  //-- Add main and shared files

                  ${shared}
                  ${gui}

                } catch(err){
                  setTimeout(() => {
                    CH_API.debug.error("${device.identifier}", "${name}", "VM: Error in script function App|Shared: " + err);
                  }, 2000);
                }

              })();

            } // End of class

            globalThis.USERSCRIPTS.push(new ${device.identifier}());

          `;

        }
      }

    }

    await addScriptSyncronously(script);

    const cssFile = document.createElement("style");
    cssFile.innerHTML = style;
    document.head.appendChild(cssFile);


    //-- Add event listeners

    CH_API.on("feedbackChanged", ({ name, value, automaIdentifier, deviceIdentifier }) => {
      for(let u = 0; u < globalThis.USERSCRIPTS.length; u++){
        if(globalThis.USERSCRIPTS[u].identifier === deviceIdentifier && globalThis.USERSCRIPTS[u].automa === automaIdentifier){
          try {
            globalThis.USERSCRIPTS[u].emit("feedbackchanged", { name, value });
          } catch (err){
            console.warn(globalThis.USERSCRIPTS[u].name + " feedbackchanged error: ", err);
          }
        }
      }
    });

  } catch (err){

    console.error("VM: startVM error: ", err);

    setTimeout(() => {
      CH_API.debug.error(undefined, "System", "Error while initializing VM: " + err);
    }, 1000);

  }

}


//-- Classes

class Homescreen {

  public swiper: any | undefined;
  public tabNavigation: any | undefined;
  public lastSlide: number = 0;

  public roomNames: Array<string> = [];

  public container: HTMLElement;

  private _swiperInitEvent: (ev: Event) => void;
  private _swiperSetTranslateEvent: (swiper, translate) => void;
  private _swiperSetTransitionEvent: (swiper, transition) => void;
  private _swiperSlideChangeEvent: () => void;


  constructor(container: HTMLElement) {

    this.container = container;


    //-- Register events

    this._swiperInitEvent = this._swiperInit.bind(this);
    this._swiperSetTranslateEvent = this._swiperSwiperSetTranslate.bind(this);
    this._swiperSetTransitionEvent = this._swiperSetTransition.bind(this);
    this._swiperSlideChangeEvent = this._swiperSlideChange.bind(this);

    this.render();

  }


  public async render() {

    const PDB = CH_PRIVATE.getPDB();

    if(PDB === undefined){
      return;
    }

    let roomList = "";

    if(PDB.rooms.length <= 0){

      roomList += `
        <div class="page-content swiper-slide">
          <div class="homescreen-add-room">
            <div class="block">${TRANSLATIONS.getText("creator-homescreen-start-by-adding-a-room")}</div>
            <div class="chip">${TRANSLATIONS.getText("creator-homescreen-add-room")}</div>
          </div>
        </div>
      `;


      //-- Hide dashboard icon for cleaner looks

      const dashboardIcon = document.querySelector(".ch-dashboard-down-icon");

      if(dashboardIcon !== null){
        dashboardIcon.classList.add("hidden");
      }

    } else {

      for(const room of PDB.rooms){

        let pageList = "";
        const isApp = CH_PRIVATE.getDevice() !== "iframe";

        for(const page of room.pages){

          let pageIcon: string;

          if(isApp === true){
            pageIcon = `
              <picture>
                <source 
                  srcset="${page.icon.includes("http") ? page.icon : constants.URLS.CREATOR_PAGE_ICONS_PATH + page.icon + "-dark.svg" }"
                  media="(prefers-color-scheme: dark)"
                />
                <source
                  srcset="${page.icon.includes("http") ? page.icon : constants.URLS.CREATOR_PAGE_ICONS_PATH + page.icon + ".svg"}"
                  media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)"
                />
                <img src="${page.icon.includes("http") ? page.icon : constants.URLS.CREATOR_PAGE_ICONS_PATH + page.icon + ".svg"}" alt=""></img>
              </picture>
            `;
          } else {
            pageIcon = `
              <img src="${page.icon.includes("http") ? page.icon : constants.URLS.CREATOR_PAGE_ICONS_PATH + page.icon + ".svg"}" alt=""></img>
            `;
          }

          pageList += `
            <li identifier="${page.identifier}">
              <a class="item-content item-link link page-link" identifier="${page.identifier}">
                <div class="item-media">
                  ${pageIcon}
                </div>
                <div class="item-inner">
                  <div class="item-title">${page.name}</div>
                </div>
              </a>
            </li>
          `;
        }

        for(let e = 0; e < 10; e++){
          pageList += `
            <li class="empty">
              <a>
                <div class="item-media">
                  <img></img>
                </div>
                <div class="item-inner">
                  <div class="item-title"></div>
                </div>
              </a>
            </li>
          `;
        }

        roomList += `
          <div class="page-content swiper-slide">
            <div class="list tiles">
              <ul>
                ${pageList}
              </ul>
            </div>
          </div>
        `;


        //-- Unhide dashboard icon

        const dashboardIcon = document.querySelector(".ch-dashboard-down-icon");

        if(dashboardIcon !== null){
          dashboardIcon.classList.remove("hidden");
        }

      }

    }


    //-- Load Homescreen elements

    this.container.innerHTML = roomList;


    //-- Initialize swiper

    const swiperSelector = this.container.closest(".swiper-container") as HTMLElement;
    let initialSlide = 0;

    if(this.swiper !== undefined){
      initialSlide = this.swiper.activeIndex;
      this.destroy();
    }

    if(initialSlide > PDB.rooms.length - 1){
      initialSlide = PDB.rooms.length - 1;
    }


    //-- Initialize swiper

    this.swiper = APP.swiper.create(swiperSelector, {
      speed: 400,
      spaceBetween: 100,
      init: false,
      initialSlide: initialSlide,
      pagination: {
        el: $$(".homescreen.tab-navigation"),
        type: "bullets",
        bulletClass: "tab",
        bulletActiveClass: "active",
        clickable: true,
        renderBullet: (index, className) => {
          return `
            <span class="${className} ${PDB.rooms.length <= 0 ? "hidden" : ""}">${ PDB.rooms[index] !== undefined ? PDB.rooms[index].name : "" }</span>
          `;
        }
      }
    });

    this.swiper.on("init", this._swiperInitEvent);
    this.swiper.on("setTranslate", this._swiperSetTranslateEvent);
    this.swiper.on("setTransition", this._swiperSetTransitionEvent);
    this.swiper.on("slideChange", this._swiperSlideChangeEvent);

    this.swiper.init();

  }


  private _swiperInit(ev) {
    this.updateSwiperPagination();
  }


  private _swiperSwiperSetTranslate(swiper, translate) {
    $$(".homescreen-title-pagination").css("transform", "translate3d(" + translate + "px, 0, 0)");
  }


  private _swiperSetTransition(swiper, transition) {
    $$(".homescreen-title-pagination").css("transition-duration", transition + "ms");
  }


  private _swiperSlideChange() {


    //-- Update last slide

    this.lastSlide = this.swiper.activeIndex;

    const CH_PDB = CH_PRIVATE.getPDB();

    if(CH_PDB === undefined){
      return;
    }

    if(CH_PDB.rooms.length <= 0){
      return;
    }

    RoomVolume.loadRoom(CH_PDB.rooms[this.swiper.activeIndex].identifier);

    if(this.swiper.activeIndex === 0){
      $$(".homescreen.tab-navigation")[0].scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else if(this.swiper.activeIndex === CH_PDB.rooms.length - 1){
      $$(".homescreen.tab-navigation")[0].scrollTo({ top: 0, left: $$(".homescreen.tab-navigation")[0].scrollWidth, behavior: "smooth" });
    } else {
      $$(".homescreen.tab-navigation .tab.active")[0].scrollIntoView({ block: "end", inline: "nearest", behavior: "smooth" });
    }

    // $$(".page.homescreen .tab-navigation").scrollTo(left, 0);

  }


  public async gotoRoomByIdentifier(identifier: string, disableAnimation: boolean = false, background: boolean = false) {

    const PDB = CH_PRIVATE.getPDB();

    if(PDB === undefined){
      return;
    }

    if(background !== true){
      if(PAGE_ROUTER.activePageIdentifier !== "home"){
        PAGE_ROUTER.gotoPageByIdentifier("home");
      }
    }

    for(let r = 0; r < PDB.rooms.length; r++){
      if(PDB.rooms[r].identifier === identifier){
        disableAnimation === true ? this.swiper.slideTo(r, 0) : this.swiper.slideTo(r);
        return;
      }
    }

  }


  public async updateSwiperPagination() {

    let homescreenPaginationRooms = "";

    const CH_PDB = CH_PRIVATE.getPDB();

    if(CH_PDB === undefined){
      return;
    }

    for(const room of CH_PDB.rooms){
      homescreenPaginationRooms += `
        <div>${room.name}</div>
      `;
    }

    $$(".homescreen-title-pagination").html(homescreenPaginationRooms);


    //-- Init TabNavigation

    if(this.tabNavigation !== undefined){
      this.tabNavigation.destroy();
    }

    //@ts-expect-error
    this.tabNavigation = APP.TabNavigation.create({
      el: $$(".homescreen.tab-navigation")[0]
    });

  }


  public getCurrentRoom() {

    const PDB = CH_PRIVATE.getPDB();

    if(PDB === undefined){
      return;
    }

    return PDB.rooms[this.swiper.activeIndex];

  }


  public destroy() {

    if(this.swiper !== undefined){

      this.swiper.off("init", this._swiperInitEvent);
      this.swiper.off("setTranslate", this._swiperSetTranslateEvent);
      this.swiper.off("setTransition", this._swiperSetTransitionEvent);
      this.swiper.off("slideChange", this._swiperSlideChangeEvent);

      this.swiper.destroy();

    }

  }

}



class SettingsPopup {

  public popup: Popup.Popup | undefined;
  public view: View.View | undefined;

  private _updateAvailable: boolean = false;

  constructor(initialPage?: string) {
    this._createPopup(initialPage);
  }


  private async _createPopup(initialPage?: string) {

    const PDB = CH_PRIVATE.getPDB();

    let timerActions = "";

    if(PDB !== undefined){


      //-- Timers

      timerActions += `<optgroup label="${TRANSLATIONS.getText("settings-automations")}">`;

      if(PDB.timers !== undefined){
        for(let t = 0; t < PDB.timers.length; t++){
          timerActions += `
              <option value="${PDB.timers[t].macro}">${PDB.timers[t].name} </option>
            `;
        }
      }

      timerActions += "</optgroup>";


      //-- Rooms

      for(const room of PDB.rooms){


        timerActions += `<optgroup label="${room.name} ${TRANSLATIONS.getText("app-on")}">`;
        for(const page of room.pages){
          timerActions += `<option value="${page.on}">${page.name} ${TRANSLATIONS.getText("app-on")}</option>`;
        }
        timerActions += "</optgroup>";

        timerActions += `<optgroup label="${room.name} ${TRANSLATIONS.getText("app-off")}">`;
        for(const page of room.pages){
          timerActions += `<option value="${page.off}">${page.name} ${TRANSLATIONS.getText("app-off")}</option>`;
        }
        timerActions += "</optgroup>";


      }
    }


    //-- Code detection

    const codeDetectionEnabledString = await Storage.getData("AUTO_CODE_DETECTION_ENABLED");
    const autoCodeDetectionChecked = codeDetectionEnabledString === "true" ? "checked" : "";


    //-- Channel numbers

    const channelNumbersEnabled = await CH_API.cloudStorage.getValue("CHANNELS_NUMBERS_ENABLED", "#SETTINGS");
    const channelNumbersChecked = channelNumbersEnabled === true ? "checked" : "";


    //-- Code history

    let codeHistoryHTML = "";

    const codeHistoryString = await Storage.getData("__CODE_HISTORY__");

    if(typeof codeHistoryString === "string"){
      const codeHistoryArray = JSON.parse(codeHistoryString);
      for(const codeEntry of codeHistoryArray){
        codeHistoryHTML += `
          <li class="swipeout code-history-entry" code="${codeEntry.code}">
            <div class="swipeout-content">
              <a href="#" class="item-link">
                <div class="item-content">
                  <div class="item-inner">${codeEntry.name + " (" + codeEntry.code + ")"}</div>
                </div>
              </a>
            </div>
            <div class="swipeout-actions-right">
              <a href="#" class="swipeout-delete swipeout-overswipe">${TRANSLATIONS.getText("settings-delete")}</a>
            </div>
          </li>
        `;
      }
    }


    const popupContent = `
      <div class="popup settings-popup">
        <div class="view settings-view">


          <!-- Subscriptions -->

          <div class="page stacked" aria-hidden="true" data-name="abo">
            <div class="navbar navbar-large navbar-transparent">
              <div class="navbar-bg"></div>
              <div class="navbar-inner sliding">
                <div class="left">
                  <a href="#" class="link back">
                    <i class="icon icon-back"></i>
                    <span>${TRANSLATIONS.getText("settings-title")}</span>
                  </a>
                </div>
                <div class="title">${TRANSLATIONS.getText("settings-subscription")}</div>
                <div class="right">
                  <a href="#" class="link popup-close">${TRANSLATIONS.getText("app-close")}</a>
                </div>
                <div class="title-large">
                  <div class="title-large-text">${TRANSLATIONS.getText("settings-subscription")}</div>
                </div>
              </div>
            </div>
            <div class="page-content">
              <div class="card card-expandable">
                <div class="card-content">
                  <div class="bg-color-red" style="height: 300px">
                    <div class="card-header text-color-white display-block">
                      Framework7
                      <br>
                      <small style="opacity: 0.7">Build Mobile Apps</small>
                    </div>
                    <a href="#" class="link card-close card-opened-fade-in color-white" style="position: absolute; right: 15px; top: 15px">
                      <i class="icon f7-icons">close_round_fill</i>
                    </a>
                  </div>
                  <div class="card-content-padding">
                    <p>Framework7 - is a free and open source HTML mobile framework to develop hybrid mobile apps or web apps with iOS or Android (Material) native look and feel. It is also an indispensable prototyping apps tool to show working app prototype as soon as possible in case you need to.</p>
                    ...
                  </div>
                </div>
              </div>
              <div class="card card-expandable">
                <div class="card-content">
                  <div class="bg-color-yellow" style="height: 300px">
                    <div class="card-header text-color-black display-block">
                      Framework7
                      <br>
                      <small style="opacity: 0.7">Build Mobile Apps</small>
                    </div>
                    <a href="#" class="link card-close card-opened-fade-in color-black" style="position: absolute; right: 15px; top: 15px">
                      <i class="icon f7-icons">close_round_fill</i>
                    </a>
                  </div>
                  <div class="card-content-padding">
                    <p>Framework7 - is a free and open source HTML mobile framework to develop hybrid mobile apps or web apps with iOS or Android (Material) native look and feel. It is also an indispensable prototyping apps tool to show working app prototype as soon as possible in case you need to.</p>
                    ...
                  </div>
                </div>
              </div>

            </div>
          </div>


          <!-- About -->

          <div class="page stacked" aria-hidden="true" data-name="about">
            <div class="navbar navbar-large navbar-transparent">
              <div class="navbar-bg"></div>
              <div class="navbar-inner sliding">
                <div class="left">
                  <a href="#" class="link back">
                    <i class="icon icon-back"></i>
                    <span>${TRANSLATIONS.getText("settings-title")}</span>
                  </a>
                </div>
                <div class="title">${TRANSLATIONS.getText("settings-about")}</div>
                <div class="right">
                  <a href="#" class="link popup-close">${TRANSLATIONS.getText("app-close")}</a>
                </div>
                <div class="title-large">
                  <div class="title-large-text">${TRANSLATIONS.getText("settings-about")}</div>
                </div>
              </div>
            </div>
            <div class="page-content">
              <div class="block-title">
                ${TRANSLATIONS.getText("settings-installer")}
              </div>
              <div class="card">
                <div class="card-content card-content-padding">
                  <div class="installer">

                  </div>
                </div>
              </div>
              <div class="block-title">
                ${TRANSLATIONS.getText("settings-developer")}
              </div>
              <div class="card">
                <div class="card-content card-content-padding">
                  <div class="developer">
                    Entwickelt von: <br>
                    controlHome GmbH <br>
                    Riedstrasse 4 <br>
                    8824 Schönenberg <br>
                  </div>
                </div>
              </div>
          
            </div>
          </div>


          <!-- Timer -->

          <div class="page page page-next stacked timer-list-page" aria-hidden="true" data-name="timer">
            <div class="navbar navbar-large navbar-transparent">
              <div class="navbar-bg"></div>
              <div class="navbar-inner sliding">
                <div class="left">
                  <a href="#" class="link back">
                    <i class="icon icon-back"></i>
                    <span>${TRANSLATIONS.getText("settings-title")}</span>
                  </a>
                </div>
                <div class="title">${TRANSLATIONS.getText("settings-automations")}</div>
                <div class="right">
                  <a class="link icon-only timer-add" href="#"><i class="icon f7-icons">plus</i></a>
                </div>
                <div class="title-large">
                  <div class="title-large-text">${TRANSLATIONS.getText("settings-automations")}</div>
                </div>
              </div>
            </div>
            <div class="page-content">
              <div class="block no-timers">${TRANSLATIONS.getText("settings-automation-no-timer")}</div>
              <div class="list inset media-list">


                <!-- Timerlist  -->

              </div>
            </div>
          </div>
          <div class="page page page-next stacked" aria-hidden="true" data-name="timer-edit">
            <div class="navbar navbar-large navbar-transparent">
              <div class="navbar-bg"></div>
              <div class="navbar-inner sliding">
                <div class="left">
                  <a href="#" class="link back">
                    <i class="icon icon-back"></i>
                    <span>${TRANSLATIONS.getText("settings-automations")}</span>
                  </a>
                </div>
                <div class="title automation-title">${TRANSLATIONS.getText("settings-automation-edit")}</div>
                <div class="right">
                  <a href="#" class="timer-save link">${TRANSLATIONS.getText("settings-automation-save")}</a>
                </div>
                <div class="title-large">
                  <div class="title-large-text automation-title">${TRANSLATIONS.getText("settings-automation-edit")}</div>
                </div>
              </div>
            </div>
            <div class="page-content">
              <form id="timer-form">

                <div class="list inset">
                  <ul>


                    <!-- identifier -->

                    <input type="hidden" name="identifier" placeholder="">


                    <!-- active -->

                    <input type="hidden" name="active" placeholder="">


                    <!-- Name -->

                    <li>
                      <div class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">Name</div>
                          <div class="item-input-wrap">
                            <input type="text" name="name" placeholder="Timer name" class="timer-name">
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>


                <!-- Event -->

                <div class="list inset">
                  <ul>
                    <li>
                      <a href="#" class="item-link smart-select smart-select-init" data-css-class="inset-lists no-shadow" data-page-back-link-text="${TRANSLATIONS.getText("settings-automation-edit")}">
                        <select name="event">
                          <option value="t" data-option-icon="f7:clock">${TRANSLATIONS.getText("settings-automation-time")}</option>
                          <!--
                            <option value="sr" data-option-icon="f7:sunrise">${TRANSLATIONS.getText("settings-automation-sunrise")}</option>
                            <option value="ss" data-option-icon="f7:sunset">${TRANSLATIONS.getText("settings-automation-sunset")}</option>
                          -->
                          <option value="r" data-option-icon="f7:dice">${TRANSLATIONS.getText("settings-automation-random")}</option>
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">${TRANSLATIONS.getText("settings-automation-event")}</div>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>


                <!-- Time -->

                <div class="list inset timer-time-list">
                  <ul>
                    <li class="timer-time">
                      <div class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">${TRANSLATIONS.getText("settings-automation-time")}</div>
                          <div class="item-input-wrap">
                            <input type="time" name="time">
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="list inset timer-time-from-to-list">
                  <ul>
                    <li class="timer-time-from">
                      <div class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">${TRANSLATIONS.getText("settings-automation-time-from")}</div>
                          <div class="item-input-wrap">
                            <input type="time" name="from">
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="timer-time-to">
                      <div class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">${TRANSLATIONS.getText("settings-automation-time-to")}</div>
                          <div class="item-input-wrap">
                            <input type="time" name="to">
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="timer-random-probability">
                      <div class="item-content item-input">
                        <div class="item-inner">
                          <div class="item-title item-label">${TRANSLATIONS.getText("settings-automation-random-probability")}</div>
                          <div class="item-input-wrap">
                            <div class="range-slider range-slider-init" data-label="true">
                              <input type="range" value="100" min="0" max="1000" step="1" name="probability">
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>


                <!-- Action -->

                <div class="list inset">
                  <ul>
                    <li>
                      <a href="#" class="item-link smart-select smart-select-init" data-css-class="inset-lists no-shadow" data-page-back-link-text="${TRANSLATIONS.getText("settings-automation-edit")}">
                        <select multiple name="actions">
                          ${timerActions}
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">${TRANSLATIONS.getText("settings-automation-action")}</div>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>


                <!-- Days -->

                <div class="list inset">
                  <ul>
                    <li>
                      <a href="#" class="item-link smart-select smart-select-init" data-css-class="inset-lists no-shadow" data-page-back-link-text="${TRANSLATIONS.getText("settings-automation-edit")}">
                        <select multiple name="days" multiple>
                          <option value="1" data-display-as="${TRANSLATIONS.getText("weekday-0-0-s")}">${TRANSLATIONS.getText("weekday-0-0")}</option>
                          <option value="2" data-display-as="${TRANSLATIONS.getText("weekday-0-1-s")}">${TRANSLATIONS.getText("weekday-0-1")}</option>
                          <option value="3" data-display-as="${TRANSLATIONS.getText("weekday-0-2-s")}">${TRANSLATIONS.getText("weekday-0-2")}</option>
                          <option value="4" data-display-as="${TRANSLATIONS.getText("weekday-0-3-s")}">${TRANSLATIONS.getText("weekday-0-3")}</option>
                          <option value="5" data-display-as="${TRANSLATIONS.getText("weekday-0-4-s")}">${TRANSLATIONS.getText("weekday-0-4")}</option>
                          <option value="6" data-display-as="${TRANSLATIONS.getText("weekday-0-5-s")}">${TRANSLATIONS.getText("weekday-0-5")}</option>
                          <option value="0" data-display-as="${TRANSLATIONS.getText("weekday-0-6-s")}">${TRANSLATIONS.getText("weekday-0-6")}</option>
                        </select>
                        <div class="item-content">
                          <div class="item-inner">
                            <div class="item-title">${TRANSLATIONS.getText("settings-automation-repeat")}</div>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>

              </form>
            </div>
          </div>


          <!-- Clients -->

          <div class="page page-next stacked client-list-page" aria-hidden="true" data-name="clients">
            <div class="navbar navbar-large navbar-transparent">
              <div class="navbar-bg"></div>
              <div class="navbar-inner sliding">
                <div class="left">
                  <a href="#" class="link back">
                    <i class="icon icon-back"></i>
                    <span>${TRANSLATIONS.getText("settings-title")}</span>
                  </a>
                </div>
                <div class="title">${TRANSLATIONS.getText("settings-connected-clients")}</div>
                <div class="right">
                  <a href="#" class="link popup-close">${TRANSLATIONS.getText("app-close")}</a>
                </div>
                <div class="title-large">
                  <div class="title-large-text">${TRANSLATIONS.getText("settings-connected-clients")}</div>
                </div>
              </div>
            </div>
            <div class="page-content">
              <div class="list inset">
                <ul>


                  <!-- Clientslist  -->

                </ul>
              </div>
            </div>
          </div>


          <!-- Update -->

          <div class="page page-with-subnavbar stacked" aria-hidden="true" data-name="update">
            <div class="navbar navbar-with-subnavbar no-hairline">
              <div class="navbar-bg"></div>
              <div class="navbar-inner sliding">
                <div class="left">
                  <a href="#" class="link back">
                    <i class="icon icon-back"></i>
                    <span>${TRANSLATIONS.getText("settings-title")}</span>
                  </a>
                </div>
                <div class="title">${TRANSLATIONS.getText("settings-update")}</div>
                <div class="right">
                  <a href="#" class="link popup-close">${TRANSLATIONS.getText("app-close")}</a>
                </div>
              </div>
            </div> 
            <div class="subnavbar">
              <div class="subnavbar-inner">
                <div class="tab-navigation tab-navigation-init tab-navigation-full-width">
                  <a href="#update-app" class="tab-link tab active no-ripple tab-link-active">${TRANSLATIONS.getText("app-app")}</a>
                  <a href="#update-automa" class="tab-link tab no-ripple">${TRANSLATIONS.getText("app-automa")}</a>
                  <a href="#update-modules" class="tab-link tab no-ripple">${TRANSLATIONS.getText("app-modules")}</a>
                </div>
              </div>
            </div>
            <div class="tabs-swipeable-wrap">
              <div class="tabs">
                <div id="update-app" class="tab tab-active update-app page-content">
                  <div class="card">
                    <div class="card-header">
                      <div>${TRANSLATIONS.getText("app-app")}</div>
                      <div class="update-installed chip">v${packagejson.version}</div>
                    </div>
                    <div class="card-content card-content-padding update-changelog">
                      ${TRANSLATIONS.getText("update-error")}
                      </br>
                      </br>
                      <button class="button button-fill update-try-again">${TRANSLATIONS.getText("update-click-to-try-again")}</button>
                    </div>
                  </div>
                </div>
                <div id="update-automa" class="tab update-automa page-content">
                  <div class="list inset automa-list card-list">

                  </div>
                  <div class="card">
                    <div class="card-content card-content-padding">
                      <div class="update-changelog">
                        ${TRANSLATIONS.getText("update-error")}
                        </br>
                        </br>
                        <button class="button button-fill update-try-again">${TRANSLATIONS.getText("update-click-to-try-again")}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="update-modules" class="tab update-automa page-content">
                  <div class="card">
                    <div class="card-content card-content-padding update-changelog">
                      ${TRANSLATIONS.getText("update-error")}
                      </br>
                      </br>
                      <button class="button button-fill update-try-again">${TRANSLATIONS.getText("update-click-to-try-again")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- Overview -->

          <div class="page" data-name="overview"><div class="statusbar"></div>
            <div class="navbar navbar-large navbar-transparent">
              <div class="navbar-bg"></div>
              <div class="navbar-inner sliding">
                <div class="title">${TRANSLATIONS.getText("settings-title")}</div>
                <div class="title-large">
                  <div class="title-large-text">${TRANSLATIONS.getText("settings-title")}</div>
                </div>
                <div class="right">
                  <a href="#" class="link popup-close">${TRANSLATIONS.getText("app-close")}</a>
                </div>
              </div>
            </div>
            <div class="page-content">  
              <!--
              <div class="list inset">
                <ul>
                  <li>
                    <a href="/abo/" data-view=".settings-view" class="item-link">
                      <div class="item-content">
                        <div class="item-inner">${TRANSLATIONS.getText("settings-subscription")}</div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              -->
              <div class="list inset">
                <ul>
                  <li>
                    <a href="/timer/" data-view=".settings-view" class="item-link">
                      <div class="item-content">
                        <div class="item-inner">${TRANSLATIONS.getText("settings-automations")}</div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="list inset">
                <ul>
                  <li>
                    <a href="/update/" data-view=".settings-view" class="item-link">
                      <div class="item-content">
                        <div class="item-inner">
                          <div class="item-title">
                            ${TRANSLATIONS.getText("settings-update")}
                          </div>
                          <div class="item-after">
                            <span class="capitalize">${TRANSLATIONS.getText("app-installed")}:&nbsp;</span> v${packagejson.version}
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="list inset">
                <ul>
                  <li>
                    <a href="/clients/" data-view=".settings-view" class="item-link">
                      <div class="item-content">
                        <div class="item-inner">${TRANSLATIONS.getText("settings-connected-clients")}</div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="list inset">
                <ul>
                  <li class="item-content">
                    <div class="item-inner">
                      <div class="item-title">${TRANSLATIONS.getText("settings-auto-code-detection")}</div>
                      <div class="item-after">
                        <label class="toggle auto-code-detection-toggle toggle-init">
                          <input type="checkbox" ${autoCodeDetectionChecked}>
                          <span class="toggle-icon"></span>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li class="item-content">
                    <div class="item-inner">
                      <div class="item-title">${TRANSLATIONS.getText("settings-channel-numbers-label")}</div>
                      <div class="item-after">
                        <label class="toggle channel-numbers-toggle toggle-init">
                          <input type="checkbox" ${channelNumbersChecked}>
                          <span class="toggle-icon"></span>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="block-title">${TRANSLATIONS.getText("settings-code-history")}</div>
              <div class="list inset code-history">
                <ul>
                  ${codeHistoryHTML}
                  <li class="signout">
                    <a href="#" class="item-link">
                      <div class="item-content">
                        <div class="item-inner">${TRANSLATIONS.getText("settings-logout-label")}</div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="list inset">
                <ul>
                  <li class="about">
                    <a href="/about/" data-view=".settings-view" class="item-link">
                      <div class="item-content">
                        <div class="item-inner">
                          <div class="item-title">
                            ${TRANSLATIONS.getText("settings-about")}
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    `;


    //-- Create popup

    this.popup = APP.popup.create({
      content: popupContent
    });

    this.popup.open();

    this.popup.on("close", this._close.bind(this));


    //-- Create view

    this.view = APP.views.create(this.popup.$el.find(".settings-view")[0] as HTMLElement, {
      "name": "settingsview",
      "stackPages": true,
      "router": true,
      "iosPageLoadDelay": 50,
      "mdPageLoadDelay": 50,
      "routes": [
        {
          path: "/overview/",
          pageName: "overview"
        }, {
          path: "/abo/",
          pageName: "abo"
        }, {
          path: "/about/",
          pageName: "about"
        }, {
          path: "/update/",
          pageName: "update"
        }, {
          path: "/clients/",
          pageName: "clients"
        }, {
          path: "/timer/",
          pageName: "timer"
        }, {
          path: "/timer/edit/",
          pageName: "timer-edit"
        }
      ],
      on: {
        pageBeforeIn: this._pageLoaded.bind(this)
      }
    });


    //-- Initialize events

    this.popup.$el.on("click", ".timer-add", this.addTimer.bind(this));
    this.popup.$el.on("click", ".timer-save", this._saveTimer.bind(this));
    this.popup.$el.on("click", ".timer-list-page .page-content ul li .item-content", this._editTimer.bind(this));
    this.popup.$el.on("swipeout:delete", ".timer-list-page .page-content ul li", this._deleteTimer.bind(this));
    this.popup.$el.on("change", ".timer-list-page .page-content ul li .toggle", this._toggleTimer.bind(this));
    this.popup.$el.on("click", ".client-list-page .page-content ul li", this._clickOnClient.bind(this));
    this.popup.$el.on("click", ".code-history ul li.signout", this._signout.bind(this));
    this.popup.$el.on("swipeout:delete", ".code-history ul li.code-history-entry", this._deleteCodeHistoryEntry.bind(this));
    this.popup.$el.on("click", ".code-history ul li.code-history-entry .item-content", this._codeHistoryClicked.bind(this));

    // this.view.on("swipebackMove", this._swipeBack.bind(this)); // Disabled because view swipeBack listener disables all sliders and this might no longer be neccessary
    // this.view.on("swipebackBeforeReset", this._swipeBackReset.bind(this));


    //-- Toggles

    this.popup.$el.on("change", ".page-content ul li input[type='checkbox']", ev => {

      if(ev.target === null){
        return;
      }

      const parent = $$(ev.target).parents("label");

      const targetInput = ev.target as HTMLInputElement;

      if(parent.hasClass("auto-code-detection-toggle")){
        Storage.storeData("AUTO_CODE_DETECTION_ENABLED", JSON.stringify(targetInput.checked));
      }
      if(parent.hasClass("channel-icons-toggle")){
        CH_API.cloudStorage.storeValue("CHANNELS_ICONS_ENABLED", targetInput.checked, "#SETTINGS");
      }
      if(parent.hasClass("channel-numbers-toggle")){
        CH_API.cloudStorage.storeValue("CHANNELS_NUMBERS_ENABLED", targetInput.checked, "#SETTINGS");
      }

    });

    if(initialPage !== undefined){
      this.view.router.navigate("/" + initialPage + "/", {
        animate: false
      });
    }

    CH_PRIVATE.on("clientsupdated", clients => {
      this._loadClientList();
    });

  }


  private _deleteCodeHistoryEntry(ev): void {

    const target = ev.target.closest("li");

    if(target === null){
      return;
    }

    const code = target.getAttribute("code");

    if(code !== undefined){
      CodeHistory.deleteEntry(code);
    }

  }


  private _codeHistoryClicked(ev) {

    const target = ev.target.closest("li");

    if(target === null){
      return;
    }

    const code = target.getAttribute("code");

    if(code !== undefined){
      CH_PRIVATE.changeCode(code, code !== CH_API.getCode());
    }

  }


  private _signout(ev): void {
    CH_PRIVATE.signout();
  }


  private async _clickOnClient(ev) {

    const target = ev.target.closest("li");
    const identifier = target.getAttribute("identifier");

    if(identifier === undefined){
      return;
    }

    const code = CH_API.getCode();

    if(code === undefined){
      return;
    }

    const clients = CH_API.getClients();

    for(const client of clients){
      if(client.identifier === identifier){

        const buttons: Array<any> = [];

        if(identifier === CH_API.getIdentifier()){
          return;
        }

        if(client.device === "automa"){


          //-- Reboot

          buttons.push({
            text: TRANSLATIONS.getText("settings-clients-reboot"),
            color: "red",
            onClick: () => {
              Cloud.send({ "func": "reboot", "params": { "target": client.identifier, "targetDevice": "automa", "payload": { "code": code } } });
            }
          });

        } else if(client.device === "app"){


          //-- Activate

          if(client.activated !== true){
            buttons.push({
              text: TRANSLATIONS.getText("settings-clients-activate"),
              onClick: () => {
                Cloud.send({ "func": "two-factor-auth-allow", "params": { "payload": { "identifier": identifier, "code": code } } });
              }
            });
          }


          //-- Block

          if(client.blocked !== true){
            buttons.push({
              text: TRANSLATIONS.getText("settings-clients-block"),
              color: "red",
              onClick: () => {
                Cloud.send({ "func": "two-factor-auth-block", "params": { "payload": { "identifier": identifier, "code": code } } });
              }
            });
          }


          //-- Signout

          buttons.push({
            text: TRANSLATIONS.getText("settings-clients-signout"),
            color: "red",
            onClick: () => {
              Cloud.send({ "func": "delete-client", "params": { "payload": { "identifier": identifier, "code": code } } });
            }
          });

        }

        let thisDevice = "";

        if(client.identifier === CH_API.getIdentifier()){
          thisDevice = `(${TRANSLATIONS.getText("settings-this-device")})`;
        }

        APP.dialog.create({
          title: client.name + " " + thisDevice,
          text: TRANSLATIONS.getText("settings-clients-manage-device"),
          buttons: buttons,
          closeByBackdropClick: true,
          verticalButtons: true
        }).open();

        return;

      }
    }

  }


  private async _deleteTimer(ev) {

    if(this.popup === undefined){
      return;
    }

    const target = ev.target.closest("li");
    const identifier = target.getAttribute("identifier");

    if(identifier === undefined){
      return;
    }

    const timers = await Timer.deleteTimer(identifier);

    if(timers.length === 0){
      this.popup.$el.find(".timer-list-page .page-content .no-timers").removeClass("hidden");
      this.popup.$el.find(".timer-list-page .page-content .list").empty();
    }

  }


  private async _toggleTimer(ev) {

    if(this.popup === undefined){
      return;
    }

    const target = ev.target.closest("li");
    const identifier = target.getAttribute("identifier");

    Timer.toggleTimer(identifier);

  }


  private _editTimer(ev): void {

    let target = $$(ev.target);

    if(target.parents(".toggle").length > 0 || target.hasClass("toggle")){
      return;
    }

    if(ev.target.tagName.toLowerCase() !== "li"){
      target = $$(ev.target).parents("li");
    }

    const identifier = target.attr("identifier");

    this.editTimer(identifier);

  }


  private async _loadTimerList() {

    if(this.popup === undefined){
      return;
    }

    const noTimersLabel = this.popup.$el.find(".timer-list-page .page-content .no-timers");
    const timers = await Timer.getTimers();

    let timerList = "";

    if(timers.length === 0){
      noTimersLabel.removeClass("hidden");
      return;
    } else {
      noTimersLabel.addClass("hidden");
    }

    for(const timer of timers){

      if(timer.hidden === true){
        continue;
      }

      const timerWeekdayArray: Array<string> = [];

      for(let w = 0; w < timer.days.length; w++){
        timerWeekdayArray.push(TRANSLATIONS.getText("weekday-1-" + timer.days[w] + "-s"));
      }

      const timerWeekdays = timerWeekdayArray.toString().replace(/,/g, ", ");

      timerList += `
        <li class="swipeout no-chevron" identifier="${timer.identifier}">
          <div class="swipeout-content">
            <div class="item-content item-link timer-edit">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">${timer.time} ${timer.name}</div>
                  <div class="item-after">
                    <label class="toggle toggle-init prevent-active-state-propagation">
                      <input type="checkbox" ${(timer.active ? " checked " : "")} >
                      <span class="toggle-icon"></span>
                    </label>
                  </div>
                </div>
                <div class="item-text">
                  ${timerWeekdays}
                </div>
              </div>
            </div>
          </div>
          <div class="swipeout-actions-right">
            <a href="#" class="swipeout-delete swipeout-overswipe">${TRANSLATIONS.getText("settings-automation-delete")}</a>
          </div>
        </li>
      `;

    }

    this.popup.$el.find(".timer-list-page .page-content .list").html(`
      <ul>
        ${timerList}
      </ul>
    `);

  }


  private async _loadAboutPage() {

    if(this.popup === undefined){
      return;
    }

    const result = await Cloud.get("retailer-infos");

    if(result.accounts.length === 1){

      const installerElement = this.popup.el.querySelector(".page[data-name='about'] .page-content .installer");

      if(installerElement === null){
        return;
      }

      installerElement.innerHTML = `
        <div class="installer-logo-container">
          <img src="${constants.URLS.DOWNLOADS_COMPANIES_BASE_PATH + result.accounts[0].CompanyID + "/account/images/" + result.accounts[0].Logo}" />
        </div>
        ${result.accounts[0].Company}<br>
        ${result.accounts[0].Street} ${result.accounts[0].HouseNumber}<br>
        ${result.accounts[0].Zip} ${result.accounts[0].City}<br>
        ${result.accounts[0].Phone}<br>
        <a href="${result.accounts[0].Website}">${result.accounts[0].Website}</a><br>
      `;

    }

  }


  private async _loadClientList() {

    if(this.popup === undefined){
      return;
    }

    let clientList = "";

    const clients = CH_API.getClients();
    const code = CH_API.getCode();

    if(code === undefined){
      return;
    }


    //-- Sort clients by last seen status

    clients.sort((a, b) => {
      if(a.lastseen > b.lastseen){
        return -1;
      } else {
        return 1;
      }
    });


    //-- Sort clients by online status

    clients.sort((a, b) => {
      if(a.online === true && b.online !== true){
        return -1;
      }
      return 0;
    });


    //-- Sort clients by automa device

    clients.sort((a, b) => {
      if(a.device === "automa" && b.device !== "automa"){
        return -1;
      }
      return 0;
    });

    clientLoop: for(const client of clients){

      if(client.device !== "app" && client.device !== "automa"){
        continue clientLoop;
      }

      let icon = "device_phone_portrait";

      if(client.device === "automa"){
        icon = "controller";
      }
      if(client.name.toLowerCase().includes("windows")){
        icon = "device_desktop";
      }
      if(client.name.toLowerCase().includes("macos")){
        icon = "desktopcomputer";
      }
      if(client.name.toLowerCase().includes("ipad")){
        icon = "device_tablet_landscape";
      }

      const lastseen = new Date(client.lastseen);

      let lock = `
        <i class="f7-icons">
          lock
        </i>
      `;

      if(client.activated === true){
        lock = `
          <i class="f7-icons" style="color: var(--ch-green-color)">
            lock_open
          </i>
        `;
      }

      if(client.blocked === true){
        lock = `
          <i class="f7-icons" style="color: var(--ch-red-color)">
            lock
          </i>
        `;
      }

      let thisDevice = "";

      if(client.identifier === CH_API.getIdentifier()){
        thisDevice = `(${TRANSLATIONS.getText("settings-this-device")})`;
      }

      clientList += `
        <li identifier="${client.identifier}">
          <div class="item-content">
            <div class="item-media">
              <div class="online-indicator ${client.online === true ? "online" : ""}"></div>
            </div>
            <div class="item-inner">
              <div class="item-title">
                <i class="f7-icons">${icon}</i>
                ${client.name ?? client.device} ${thisDevice}
                <div class="item-footer">
                  ${lock}
                  ${client.online === true ? TRANSLATIONS.getText("settings-online-since") : TRANSLATIONS.getText("settings-last-seen")}: ${lastseen.toLocaleDateString([], { day: "2-digit", month: "2-digit", year: "numeric" })} ${lastseen.toLocaleTimeString([], { hour: "2-digit", minute:"2-digit" })}
                </div>
              </div>
            </div>
          </div>
        </li>
      `;
    }

    this.popup.$el.find(".client-list-page .page-content ul").html(clientList);

  }


  public addTimer(): void {
    this.editTimer();
  }


  public async editTimer(identifier?: string) {

    if(this.popup === undefined){
      return;
    }

    let timer: types.Timer | undefined = identifier !== undefined ? await Timer.getTimer(identifier) : undefined;

    if(timer === undefined || identifier === undefined){

      const date = new Date();

      timer = {
        "identifier": functions.generateIdentifier(),
        "name": "new automation",
        "event": "t",
        "days": [],
        "actions": [],
        "active": true,
        "time": functions.zeroFill(date.getHours(), 2) + ":" + functions.zeroFill(date.getMinutes(), 2)
      };

    }


    //-- Update timer title

    const automationTitleElements = this.popup.$el.find(".page[data-name='timer-edit'] .automation-title");
    automationTitleElements.text(timer.name);


    //-- Load edit page

    this.gotoPage("/timer/edit/");


    //-- Detect changes to show/hide elements

    $$("#timer-form").on("input", ev => {

      const target = ev.target as HTMLElement;

      if(target.tagName.toLowerCase() !== "input"){
        return;
      }

      if(target.classList.contains("timer-name") === true){
        automationTitleElements.text((target as HTMLInputElement).value);
      }

    });

    $$("#timer-form").on("change", ev => {

      if(this.popup === undefined){
        return;
      }

      const formData: types.Object = APP.form.convertToData("#timer-form");

      if(formData.event !== undefined){

        if(formData.event === "r"){
          this.popup.$el.find(".timer-time-list").hide();
          this.popup.$el.find(".timer-time-from-to-list").show();
        } else if(formData.event === "t"){
          this.popup.$el.find(".timer-time-from-to-list").hide();
          this.popup.$el.find(".timer-time-list").show();
        }

      }

    });


    //-- Autofill timer

    APP.form.fillFromData("#timer-form", timer);

  }


  private async _saveTimer() {

    if(this.view === undefined){
      return;
    }

    const formData: types.Object = APP.form.convertToData("#timer-form");

    formData.active = formData.active === "true" || formData.active === true;

    const timer: types.Timer | types.Object = await Timer.getTimer(formData.identifier) ?? {};
    Object.assign(timer, formData);

    if(timer !== undefined){
      await Timer.saveTimer(timer as types.Timer);
    }

    this.view.router.back();

  }


  public gotoPage(page: string): void {

    if(this.view === undefined){
      return;
    }

    this.view.router.navigate(page, {
      "animate": true,
      "reloadCurrent": false
    });

  }


  private _pageLoaded(page): void {

    if(this.popup === undefined){
      return;
    }

    if(page.name === "update"){

      const getUpdate = async() => {


        //-- Get module list

        const pdb = CH_PRIVATE.getPDB();
        const modules: Array<{ "identifier": string; "version": string | undefined; }> = [];

        if(pdb === undefined){
          return;
        }

        for(const automa of pdb.automa){
          for(const device of automa.devices){
            if(device.module !== undefined){
              modules.push({ identifier: device.module, version: device.version });
            }
          }
        }


        //-- Get changelog

        Cloud.get({
          "func": "get-updates",
          "params": {
            "payload": {
              "modules": modules
            }
          }
        }).then(response => {

          if(this.popup === undefined){
            return;
          }

          if(response.app.newest !== undefined){

            this.popup.$el.find(".page #update-app .update-installed").text("v" + packagejson.version);
            this.popup.$el.find(".page #update-app .update-changelog").html(marked("## Changelog \n" + response.app.changelog));

            if(functions.compareVersions(packagejson.version, response.app.newest)){
              this._updateAvailable = true;
            }

          }
          if(response.automa.newest !== undefined){

            const clients = CH_API.getClients();
            const automa = clients.filter(client => { return client.device === "automa"; });

            const automaListContainer = this.popup.$el.find(".page #update-automa .automa-list");

            let automaListHTML = "";

            for(let a = 0; a < automa.length; a++){

              const version = automa[a].version !== undefined ? "v" + automa[a].version : TRANSLATIONS.getText("update-unkown-version");

              automaListHTML += `
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-title">
                      ${automa[a].name}
                    </div>
                    <div class="item-after">
                      <div class="chip">${version}</div>
                    </div>
                  </div>
                </li>
              `;
            }

            automaListContainer.html(`
              <ul>
                ${automaListHTML}
              </ul>
            `);

            this.popup.$el.find(".page #update-automa .update-changelog").html(marked("## Changelog \n" + response.automa.changelog));

          }
          if(response.modules.length > 0){

            let moduleList = "";

            for(const mod of response.modules){

              const installedMod = Database.getModuleByIdentifier(mod.identifier);

              const version = installedMod?.version !== undefined ? "v" + installedMod.version : TRANSLATIONS.getText("update-unkown-version");

              moduleList += `
                <li class="accordion-item">
                  <a href="" class="item-link item-content">
                    <div class="item-inner">
                      <div class="item-title">${mod.name}</div>
                      <div class="item-after">
                        <div class="chip update-installed">${version}</div>
                      </div>
                    </div>
                  </a>
                  <div class="accordion-item-content">
                    <div class="card-content-padding">
                      ${marked("## Changelog \n" + mod.changelog)}
                    </div>
                  </div>
                </li>
              `;

            }

            this.popup.$el.find(".page #update-modules .update-changelog").html(`
              <div class="list inset accordion-list">
                <ul>${moduleList}</ul>
              </div>
            `);

          }

        }).catch(err => {
          console.error("get-updates: ", err);
        });

      };

      getUpdate();

      this.popup.$el.find(".page .swiper-container").show();

      $$(".update-try-again").off("click", getUpdate);
      $$(".update-try-again").on("click", getUpdate);

    }

    if(page.name === "timer"){
      this._loadTimerList();
    }

    if(page.name === "clients"){
      this._loadClientList();
    }

    if(page.name === "about"){
      this._loadAboutPage();
    }

  }


  private _swipeBack(ev) {

    const $currentPageEl = $$(ev.currentPageEl);

    if($currentPageEl.attr("data-name") === "update"){
      $currentPageEl.find(".swiper-container").hide();
    }

  }


  private _swipeBackReset(ev) {

    const $currentPageEl = $$(ev.currentPageEl);

    if($currentPageEl.attr("data-name") === "update"){
      $currentPageEl.find(".swiper-container").show();
    }

  }


  private _close(ev): void {

    if(this._updateAvailable === true){
      reload(true);
    }

  }

}


class PageRouter {

  public activePageIdentifier: string = "home";

  private _activeFloorplanInstance: FloorplanPage | undefined;
  private _pendingFloorplanStory: types.FloorplanStory | undefined;

  private _buttonDownEvent: (ev) => void;
  private _floorplanClickEvent: (ev) => void;
  private _buttonUpEvent: (ev) => void;

  static _pressedButtons: Array<{ id: number; commandIdentifier: string; deviceIdentifier: string; }> = [];


  constructor() {

    APP.on("routeChange", this._routeChange.bind(this));
    APP.on("pageMounted", this._pageMounted.bind(this));
    APP.on("pageInit", this._pageInit.bind(this));
    APP.on("routeChanged", this._routeChanged.bind(this));
    APP.on("viewInit", this._viewInit.bind(this));

    $$(document).on("click taphold", ".page-link", this._pageLinkClicked.bind(this));


    //-- Add button events

    this._buttonDownEvent = this._buttonDown.bind(this);
    this._floorplanClickEvent = this._floorplanClick.bind(this);
    this._buttonUpEvent = this._buttonUp.bind(this);

    $$("body:not(.creator)").off(pointerdown, ".button.programmed", this._buttonDownEvent);
    $$("body:not(.creator)").on(pointerdown, ".button.programmed", this._buttonDownEvent);

    $$("body:not(.creator)").off("click", ".floorplan .floorplan-item.touch-area.programmed", this._floorplanClickEvent);
    $$("body:not(.creator)").on("click", ".floorplan .floorplan-item.touch-area.programmed", this._floorplanClickEvent);

    $$("body:not(.creator)").off(pointerup, ".button.programmed", this._buttonUpEvent);
    $$("body:not(.creator)").on(pointerup, ".button.programmed", this._buttonUpEvent);

  }


  private _viewInit(view): void {
    view.on("swipebackMove", this._swipeBack.bind(this));
    view.on("swipebackBeforeReset", this._swipeBackReset.bind(this));
  }


  private _floorplanClick(ev: Event) {

    const target = ev.target as HTMLElement;

    if(target === null){
      return;
    }


    //-- Abort if swipeback

    const pageEl = target.closest(".page");

    if(pageEl !== null){
      if(pageEl.classList.contains("page-swipeback-active")){
        return;
      }
    }

    const itemIdentifier = target.getAttribute("identifier");
    const commandIdentifier = target.getAttribute("command");
    const macroOrRoomIdentifier = target.getAttribute("macro");
    const deviceIdentifier = target.getAttribute("command-device");

    if(this.activePageIdentifier === undefined){
      return;
    }

    const page = Database.getPageByIdentifier(this.activePageIdentifier) as types.FloorplanPage;

    if(page === undefined || page.stories === undefined){
      return;
    }

    for(const story of page.stories){
      for(const layer of story.layers){
        for(const item of layer.items){

          if(item.identifier !== itemIdentifier){
            continue;
          }

          if(item.actionIdentifier === undefined){
            continue;
          }

          if(item.actionIdentifier === macroOrRoomIdentifier){
            CH_API.runMacro(item.actionIdentifier);
          }

          if(item.actionIdentifier === macroOrRoomIdentifier){
            CH_API.runMacro(item.actionIdentifier);
          }

          if(item.actionIdentifier === commandIdentifier && item.actionDevice === deviceIdentifier){
            CH_API.runCommand(commandIdentifier, deviceIdentifier, types.HoldingPatterns.once, item.parameters);
          }

          return;

        }

      }

    }

  }


  private _buttonDown(ev: PointerEvent) {

    let target = ev.target as HTMLElement;

    if(target === null){
      return;
    }

    if(!target.classList.contains("button")){
      const button = target.closest(".button");
      if(button !== null){
        target = button as HTMLElement;
      }
    }


    //-- Abort if swipeback

    const pageEl = target.closest(".page");

    if(pageEl !== null){
      if(pageEl.classList.contains("page-swipeback-active")){
        return;
      }
    }

    const commandIdentifier = target.getAttribute("command");
    const macroOrRoomIdentifier = target.getAttribute("macro");
    const deviceIdentifier = target.getAttribute("command-device");

    if(this.activePageIdentifier === undefined){
      return;
    }

    const page = Database.getPageByIdentifier(this.activePageIdentifier);

    if(page === undefined){
      return;
    }

    for(const button of page.buttons){

      if(button.tag !== target.getAttribute("command-tag")){
        continue;
      }

      if(button.actionIdentifier === undefined){
        continue;
      }


      if(button.actionIdentifier === macroOrRoomIdentifier){
        CH_API.runMacro(button.actionIdentifier);
      }

      if(button.actionIdentifier === commandIdentifier && button.actionDevice === deviceIdentifier){

        CH_API.runCommand(commandIdentifier, deviceIdentifier, button.hold || types.HoldingPatterns.once, button.parameters);

        PageRouter._pressedButtons.push({
          id: ev.pointerId,
          commandIdentifier,
          deviceIdentifier
        });

      }

    }

  }


  private _buttonUp(ev: PointerEvent) {

    for(let i = PageRouter._pressedButtons.length - 1; i >= 0; i--){
      if(PageRouter._pressedButtons[i].id === ev.pointerId){
        CH_API.cancelCommand(PageRouter._pressedButtons[i].commandIdentifier, PageRouter._pressedButtons[i].deviceIdentifier);
        PageRouter._pressedButtons.splice(i, 1);
      }
    }

  }


  private _pageLinkClicked(ev: Event): void {

    if(ev.target === null){
      return;
    }

    let target: Dom7Array;

    if($$(ev.target).hasClass("page-link")){
      target = $$(ev.target);
    } else {
      target = $$(ev.target).parents(".page-link");
    }

    if(target === undefined){
      return;
    }

    const pageIdentifier = target.attr("identifier");

    if(pageIdentifier === undefined){
      return;
    }

    const page = Database.getPageByIdentifier(pageIdentifier);

    if(page === undefined){
      return;
    }


    //-- Run macro

    if(CH_PRIVATE.getDevice() !== "iframe" && ev.type !== "taphold" && page.on !== undefined){
      CH_API.runMacro(page.on);
    }


    //-- Open page or scheme

    if(page.template === "scheme"){
      if(page.schemes !== undefined){
        for(const scheme of page.schemes){
          CH_API.openURLScheme(scheme);
        }
      }
    } else if(page.template === "page-link"){
      if(page.link !== undefined){
        this.gotoPageByIdentifier(page.link);
      }
    } else {
      this.gotoPageByIdentifier(pageIdentifier);
    }

  }


  private async _routeChanged(newRoute, previousRoute, router): Promise<void> {

    if(newRoute.path === "/index.html" || newRoute.path === "/"){
      document.documentElement.removeAttribute("scope");
      return;
    }


    //-- Update splitview

    splitview.update();

  }


  private _swipeBack(view) {

    if(view.currentPageEl.classList.contains("page-swipeback-active")){
      return;
    }

    const swiperElements = view.currentPageEl.querySelectorAll(".swiper-container");

    for(let i = 0; i < swiperElements.length; i++){
      const swiper = APP.swiper.get(swiperElements[i]);
      if(swiper === undefined){
        continue;
      }
      swiper.detachEvents();
    }

  }


  private _swipeBackReset(view) {

    const swiperElements = view.currentPageEl.querySelectorAll(".swiper-container");

    for(let i = 0; i < swiperElements.length; i++){
      const swiper = APP.swiper.get(swiperElements[i]);
      if(swiper === undefined){
        continue;
      }
      //@ts-ignore
      swiper.attachEvents();
    }

  }


  private _pageMounted(pageData) {

    if(pageData.name === "home"){


      //-- Create Homescreen

      if(HOMESCREEN === undefined){
        HOMESCREEN = new Homescreen(pageData.el.querySelector(".page[data-name='home'] .swiper-container .swiper-wrapper") as HTMLElement);
        globalThis.HOMESCREEN = HOMESCREEN;
      }


      //-- Initialize RoomVolume

      RoomVolume.initialize();


      //-- Initialize settings

      $$(".ch-settings-icon").on("click", () => {
        const settings = new SettingsPopup();
      });


      //-- Initialize dashboard down icon

      $$(".ch-dashboard-down-icon").on("click", () => {
        DASHBOARD.down();
      });

      return;

    }

    const page = Database.getPageByIdentifier(this.activePageIdentifier);

    if(page === undefined){
      return;
    }


    //-- Add page identifier

    pageData.$el.attr("identifier", page.identifier);


    //-- Add page module identifier

    if(page.device !== undefined){
      pageData.$el.attr("device", page.device);
    }


    //-- Emit onPageOpen onPageLeave floorplanleave events

    for(let u = 0; u < globalThis.USERSCRIPTS.length; u++){

      try {
        globalThis.USERSCRIPTS[u].emit("pageopen", pageData.$pageEl[0]);
      } catch (err){
        console.warn(globalThis.USERSCRIPTS[u].name + " pageopen error: ", err);
      }
    }


    //-- Emit script modulepageopen events

    if(page.device !== undefined){

      const device = Database.getDeviceByIdentifier(page.device);
      const userscript = common.getUserScriptByDeviceIdentifier(page.device);

      if(device === undefined || userscript === undefined){
        return;
      }

      userscript.emit("modulepageopen", pageData.$pageEl[0]);
      userscript.emit("devicepageopen", pageData.$pageEl[0]);

    }


    //-- Emit onFloorplanOpen events

    if(page.template === "floorplan"){

      const floorplanSwiper = APP.swiper.get(pageData.$pageEl.find(".swiper-container")[0] as HTMLElement);

      //@ts-ignore
      floorplanSwiper.init();

      for(let u = 0; u < globalThis.USERSCRIPTS.length; u++){
        try {
          globalThis.USERSCRIPTS[u].emit("floorplanopen", pageData.$pageEl[0]);
        } catch (err){
          console.warn(globalThis.USERSCRIPTS[u].name + " floorplanopen error: ", err);
        }
      }

    } else if(page.template === "channellist-remote"){ // Only load page channellists if page has a channellist


      //-- Load channel lists

      this.loadPageChannelLists();

    }

  }


  private _pageInit(pageData) {

    const page = Database.getPageByIdentifier(this.activePageIdentifier);

    if(page === undefined){
      return;
    }


    //-- Add page identifier

    pageData.$el.attr("identifier", page.identifier);


    //-- Add page device identifier

    if(page.device !== undefined){
      pageData.$el.attr("device", page.device);
    }


    //-- Emit onPageInit events

    for(let u = 0; u < globalThis.USERSCRIPTS.length; u++){

      try {
        globalThis.USERSCRIPTS[u].emit("pageinit", pageData.$pageEl[0]);
      } catch (err){
        console.warn(globalThis.USERSCRIPTS[u].name + " pageinit error: ", err);
      }

    }


    //-- Emit script modulepageopen events

    if(page.device !== undefined){

      const device = Database.getDeviceByIdentifier(page.device);
      const userscript = common.getUserScriptByDeviceIdentifier(page.device);

      if(device === undefined || userscript === undefined){
        return;
      }

      userscript.emit("modulepageinit", pageData.$pageEl[0]);
      userscript.emit("devicepageinit", pageData.$pageEl[0]);

    }


    //-- Emit onFloorplanOpen events

    if(page.template === "floorplan"){

      const floorplanSwiper = APP.swiper.get(pageData.$pageEl.find(".swiper-container")[0] as HTMLElement);

      for(let u = 0; u < globalThis.USERSCRIPTS.length; u++){
        try {
          globalThis.USERSCRIPTS[u].emit("floorplaninit", pageData.$pageEl[0]);
        } catch (err){
          console.warn(globalThis.USERSCRIPTS[u].name + " floorplaninit error: ", err);
        }
      }

    }

    this._updatePageFeedbacks();

  }


  private _updatePageFeedbacks() {
    CH_API.updateFeedback();
  }


  private async loadPageChannelLists() {

    const page = Database.getPageByIdentifier(this.activePageIdentifier);

    if(page === undefined){
      return;
    }

    //@ts-ignore
    const currentPageElement = APP.views.main.router.currentPageEl as HTMLElement;


    //-- Load channel list

    if(page.channellist !== undefined){

      const listContainer = currentPageElement.querySelector(".list.channellist") as HTMLElement | null;
      const searchInput = currentPageElement.querySelector("input.channel-search") as HTMLInputElement | null;

      if(listContainer === null){
        return;
      }


      //-- channel list

      const getChannelList = async(identifier: string) => {

        const channelList: Array<string> = [];
        const listData = await CH_API.getData("CHLIST_" + identifier);

        if(typeof listData === "string"){

          channelList.push(...JSON.parse(listData));

          Cloud.get({ "func": "get-channellist", "params": { "payload": { "identifier": identifier } } }).then(async result => {
            if(functions.isParseableJSON(result.channellist.Channels)){
              CH_API.storeData("CHLIST_" + identifier, result.channellist.Channels);
            }
          });

        } else {
          const result = await Cloud.get({ "func": "get-channellist", "params": { "payload": { "identifier": identifier } } });
          if(functions.isParseableJSON(result.channellist.Channels)){
            channelList.push(...JSON.parse(result.channellist.Channels));
            CH_API.storeData("CHLIST_" + identifier, result.channellist.Channels);
          }
        }

        return channelList;

      };


      //-- Page channel list

      const getPageChannelList = async(pageIdentifier: string) => {

        const page = Database.getPageByIdentifier(pageIdentifier);

        if(page?.channellist === undefined){
          return;
        }

        const channelList: Array<string> = [];

        if(page.channellist.identifier === "custom"){
          if(page.channellist.customChannels !== undefined){
            channelList.push(...page.channellist.customChannels);
          }
        } else {
          channelList.push(...await getChannelList(page.channellist.identifier));
        }

        if(channelList.length <= 0){
          return;
        }


        //-- Favorites

        const favoritesString = await CH_API.cloudStorage.getValue("PAGE_CHANNELLIST_" + page.identifier, "#CHANNELLISTS");
        const favoriteChannels: Array<{ number: number; name: string; sortable: boolean; }> = [];

        if(favoritesString !== undefined){
          if(typeof favoritesString === "string" && functions.isParseableJSON(favoritesString)){

            const favorites = JSON.parse(favoritesString) as Array<{ number: number; name: string; }>;

            favorites.forEach(favorite => {
              favoriteChannels.push({ name: favorite.name, number: favorite.number, sortable: true });
            });

          }
        }


        //-- Channels

        const channels: Array<{ number: number; name: string; sortable: boolean; }> = [];

        for(let c = 0; c < channelList.length; c++){
          if(channelList[c] !== ""){
            channels.push({ name: channelList[c], number: c + 1, sortable: false });
          }
        }

        return { channels, favoriteChannels };

      };


      //-- Initialize virtuallist

      const pageChannelList = await getPageChannelList(page.identifier);

      if(pageChannelList === undefined){
        return;
      }

      const channelNumbersEnabled = await CH_API.cloudStorage.getValue("CHANNELS_NUMBERS_ENABLED", "#SETTINGS");
      const height = globalThis.APP.theme === "ios" ? 44 : globalThis.APP.theme === "md" ? 52 : 56;
      const items = [...pageChannelList.favoriteChannels, ...pageChannelList.channels];

      const virtualList = globalThis.APP.virtualList.create({
        el: $$(listContainer),
        height: height,
        items: items,
        renderItem(item) {
          const sortable = item.sortable === true ? "" : "no-sorting";
          return `
              <li channelnumber="${item.number}" channelname="${item.name}" class="swipeout ${sortable}">
                <div class="swipeout-content">
                  <a href="#" class="item-link item-content channel-macro">
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title">${channelNumbersEnabled === true ? item.number + ". " : "" }${item.name}</div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="swipeout-actions-right">
                  <a href="#" class="swipeout-favorite swipeout-overswipe color-yellow">
                    <i class="f7-icons">star</i>
                  </a>
                </div>
              </li>
            `;
        }
      });


      //-- Search input

      if(searchInput !== null){


        //-- Translate placeholder

        searchInput.placeholder = TRANSLATIONS.getText("app-search");


        //-- Filter inputs

        searchInput.addEventListener("input", ev => {

          const target = ev.target as HTMLInputElement;

          if(target === null){
            return;
          }

          const searchValue = target.value.toLowerCase();
          const indices: Array<number> = [];

          virtualList.items.forEach((item, index) => {
            if(searchValue === "" || item.name.toLowerCase().indexOf(searchValue) !== -1 || item.number.toString().indexOf(searchValue) !== -1){
              indices.push(index);
            }
          });

          virtualList.filterItems(indices);

        });
      }


      //-- Click event

      $$(listContainer).on("click", "li", async ev => {

        if(ev.target === null){
          return;
        }

        const $target = $$(ev.target);

        let $li = $$(ev.target);

        if($li[0].tagName.toLocaleLowerCase() !== "li"){
          $li = $li.parents("li");
        }

        const number = +$li.attr("channelnumber");
        const name = $li.attr("channelname");


        //-- Send command

        if(!$target.hasClass("swipeout-favorite") && $target.parents(".swipeout-favorite").length <= 0){
          Cloud.send({ "func": "channel-macro", "params": { "payload": { "number": number, "page": page.identifier } } });
          return;
        }


        //-- Check if channel was already in favorites

        const pageChannelList = await getPageChannelList(page.identifier);

        if(pageChannelList === undefined){
          return;
        }

        let favoriteFound = false;

        for(let f = pageChannelList.favoriteChannels.length - 1; f >= 0; f--){
          if(pageChannelList.favoriteChannels[f].name === name){
            favoriteFound = true;
            pageChannelList.favoriteChannels.splice(f, 1);
          }
        }

        if(favoriteFound === false){
          pageChannelList.favoriteChannels.push({ number, name: name, sortable: true });
        }


        //-- Store page favorites

        CH_API.cloudStorage.storeValue("PAGE_CHANNELLIST_" + page.identifier, JSON.stringify(pageChannelList.favoriteChannels), "#CHANNELLISTS");

        const items = [...pageChannelList.favoriteChannels, ...pageChannelList.channels];
        const virtualList = APP.virtualList.get(listContainer);
        virtualList.resetFilter();
        virtualList.replaceAllItems(items);


        //-- Reset search input

        if(searchInput !== null){
          searchInput.value = "";
          APP.input.blur(searchInput);
          APP.input.checkEmptyState(searchInput);
        }

      });


      //-- Sort event

      APP.on("sortableSort", async(element, data, listEL) => { // Events on the listContainer do not fire consistently

        if(listEL !== listContainer){
          return;
        }

        const from = data.from;
        const to = data.to;

        if(typeof from !== "number" || typeof to !== "number"){
          return;
        }


        //-- Get old favorites

        const pageChannelList = await getPageChannelList(page.identifier);

        if(pageChannelList === undefined){
          return;
        }


        //-- Move item

        if(from < pageChannelList.favoriteChannels.length && to < pageChannelList.favoriteChannels.length){
          const item = pageChannelList.favoriteChannels.splice(from, 1)[0];
          pageChannelList.favoriteChannels.splice(to, 0, item);
        }


        //-- Store page favorites

        CH_API.cloudStorage.storeValue("PAGE_CHANNELLIST_" + page.identifier, JSON.stringify(pageChannelList.favoriteChannels), "#CHANNELLISTS");

      });

    }

  }


  private async _routeChange(newRoute, previousRoute, router): Promise<void> {


    //-- Destroy floorplan instance

    if(this._activeFloorplanInstance !== undefined){
      this._activeFloorplanInstance.destroy();
      this._activeFloorplanInstance = undefined;
    }

    if(newRoute.path === "/index.html" || newRoute.path === "/"){


      //-- Emit script modulepageleave floorplanleave events

      for(let u = 0; u < globalThis.USERSCRIPTS.length; u++){

        const device = Database.getDeviceByIdentifier(globalThis.USERSCRIPTS[u].identifier);

        if(device?.page?.identifier === this.activePageIdentifier){
          try {
            globalThis.USERSCRIPTS[u].emit("modulepageleave");
            globalThis.USERSCRIPTS[u].emit("devicepageleave");
          } catch (err){
            console.warn(globalThis.USERSCRIPTS[u].name + " modulepageleave error: ", err);
          }
        }

        if(previousRoute.path === "/floorplan/"){
          try {
            globalThis.USERSCRIPTS[u].emit("floorplanleave");
          } catch (err){
            console.warn(globalThis.USERSCRIPTS[u].name + " floorplanleave error: ", err);
          }
        }
      }

      this.activePageIdentifier = "home";

      return;
    }

    if(this.activePageIdentifier === undefined){
      return;
    }

    const PDB = CH_PRIVATE.getPDB();

    if(PDB === undefined){
      return;
    }

    const page = Database.getPageByIdentifier(this.activePageIdentifier);

    if(page === undefined){
      return;
    }

    const currentPageElement: HTMLElement = router.currentPageEl;
    let currentNavbarElement: HTMLElement = router.currentNavbarEl;


    //-- Get currentNavbarElement for material theme

    if(currentNavbarElement === undefined){
      currentNavbarElement = currentPageElement.querySelector(".navbar") as HTMLElement;
    }


    //-- Load page title

    const pageTitleElement = currentPageElement.querySelector(".ch-page-title");

    if(pageTitleElement !== null){
      (pageTitleElement as HTMLElement).innerText = page.name;
    }

    const room = Database.getRoomByPageIdentifier(page.identifier);

    if(room !== undefined){


      //-- Update back title

      const backTitleElement = currentPageElement.querySelector(".ch-back-title");

      if(backTitleElement !== null){
        (backTitleElement as HTMLElement).innerText = room.name;
      }


      //-- Update room volume

      const volumeHTML = RoomVolume.getRoomVolumeHTML(room.identifier, false);

      if(volumeHTML !== undefined){

        const roomVolumeContainer = currentPageElement.querySelector(".room-volume");

        if(roomVolumeContainer !== null){
          roomVolumeContainer.innerHTML = volumeHTML;
        }

      }

    }


    //-- Set current page identifier

    currentPageElement.setAttribute("identifier", page.identifier);


    //-- Emit routeChange events

    for(let u = 0; u < globalThis.USERSCRIPTS.length; u++){

      try {
        globalThis.USERSCRIPTS[u].emit("routeChange", currentPageElement);
      } catch (err){
        console.warn(globalThis.USERSCRIPTS[u].name + " routeChange error: ", err);
      }


      //-- Emit script routeChangeToModulePage events

      if(page.device !== undefined && page.device === globalThis.USERSCRIPTS[u].identifier){

        const device = Database.getDeviceByIdentifier(page.device);

        if(device === undefined){
          return;
        }

        if(page.device === globalThis.USERSCRIPTS[u].identifier){

          document.documentElement.setAttribute("scope", page.device + " " + (device.module ?? ""));

          try {
            globalThis.USERSCRIPTS[u].emit("routeChangeToModulePage", currentPageElement);
            globalThis.USERSCRIPTS[u].emit("routeChangeToDevicePage", currentPageElement);
          } catch (err){
            console.warn(globalThis.USERSCRIPTS[u].name + " routeChangeToModulePage error: ", err);
          }

        }

      }

    }


    //-- Load programmed commands

    for(const button of page.buttons){

      if(button.actionIdentifier === undefined){
        continue;
      }

      let device: types.Device | undefined;
      let command: types.Command | undefined;

      if(button.actionDevice !== undefined){
        device = Database.getDeviceByIdentifier(button.actionDevice);
        command = Database.getCommandByIdentifier(button.actionIdentifier, button.actionDevice);
      }

      const macro = Database.getMacroByIdentifier(button.actionIdentifier);
      const room = Database.getRoomByIdentifier(button.actionIdentifier);

      const programmables = currentPageElement.querySelectorAll(".programmable.command");


      //-- Set macro name

      let macroName = "";

      if(macro !== undefined){
        if(macro.event !== undefined){
          const event = Database.getScriptEventByIdentifier(macro.event);
          if(event !== undefined){
            macroName = event.name;
          }
        }
        if(macro.page !== undefined){
          const page = Database.getPageByIdentifier(macro.page);
          if(page !== undefined){
            if(page.on === macro.identifier){
              macroName = page.name + " " + TRANSLATIONS.getText("app-on");
            }
            if(page.off === macro.identifier){
              macroName = page.name + " " + TRANSLATIONS.getText("app-off");
            }
          }
        }
      }

      if(room !== undefined){
        macroName = room.name + " " + TRANSLATIONS.getText("app-off");
      }


      //-- Set device name

      for(let p = 0; p < programmables.length; p++){


        if(programmables[p].getAttribute("command-tag") === button.tag){

          if(device !== undefined && command !== undefined){

            const deviceName = Database.getDeviceName(device);

            programmables[p].classList.add("programmed");
            programmables[p].setAttribute("command-type", device.type);
            programmables[p].setAttribute("command", button.actionIdentifier);
            programmables[p].setAttribute("command-name", command.name);
            programmables[p].setAttribute("command-device", button.actionDevice!);
            programmables[p].setAttribute("title", deviceName + ": " + command.name);
            programmables[p].setAttribute("hold", button.hold + "" || "0");
          }

          if(macro !== undefined || room !== undefined){
            programmables[p].classList.add("programmed");
            programmables[p].setAttribute("command-type", "macro");
            programmables[p].setAttribute("macro", button.actionIdentifier);
            programmables[p].setAttribute("title", "macro" + ": " + macroName);
            programmables[p].setAttribute("hold", button.hold + "" || "0");
          }


          //-- Add command parameters as attributes

          if(button.actionDevice !== undefined && button.actionIdentifier !== undefined && button.parameters !== undefined){

            if(device !== undefined && command !== undefined){

              for(const itemParameter of button.parameters){
                if(command.parameters !== undefined){
                  for(const commandParameter of command.parameters){
                    if(commandParameter.identifier === itemParameter.template){
                      programmables[p].setAttribute("command-parameter-" + commandParameter.name, itemParameter.value + "");
                    }
                  }
                }
              }

            }

          }

          if(button.feedback !== undefined && button.feedbackDevice !== undefined && button.feedbackParameters !== undefined){

            let feedbackName = "";

            const feedback = Database.getFeedbackByIdentifier(button.feedback, button.feedbackDevice);

            if(feedback !== undefined){
              feedbackName = feedback.name;
            }

            programmables[p].setAttribute("feedback", button.feedback);
            programmables[p].setAttribute("feedback-device", button.feedbackDevice);
            programmables[p].setAttribute("feedback-name", feedbackName);


            //-- Add feedback parameters as attributes

            if(device !== undefined && feedback !== undefined){

              for(const itemParameter of button.feedbackParameters){
                if(feedback.parameters !== undefined){
                  for(const feedbackParameter of feedback.parameters){
                    if(feedbackParameter.identifier === itemParameter.template){
                      programmables[p].setAttribute("feedback-parameter-" + feedbackParameter.name, itemParameter.value + "");
                    }
                  }
                }
              }
            }

          }


          //-- Apply decorations

          const span = programmables[p].querySelector("span");

          if(span !== null){

            if(span.classList.contains("f7-icons")){
              span.setAttribute("original-icon", span.innerText);
            } else {
              span.setAttribute("original-label", span.innerText);
            }

            if(button.decorations !== undefined){
              if(button.decorations.heavy === true){
                span.classList.add("heavy");
              }
              if(button.decorations.italic === true){
                span.classList.add("italic");
              }
              if(button.decorations.underline === true){
                span.classList.add("underline");
              }
              if(button.decorations.color !== undefined){
                span.style.setProperty("color", button.decorations.color);
              }

              if(button.decorations.icon !== undefined && button.decorations.icon === true){
                span.classList.add("f7-icons");
              } else {
                span.classList.remove("f7-icons");
              }

              if(button.decorations.label !== undefined){
                span.innerText = button.decorations.label;
              }
              if(button.decorations.size !== undefined){
                span.style.setProperty("font-size", button.decorations.size + "px");
              }
            }
          }

        }
      }

    }


    //-- Load floorplan stories

    if(page.template === "floorplan"){
      this._activeFloorplanInstance = new FloorplanPage(currentPageElement, currentNavbarElement, page.identifier, this._pendingFloorplanStory?.identifier);
      this._pendingFloorplanStory = undefined;
    }

  }


  public getActivePageIdentifier(): string | undefined {
    return this.activePageIdentifier;
  }


  public getActiveFloorplanStoryIdentifier(): string | undefined {

    if(this._activeFloorplanInstance !== undefined){
      return this._activeFloorplanInstance.getActiveFloorplanStoryIdentifier();
    }

    return;

  }


  public reloadFloorplanStory() {

    if(this._activeFloorplanInstance === undefined){
      return;
    }

    const floorplanStoryIdentifier = this._activeFloorplanInstance.getActiveFloorplanStoryIdentifier();

    if(floorplanStoryIdentifier === undefined){
      return;
    }

    const floorplanStory = this._activeFloorplanInstance.getFloorplanStoryByIdentifier(floorplanStoryIdentifier);

    if(floorplanStory === undefined){
      return;
    }

    this._activeFloorplanInstance.renderFloorplanStoryContent(floorplanStory);

  }


  public gotoFloorplanStoryByIdentifier(identifier: string) {
    if(this._activeFloorplanInstance !== undefined){
      this._activeFloorplanInstance?.gotoFloorplanStoryByIdentifier(identifier);
    }
  }


  public gotoPageByIdentifier(pageIdentifier: string): void;
  public gotoPageByIdentifier(floorplanStoryIdentifier: string): void;
  public gotoPageByIdentifier(identifiier: "home"): void;
  public gotoPageByIdentifier(identifier: string): void {


    //-- Homescreen

    if(identifier === "home"){

      if(this.activePageIdentifier !== identifier){
        APP.views.main.router.back("/");
      }

      this.activePageIdentifier = identifier;

      return;

    }


    //-- Template page

    let page = Database.getPageByIdentifier(identifier) ?? Database.getPageByFloorplanStoryIdentifier(identifier);

    if(page?.template === "page-link" && page.link !== undefined){
      this._pendingFloorplanStory = Database.getFloorplanStoryByIdentifier(page.link);
      page = Database.getPageByIdentifier(page.link) ?? Database.getPageByFloorplanStoryIdentifier(page.link);
    } else {
      this._pendingFloorplanStory = Database.getFloorplanStoryByIdentifier(identifier);
    }

    if(page === undefined){
      return;
    }


    //-- Abort if page is already loaded

    if(page.identifier === this.activePageIdentifier){


      //-- Check floorplan story

      if(this._pendingFloorplanStory !== undefined){
        if(this._pendingFloorplanStory.identifier !== this._activeFloorplanInstance?.getActiveFloorplanStoryIdentifier()){
          this.gotoFloorplanStoryByIdentifier(this._pendingFloorplanStory.identifier);
          this._pendingFloorplanStory = undefined;
        }
      }

      return;

    }

    let sameTemplate = false;

    if(this.activePageIdentifier !== undefined){
      const oldPage = Database.getPageByIdentifier(this.activePageIdentifier);
      if(oldPage !== undefined){
        if(oldPage.template === page.template){
          sameTemplate = true;
        }
      }
    }

    this.activePageIdentifier = page.identifier;

    if(sameTemplate === true){
      APP.views.main.router.navigate("/" + page.template + "/", {
        animate: APP.params.view?.animate ?? true,
        reloadCurrent: true
      });
    } else {

      if(APP.views.main.router.currentRoute.path !== "/index.html" &&
          APP.views.main.router.currentRoute.path !== "/" &&
          APP.views.main.router.currentRoute.path !== "/app/index.html" &&
          APP.views.main.router.currentRoute.path !== "/app/"){

        APP.views.main.router.navigate("/" + page.template + "/", {
          animate: APP.params.view?.animate ?? true,
          reloadCurrent: true
        });

      } else {

        APP.views.main.router.navigate("/" + page.template + "/", {
          animate: APP.params.view?.animate ?? true,
          reloadCurrent: false
        });

      }

    }

  }

}


class FloorplanPage {

  private _container: HTMLElement;
  private _navbar: HTMLElement;
  private _pageIdentifier: string;
  private _initialStoryIdentifier: string | undefined;
  private _layerSelectionElement: HTMLElement | null;
  private _hiddenLayers: Array<types.FloorplanLayerCategory> = [];

  public swiper: any;

  private _swiperInitEvent: (ev: Event) => void;
  private _swiperSlideChangeEvent: (ev: Event) => void;
  private _layerSelectionClickEvent: (ev: Event) => void;

  constructor(container: HTMLElement, navbar: HTMLElement, floorplanPageIdentifier: string, floorplanStoryIdentifier?: string) {

    this._container = container;
    this._navbar = navbar;
    this._pageIdentifier = floorplanPageIdentifier;
    this._initialStoryIdentifier = floorplanStoryIdentifier;
    this._layerSelectionElement = this._container.querySelector(".floorplan-layer-selection");


    //-- Initialize event listeners

    this._swiperInitEvent = this._swiperInit.bind(this);
    this._swiperSlideChangeEvent = this._swiperSlideChange.bind(this);
    this._layerSelectionClickEvent = this.openLayerSelection.bind(this);

    this.initialize();

  }


  public initialize() {


    //-- Create floorplan

    let storyList = "";

    const floorplanPage = Database.getPageByIdentifier(this._pageIdentifier) as types.FloorplanPage;

    if(floorplanPage === undefined){
      return;
    }

    if(floorplanPage.stories !== undefined){

      for(const floorplanStory of floorplanPage.stories){

        const floorplanBackgroundHidden = floorplanStory.backgroundImage === undefined || floorplanStory.backgroundImage === "" ? " hidden " : " ";


        //-- Add floorplan layers

        storyList += `
          <div class="swiper-slide floorplan-story" identifier="${floorplanStory.identifier}">
            <div class="selectable-container page-content" selectgroup="floorplan-items">
              <div class="zoom-container zoom">


                <!-- Background image -->

                <img class="floorplan-layer background ${floorplanBackgroundHidden}" src="${floorplanStory.backgroundImage}" alt=""></img>


                <!-- Upload button -->

                <div class="floorplan-upload-image hidden">
                  <div class="chip">${TRANSLATIONS.getText("app-floorplan-upload")}</div>
                </div>


                <!-- Lamps -->

                <svg class="floorplan-layer lamps" width="100%" height="100%" viewBox="0 0 ${floorplanStory.backgroundWidth} ${floorplanStory.backgroundHeight}">

                </svg>


                <!-- Windows -->

                <svg class="floorplan-layer windows" width="100%" height="100%" viewBox="0 0 ${floorplanStory.backgroundWidth} ${floorplanStory.backgroundHeight}">

                </svg>


                <!-- Blinds -->

                <svg class="floorplan-layer blinds" width="100%" height="100%" viewBox="0 0 ${floorplanStory.backgroundWidth} ${floorplanStory.backgroundHeight}">

                </svg>


                <!-- Thermostats -->

                <!-- https://stackoverflow.com/a/20593342/10209765 -->

                <div class="floorplan-layer thermostats" style="aspect-ratio: ${floorplanStory.backgroundWidth} / ${floorplanStory.backgroundHeight};">

                </div>

              </div>
            </div>
          </div>
        `;

      }

      const swiperWrapper = this._container.querySelector(".swiper-container .swiper-wrapper");
      const swiperContainer = this._container.querySelector(".swiper-container");

      if(swiperContainer === null || swiperWrapper === null){
        return;
      }

      swiperWrapper.innerHTML = storyList;

      for(const floorplanStory of floorplanPage.stories){
        this.renderFloorplanStoryContent(floorplanStory);
      }


      //-- Initialize swiper

      this.swiper = APP.swiper.create(swiperContainer as HTMLElement, {
        speed: 400,
        spaceBetween: 100,
        touchStartPreventDefault: false, // Allows mousedown event on creator
        init: false,
        pagination: {
          el: this._navbar.querySelector(".floorplan-story-navigation"),
          type: "bullets",
          bulletClass: "tab",
          bulletActiveClass: "active",
          clickable: true,
          renderBullet: (index, className) => {
            return `
                <span class="${className} tab">${ floorplanPage.stories![index].name }</span>
              `;
          }
        }
      });


      //@ts-expect-error
      swiperContainer.swiper = this.swiper;


      //-- Initialize events

      this.swiper.on("init", this._swiperInitEvent);
      this.swiper.on("slideChange", this._swiperSlideChangeEvent);

      if(this._layerSelectionElement !== null){
        this._layerSelectionElement.addEventListener("click", this._layerSelectionClickEvent);
      }
    }


    //-- Hide hidden layers on app

    if(CH_PRIVATE.getDevice() !== "iframe"){
      Storage.getData("HIDDEN_FLOORPLAN_LAYERS_" + this._pageIdentifier).then(hiddenFloorplanLayers => {

        if(typeof hiddenFloorplanLayers === "string"){
          this._hiddenLayers = JSON.parse(hiddenFloorplanLayers);
        }

        for(const hiddenLayer of this._hiddenLayers){
          if(this._hiddenLayers.includes(hiddenLayer)){
            this.hideFloorplanLayer(hiddenLayer);
          } else {
            this.showFloorplanLayer(hiddenLayer);
          }
        }

      });
    }

  }


  public async openLayerSelection(ev: Event) {

    if(this._layerSelectionElement === null){
      return;
    }

    const page = Database.getPageByIdentifier(this._pageIdentifier);

    const availableLayers: Array<{ label: string; category: types.FloorplanLayerCategory; hidden: boolean; }> = [];

    const hiddenFloorplanLayers = await Storage.getData("HIDDEN_FLOORPLAN_LAYERS_" + this._pageIdentifier);

    if(typeof hiddenFloorplanLayers === "string"){
      this._hiddenLayers = JSON.parse(hiddenFloorplanLayers);
    }

    //@ts-ignore
    const hasLamps = (page as types.FloorplanPage).stories?.some(story => story.layers.some(layer => layer.category === "lamps" || layer.category === "lamp" && layer.items.length > 0));
    const hasWindows = (page as types.FloorplanPage).stories?.some(story => story.layers.some(layer => layer.category === "windows" && layer.items.length > 0));
    //@ts-ignore
    const hasThermostats = (page as types.FloorplanPage).stories?.some(story => story.layers.some(layer => layer.category === "thermostats" || layer.category === "thermostat" && layer.items.length > 0));
    const hasBlinds = (page as types.FloorplanPage).stories?.some(story => story.layers.some(layer => layer.category === "blinds" && layer.items.length > 0));

    if(hasLamps === true){
      availableLayers.push({
        label: TRANSLATIONS.getText("app-floorplan-layer-lamps"),
        category: "lamps",
        hidden: this._hiddenLayers.includes("lamps")
      });
    }
    if(hasWindows === true){
      availableLayers.push({
        label: TRANSLATIONS.getText("app-floorplan-layer-windows"),
        category: "windows",
        hidden: this._hiddenLayers.includes("windows")
      });
    }
    if(hasBlinds === true){
      availableLayers.push({
        label: TRANSLATIONS.getText("app-floorplan-layer-blinds"),
        category: "blinds",
        hidden: this._hiddenLayers.includes("blinds")
      });
    }
    if(hasThermostats === true){
      availableLayers.push({
        label: TRANSLATIONS.getText("app-floorplan-layer-thermostats"),
        category: "thermostats",
        hidden: this._hiddenLayers.includes("thermostats")
      });
    }

    let content = "";

    for(const availableLayer of availableLayers){

      const itemChecked = availableLayer.hidden === true ? " " : " checked='checked' ";

      content += `
        <li>
          <label class="item-checkbox item-content">
            <input type="checkbox" ${itemChecked} category="${availableLayer.category}" />
            <i class="icon icon-checkbox"></i>
            <div class="item-inner">
              <div class="item-title">${availableLayer.label}</div>
            </div>
          </label>
        </li>
      `;
    }

    const popover = APP.popover.create({
      "targetEl": this._layerSelectionElement,
      "content": `
        <div class="popover">
          <div class="popover-inner">
            <div class="list">
              <ul>
                ${content}
              </ul>
            </div>
          </div>
        </div>
      `,
      "closeByBackdropClick": true,
      "closeByOutsideClick": false
    });

    popover.open();


    //-- Initialize events

    const popoverCheckboxes = popover.el.querySelectorAll("input[type='checkbox']");

    for(let c = 0; c < popoverCheckboxes.length; c++){
      popoverCheckboxes[c].addEventListener("change", ev => {

        const target = ev.target as HTMLInputElement;

        if(target === null){
          return;
        }

        const category = target.getAttribute("category") as types.FloorplanLayerCategory;

        if(category === null){
          return;
        }

        const checked = target.checked;

        if(checked === true){
          for(let e = this._hiddenLayers.length - 1; e >= 0; e--){
            if(this._hiddenLayers[e] === category){
              this._hiddenLayers.splice(e, 1);
              this.showFloorplanLayer(category);
              break;
            }
          }
        } else {
          this._hiddenLayers.push(category);
          this.hideFloorplanLayer(category);
        }

        Storage.storeData("HIDDEN_FLOORPLAN_LAYERS_" + this._pageIdentifier, JSON.stringify(this._hiddenLayers));

      });
    }

  }


  public showFloorplanLayer(category: types.FloorplanLayerCategory) {
    $$(`.page.floorplan[identifier="${this._pageIdentifier}"] .floorplan-layer.${category}`).removeClass("hidden");
  }


  public hideFloorplanLayer(category: types.FloorplanLayerCategory) {
    $$(`.page.floorplan[identifier="${this._pageIdentifier}"] .floorplan-layer.${category}`).addClass("hidden");
  }


  public getFloorplanStoryByIdentifier(identifier: string): types.FloorplanStory | undefined {

    const floorplanPage = Database.getPageByIdentifier(this._pageIdentifier) as types.FloorplanPage;

    if(floorplanPage === undefined){
      return;
    }

    if(floorplanPage.stories !== undefined){
      for(const floorplanStory of floorplanPage.stories){
        if(floorplanStory.identifier === identifier){
          return floorplanStory;
        }
      }
    }

    return;

  }


  public gotoFloorplanStoryByIdentifier(identifier: string, disableAnimation = false): void {

    const floorplanStoryIndex = Database.getFloorplanStoryIndexByIdentifier(identifier);

    const swiper = APP.swiper.get(this._container.querySelector(".swiper-container") as HTMLElement);

    if(floorplanStoryIndex === undefined){
      return;
    }

    if(swiper !== undefined){
      disableAnimation === true ? swiper.slideTo(floorplanStoryIndex, 0) : swiper.slideTo(floorplanStoryIndex);
    }

  }


  public getActiveFloorplanStoryIdentifier(): string | undefined {

    const activeFloorplanStoryElement = this._container.querySelector(".floorplan-story.swiper-slide-active");

    if(activeFloorplanStoryElement !== null){
      return activeFloorplanStoryElement.getAttribute("identifier") ?? undefined;
    }

    return undefined;

  }


  private _swiperInit(ev) {

    const floorplanPage = Database.getPageByIdentifier(this._pageIdentifier) as types.FloorplanPage;

    if(floorplanPage === undefined){
      return;
    }

    const zoomContainers = this._container.querySelectorAll(".zoom-container");

    for(let z = 0; z < zoomContainers.length; z++){
      const zoom = new Zoom(zoomContainers[z] as HTMLElement);
      //@ts-expect-error
      zoomContainers[z].zoom = zoom;
    }


    //-- Init tab navigation

    //@ts-expect-error
    const tabNavigation = APP.TabNavigation.create({
      el: this._navbar.querySelector(".floorplan-story-navigation") as HTMLElement
    });


    //-- Load first story

    if(floorplanPage.stories !== undefined && floorplanPage.stories.length > 0){
      if(this._initialStoryIdentifier !== undefined){
        this.gotoFloorplanStoryByIdentifier(this._initialStoryIdentifier);
      }
    }

  }


  private _swiperSlideChange(ev) {

    const floorplanPage = Database.getPageByIdentifier(this._pageIdentifier) as types.FloorplanPage;

    if(floorplanPage === undefined){
      return;
    }

    if(floorplanPage.stories === undefined){
      return;
    }

    if(this.swiper.activeIndex === 0){
      (this._navbar.querySelector(".floorplan-story-navigation") as HTMLElement).scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else if(this.swiper.activeIndex === floorplanPage.stories.length - 1){
      (this._navbar.querySelector(".floorplan-story-navigation") as HTMLElement).scrollTo({ top: 0, left: (this._navbar.querySelector(".floorplan-story-navigation") as HTMLElement).scrollWidth, behavior: "smooth" });
    } else {
      (this._navbar.querySelector(".floorplan-story-navigation .tab.active") as HTMLElement).scrollIntoView({ block: "end", inline: "nearest", behavior: "smooth" });
    }

  }


  public renderFloorplanStoryContent(floorplanStory: types.FloorplanStory) {

    const floorplanLayer = this._container.querySelector(`.floorplan-story[identifier="${floorplanStory.identifier}"]`);

    if(floorplanLayer === null){
      return;
    }

    const floorplanLayerLampsContainer = floorplanLayer.querySelector(".floorplan-layer.lamps");
    const floorplanLayerWindowsContainer = floorplanLayer.querySelector(".floorplan-layer.windows");
    const floorplanLayerBlindsContainer = floorplanLayer.querySelector(".floorplan-layer.blinds");
    const floorplanLayerThermostatsContainer = floorplanLayer.querySelector(".floorplan-layer.thermostats");

    if(floorplanLayerLampsContainer === null ||
      floorplanLayerWindowsContainer === null ||
      floorplanLayerBlindsContainer === null ||
      floorplanLayerThermostatsContainer === null){
      return;
    }

    let lamps = "";
    let windows = "";
    let lampDefs = "";
    let blinds = "";
    let thermostats = "";

    const windowDefs = `
      <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
        <path d="M-1,1 l2,-2
          M0,4 l4,-4
          M3,5 l2,-2" 
        style="stroke:black; stroke-width:white; stroke-opacity: 0.5;" />
      </pattern>
    `;

    const borderRadius = 100 / constants.FLOORPLAN_ITEM_SCALE_FACTOR * 12;
    const padding = constants.FLOORPLAN_ITEM_PADDING / window.innerWidth * 75;


    //-- Add layers

    for(const layer of floorplanStory.layers){

      for(const item of layer.items){

        let commandName = "";
        let feedbackName = "";

        if(item.actionIdentifier !== undefined && item.actionDevice !== undefined){
          const command = Database.getCommandByIdentifier(item.actionIdentifier, item.actionDevice);
          if(command !== undefined){
            commandName = command.name;
          }
        }

        if(item.feedback !== undefined){
          const feedback = Database.getFeedbackByIdentifier(item.feedback, item.feedbackDevice);
          if(feedback !== undefined){
            feedbackName = feedback.name;
          }
        }


        //-- Add command parameters as attributes

        let commandAttributes = "";

        if(item.actionDevice !== undefined && item.actionIdentifier !== undefined && item.parameters !== undefined){

          const device = Database.getDeviceByIdentifier(item.actionDevice);
          const command = Database.getCommandByIdentifier(item.actionIdentifier, item.actionDevice);

          if(device !== undefined && command !== undefined){

            for(const itemParameter of item.parameters){
              if(command.parameters !== undefined){
                for(const commandParameter of command.parameters){
                  if(commandParameter.identifier === itemParameter.template){
                    commandAttributes += " command-parameter-" + commandParameter.name + "=\"" + itemParameter.value + "\"";
                  }
                }
              }
            }
          }
        }


        //-- Add feedback parameters as attributes

        let feedbackAttributes = "";

        if(item.feedbackDevice !== undefined && item.feedback !== undefined && item.feedbackParameters !== undefined){

          const device = Database.getDeviceByIdentifier(item.feedbackDevice);
          const feedback = Database.getFeedbackByIdentifier(item.feedback, item.feedbackDevice);

          if(device !== undefined && feedback !== undefined){

            for(const itemParameter of item.feedbackParameters){
              if(feedback.parameters !== undefined){
                for(const feedbackParameter of feedback.parameters){
                  if(feedbackParameter.identifier === itemParameter.template){
                    feedbackAttributes += " feedback-parameter-" + feedbackParameter.name + "=\"" + itemParameter.value + "\"";
                  }
                }
              }
            }
          }
        }


        //-- Set command type

        let commandType = "";
        let programmed = "";

        if(item.actionIdentifier !== undefined && item.actionDevice !== undefined){
          const device = Database.getDeviceByIdentifier(item.actionDevice);
          const command = Database.getCommandByIdentifier(item.actionIdentifier, item.actionDevice);
          if(device !== undefined && command !== undefined){
            commandType = device.type;
            programmed = " programmed ";
          }
        }

        const attributes = `identifier="${item.identifier}" command="${item.actionIdentifier ?? ""}" command-type="${commandType}" command-name="${commandName}" feedback="${item.feedback}" feedback-status="${feedbackName}" command-device="${item.actionDevice}" feedback-device="${item.feedbackDevice ?? ""}" `;


        //-- Lamps

        //@ts-ignore
        if(layer.category === "lamps" || layer.category === "lamp"){

          if(item.icon === "circle"){

            const radius = 100 / constants.FLOORPLAN_ITEM_SCALE_FACTOR * (item.width / 2);

            // const radiusMax = floorplanStory.backgroundWidth < floorplanStory.backgroundHeight ? floorplanStory.backgroundWidth : floorplanStory.backgroundHeight;
            // const multiplierMax = 4;
            // const percentageOfRadius = (100 / radiusMax) * radius;
            // const scaleFactor = functions.mapNumber(100 - percentageOfRadius, 0, 100, 1, multiplierMax);
            // const glowRadius = radius * scaleFactor;
            // const finalRadius = glowRadius > radius ? glowRadius : radius * 2;

            const finalRadius = radius * 2;

            lamps += `
              <rect class="floorplan-item touch-area lamp programmable command feedback selectable ${programmed}" vector-effect="non-scaling-stroke" ${attributes} ${commandAttributes} ${feedbackAttributes} selectgroup="floorplan-items" rx="${borderRadius}" ry="${borderRadius}" x="${item.left / 100 * floorplanStory.backgroundWidth - (padding / 2) - (radius / 2)}" y="${item.top / 100 * floorplanStory.backgroundHeight - (padding / 2) - (radius / 2)}" width="${padding + radius}" height="${padding + radius}" />
              <circle class="floorplan-item glow" ${attributes} ${commandAttributes} ${feedbackAttributes} fill="url(#glow-effect-${item.identifier})" cx="${item.left / 100 * floorplanStory.backgroundWidth}" cy="${item.top / 100 * floorplanStory.backgroundHeight}" r="${finalRadius}" />
              <circle class="floorplan-item icon" vector-effect="non-scaling-stroke ${programmed}" ${attributes} ${commandAttributes} ${feedbackAttributes} cx="${item.left / 100 * floorplanStory.backgroundWidth}" cy="${item.top / 100 * floorplanStory.backgroundHeight}" r="${radius}" />
            `;

          } else if(item.icon === "rectangle"){

            const width = 100 / constants.FLOORPLAN_ITEM_SCALE_FACTOR * item.width;
            const height = 100 / constants.FLOORPLAN_ITEM_SCALE_FACTOR * item.height;

            // const widthMax = floorplanStory.backgroundWidth / 4;
            // const heightMax = floorplanStory.backgroundHeight / 4;

            // const multiplierMax = 4;

            // const percentageOfWidth = (100 / widthMax) * width;
            // const percentageOfHeight = (100 / heightMax) * height;

            // const scaleFactorX = functions.mapNumber(100 - percentageOfWidth, 0, 100, 1, multiplierMax);
            // const scaleFactorY = functions.mapNumber(100 - percentageOfHeight, 0, 100, 1, multiplierMax);

            // const glowWidth = width * scaleFactorX;
            // const glowHeight = height * scaleFactorY;

            // const finalWidth = glowWidth > width ? glowWidth : width;
            // const finalHeight = glowHeight > height ? glowHeight : height;

            const smallestSide = width < height ? width : height;

            const finalWidth = width + smallestSide * 2;
            const finalHeight = height + smallestSide * 2;

            lamps += `
              <rect class="floorplan-item touch-area lamp programmable command feedback selectable ${programmed}" vector-effect="non-scaling-stroke" ${attributes} ${commandAttributes} ${feedbackAttributes} selectgroup="floorplan-items" rx="${borderRadius}" ry="${borderRadius}" x="${item.left / 100 * floorplanStory.backgroundWidth - (padding / 2) - (width / 2)}" y="${item.top / 100 * floorplanStory.backgroundHeight - (padding / 2) - (height / 2)}" width="${padding + width}" height="${padding + height}" />
              <rect class="floorplan-item glow" ${attributes} ${commandAttributes} ${feedbackAttributes} feedback-status="${feedbackName}" fill="url(#glow-effect-${item.identifier})" x="${item.left / 100 * floorplanStory.backgroundWidth - (finalWidth / 2)}" y="${item.top / 100 * floorplanStory.backgroundHeight - (finalHeight / 2)}" width="${finalWidth}" height="${finalHeight}" />
              <rect class="floorplan-item icon ${programmed}" vector-effect="non-scaling-stroke" ${attributes} ${commandAttributes} ${feedbackAttributes} feedback-status="${feedbackName}" x="${item.left / 100 * floorplanStory.backgroundWidth - (width / 2)}" y="${item.top / 100 * floorplanStory.backgroundHeight - (height / 2)}" width="${width}" height="${height}" />
            `;

          }

          lampDefs += `
            <radialGradient id="glow-effect-${item.identifier}" class="floorplan-filter glow-effect" identifier="${item.identifier}" cx="0.5" cy="0.5" r="0.5">
              <stop offset="0" stop-color="rgba(255, 221, 89, 0)" />
              <stop offset="1" stop-color="rgba(255, 221, 89, 0)" />
            </radialGradient>
          `;

        }


        //-- Windows

        if(layer.category === "windows"){

          const width = 100 / constants.FLOORPLAN_ITEM_SCALE_FACTOR * item.width;
          const height = 100 / constants.FLOORPLAN_ITEM_SCALE_FACTOR * item.height;

          windows += `
            <rect class="floorplan-item touch-area window programmable command feedback selectable ${programmed}" vector-effect="non-scaling-stroke" ${attributes} ${commandAttributes} ${feedbackAttributes} selectgroup="floorplan-items" rx="${borderRadius}" ry="${borderRadius}" x="${item.left / 100 * floorplanStory.backgroundWidth - (padding / 2) - (width / 2)}" y="${item.top / 100 * floorplanStory.backgroundHeight - (padding / 2) - (height / 2)}" width="${padding + width}" height="${padding + height}" />
            <rect class="floorplan-item icon ${programmed}" vector-effect="non-scaling-stroke" ${attributes} ${commandAttributes} ${feedbackAttributes} feedback-status="${feedbackName}" x="${item.left / 100 * floorplanStory.backgroundWidth - (width / 2)}" y="${item.top / 100 * floorplanStory.backgroundHeight - (height / 2)}" width="${width}" height="${height}" />
            <rect class="floorplan-item overlay" fill="url(#diagonalHatch)" vector-effect="non-scaling-stroke" x="${item.left / 100 * floorplanStory.backgroundWidth - (width / 2)}" y="${item.top / 100 * floorplanStory.backgroundHeight - (height / 2)}" width="${width}" height="${height}" />
          `;

        }


        //-- Blinds

        if(layer.category === "blinds"){

          const width = 100 / constants.FLOORPLAN_ITEM_SCALE_FACTOR * item.width;
          const height = 100 / constants.FLOORPLAN_ITEM_SCALE_FACTOR * item.height;

          const scaleFactor = 1 / 13 * width;

          blinds += `
            <rect class="floorplan-item touch-area blinds programmable command feedback selectable ${programmed}" vector-effect="non-scaling-stroke" ${attributes} ${commandAttributes} ${feedbackAttributes} selectgroup="floorplan-items" rx="${borderRadius}" ry="${borderRadius}" x="${item.left / 100 * floorplanStory.backgroundWidth - (padding / 2) - (width / 2)}" y="${item.top / 100 * floorplanStory.backgroundHeight - (padding / 2) - (height / 2)}" width="${padding + width}" height="${padding + height}" />
            <g class="floorplan-item icon ${programmed}" ${attributes} ${commandAttributes} ${feedbackAttributes} transform="translate(${item.left / 100 * floorplanStory.backgroundWidth - (width / 2)}, ${item.top / 100 * floorplanStory.backgroundHeight - (height / 2)})">
              <path class="background" fill-rule="evenodd" clip-rule="evenodd" d="${svgpath("M2 0C1.44772 0 1 0.447715 1 1V14C1 14.5523 1.44772 15 2 15H13C13.5523 15 14 14.5523 14 14V1C14 0.447715 13.5523 0 13 0H2Z").scale(scaleFactor)}" fill="var(--fp-blinds-bg-color)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="${svgpath("M2 0C1.44772 0 1 0.447715 1 1V14C1 14.5523 1.44772 15 2 15H13C13.5523 15 14 14.5523 14 14V1C14 0.447715 13.5523 0 13 0H2ZM2 0.5C1.72386 0.5 1.5 0.723858 1.5 1V14C1.5 14.2761 1.72386 14.5 2 14.5H13C13.2761 14.5 13.5 14.2761 13.5 14V1C13.5 0.723858 13.2761 0.5 13 0.5H2Z").scale(scaleFactor)}" fill="var(--fp-blinds-color)"/>
              <g class="fins">
                <path id="fin-1" d="${svgpath("M3 2.5C3 2.22386 3.22386 2 3.5 2H11.5C11.7761 2 12 2.22386 12 2.5C12 2.77614 11.7761 3 11.5 3H3.5C3.22386 3 3 2.77614 3 2.5Z").scale(scaleFactor)}" fill="var(--fp-blinds-color)"/>
                <path id="fin-2" d="${svgpath("M3 4.5C3 4.22386 3.22386 4 3.5 4H11.5C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H3.5C3.22386 5 3 4.77614 3 4.5Z").scale(scaleFactor)}" fill="var(--fp-blinds-color)"/>
                <path id="fin-3" d="${svgpath("M3 6.5C3 6.22386 3.22386 6 3.5 6H11.5C11.7761 6 12 6.22386 12 6.5C12 6.77614 11.7761 7 11.5 7H3.5C3.22386 7 3 6.77614 3 6.5Z").scale(scaleFactor)}" fill="var(--fp-blinds-color)"/>
                <path id="fin-4" d="${svgpath("M3 8.5C3 8.22386 3.22386 8 3.5 8H11.5C11.7761 8 12 8.22386 12 8.5C12 8.77614 11.7761 9 11.5 9H3.5C3.22386 9 3 8.77614 3 8.5Z").scale(scaleFactor)}" fill="var(--fp-blinds-color)"/>
                <path id="fin-5" d="${svgpath("M3 10.5C3 10.2239 3.22386 10 3.5 10H11.5C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H3.5C3.22386 11 3 10.7761 3 10.5Z").scale(scaleFactor)}" fill="var(--fp-blinds-color)"/>
                <path id="fin-6" d="${svgpath("M3 12.5C3 12.2239 3.22386 12 3.5 12H11.5C11.7761 12 12 12.2239 12 12.5C12 12.7761 11.7761 13 11.5 13H3.5C3.22386 13 3 12.7761 3 12.5Z").scale(scaleFactor)}" fill="var(--fp-blinds-color)"/>
              </g>
            </g>
          `;

        }


        //-- Thermostats

        //@ts-ignore
        if(layer.category === "thermostats" || layer.category === "thermostat"){

          thermostats += `
            <div class="floorplan-item touch-area thermostat programmable command feedback selectable ${programmed}" vector-effect="non-scaling-stroke" ${attributes} ${commandAttributes} ${feedbackAttributes} selectgroup="floorplan-items" style="left: ${item.left}%; top: ${item.top}%; transform: translate3d(-50%, -50%, 0);">
              <div class="floorplan-item icon thermostat programmable command feedback selectable ${programmed}" vector-effect="non-scaling-stroke" ${attributes} ${commandAttributes} ${feedbackAttributes} feedback-text="${feedbackName}" selectgroup="floorplan-items"></div>
            </div>
          `;

        }

      }

    }

    floorplanLayerLampsContainer.innerHTML = `${lampDefs}${lamps}`;
    floorplanLayerWindowsContainer.innerHTML = `${windowDefs}${windows}`;
    floorplanLayerBlindsContainer.innerHTML = blinds;
    floorplanLayerThermostatsContainer.innerHTML = thermostats;

  }


  public destroy() {
    this.swiper.off("init", this._swiperInitEvent);
    this.swiper.off("slideChange", this._swiperSlideChangeEvent);
  }

}


interface RoomVolumeSliders {
  room: string;
  slider: VolumeSlider;
}

abstract class RoomVolume {

  static sliders: Array<RoomVolumeSliders> = [];
  static _pressedButtons: Array<{ id: number; commandIdentifier: string; deviceIdentifier: string; }> = [];
  static initialized = false;

  static async initialize() {

    if(RoomVolume.initialized === true){
      return;
    }

    RoomVolume.initialized = true;
    RoomVolume.generateHomescreenRoomVolumeHTML();

    const CH_PDB = CH_PRIVATE.getPDB();

    if(CH_PDB === undefined){
      return;
    }

    if(CH_PDB.rooms.length <= 0){
      return;
    }

    if(HOMESCREEN !== undefined){
      const room = HOMESCREEN.getCurrentRoom();
      if(room !== undefined){
        RoomVolume.loadRoom(room.identifier);
      }
    }


    //-- Initialize event listeners

    CH_API.on("feedbackChanged", RoomVolume.updatVolumesFromFeedbacks);
    CH_PRIVATE.on("feedbacksChanged", RoomVolume.updatVolumesFromFeedbacks);
    // CH_API.on("signedin", () => { RoomVolume.loadRoom(CH_PDB.rooms[Homescreen.swiper.activeIndex].identifier); });

  }


  static async updatVolumesFromFeedbacks(feedback: types.Object) {

    const isGlobalFeedback = feedback.name === undefined;

    if(isGlobalFeedback === false && feedback.name !== "VOLUME"){
      return;
    }

    const CH_PDB = CH_PRIVATE.getPDB();

    if(CH_PDB === undefined){
      return;
    }

    roomLoop: for(const room of CH_PDB.rooms){

      if(room.volume === undefined){
        continue roomLoop;
      }

      if(room.volume.type !== "slider"){
        continue roomLoop;
      }

      const device = Database.getDeviceByIdentifier(room.volume.device);

      if(device === undefined){
        continue roomLoop;
      }

      const automa = Database.getAutomaByDeviceIdentifier(device.identifier);

      if(automa === undefined){
        continue roomLoop;
      }

      let value;

      if(feedback.automaIdentifier === automa.identifier && feedback.deviceIdentifier === device.identifier){
        value = feedback.value;
      } else {
        if(isGlobalFeedback === true){
          value = CH_API.getFeedback("VOLUME", automa.identifier, device.identifier);
        }
      }


      //-- Set volume to current feedback value

      if(value !== undefined){
        RoomVolume.setRoomVolume(room.identifier, +value);
      }

    }

  }


  static getRoomVolumeHTML(roomidentifier: string, hiddenByDefault = true): string | undefined {

    const room = Database.getRoomByIdentifier(roomidentifier);

    if(room === undefined){
      return;
    }

    if(room.volume === undefined){
      return;
    }

    const device = Database.getDeviceByIdentifier(room.volume.device);

    if(device === undefined){
      return;
    }


    //-- Receive default values

    let value = 0;

    for(const slider of RoomVolume.sliders){
      if(slider.room === room.identifier){
        value = slider.slider.lastValue;
      }
    }


    //-- Set hidden

    const hidden = hiddenByDefault === true ? " hidden " : "";

    const speaker = RoomVolume.getSpeakerIconFromValue(value);

    if(room.volume.type === "slider"){
      return `
        <div class="volume-slider ${hidden}" identifier="${room.identifier}">
          <input class="slider" type="range" value="${value}" min="0" max="100" step="1">
          <a href="#" class="link no-ripple handle">
            <i class="f7-icons">${speaker}</i>
          </a>
        </div>
      `;
    }
    if(room.volume.type === "buttons"){

      let isMuteHidden = "hidden";

      if(device.overrides !== undefined){
        for(const command of device.overrides.commands){
          if(command.name === "VOLUME_MUTE_TOGGLE"){
            isMuteHidden = "";
          }
        }
      }
      if(device.module !== undefined){

        const mod = Database.getModuleByIdentifier(device.module, device.version);

        if(mod !== undefined){
          for(const command of mod.commands){
            if(command.name === "VOLUME_MUTE_TOGGLE"){
              isMuteHidden = "";
            }
          }
        }

      }

      return `
        <div class="volume-buttons ${hidden}" identifier="${room.identifier}">
          <a href="#" class="link no-ripple volume-down icon-only">
            <i class="f7-icons">speaker_1</i>
          </a>
          <a href="#" class="link no-ripple volume-up icon-only">
            <i class="f7-icons">speaker_3</i>
          </a>
          <a href="#" class="link no-ripple volume-mute icon-only ${isMuteHidden}">
            <i class="f7-icons">speaker_slash</i>
          </a>
        </div>
      `;
    }

    return;

  }


  static async generateHomescreenRoomVolumeHTML() {

    const CH_PDB = CH_PRIVATE.getPDB();

    if(CH_PDB === undefined){
      return;
    }


    //-- Load volume sliders into navbar

    let roomVolumeList = "";

    for(const room of CH_PDB.rooms){

      if(room.volume === undefined){
        continue;
      }

      const html = RoomVolume.getRoomVolumeHTML(room.identifier);

      if(html !== undefined){
        roomVolumeList += html;
      }

    }

    $$(".navbar .room-volume").html(roomVolumeList);


    //-- Initialize room volume buttons

    $$(document).off(pointerdown, ".volume-buttons a", RoomVolume.buttonDown);
    $$(document).on(pointerdown, ".volume-buttons a", RoomVolume.buttonDown);
    $$(document).off(pointerup, RoomVolume.buttonUp);
    $$(document).on(pointerup, RoomVolume.buttonUp);


    //-- Initialize room volume sliders

    for(const room of CH_PDB.rooms){

      if(room.volume !== undefined){

        if(room.volume.type === "slider"){


          //-- Slider

          const volumeslider = new VolumeSlider(".volume-slider[identifier='" + room.identifier + "']");

          RoomVolume.sliders.push({ "room": room.identifier, "slider": volumeslider });

          volumeslider.on("change", value => {

            $$(volumeslider.selector).find(".handle i").text(RoomVolume.getSpeakerIconFromValue(value));

            if(room.volume !== undefined){
              if(room.volume.type === "slider"){

                const device = Database.getDeviceByIdentifier(room.volume.device);

                if(device === undefined){
                  return;
                }

                if(device.overrides !== undefined){

                  commandLoop: for(const command of device.overrides.commands){

                    if(command.name !== "SET_VOLUME"){
                      continue commandLoop;
                    }

                    if(command.parameters !== undefined && command.parameters.length === 1){
                      CH_API.runCommand(command.identifier, device.identifier, types.HoldingPatterns.once, [{ "value": Math.round(value), "template": command.parameters[0].identifier }]);
                      return;
                    }

                  }
                }

                if(device.module !== undefined){

                  const mod = Database.getModuleByIdentifier(device.module, device.version);

                  if(mod !== undefined){
                    commandLoop: for(const command of mod.commands){

                      if(command.name !== "SET_VOLUME"){
                        continue commandLoop;
                      }

                      if(command.parameters !== undefined && command.parameters.length === 1){
                        CH_API.runCommand(command.identifier, device.identifier, types.HoldingPatterns.once, [{ "value": Math.round(value), "template": command.parameters[0].identifier }]);
                        return;
                      }

                    }
                  }

                }

              }
            }

          });

          volumeslider.on("show", () => {
            APP.navbar.size($$(".homescreen-title").parents(".navbar")[0] as HTMLElement);
          });

          volumeslider.on("hide", () => {
            APP.navbar.size($$(".homescreen-title").parents(".navbar")[0] as HTMLElement);
          });

        }
      }
    }

  }


  static buttonDown(ev) {

    const target = ev.target.closest("a.link");
    const parent = target.closest(".volume-buttons");

    const roomIdentifier = parent.getAttribute("identifier");
    const room = Database.getRoomByIdentifier(roomIdentifier);

    if(room === undefined){
      return;
    }

    if(room.volume === undefined){
      return;
    }

    if(room.volume.type !== "buttons"){
      return;
    }

    const device = Database.getDeviceByIdentifier(room.volume.device);

    if(device === undefined){
      return;
    }

    let volumeUp: types.Command | undefined;
    let volumeDown: types.Command | undefined;
    let volumeMute: types.Command | undefined;

    if(device.module !== undefined){

      const mod = Database.getModuleByIdentifier(device.module, device.version);

      if(mod !== undefined){
        for(const command of mod.commands){
          if(command.name === "VOLUME_UP"){
            volumeUp = command;
          }
          if(command.name === "VOLUME_DOWN"){
            volumeDown = command;
          }
          if(command.name === "VOLUME_MUTE_TOGGLE"){
            volumeMute = command;
          }
        }
      }

    }

    if(device.overrides !== undefined){
      for(const command of device.overrides.commands){
        if(command.name === "VOLUME_UP"){
          volumeUp = command;
        }
        if(command.name === "VOLUME_DOWN"){
          volumeDown = command;
        }
        if(command.name === "VOLUME_MUTE_TOGGLE"){
          volumeMute = command;
        }
      }
    }

    if(target.classList.contains("volume-down")){

      if(volumeDown === undefined){
        return;
      }

      CH_API.runCommand(volumeDown.identifier, device.identifier, room.volume.hold);

      RoomVolume._pressedButtons.push({
        id: ev.pointerId,
        commandIdentifier: volumeDown.identifier,
        deviceIdentifier: device.identifier
      });

    }
    if(target.classList.contains("volume-up")){
      if(volumeUp === undefined){
        return;
      }

      CH_API.runCommand(volumeUp.identifier, device.identifier, room.volume.hold);

      RoomVolume._pressedButtons.push({
        id: ev.pointerId,
        commandIdentifier: volumeUp.identifier,
        deviceIdentifier: device.identifier
      });

    }
    if(target.classList.contains("volume-mute")){
      if(volumeMute === undefined){
        return;
      }
      CH_API.runCommand(volumeMute.identifier, device.identifier, types.HoldingPatterns.once);
    }

  }


  static buttonUp(ev) {

    for(let i = RoomVolume._pressedButtons.length - 1; i >= 0; i--){
      if(RoomVolume._pressedButtons[i].id === ev.pointerId){
        CH_API.cancelCommand(RoomVolume._pressedButtons[i].commandIdentifier, RoomVolume._pressedButtons[i].deviceIdentifier);
        RoomVolume._pressedButtons.splice(i, 1);
      }
    }

  }


  static getSpeakerIconFromValue(value: number): string {

    if(value > 66 && value <= 100){
      return "speaker_3";
    }
    if(value > 33 && value <= 66){
      return "speaker_2";
    }
    if(value > 5 && value <= 33){
      return "speaker_1";
    }
    if(value > 0 && value <= 5){
      return "speaker";
    }
    if(value === 0){
      return "speaker_slash";
    }
    return "speaker";

  }


  static setRoomVolume(roomIdentifier: string, volume: number): void {

    const room = Database.getRoomByIdentifier(roomIdentifier);

    if(room === undefined){
      return;
    }

    let isCurrentlyChanging = false;

    sliderLoop: for(const volumeslider of RoomVolume.sliders){
      if(volumeslider.room !== roomIdentifier){
        continue sliderLoop;
      }

      volumeslider.slider.setValue(volume);
      isCurrentlyChanging = volumeslider.slider.isHolding;

    }

    if(isCurrentlyChanging === false){
      $$(".volume-slider[identifier='" + room.identifier + "']").find(".handle i").text(RoomVolume.getSpeakerIconFromValue(volume));
    }

  }


  static loadRoom(identifier: string): void {


    //-- Update page title

    const room = Database.getRoomByIdentifier(identifier);

    if(room === undefined){
      return;
    }


    $$(".homescreen-title").text(room.name);


    //-- Show current volume slider

    $$(".navbar .volume-slider:not([identifier='" + identifier + "'])").addClass("hidden");
    $$(".navbar .volume-buttons:not([identifier='" + identifier + "'])").addClass("hidden");
    $$(".navbar .volume-slider[identifier='" + identifier + "']").removeClass("hidden");
    $$(".navbar .volume-buttons[identifier='" + identifier + "']").removeClass("hidden");


    //-- Update navbar size

    APP.navbar.size($$(".homescreen-title").parents(".navbar")[0] as HTMLElement);


    //-- Get volume

    if(room.volume !== undefined){
      if(room.volume.type === "slider"){

        sliderLoop: for(const volumeslider of RoomVolume.sliders){
          if(volumeslider.room !== room.identifier){
            continue sliderLoop;
          }

          const device = Database.getDeviceByIdentifier(room.volume.device);

          if(device === undefined){
            return;
          }


          //-- Set volume to current feedback value

          const automaIdentifier = Database.getAutomaByDeviceIdentifier(device.identifier);

          if(automaIdentifier !== undefined){

            const volume = CH_API.getFeedback("VOLUME", automaIdentifier.identifier, device.identifier);

            if(volume !== undefined){
              RoomVolume.setRoomVolume(room.identifier, +volume);
            }

          }


          //-- Update volume

          if(device.overrides !== undefined){

            commandLoop: for(const command of device.overrides.commands){

              if(command.name !== "GET_VOLUME"){
                continue commandLoop;
              }

              if(command.parameters === undefined || command.parameters.length === 0){
                CH_API.runCommand(command.identifier, device.identifier, types.HoldingPatterns.once);
                return;
              }

            }
          }

          if(device.module !== undefined){

            const mod = Database.getModuleByIdentifier(device.module, device.version);

            if(mod !== undefined){
              commandLoop: for(const command of mod.commands){

                if(command.name !== "GET_VOLUME"){
                  continue commandLoop;
                }

                if(command.parameters === undefined || command.parameters.length === 0){
                  CH_API.runCommand(command.identifier, device.identifier, types.HoldingPatterns.once);
                  return;
                }

              }
            }

          }
        }

      }
    }

  }

}

class Dashboard extends TinyEventEmitter {

  private _startY: number = 0;
  private _currentY: number = 0;
  private _lastY: number = 0;

  private _direction: "up" | "down" = "down";
  private _holding: boolean = false;

  private _animationFrame: any;

  private _dashboardElement: HTMLDivElement;
  private _overlayElement: HTMLDivElement;

  private _dashboardModuleElement: HTMLDivElement;
  private _dashboardRoomElement: HTMLDivElement;
  private _dashboardDeviceElement: HTMLDivElement;

  private _startEvent: (ev: Event) => void;
  private _endEvent: (ev: Event) => void;
  private _downEvent: (ev: Event) => void;
  private _moveEvent: (ev: Event) => void;

  private _dashboardOpenEvent: (ev: Event) => void;
  private _dashboardCloseEvent: (ev: Event) => void;

  public dashboardSwiper: any;

  constructor() {

    super();

    this._dashboardElement = document.createElement("div");
    this._dashboardElement.classList.add("dashboard");

    this._overlayElement = document.createElement("div");
    this._overlayElement.classList.add("dashboard-overlay");
    this._overlayElement.classList.add("overlay");


    this._dashboardElement.innerHTML = `
      <div class="backdrop">

        <div class="dashboard-content">

          <div class="popover sleeptimer-popover">
            <div class="popover-angle"></div>
            <div class="popover-inner">
              <div class="block-title">${TRANSLATIONS.getText("dashboard-sleeptimer")}: <span class="range-value">${TRANSLATIONS.getText("sleeptimer-off")}</span></div>
              <div class="block">
                <div class="range-slider">
                  <!-- range input -->
                  <input type="range" min="0" max="240" step="1" value="0" />
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide dashboard-modules">
                <!-- Module content -->
              </div>
              <div class="swiper-slide dashboard-rooms">
                <!-- Room content -->
              </div>
              <div class="swiper-slide dashboard-devices">
                <!-- Device content -->
              </div>
            </div>
            <div class="dashboard-swiper-pagination swiper-pagination swiper-pagination-bullets"></div>
          </div>
        </div>

        <a class="button button-fill all-off" draggable="false">
          <span class="chip sleeptimer-end-time hidden" identifier="${"SLEEPTIMER_all-off"}"></span>
          ${TRANSLATIONS.getText("dashboard-all-off")}
        </a>

        <div class="dashboard-handle">
          <i class="icon another-icon f7-icons ch-dashboard-up-icon">chevron_compact_up</i>
        </div>
      </div>
    `;

    document.body.appendChild(this._overlayElement);
    document.body.appendChild(this._dashboardElement);

    this._dashboardModuleElement = this._dashboardElement.querySelector(".dashboard-modules")!;
    this._dashboardRoomElement = this._dashboardElement.querySelector(".dashboard-rooms")!;
    this._dashboardDeviceElement = this._dashboardElement.querySelector(".dashboard-devices")!;


    //-- Initialize events

    this._startEvent = this._start.bind(this);
    this._endEvent = this._end.bind(this);
    this._moveEvent = this._move.bind(this);
    this._downEvent = this.down.bind(this);
    this._dashboardCloseEvent = this._dashboardClose.bind(this);
    this._dashboardOpenEvent = this._dashboardOpen.bind(this);

    $$("body").on("mousedown touchstart", ".dashboard-handle", this._startEvent);
    $$("body").on("mousemove touchmove", this._moveEvent);
    $$("body").on("mouseup touchend", this._endEvent);
    $$("body").on("click", ".ch-dashboard-down-icon", this._downEvent);

    this.on("dashboardopen", this._dashboardOpenEvent);
    this.on("dashboardclose", this._dashboardCloseEvent);

    CH_PRIVATE.on("feedbacksChanged", this._updateFeedbacks.bind(this));

    $$(".dashboard").on("click", "a.dashboard-room-off", (ev: any) => {

      const target = (ev.target.tagName.toLowerCase() === "a" ? $$(ev.target) : $$(ev.target).parents("a.dashboard-room-off"));
      const room = Database.getRoomByIdentifier(target.attr("identifier"));

      if(room !== undefined){
        for(const page of room.pages){
          if(page.off !== undefined){
            CH_API.runMacro(page.off);
          }
        }
      }

    });

    $$(".dashboard").on("taphold contextmenu", "a.dashboard-room-off", (ev: any) => {

      const target = (ev.target.tagName.toLowerCase() === "a" ? $$(ev.target) : $$(ev.target).parents("a.dashboard-room-off"));
      const targetElement = target.length > 0 ? target[0] as HTMLElement : undefined;

      if(targetElement === undefined){
        return;
      }

      const popover = APP.popover.get(".dashboard .sleeptimer-popover");

      popover.el.removeAttribute("page");
      popover.el.removeAttribute("device");
      popover.el.removeAttribute("all-off");
      popover.el.setAttribute("room", target.attr("identifier"));

      popover.open(targetElement);

    });

    $$(".dashboard").on("click", "a.dashboard-page-off", (ev: any) => {

      const target = (ev.target.tagName.toLowerCase() === "a" ? $$(ev.target) : $$(ev.target).parents("a.dashboard-page-off"));
      const page = Database.getPageByIdentifier(target.attr("identifier"));

      if(page !== undefined){
        CH_API.runMacro(page.off);
      }

    });

    $$(".dashboard").on("taphold contextmenu", "a.dashboard-page-off", (ev: any) => {

      const target = (ev.target.tagName.toLowerCase() === "a" ? $$(ev.target) : $$(ev.target).parents("a.dashboard-room-off"));
      const targetElement = target.length > 0 ? target[0] as HTMLElement : undefined;

      if(targetElement === undefined){
        return;
      }

      const popover = APP.popover.get(".dashboard .sleeptimer-popover");

      popover.el.removeAttribute("room");
      popover.el.removeAttribute("device");
      popover.el.removeAttribute("all-off");
      popover.el.setAttribute("page", target.attr("identifier"));

      popover.open(targetElement);

    });

    $$(".dashboard").on("click", "li.device-power", (ev: any) => {

      if(ev.target === null){
        return;
      }

      let target = $$(ev.target);

      if(target[0].tagName.toLocaleLowerCase() !== "li"){
        target = target.parents("li");
      }

      const deviceIdentifier = target.attr("identifier");
      const device = Database.getDeviceByIdentifier(deviceIdentifier);

      if(device === undefined){
        return;
      }

      let commands: Array<types.Command> = [];

      if(device.module !== undefined){
        const mod = Database.getModuleByIdentifier(device.module, device.version);
        if(mod !== undefined){
          commands = [...mod.commands];
        }
      }

      if(device.overrides.commands !== undefined){
        overrideCommandLoop: for(const overrideComand of device.overrides.commands){
          for(let c = 0; c < commands.length; c++){
            if(commands[c].identifier === overrideComand.identifier){
              commands[c] = overrideComand;
              continue overrideCommandLoop;
            }
          }
          commands.push(overrideComand);
        }
      }

      for(const command of commands){
        if(command.parameters === undefined || command.parameters.length === 0){
          if(command.name === "POWER_OFF_ALL"){
            CH_API.runCommand(command.identifier, device.identifier, types.HoldingPatterns.once);
            return;
          }
        }
        if(command.parameters === undefined || command.parameters.length === 0){
          if(command.name === "POWER_OFF"){
            CH_API.runCommand(command.identifier, device.identifier, types.HoldingPatterns.once);
            return;
          }
        }
      }
      for(const command of commands){
        if(command.parameters === undefined || command.parameters.length === 0){
          if(command.name === "POWER_TOGGLE"){
            CH_API.runCommand(command.identifier, device.identifier, types.HoldingPatterns.once);
            return;
          }
        }
      }

    });

    $$(".dashboard").on("taphold contextmenu", "li.device-power.power-off, li.device-power.power-off-all", (ev: any) => {

      const target = (ev.target.tagName.toLowerCase() === "a" ? $$(ev.target) : $$(ev.target).parents("a.dashboard-room-off"));
      const targetElement = target.length > 0 ? target[0] as HTMLElement : undefined;

      if(targetElement === undefined){
        return;
      }

      const popover = APP.popover.get(".dashboard .sleeptimer-popover");

      popover.el.removeAttribute("room");
      popover.el.removeAttribute("page");
      popover.el.removeAttribute("all-off");
      popover.el.setAttribute("device", target.attr("identifier"));

      popover.open(targetElement);

    });

    $$(".dashboard").on("click", ".all-off", async(ev: any) => {

      const PDB = CH_PRIVATE.getPDB();

      if(PDB === undefined){
        return;
      }

      for(const room of PDB.rooms){
        for(const page of room.pages){
          if(page.off !== undefined){
            CH_API.runMacro(page.off);
          }
        }
      }

    });

    $$(".dashboard").on("taphold contextmenu", ".all-off", (ev: any) => {

      const target = (ev.target.tagName.toLowerCase() === "a" ? $$(ev.target) : $$(ev.target).parents("a"));
      const targetElement = target.length > 0 ? target[0] as HTMLElement : undefined;

      if(targetElement === undefined){
        return;
      }

      const popover = APP.popover.get(".dashboard .sleeptimer-popover");

      popover.el.removeAttribute("page");
      popover.el.removeAttribute("room");
      popover.el.removeAttribute("device");
      popover.el.setAttribute("all-off", "all-off");

      popover.open(targetElement);

    });


    //-- Create swiper

    this.dashboardSwiper = APP.swiper.create(".dashboard .swiper-container", {
      speed: 400,
      spaceBetween: 10,
      slidesPerView: 1,
      breakpoints: {
        768: {
          slidesPerView: 2
        }
      },
      noSwipingClass: "volume-slider",
      pagination: {
        el: ".dashboard-swiper-pagination",
        type: "bullets"
      }
    });


    //-- Create sleeptimer popover

    const sleepTimerPopover = APP.popover.create({
      el: ".dashboard .sleeptimer-popover",
      closeByBackdropClick: false,
      closeByOutsideClick: false,
      on: {
        "open": () => { updateSleepTimerFromTimer(); }
      }
    });

    sleepTimerPopover.backdropEl.addEventListener("click", ev => { // Handle backdrop click because built in closeByBackdropClick doesn't work with taphold
      sleepTimerPopover.close();
    });

    const rangeSlider = APP.range.create({
      el: ".dashboard .sleeptimer-popover .range-slider",
      on: {
        "change": () => { updateSleepTimerFromSlider(false); },
        "changed": () => { updateSleepTimerFromSlider(true); }
      }
    });


    const updateSleepTimerFromTimer = async() => {

      const pageIdentifier = sleepTimerPopover.el.getAttribute("page");
      const roomIdentifier = sleepTimerPopover.el.getAttribute("room");
      const deviceIdentifier = sleepTimerPopover.el.getAttribute("device");
      const allOff = sleepTimerPopover.el.getAttribute("all-off");

      const rangeValueElement = sleepTimerPopover.el.querySelector(".range-value") as HTMLElement;
      const rangeSliderElement = sleepTimerPopover.el.querySelector(".range-slider") as HTMLElement;
      const rangeSlider = APP.range.get(rangeSliderElement);

      const timerIdentifier = "SLEEPTIMER_" + (pageIdentifier ?? roomIdentifier ?? deviceIdentifier ?? allOff);
      const timer = await Timer.getTimer(timerIdentifier);

      if(timer === undefined || timer.timestamp === undefined || timer.timestamp < Date.now()){
        rangeValueElement.innerHTML = TRANSLATIONS.getText("sleeptimer-off");
        rangeSlider.setValue(0);
        this._updateSleepTimerLabels();
        return;
      }

      const date = new Date(timer.timestamp);
      const minuteDifference = Math.floor((timer.timestamp - Date.now()) / 60 / 1000);
      const time = functions.zeroFill(date.getHours(), 2) + ":" + functions.zeroFill(date.getMinutes(), 2);


      //-- Update popover

      rangeValueElement.innerHTML = time;
      rangeSlider.setValue(minuteDifference + 1);

    };


    const updateSleepTimerFromSlider = async(save: boolean) => {

      const minutes = rangeSlider.getValue();
      const rangeValueElement = sleepTimerPopover.el.querySelector(".range-value");

      if(rangeValueElement === null){
        return;
      }

      if(typeof minutes !== "number"){
        return;
      }


      //-- Create timer

      const pageIdentifier = sleepTimerPopover.el.getAttribute("page");
      const roomIdentifier = sleepTimerPopover.el.getAttribute("room");
      const deviceIdentifier = sleepTimerPopover.el.getAttribute("device");
      const allOff = sleepTimerPopover.el.getAttribute("all-off");

      const timerIdentifier = "SLEEPTIMER_" + (pageIdentifier ?? roomIdentifier ?? deviceIdentifier ?? allOff);
      const actions: Array<string | { command: string; device: string; }> = [];
      const date = new Date(Date.now() + (minutes * 60 * 1000));
      const time = functions.zeroFill(date.getHours(), 2) + ":" + functions.zeroFill(date.getMinutes(), 2);


      //-- Update label

      if(minutes === 0){
        rangeValueElement.innerHTML = TRANSLATIONS.getText("sleeptimer-off");
        await Timer.deleteTimer(timerIdentifier);
        this._updateSleepTimerLabels();
        return;
      } else {
        rangeValueElement.innerHTML = time;
      }


      //-- Gather actions

      if(timerIdentifier === undefined){
        return;
      }

      if(pageIdentifier !== null){
        const page = Database.getPageByIdentifier(pageIdentifier);
        if(page !== undefined){
          actions.push(page.off);
        }
      }
      if(roomIdentifier !== null){
        const room = Database.getRoomByIdentifier(roomIdentifier);
        if(room !== undefined){
          for(const page of room.pages){
            actions.push(page.off);
          }
        }
      }
      if(allOff !== null){

        const PDB = CH_PRIVATE.getPDB();

        if(PDB === undefined){
          return;
        }

        for(const room of PDB.rooms){
          for(const page of room.pages){
            if(page.off !== undefined){
              actions.push(page.off);
            }
          }
        }

      }

      deviceCommandSearch: if(deviceIdentifier !== null){

        const device = Database.getDeviceByIdentifier(deviceIdentifier);

        if(device !== undefined){

          for(const command of device.overrides.commands){
            if(command.name === "POWER_OFF"){
              actions.push({ device: device.identifier, command: command.identifier });
              break deviceCommandSearch;
            }
          }

          if(device.module !== undefined){
            const module = Database.getModuleByIdentifier(device.module);
            if(module !== undefined){
              for(const command of module.commands){
                if(command.name === "POWER_OFF"){
                  actions.push({ device: device.identifier, command: command.identifier });
                  break;
                }
              }
            }
          }
        }

      }

      if(save === true){
        await Timer.setSleepTimer(actions, timerIdentifier, date.getTime());
        await this._updateSleepTimerLabels();
      }

    };


    Database.on("cloudValueChanged", (name, value, deviceIdentifier, time) => {
      if(deviceIdentifier === "__TIMER__"){
        this._updateSleepTimerLabels();
      }
    });


    //-- Render dashboard content

    this.render();

  }


  private async _updateSleepTimerLabels() {

    const PDB = CH_PRIVATE.getPDB();

    if(PDB === undefined){
      return;
    }


    //-- Rooms and pages

    for(const room of PDB.rooms){

      const roomSleepTimerIdentifier = "SLEEPTIMER_" + room.identifier;
      const roomSleepTimer = await Timer.getTimer(roomSleepTimerIdentifier);

      if(roomSleepTimer !== undefined && roomSleepTimer.timestamp !== undefined){

        const date = new Date(roomSleepTimer.timestamp);
        const time = functions.zeroFill(date.getHours(), 2) + ":" + functions.zeroFill(date.getMinutes(), 2);

        $$(`.dashboard .sleeptimer-end-time[identifier="${roomSleepTimerIdentifier}"]`).removeClass("hidden");
        $$(`.dashboard .sleeptimer-end-time[identifier="${roomSleepTimerIdentifier}"]`).text(time);
      } else {
        $$(`.dashboard .sleeptimer-end-time[identifier="${roomSleepTimerIdentifier}"]`).addClass("hidden");
      }

      for(const page of room.pages){

        const pageSleepTimerIdentifier = "SLEEPTIMER_" + page.identifier;
        const pageSleepTimer = await Timer.getTimer(pageSleepTimerIdentifier);

        if(pageSleepTimer !== undefined && pageSleepTimer.timestamp !== undefined){

          const date = new Date(pageSleepTimer.timestamp);
          const time = functions.zeroFill(date.getHours(), 2) + ":" + functions.zeroFill(date.getMinutes(), 2);

          $$(`.dashboard .sleeptimer-end-time[identifier="${pageSleepTimerIdentifier}"]`).removeClass("hidden");
          $$(`.dashboard .sleeptimer-end-time[identifier="${pageSleepTimerIdentifier}"]`).text(time);
        } else {
          $$(`.dashboard .sleeptimer-end-time[identifier="${pageSleepTimerIdentifier}"]`).addClass("hidden");
        }

      }

    }


    //-- Devices

    for(const automa of PDB.automa){
      for(const device of automa.devices){

        const deviceSleepTimerIdentifier = "SLEEPTIMER_" + device.identifier;
        const deviceSleepTimer = await Timer.getTimer(deviceSleepTimerIdentifier);

        if(deviceSleepTimer !== undefined && deviceSleepTimer.timestamp !== undefined){

          const date = new Date(deviceSleepTimer.timestamp);
          const time = functions.zeroFill(date.getHours(), 2) + ":" + functions.zeroFill(date.getMinutes(), 2);

          $$(`.dashboard .sleeptimer-end-time[identifier="${deviceSleepTimerIdentifier}"]`).removeClass("hidden");
          $$(`.dashboard .sleeptimer-end-time[identifier="${deviceSleepTimerIdentifier}"]`).text(time);
        } else {
          $$(`.dashboard .sleeptimer-end-time[identifier="${deviceSleepTimerIdentifier}"]`).addClass("hidden");
        }

      }
    }


    //-- All off

    const allOffSleepTimerIdentifier = "SLEEPTIMER_all-off";
    const allOffSleepTimer = await Timer.getTimer(allOffSleepTimerIdentifier);

    if(allOffSleepTimer !== undefined && allOffSleepTimer.timestamp !== undefined){

      const date = new Date(allOffSleepTimer.timestamp);
      const time = functions.zeroFill(date.getHours(), 2) + ":" + functions.zeroFill(date.getMinutes(), 2);

      $$(`.dashboard .sleeptimer-end-time[identifier="${allOffSleepTimerIdentifier}"]`).removeClass("hidden");
      $$(`.dashboard .sleeptimer-end-time[identifier="${allOffSleepTimerIdentifier}"]`).text(time);
    } else {
      $$(`.dashboard .sleeptimer-end-time[identifier="${allOffSleepTimerIdentifier}"]`).addClass("hidden");
    }

  }


  public async render() {

    const CH_PDB = CH_PRIVATE.getPDB();

    if(CH_PDB === undefined){
      throw new Error("CH_PDB is undefined");
    }

    let moduleCards = "";
    let roomCards = "";
    let deviceCards = "";


    //-- Modules

    for(const automa of CH_PDB.automa){
      for(const device of automa.devices){
        moduleCards += `
          <div class="dashboard-module" identifier="${device.identifier}">
            <div class="block-title">${Database.getDeviceName(device)}</div>
            <div class="card">
              <div class="card-content dashboard-module-content" identifier="${device.identifier}">

              </div>
            </div>
          </div>
        `;
      }
    }


    //-- Rooms

    for(const room of CH_PDB.rooms){

      let roomControls = "";
      let pageControls = "";

      if(room.volume !== undefined){

        const volumeHTML = RoomVolume.getRoomVolumeHTML(room.identifier, false);

        roomControls += `
          <div class="room-volume">
            ${volumeHTML}
          </div>
        `;

      }


      //-- Pages

      for(const page of room.pages){

        if(page.off === undefined){
          continue;
        }

        const macro = Database.getMacroByIdentifier(page.off);

        if(macro === undefined){
          continue;
        }

        if(macro.commands.length <= 0){
          continue;
        }

        pageControls += `
          <li class="item-content">
            <div class="item-inner">
              <div class="item-title">
                ${page.name}
              </div>
              <div class="item-after">
                <span class="chip sleeptimer-end-time hidden" identifier="${"SLEEPTIMER_" + page.identifier}"></span>
                <a href="#" class="link dashboard-page-off" identifier="${page.identifier}" draggable="false">
                  ${TRANSLATIONS.getText("dashboard-pageoff")}
                </a>
              </div>
            </div>
          </li>
        `;
      }

      roomCards += `
        <div class="card">
          <div class="card-header room-card">
            <div class="flexbox">
              <div class="card-header-title">
                ${room.name}
              </div>
              <div class="card-header-after">
                <span class="chip sleeptimer-end-time hidden" identifier="${"SLEEPTIMER_" + room.identifier}"></span>
                <a href="#" class="link dashboard-room-off" identifier="${room.identifier}" draggable="false">
                  ${TRANSLATIONS.getText("dashboard-roomoff")}
                </a>
              </div>
            </div>
            ${roomControls}
          </div>
          <div class="card-content">
            <div class="list">
              <ul>
                ${pageControls}
              </ul>
            </div>
          </div>
        </div>
      `;

    }


    //-- Devices

    for(const automa of CH_PDB.automa){
      for(const device of automa.devices){

        let commands: Array<types.Command> = [];
        let feedbacks: Array<types.Feedback> = [];
        let powerStatusFeedback: string | types.PowerStatusFeedback | undefined | false;

        if(device.module !== undefined){
          const mod = Database.getModuleByIdentifier(device.module, device.version);
          if(mod !== undefined){
            commands = [...mod.commands];
            feedbacks = [...mod.feedbacks];
            powerStatusFeedback = mod.powerStatusFeedback;
          }
        }

        if(device.overrides.commands !== undefined){
          overrideCommandLoop: for(const overrideCommand of device.overrides.commands){
            for(let c = 0; c < commands.length; c++){
              if(commands[c].identifier === overrideCommand.identifier){
                commands[c] = overrideCommand;
                continue overrideCommandLoop;
              }
            }
            commands.push(overrideCommand);
          }
        }

        if(device.overrides.feedbacks !== undefined){
          overrideFeedbackLoop: for(const overrideFeedback of device.overrides.feedbacks){
            for(let f = 0; f < feedbacks.length; f++){
              if(feedbacks[f].identifier === overrideFeedback.identifier){
                feedbacks[f] = overrideFeedback;
                continue overrideFeedbackLoop;
              }
            }
            feedbacks.push(overrideFeedback);
          }
        }

        if(device.overrides.powerStatusFeedback !== undefined){
          powerStatusFeedback = device.overrides.powerStatusFeedback;
        }

        let hasPowerOff = false;
        let hasPowerOffAll = false;
        let hasPowerToggle = false;

        for(const command of commands){
          if(command.parameters === undefined || command.parameters.length === 0 || command.parameters[0].optional === true){
            if(command.name === "POWER_OFF_ALL"){
              hasPowerOffAll = true;
              break;
            }
            if(command.name === "POWER_OFF"){
              hasPowerOff = true;
              break;
            }
            if(command.name === "POWER_TOGGLE"){
              hasPowerToggle = true;
              break;
            }
          }
        }


        //-- Add feedback parameters as attributes

        let feedbackStatusAttribute = "";
        let feedbackAttributes = "";
        let feedbackParameterAttributes = "";
        let operatorAttributes = "";

        if(typeof powerStatusFeedback === "object" && powerStatusFeedback.parameters?.[0]?.template !== undefined && "operator" in powerStatusFeedback.parameters[0]){

          const feedback = Database.getFeedbackByIdentifier(powerStatusFeedback.parameters[0].template, powerStatusFeedback.parameters[0].device);

          if(feedback !== undefined){

            const feedbackAutoma = Database.getAutomaByFeedbackIdentifier(feedback.identifier, powerStatusFeedback.parameters[0].device);

            feedbackStatusAttribute = ` feedback-status="${feedback.name}" `;
            operatorAttributes = ` feedback-condition-operator="${powerStatusFeedback.parameters[0].operator}" feedback-condition-value="${powerStatusFeedback.parameters[0].value}" `;

            if(powerStatusFeedback.parameters[0].device !== undefined){
              feedbackAttributes += ` feedback-device="${powerStatusFeedback.parameters[0].device}" `;
            }
            if(feedbackAutoma !== undefined){
              feedbackAttributes += ` feedback-controller="${feedbackAutoma.identifier}" `;
            }

            for(const parameter of powerStatusFeedback.parameters.slice(1)){
              if(feedback.parameters !== undefined){
                for(const feedbackParameter of feedback.parameters){
                  if(feedbackParameter.identifier === parameter.template){
                    feedbackParameterAttributes += " feedback-parameter-" + feedbackParameter.name + "=\"" + parameter.value + "\"";
                  }
                }
              }
            }
          }

        } else if(typeof powerStatusFeedback === "string" && powerStatusFeedback !== ""){

          const feedback = Database.getFeedbackByIdentifier(powerStatusFeedback, device.identifier);

          if(feedback !== undefined){
            feedbackStatusAttribute = ` feedback-status="${feedback.name}" `;
            feedbackAttributes = ` feedback-controller="${automa.identifier}" `;
            feedbackAttributes = ` feedback-device="${device.identifier}" `;
          }

        }

        const powerStatusHidden = powerStatusFeedback === undefined || powerStatusFeedback === false || powerStatusFeedback === "" ? " hidden " : "";
        const powerOffAllClass = hasPowerOffAll ? " power-off-all " : " ";
        const powerOffClass = hasPowerOff ? " power-off " : " ";
        const powerToggleClass = hasPowerToggle ? " power-toggle " : " ";

        let powerText: string | undefined;

        if(hasPowerOffAll === true){
          powerText = TRANSLATIONS.getText("dashboard-device-power-off-all");
        } else if(hasPowerOff === true){
          powerText = TRANSLATIONS.getText("dashboard-device-power-off");
        } else if(hasPowerToggle === true){
          powerText = TRANSLATIONS.getText("dashboard-device-power-toggle");
        }

        if((hasPowerOffAll === true || hasPowerOff === true || hasPowerToggle === true) && powerText !== undefined){

          deviceCards += `
            <li class="device-power ${powerOffAllClass} ${powerOffClass} ${powerToggleClass}" identifier="${device.identifier}">
              <div class="item-content">
                <div class="item-media">
                  <i class="icon power-indicator ${powerStatusHidden}" ${feedbackStatusAttribute} ${feedbackAttributes} ${feedbackParameterAttributes} ${operatorAttributes} ></i>
                </div>
                <div class="item-inner">
                  <div class="item-title">
                    ${Database.getDeviceName(device)}
                  </div>
                  <div class="item-after">
                    <span class="chip sleeptimer-end-time" identifier="${"SLEEPTIMER_" + device.identifier}"></span>
                    <a href="#" class="link" identifier="${device.identifier}" draggable="false">
                      ${powerText}
                    </a>
                  </div>
                </div>
              </div>
            </li>
          `;

        }

      }
    }


    //-- Left Panel of Dashboard

    const dashboardRoomCards = `
      <div class="block-title">${ TRANSLATIONS.getText("dashboard-rooms") }</div>
      ${roomCards}
    `;


    //-- Right Panel of Dashboard

    const dashboardDeviceCards = `
      <div class="block-title">${ TRANSLATIONS.getText("dashboard-devices") }</div>
      <div class="card">
        <div class="card-content">
          <div class="list">
            <ul>
              ${deviceCards}
            </ul>
          </div>
        </div>
      </div>
    `;

    this._dashboardModuleElement.innerHTML = moduleCards;
    this._dashboardRoomElement.innerHTML = dashboardRoomCards;
    this._dashboardDeviceElement.innerHTML = dashboardDeviceCards;


    //-- Create mutation observer for dashboard modules

    const observer = new MutationObserver(this._dashboardModuleContentChanged.bind(this));
    observer.observe(this._dashboardModuleElement, { childList: true, subtree: true });


    //-- Emit user script events

    this._emitUserScriptEvent("dashboardrender");


    //-- Trigger moduleContentChange

    this._dashboardModuleContentChanged();

  }


  private _dashboardOpen() {
    this._updateFeedbacks();
    this._updateSleepTimerLabels();
    this._emitUserScriptEvent("dashboardopen");
  }


  private _dashboardClose() {
    this._emitUserScriptEvent("dashboardclose");
  }


  private _dashboardModuleContentChanged() {

    let hasAnyContent: boolean = false;

    const dashboardModules = this._dashboardModuleElement.querySelectorAll(".dashboard-module");

    for(let m = 0; m < dashboardModules.length; m++){

      const dashboardModuleContainer = dashboardModules[m].querySelector(".dashboard-module-content");

      if(dashboardModuleContainer !== null && dashboardModuleContainer.childElementCount > 0){
        hasAnyContent = true;
        dashboardModules[m].classList.remove("hidden");
      } else {
        dashboardModules[m].classList.add("hidden");
      }

    }

    if(hasAnyContent === false){
      this._dashboardModuleElement.classList.add("hidden");
      this._setSwiperSlidesPerViewOnTablet(2);
    } else {
      this._dashboardModuleElement.classList.remove("hidden");
      this._setSwiperSlidesPerViewOnTablet(3);
    }

  }


  private _setSwiperSlidesPerViewOnTablet(slides: number) {

    this.dashboardSwiper.params.breakpoints = {
      768: {
        slidesPerView: slides
      }
    };


    //-- Set original params to make sure that the new breakpoints are also applied after resize

    this.dashboardSwiper.originalParams.breakpoints = this.dashboardSwiper.params.breakpoints;


    //-- Unset current breakpoint to force a recalculation

    this.dashboardSwiper.currentBreakpoint = undefined;


    //-- Force a recalculation

    this.dashboardSwiper.update();

  }


  private _emitUserScriptEvent(event: string) {

    const CH_PDB = CH_PRIVATE.getPDB();

    if(CH_PDB === undefined){
      return;
    }

    for(const automa of CH_PDB.automa){

      for(const device of automa.devices){

        if(device.type !== "other"){
          continue;
        }

        const userscript = common.getUserScriptByDeviceIdentifier(device.identifier);

        if(userscript === undefined){
          return;
        }

        const moduleContainer = this._dashboardModuleElement.querySelector(`.dashboard-module[identifier="${device.identifier}"] .dashboard-module-content`);

        userscript.emit(event, moduleContainer);

      }

    }

  }


  private _updateFeedbacks() {

    const PDB = CH_PRIVATE.getPDB();

    if(PDB === undefined){
      return;
    }

    for(const automa of PDB.automa){
      for(const device of automa.devices){


        let getVolumeCommand: types.Command | undefined;
        let getPowerStatusCommand: types.Command | undefined;
        let powerStatusFeedback: string | types.PowerStatusFeedback | false | undefined;

        if(device.module !== undefined){

          const mod = Database.getModuleByIdentifier(device.module, device.version);

          if(mod !== undefined){

            powerStatusFeedback = mod.powerStatusFeedback;

            for(const command of mod.commands){
              if(command.parameters === undefined || command.parameters.length === 0 || command.parameters[0].optional === true){
                if(command.name === "GET_VOLUME"){
                  getVolumeCommand = command;
                }
                if(command.name === "GET_POWER_STATUS"){
                  getPowerStatusCommand = command;
                }
              }
            }
          }

        }

        if(device.overrides !== undefined){

          if(device.overrides.powerStatusFeedback !== undefined){
            powerStatusFeedback = device.overrides.powerStatusFeedback;
          }

          for(const command of device.overrides.commands){
            if(command.parameters === undefined || command.parameters.length === 0 || command.parameters[0].optional === true){
              if(command.name === "GET_VOLUME"){
                getVolumeCommand = command;
              }
              if(command.name === "GET_POWER_STATUS"){
                getPowerStatusCommand = command;
              }
            }
          }
        }


        //-- Update power status feedbacks

        if(typeof powerStatusFeedback === "string"){
          const feedback = Database.getFeedbackByIdentifier(powerStatusFeedback);
          if(feedback !== undefined){
            CH_API.updateFeedback({
              name: feedback?.name,
              automaIdentifier: automa.identifier,
              deviceIdentifier: device.identifier
            });
          }
        } else if(typeof powerStatusFeedback === "object" && powerStatusFeedback.parameters?.[0]?.template !== undefined){
          const feedback = Database.getFeedbackByIdentifier(powerStatusFeedback.parameters[0].template, powerStatusFeedback.parameters[0].device);
          if(feedback !== undefined){
            const feedbackAutoma = Database.getAutomaByFeedbackIdentifier(feedback.identifier, powerStatusFeedback.parameters[0].device);
            if(feedbackAutoma !== undefined){
              CH_API.updateFeedback({
                name: feedback?.name,
                deviceIdentifier: powerStatusFeedback.parameters[0].device,
                automaIdentifier: feedbackAutoma.identifier
              });
            }
          }
        }


        //-- Get volume

        if(getVolumeCommand !== undefined){
          CH_API.runCommand(getVolumeCommand.identifier, device.identifier, types.HoldingPatterns.once);
        }


        //-- Get power status

        if(getPowerStatusCommand !== undefined){
          CH_API.runCommand(getPowerStatusCommand.identifier, device.identifier, types.HoldingPatterns.once);
        }

      }
    }

  }


  private _start(ev: any): void {

    if(ev.target.tagName.toLowerCase() !== "div"){
      return;
    }

    if(ev.target.classList.contains("no-dashboard") || ev.target.closest(".no-dashboard") !== null){
      return;
    }

    // ev.stopPropagation();
    ev.preventDefault();

    this._startY = (ev.touches !== undefined ? ev.touches[0].screenY : ev.pageY);
    this._currentY = this._startY;

    this._direction = "up";
    this._holding = true;

    const bodyHeight = document.body.offsetHeight;


    //-- Enable delay and slide into view

    $$(".dashboard").css("-webkit-transition", "transform .3s ease-in-out");
    $$(".dashboard").css("transition", "transform .3s ease-in-out");

    $$(".dashboard").css("-webkit-transform", "translate3d(0," + (this._currentY - bodyHeight + 64) + "px,0)");
    $$(".dashboard").css("transform", "translate3d(0," + (this._currentY - bodyHeight + 64) + "px,0)");

    $$(".overlay").css("transition", "opacity .3s ease-in-out");
    $$(".overlay").css("opacity", ((0.6 / bodyHeight) * this._currentY));


    //-- Disable delay

    setTimeout(() => {
      $$(".dashboard").css("-webkit-transition", "transform 0s");
      $$(".dashboard").css("transition", "transform 0s");

      $$(".overlay").css("-webkit-transition", "opacity 0s");
      $$(".overlay").css("transition", "opacity 0s");
    }, 300);

  }


  private _end(ev: any) {

    if(this._holding !== true){
      return;
    }

    if(this._animationFrame !== undefined){
      window.cancelAnimationFrame(this._animationFrame);
      this._animationFrame = undefined;
    }

    this._holding = false;

    $$(".dashboard").css("-webkit-transition", "transform .3s ease-in-out");
    $$(".dashboard").css("transition", "transform .3s ease-in-out");
    $$(".overlay").css("-webkit-transition", "opacity .3s ease-in-out");
    $$(".overlay").css("transition", "opacity .3s ease-in-out");

    if(this._direction == "down"){
      $$(".dashboard").css("-webkit-transform", "translate3d(0,0,0)");
      $$(".dashboard").css("transform", "translate3d(0,0,0)");
      $$(".overlay").css("opacity", 0.6);
      this.emit("dashboardopen");
    } else {
      $$(".overlay").css("opacity", 0);
      $$(".dashboard").css("-webkit-transform", "translate3d(0, calc(-100% - 64px),0)");
      $$(".dashboard").css("transform", "translate3d(0, calc(-100% - 64px),0)");
      this.emit("dashboardclose");
    }

  }


  private _move(ev: any) {

    // ev.preventDefault();

    if(this._holding !== true){
      return;
    }

    if(this._animationFrame !== undefined){
      return;
    }

    this._currentY = parseInt(ev.touches !== undefined ? ev.touches[0].screenY : ev.pageY, 10);

    this._animationFrame = window.requestAnimationFrame(() => {

      const bodyHeight = document.body.offsetHeight;

      if(this._currentY === this._lastY){
        this._animationFrame = undefined;
        return;
      }


      //-- Set direction

      if(this._currentY > 50){
        if(this._currentY > this._lastY){
          this._direction = "down";
        } else {
          this._direction = "up";
        }
      } else {
        this._direction = "up";
      }

      $$(".dashboard").css("-webkit-transition", "transform .0s");
      $$(".dashboard").css("transition", "transform .0s");

      $$(".overlay").css("-webkit-transition", "opacity .0s");
      $$(".overlay").css("transition", "opacity .0s");

      $$(".dashboard").css("-webkit-transform", "translate3d(0," + (this._currentY - bodyHeight + 64) + "px,0)");
      $$(".dashboard").css("transform", "translate3d(0," + (this._currentY - bodyHeight + 64) + "px,0)");
      $$(".overlay").css("opacity", ((0.6 / bodyHeight) * this._currentY));

      this._lastY = this._currentY;

      this._animationFrame = undefined;

    });

  }


  public up(): void {

    $$(".dashboard").css("-webkit-transition", "transform .3s ease-in-out");
    $$(".dashboard").css("transition", "transform .3s ease-in-out");

    $$(".dashboard").css("-webkit-transform", "translate3d(0, calc(-100% - 64px),0)");
    $$(".dashboard").css("transform", "translate3d(0, calc(-100% - 64px),0)");

    $$(".overlay").css("-webkit-transition", "opacity .3s ease-in-out");
    $$(".overlay").css("transition", "opacity .3s ease-in-out");

    $$(".overlay").css("opacity", "0");

    this.emit("dashboardclose");

  }


  public down(): void {

    $$(".dashboard").css("-webkit-transition", "transform .5s ease-in-out");
    $$(".dashboard").css("transition", "transform .5s ease-in-out");

    $$(".dashboard").css("-webkit-transform", "translate3d(0,0,0)");
    $$(".dashboard").css("transform", "translate3d(0,0,0)");

    $$(".overlay").css("opacity", 0);

    $$(".overlay").css("-webkit-transition", "opacity .5s ease-in-out");
    $$(".overlay").css("transition", "opacity .5s ease-in-out");

    $$(".overlay").css("opacity", 0.6);

    this.emit("dashboardopen");

  }

}


function showLoginScreen(): void {

  const loginScreen = APP.loginScreen.create({
    content: `
      <div class="login-screen">
        <div class="view">
          <div class="page">
            <div class="page-content login-screen-content">
              <div class="login-screen-logo"><img src="${constants.URLS.WEBSITE_ASSET_PATH + "controlHome-logo-stacked.svg" }" /></div>
                <div class="card big">
                  <div class="card-header">${TRANSLATIONS.getText("login-screen-login-title")}</div>
                  <div class="card-content card-content-padding">
                    <div class="description">${TRANSLATIONS.getText("login-screen-code-description")}</div>
                    <div class="code-input">
                      <div class="code-input-wrapper">
                        <input type="text" maxlength="1" autocapitalize="none" class="code-input-1" />
                      </div>
                      <div class="code-input-wrapper">
                        <input type="text" maxlength="1" autocapitalize="none" class="code-input-2" />
                      </div>
                      <div class="code-input-wrapper">
                        <input type="text" maxlength="1" autocapitalize="none" class="code-input-3" />
                      </div>
                      <div class="code-input-wrapper">
                        <input type="text" maxlength="1" autocapitalize="none" class="code-input-4" />
                      </div>
                      <div class="code-input-wrapper">
                        <input type="text" maxlength="1" autocapitalize="none" class="code-input-5" />
                      </div>
                      <div class="code-input-wrapper">
                        <input type="text" maxlength="1" autocapitalize="none" class="code-input-6" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  });


  loginScreen.on("open", () => {

    const codeInput1Element = loginScreen.$el.find(".login-screen .page-content input.code-input-1");
    const codeInput2Element = loginScreen.$el.find(".login-screen .page-content input.code-input-2");
    const codeInput3Element = loginScreen.$el.find(".login-screen .page-content input.code-input-3");
    const codeInput4Element = loginScreen.$el.find(".login-screen .page-content input.code-input-4");
    const codeInput5Element = loginScreen.$el.find(".login-screen .page-content input.code-input-5");
    const codeInput6Element = loginScreen.$el.find(".login-screen .page-content input.code-input-6");

    const loadProject = async() => {

      const codeInput1Value = codeInput1Element.val();
      const codeInput2Value = codeInput2Element.val();
      const codeInput3Value = codeInput3Element.val();
      const codeInput4Value = codeInput4Element.val();
      const codeInput5Value = codeInput5Element.val();
      const codeInput6Value = codeInput6Element.val();

      const code = codeInput1Value + codeInput2Value + codeInput3Value + codeInput4Value + codeInput5Value + codeInput6Value;

      if(code.length !== 6){
        return;
      }


      //-- Disable event listenters

      disableEvents();

      await CH_PRIVATE.storeCode(code);
      reload(true);

    };

    const onPaste = ev => {

      const code = ev.clipboardData.getData("text");

      for(let c = 0; c < code.length; c++){
        const inputElement = loginScreen.$el.find(".login-screen .page-content input.code-input-" + (c + 1));
        inputElement.focus();
        inputElement.val(code[c]);
      }

      if(code.length >= 6){
        loadProject();
      }

    };

    const onKeyDown = ev => {

      const target = ev.target as HTMLInputElement;
      const value = target.value;

      if(ev.key.toLowerCase() === "backspace"){
        if(value === ""){
          const previousElementInput = getPreviousInputFromInput(target);
          if(previousElementInput !== undefined){
            previousElementInput.value = "";
            previousElementInput.focus();
          }
        }
      }
      if(ev.key.toLowerCase() === "arrowleft"){
        if(ev.shiftKey !== true){
          getPreviousInputFromInput(target)?.focus();
        }
      }
      if(ev.key.toLowerCase() === "arrowright"){
        if(ev.shiftKey !== true){
          if(target.value !== ""){
            getNextInputFromInput(target)?.focus();
          }
        }
      }

    };

    const onInput = ev => {

      const target = ev.target as HTMLInputElement;
      const value = target.value;

      if(value === " "){
        target.value = "";
        return;
      }


      //-- Focus previous

      if(value !== ""){
        getNextInputFromInput(target)?.focus();
      }

      if(target.classList.contains("code-input-6")){
        loadProject();
      }

    };

    const getPreviousInputFromInput = (input: HTMLInputElement): HTMLInputElement | undefined => {
      const previousInput = input.closest(".code-input-wrapper")!.previousElementSibling as HTMLElement;
      if(previousInput !== null){
        const input = previousInput.querySelector("input");
        if(input !== null){
          return input;
        }
      }
      return;
    };

    const getNextInputFromInput = (input: HTMLInputElement): HTMLInputElement | undefined => {
      const nextInput = input.closest(".code-input-wrapper")!.nextElementSibling as HTMLElement;
      if(nextInput !== null){
        const input = nextInput.querySelector("input");
        if(input !== null){
          return input;
        }
      }
      return;
    };

    const disableEvents = () => {
      loginScreen.$el.find(".login-screen .page-content input").off("paste", onPaste);
      loginScreen.$el.find(".login-screen .page-content input").off("input", onInput);
      loginScreen.$el.find(".login-screen .page-content input").off("keydown", onKeyDown);
    };

    disableEvents();

    loginScreen.$el.find(".login-screen .page-content input").on("paste", onPaste);
    loginScreen.$el.find(".login-screen .page-content input").on("input", onInput);
    loginScreen.$el.find(".login-screen .page-content input").on("keydown", onKeyDown);

  });

  loginScreen.open(true);

}


function initializeCloud() {

  CH_PRIVATE.setServerStatus("connecting");

  const signedin = async status => {


    //-- Update identifier

    await CH_PRIVATE.storeIdentifier(status.identifier);

    Cloud.setCode = status.code;
    Cloud.setIdentifier = status.identifier;

    CH_PRIVATE.setServerStatus("connecting");

    let shouldReload = false;

    if(status.blocked === true){
      deviceBlockedPopup();
      return;
    }

    if(status.activated === false){
      deviceNotYetActivatedPopup();
    }


    //-- Store code in history

    if(status.code !== undefined){
      const name = status.location !== "" ? status.location : status.lastname + " " + status.firstname;
      CodeHistory.addEntry(status.code, name);
    }


    //-- Update SDB

    if(status.SDB !== undefined){
      if(functions.isParseableJSON(status.SDB)){

        const sdb: types.SDB = JSON.parse(status.SDB);

        for(const device of sdb){
          for(const variable of device.variables){
            Database.cloudStorage.storeValue(variable.name, variable.value, device.identifier, variable.time, true);
          }
        }

      }
    }


    //-- Update MDB

    if(status.MDB !== undefined){
      const changed = await CH_PRIVATE.storeMDB(status.MDB);
      if(changed === true){
        shouldReload = true;
      }
    }


    //-- Update PDB

    if(status.PDB !== undefined){

      let changed = false;

      if(functions.isParseableJSON(status.PDB)){

        const localPDB = CH_PRIVATE.getPDB();

        if(localPDB !== undefined){

          if(localPDB.time === undefined || JSON.parse(status.PDB).time > localPDB.time){
            changed = await CH_PRIVATE.storePDB(status.PDB);
          }

        } else {
          changed = await CH_PRIVATE.storePDB(status.PDB);
        }

      }

      if(changed === true){
        shouldReload = true;
      }

    }

    if(shouldReload === true){
      reload(true);
    }


    //-- Update Clients

    if(status.clients !== undefined){

      CH_PRIVATE.updateClients(status.clients);


      //-- Update feedbacks from automa clients

      for(const client of status.clients){
        if(client.device === "automa" && client.online === true){

          Cloud.send({
            "func": "get-feedbacks",
            "params": {
              "target": client.identifier,
              "targetDevice": "automa"
            }
          }, data => {

            if(data.params.sid !== 5 || data.params.payload === undefined){
              return;
            }

            if(data.params.payload.feedbacks !== undefined){
              if(data.params.payload.feedbacks.time > CH_PRIVATE.getFeedbacks().time){
                CH_PRIVATE.storeFeedbacks(JSON.stringify(data.params.payload.feedbacks));
              }
            }

          });

        }
      }
    }


    //-- Update push token

    if(NATIVE.pushToken !== ""){
      Cloud.send({ "func": "update-push-token", "params": { "payload": { "token": NATIVE.pushToken } } });
    }


    //-- Release cached cloud messages

    CH_API.emit("signedin");

  };


  const signin = async() => {

    const code = CH_API.getCode();
    const identifier = CH_API.getIdentifier();
    const name = CH_API.getDeviceName();
    const device = CH_PRIVATE.getDevice();

    if(code === undefined){
      return;
    }

    Cloud.setCode = code;
    Cloud.setDevice = device;

    if(identifier !== undefined){
      Cloud.setIdentifier = identifier;
    }


    //-- Code history

    const codeHistory: Array<string> = [];
    const codeHistoryString = await CodeHistory.getEntries();

    if(typeof codeHistoryString === "string"){

      const codeHistoryArray = JSON.parse(codeHistoryString);

      for(const codeEntry of codeHistoryArray){
        codeHistory.push(codeEntry.code);
      }

    }

    console.log(`Connected to ${constants.URLS.SERVER_ADDRESS}`);
    console.log(`Signin as ${identifier} with code ${code}`);

    Cloud.get({
      "func": "signin",
      "params": {
        "code": code,
        "device": CH_PRIVATE.getDevice(),
        "payload": {
          "identifier": identifier,
          "uuid": NATIVE.UUID,
          "uniqueid": NATIVE.UUID,
          "pushToken": NATIVE.pushToken,
          "codes": codeHistory,
          "version": packagejson.version,
          "name": name
        }
      }
    }).then(status => {

      signedin(status);

    }).catch(error => {

      console.error("App error while signin: ", error);

      showLoginScreen();

    });


  };


  //-- check if user is signedin

  if(Cloud.readyState === 1){
    signin();
  }


  //-- check if user is signedin

  Cloud.on("connect", async() => {
    CH_PRIVATE.setServerStatus("connecting");
    signin();
  });


  Cloud.on("disconnect", () => {
    CH_PRIVATE.setServerStatus("error");
  });


  Cloud.on("message", async msg => {

    switch (msg.func){

      case "two-factor-auth-needed":{

        if(msg.params.payload.devices === undefined){
          return;
        }

        for(const device of msg.params.payload.devices){
          new TFAPopup(device.identifier, device.code, device.device, device.name);
        }

        break;
      }
      case "two-factor-auth-done":{

        if(msg.params.payload.identifier === undefined){
          return;
        }

        if(msg.params.payload.code === undefined){
          return;
        }

        TFAPopup.close(msg.params.payload.identifier, msg.params.payload.code);

        break;
      }
      case "change-debugging":{
        if(msg.params.target === CH_API.getIdentifier()){

          CH_API.storeData("DEBUG_ENABLED", msg.params.payload.enabled);

          if(msg.id !== undefined){
            Cloud.send({
              "func": "cb",
              "id": msg.id,
              "params": {
                "sid": 5,
                "target": msg.params.src,
                "targetDevice": msg.params.device,
                "status": "success",
                "statustext": "Debugging is now " + (msg.params.payload.enabled ? "enabled" : "disabled")
              }
            });
          }

        }
        break;
      }
      case "update-clients":{

        CH_PRIVATE.updateClients(msg.params.payload.clients);

        break;
      }
      case "deploy":{

        const CH_PDB = CH_PRIVATE.getPDB();

        if(!functions.isParseableJSON(msg.params.payload.PDB)){
          console.warn("deploy warning: pdb is no valid json");
          return;
        }

        if(CH_PDB === undefined || (JSON.parse(msg.params.payload.PDB).time > CH_PDB.time) || CH_PDB.time === undefined){
          CH_PRIVATE.storePDB(msg.params.payload.PDB);
        }

        break;
      }
      case "update-received":{

        if(functions.compareVersions(packagejson.version, msg.params.payload.app)){
          const popup = new SettingsPopup("update");
        }

        break;
      }
      case "app-reset":{

        if(msg.params.payload.code !== CH_API.getCode()){
          return;
        }

        CH_PRIVATE.deleteCode();

        break;
      }
      case "app-reload":{

        reload(true);

        break;
      }
      case "run-function-on-app":{

        const { deviceIdentifier, functionName, argument } = msg.params.payload;

        const data = await CH_API.runFunctionOnApp(deviceIdentifier, functionName, argument);

        if(msg.id !== undefined){

          msg.target = msg.params.src;

          Cloud.send({
            "func": "cb",
            "id": msg.id,
            "params": {
              "target": msg.params.src,
              "targetDevice": msg.params.device,
              "payload": {
                "sid": 5,
                "response": data
              }
            }
          });

        }

        break;
      }
      case "module-updated":{

        const mdb = CH_PRIVATE.getMDB();

        if(mdb === undefined){
          return;
        }

        reload(true);

        // for(const mod of mdb){
        //   if(mod.identifier === msg.params.payload.identifier){
        //     if(functions.compareVersions(mod.version, msg.params.payload.version)){
        //       CH_PRIVATE.updateMDB();
        //       return;
        //     }
        //   }
        // }

        break;
      }
      case "set-feedback":{

        if(msg.params.payload.automaIdentifier === undefined){
          return;
        }
        if(msg.params.payload.name === undefined){
          return;
        }
        if(msg.params.payload.value === undefined){
          return;
        }

        if(msg.params.payload.deviceIdentifier !== undefined){
          CH_API.setFeedback(msg.params.payload.name, msg.params.payload.value, msg.params.payload.automaIdentifier, msg.params.payload.deviceIdentifier, false);
        } else {
          CH_API.setFeedback(msg.params.payload.name, msg.params.payload.value, msg.params.payload.automaIdentifier, false);
        }

        break;
      }
      case "cloud-store":{

        if(msg.params.payload.deviceIdentifier === undefined){
          return;
        }
        if(msg.params.payload.name === undefined){
          return;
        }
        if(msg.params.payload.value === undefined){
          return;
        }
        if(msg.params.payload.time === undefined){
          return;
        }

        Database.cloudStorage.storeValue(msg.params.payload.name, msg.params.payload.value, msg.params.payload.deviceIdentifier, msg.params.payload.time, true);

        break;
      }
      case "code-change-request":{

        if(CH_PRIVATE.getDevice() === "iframe"){
          return;
        }

        if(msg.params.code !== CH_API.getCode()){
          return;
        }

        if(await Storage.getData("AUTO_CODE_DETECTION_ENABLED") !== "true"){
          return;
        }

        const codeHistoryString = await CodeHistory.getEntries();

        if(typeof codeHistoryString !== "string"){
          return;
        }

        if(!functions.isParseableJSON(codeHistoryString)){
          return;
        }

        const codeHistory = JSON.parse(codeHistoryString);

        for(const codeEntry of codeHistory){
          if(codeEntry.code === msg.params.payload["recommended-code"]){
            if(msg.params.payload["recommended-code"] !== CH_API.getCode()){
              CH_PRIVATE.changeCode(msg.params.payload["recommended-code"], false);
            }
          }
        }

        break;

      }
    }

  });

}


abstract class CodeHistory {

  public static async deleteEntry(code: string) {

    const codeHistoryString = await CodeHistory.getEntries();

    if(typeof codeHistoryString === "string"){

      const codeHistoryArray = JSON.parse(codeHistoryString);

      for(let h = codeHistoryArray.length - 1; h >= 0; h--){
        if(codeHistoryArray[h].code === code){
          codeHistoryArray.splice(h, 1);
        }
      }

      await Storage.storeData("__CODE_HISTORY__", JSON.stringify(codeHistoryArray));

    }

  }


  public static async addEntry(code: string, name: string) {

    const codeHistoryString = await CodeHistory.getEntries();

    let codeHistoryArray: Array<types.Object> = [];

    if(typeof codeHistoryString === "string"){
      codeHistoryArray = JSON.parse(codeHistoryString);
    }

    let codeFound = false;

    for(let h = codeHistoryArray.length - 1; h >= 0; h--){
      if(codeHistoryArray[h].code === code){
        codeHistoryArray[h].name = name;
        codeFound = true;
        break;
      }
    }

    if(codeFound === false){
      codeHistoryArray.push({ code, name });
    }

    await Storage.storeData("__CODE_HISTORY__", JSON.stringify(codeHistoryArray));

  }


  public static async getEntries() {
    return await Storage.getData("__CODE_HISTORY__");
  }

}


function deviceBlockedPopup() {

  if(CH_PRIVATE.getDevice() === "iframe"){
    return;
  }

  if(APP.popup.get(".device-blocked") !== undefined){
    return;
  }

  const popup = APP.popup.create({
    content: `
      <div class="popup device-blocked">
        <div class="page">
          <div class="page-content">
            </br>
            </br>
            </br>
            <div class="text-align-center">
              <i class="f7-icons" style="font-size: 20vh; color: var(--ch-red-color);">
                lock
              </i>
            </div>
            </br>
            <div class="card big">
              <div class="card-header">${TRANSLATIONS.getText("two-factor-popup-blocked-title")}</div>
              <div class="card-content card-content-padding">
                ${TRANSLATIONS.getText("two-factor-popup-blocked-text")}
              </div>
            </div>
            <button class="button color-red signout">${TRANSLATIONS.getText("settings-logout-label")}</button>
          </div>
        </div>
      </div>
    `,
    closeByBackdropClick: false,
    swipeToClose: false
  }).open();

  popup.$el.on("click", ".signout", ev => {
    CH_PRIVATE.signout();
  });

}


function deviceNotYetActivatedPopup() {

  if(CH_PRIVATE.getDevice() === "iframe"){
    return;
  }

  if(APP.popup.get(".device-not-yet-activated") !== undefined){
    return;
  }

  const popup = APP.popup.create({
    content: `
      <div class="popup device-not-yet-activated">
        <div class="page">
          <div class="page-content">
            </br>
            </br>
            </br>
            <div class="text-align-center">
              <i class="f7-icons" style="font-size: 20vh; color: var(--ch-yellow-color);">
                lock
              </i>
            </div>
            </br>
            <div class="card big">
              <div class="card-header">${TRANSLATIONS.getText("two-factor-popup-not-activated-title")}</div>
              <div class="card-content card-content-padding">
                ${TRANSLATIONS.getText("two-factor-popup-not-activated-text")}
              </div>
            </div>
            <div class="block">
              <button class="button button-large color-red signout">${TRANSLATIONS.getText("settings-logout-label")}</button>
            </div>
          </div>
        </div>
      </div>
    `,
    closeByBackdropClick: false,
    swipeToClose: false
  }).open();

  popup.$el.on("click", ".signout", ev => {
    CH_PRIVATE.signout();
  });

}


class TFAPopup {

  public popup: Popup.Popup | undefined;

  private _identifier: string;
  private _code: string;

  static popups: Array<{ identifier: string; code: string; }> = [];

  constructor(identifier: string, code: string, device: string, name: string) {

    this._identifier = identifier;
    this._code = code;

    for(const oldPopup of TFAPopup.popups){
      if(oldPopup.identifier === identifier && oldPopup.code === code){
        return;
      }
    }

    this.popup = APP.popup.create({
      content: `
        <div class="popup" identifier="${this._identifier}" code="${this._code}">
          <div class="page">
            <div class="navbar">
              <div class="navbar-inner">
                <div class="title">${TRANSLATIONS.getText("two-factor-popup-title")}</div>
                <div class="right">
                  <a class="link popup-close">${TRANSLATIONS.getText("app-close")}</a>
                </div>
              </div>
            </div>
            <div class="page-content">
              </br>
              </br>
              </br>
              <div class="text-align-center">
                <i class="f7-icons" style="font-size: 20vh; color: var(--ch-yellow-color);">
                  lock
                </i>
              </div>
              </br>
              <div class="card big">
                <div class="card-header">${name + " " + TRANSLATIONS.getText("two-factor-popup-heading")}</div>
                <div class="card-content card-content-padding">
                  ${"\"" + name + "\" " + TRANSLATIONS.getText("two-factor-popup-text")}
                </div>
              </div>
              <div class="block">
                <div class="row">
                  <button class="tfa-allow col button button-fill button-large color-green">${TRANSLATIONS.getText("two-factor-popup-allow")}</button>
                  <button class="tfa-block col button button-fill button-large color-red">${TRANSLATIONS.getText("two-factor-popup-block")}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      `
    });

    TFAPopup.popups.push({ identifier: this._identifier, code: this._code });

    this.popup.open();
    this.popup.on("closed", this._closed.bind(this));

    $$(this.popup.el).on("click", "button", this._buttonClicked.bind(this));

  }


  private _closed() {
    for(let i = TFAPopup.popups.length - 1; i >= 0; i--){
      if(TFAPopup.popups[i].identifier === this._identifier && TFAPopup.popups[i].code === this._code){
        TFAPopup.popups.splice(i, 1);
      }
    }
    this.popup!.destroy();
  }


  private _buttonClicked(ev) {

    if(ev.target === null){
      return;
    }

    let target = $$(ev.target);

    if(target[0].tagName.toLocaleLowerCase() !== "button"){
      target = target.parents("button");
    }

    let popup = target;

    //@ts-ignore
    if(popup.hasClass("popup") !== true){
      popup = popup.parents(".popup");
    }

    const identifier = popup.attr("identifier");
    const code = popup.attr("code");

    if(target.hasClass("tfa-allow")){
      Cloud.send({ "func": "two-factor-auth-allow", "params": { "payload": { "identifier": identifier, code: code } } });
      this.popup!.close();
    } else if(target.hasClass("tfa-block")){
      Cloud.send({ "func": "two-factor-auth-block", "params": { "payload": { "identifier": identifier, code: code } } });
      this.popup!.close();
    }
  }


  static close(identifier: string, code: string): void {
    for(let i = TFAPopup.popups.length - 1; i >= 0; i--){
      if(TFAPopup.popups[i].identifier === identifier && TFAPopup.popups[i].code === code){
        const popup = APP.popup.get(`.popup[identifier="${identifier}"]`);
        popup.close();
      }
    }
  }

}


//-- Userscript functions

function getUserScriptByDeviceName(name: string) {

  for(let u = 0; u < globalThis.USERSCRIPTS.length; u++){
    if(globalThis.USERSCRIPTS[u].name === name){
      return globalThis.USERSCRIPTS[u];
    }
  }

  return;

}

async function addScriptSyncronously(content: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const scriptFile = document.createElement("script");
    scriptFile.src = "data:text/javascript," + encodeURIComponent(content);
    scriptFile.async = false;
    scriptFile.onload = () => { resolve(); };
    scriptFile.onerror = err => { reject(err); };
    document.head.appendChild(scriptFile);
  });
}

function logEvent(event: Event) {
  console.log(event.type);
}

initialize();