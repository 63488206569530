import Framework7 from "framework7";
import { Popover } from "framework7/components/popover";

import Dom7 from "../../../shared/modules/dom7";
import { Dom7Array } from "dom7";

import { CH_PRIVATE } from "../../../js/modules/ch-api";

import "./f7.fullscreen-modal.css";
import { pointerdown, pointermove, pointerup } from "../../../js/modules/event-names";

const $$ = Dom7;

type ModalType = "fullscreen" | "popover" | "auto" | "inline";

export default function(Framework7Class) {
  return class FullscreenModal extends Framework7Class {

    public static breakPointX: 768;

    public $targetEl: Dom7Array | undefined;
    public $containerEl: Dom7Array | undefined;
    public $arrowTarget: Dom7Array | undefined;

    public $contentEl: Dom7Array | undefined;

    public startX: number = 0;
    public startY: number = 0;
    public currentX: number = 0;
    public currentY: number = 0;

    public autoClose: boolean = false;
    public backdrop: boolean = true;
    public openIn: ModalType = "popover";
    public openInPhone: ModalType = "fullscreen";
    public closeByBackdropClick: boolean = true;
    public autoInit: boolean = false;
    public content: string | undefined;
    public popover: Popover.Popover | undefined;

    public isOpen: boolean = false;

    private _pointerIsDown: boolean = false;
    private _rendered: boolean = false;
    private _modalType: ModalType = "auto";

    private _delayTimeout: any;

    private _app: Framework7;
    private _$fullscreenModalBackdrop: Dom7Array | undefined;

    private _pointerDownEvent: (event) => void;
    private _pointerMoveEvent: (event) => void;
    private _pointerUpEvent: (event) => void;
    private _backdropClickedEvent: (event) => void;
    private _touchMoveEvent: (event) => void;


    constructor(app: Framework7, params) {

      super(params, [app]);

      this.autoClose = this.params.autoClose || this.params.autoclose || this.autoClose;
      this.backdrop = this.params.backdrop || this.backdrop;
      this.openIn = this.params.openIn || this.params.openin || this.openIn;
      this.openInPhone = this.params.openInPhone || this.params.openinphone || this.openInPhone;
      this.closeByBackdropClick = this.params.closeByBackdropClick || this.params.closebybackdropclick || this.closeByBackdropClick;
      this.content = this.params.content;
      this.autoInit = this.params.init || this.autoInit;

      const targetEl = params.targetEl || params.targetel;
      const arrowTarget = params.arrowTarget || params.arrowtarget;
      const containerEl = params.containerEl || params.containerel;

      this._app = app;


      //-- Set modal type

      if(containerEl !== undefined){
        this._modalType = "inline";
        this.isOpen = true;
      } else {

        if(this._app.device.ios){
          this._modalType = this._app.device.ipad ? this.openIn : this.openInPhone;
        }
        if(this._app.width >= FullscreenModal.breakPointX || (this._app.device.desktop && this._app.theme === "aurora")){
          this._modalType = this.openIn;
        }

        this._modalType = this.openInPhone;

      }

      if(targetEl !== undefined){

        this.$targetEl = $$(targetEl);
        this.$arrowTarget = this.$targetEl;

        if(arrowTarget !== undefined){
          this.$arrowTarget = $$(arrowTarget);
        }
      }

      if(containerEl !== undefined){
        this.$containerEl = $$(containerEl);
      }

      this._contextmenuEvent = this._contextmenu.bind(this);
      this._pointerDownEvent = this._pointerDown.bind(this);
      this._pointerMoveEvent = this._pointerMove.bind(this);
      this._pointerUpEvent = this._pointerUp.bind(this);
      this._backdropClickedEvent = this._backdropClicked.bind(this);
      this._touchMoveEvent = this._touchMove.bind(this);

      if(this.autoInit === true){
        this.init();
      }


      //-- Initialize events

      if(this.$targetEl !== undefined){
        this.$targetEl.on(pointerdown, this._pointerDownEvent);
        this.$targetEl.on("contextmenu", this._contextmenuEvent);
      }

      $$(document).on(pointermove, this._pointerMoveEvent);
      $$(document).on(pointerup, this._pointerUpEvent);

    }


    protected init(): void {

      if(this._modalType === "fullscreen"){


        //-- Add popoverContainer

        this.$contentEl = $$(`
          <div class="fullscreenmodal-container"></div>
        `);

        this._app.$el.append(this.$contentEl);

      } else if(this._modalType === "inline"){

        const content = this._render();

        if(content !== undefined){
          if(this.$containerEl !== undefined){
            this.$containerEl.html(content);
            this.$contentEl = this.$containerEl;
          }
        }

      } else if(this._modalType === "popover"){

        const content = this._render();

        if(content !== undefined){

          if(this.$targetEl !== undefined && this.$arrowTarget !== undefined){

            this.popover = this._app.popover.create({
              "targetEl": this.$arrowTarget[0] as HTMLElement,
              "content": `
                <div class="popover">
                  <div class="popover-inner text-align-center">
                    <div class="block">
                      ${content}
                    </div>
                  </div>
                </div>
              `,
              "closeByBackdropClick": this.closeByBackdropClick,
              "closeByOutsideClick": false,
              "on": {
                "close" : ev => {
                  this.isOpen = false;
                }
              }
            });

            this.$contentEl = this.popover.$el;

          }

        }

      }

      if(this.$contentEl === undefined){
        return;
      }


      //-- Initialize content events

      this.$contentEl.on(pointerdown, ".touch-area", this._pointerDownEvent);


      //-- Prevent touchmove events on parent slider etc.

      this.$contentEl.on("touchmove", ".touch-area", this._touchMoveEvent);


    }


    public open(): void {

      if(this.$targetEl === undefined){
        return;
      }

      if(this.$contentEl === undefined){
        this.init();
      }

      if(this.isOpen === true){
        return;
      }


      //-- Render content

      const content = this._render();

      if(content !== undefined){
        this.$contentEl!.html(content);
      }

      if(this._modalType === "fullscreen"){

        this.emit("local::beforeOpen fullscreenModalBeforeOpen", this);
        this.$contentEl!.trigger("fullscreenModal:beforeopen", this);

        this.isOpen = true;

        CH_PRIVATE.TapticEngine.impact.heavy();

        this._disableAnimation();

        const backdrop = this._getBackdrop();


        //-- Set up styling

        backdrop?.css("opacity", "0");

        this.$contentEl!.css("opacity", "0");
        this.$contentEl!.css("transform", "scale(.3)");
        this.$contentEl!.addClass("visible");

        this._updatePosition();

        this._enableAnimation();

        backdrop?.addClass("visible");
        backdrop?.css("opacity", "1");

        this._updatePosition();

        this.$contentEl!.css("transform", "scale(1)");
        this.$contentEl!.css("opacity", "1");

        this.emit("local::open fullscreenModalOpen", this);
        this.$contentEl!.trigger("fullscreenModal:open", this);

        setTimeout(() => {

          this.emit("local::opened fullscreenModalOpened", this);
          this.$contentEl!.trigger("fullscreenModal:opened", this);

        }, 200);

      } else if(this._modalType === "popover"){

        if(this.popover !== undefined){

          this.isOpen = true;
          this.popover.open();

        }

      }

    }


    private _render(): string | undefined {


      //-- Render content

      if(typeof this.render === "function"){
        this._rendered = true;
        return this.render();
      } else if(this.content !== undefined){
        return this.content;
      }

      return;

    }


    private _updatePosition(): void {

      if(this.$targetEl === undefined || this.$contentEl === undefined){
        return;
      }

      let top = (this.$targetEl.offset().top + (this.$targetEl[0].getBoundingClientRect().height / 2) - this.$contentEl.height() / 2);
      let left = (this.$targetEl.offset().left + (this.$targetEl[0].getBoundingClientRect().width / 2) - this.$contentEl.width() / 2);


      //-- Override position for smartphones

      if(window.innerWidth < FullscreenModal.breakPointX){
        left = (window.innerWidth / 2) - (this.$contentEl.width() / 2);
        top = (window.innerHeight / 2) - (this.$contentEl.height() / 2);
      }

      if(top < 0){
        top = 0;
      }
      if(left < 0){
        left = 0;
      }
      if(top > window.innerHeight - this.$contentEl.height()){
        top = window.innerHeight - this.$contentEl.height();
      }
      if(left > window.innerWidth - this.$contentEl.width()){
        left = window.innerWidth - this.$contentEl.width();
      }

      this.$contentEl.css("top", top + "px");
      this.$contentEl.css("left", left + "px");

    }


    public close(): void {

      if(this._modalType === "fullscreen"){

        this.isOpen = false;

        this.emit("local::beforeClose fullscreenModalBeforeClose", this);
        this.$contentEl!.trigger("fullscreenModal:beforeclose", this);


        //-- Remove backdrop

        this._enableAnimation();

        const backdrop = this._getBackdrop();

        backdrop?.css("opacity", "0");
        this.$contentEl!.css("opacity", "0");
        this.$contentEl!.css("transform", "scale(.3)");

        this._updatePosition();

        this.emit("local::close fullscreenModalClose", this);
        this.$contentEl!.trigger("fullscreenModal:close", this);

        setTimeout(() => {

          backdrop?.removeClass("visible");

          if(this.$contentEl !== undefined){
            this.$contentEl.removeClass("visible");
            this.$contentEl.trigger("fullscreenModal:closed", this);
          }

          this.emit("local::closed fullscreenModalClosed", this);


          //-- Remove element

          this._remove();

        }, 200);

      } else if(this._modalType === "popover"){

        if(this.popover === undefined){
          return;
        }

        this.isOpen = false;

        this.popover.close();

        if(this.destroyOnClose === true){
          this.popover.once("closed", this.remove.bind(this));
        }

      }

    }


    private _contextmenu(ev) {
      ev.preventDefault();
      this.open();
    }


    private _touchStart(ev) {
      ev.stopPropagation();
    }


    private _touchMove(ev) {
      ev.stopPropagation();
    }


    private _pointerDown(ev: PointerEvent): void {

      ev.stopPropagation();

      if(ev.isPrimary === false){
        return;
      }

      this.startX = ev.pageX;
      this.startY = ev.pageY;
      this.currentX = ev.pageX;
      this.currentY = ev.pageY;

      this._pointerIsDown = true;

      if(this.isOpen !== true){

        this._delayTimeout = setTimeout(() => {

          this._delayTimeout = undefined;

          this.open();


          //-- Run extended pointerDown method

          if(typeof this.pointerDown === "function"){
            this.pointerDown(ev);
          }

        }, 150);

      } else {


        //-- Run extended pointerDown method

        if(typeof this.pointerDown === "function"){
          this.pointerDown(ev);
        }

      }
    }


    private _pointerMove(ev): void {

      ev.stopPropagation();

      this.currentX = ev.pageX;
      this.currentY = ev.pageY;

      if(this._pointerIsDown !== true){
        return;
      }

      if(Math.abs(this.startX - this.currentX) > 20 || Math.abs(this.startY - this.currentY) > 20){

        if(this._delayTimeout !== undefined){
          clearTimeout(this._delayTimeout);
          this._delayTimeout = undefined;
          return;
        }

      }

      if(this.isOpen !== true){
        return;
      }


      //-- Run extended pointerMove method

      if(typeof this.pointerMove === "function"){
        this.pointerMove(ev);
      }

    }


    private _pointerUp(ev): void {

      this._pointerIsDown = false;

      if(this._delayTimeout !== undefined){
        clearTimeout(this._delayTimeout);
        this._delayTimeout = undefined;
        return;
      }

      if(this.isOpen !== true){
        return;
      }


      //-- Run extended pointerup method

      if(typeof this.pointerUp === "function"){
        this.pointerUp(ev);
      }


      //-- Close

      if(this.autoClose === true){
        this.close();
      }

    }


    private _backdropClicked(ev: PointerEvent): void {

      if(this.isOpen !== true){
        return;
      }

      if(this.closeByBackdropClick === true){
        this.close();
      }

    }


    public destroy(): void {

      this.emit("local::beforeDestroy fullscreenModalBeforeDestroy", this);

      if(this.$contentEl !== undefined){
        this.$contentEl.trigger("fullscreenModal:beforedestroy", this);
      }


      //-- Remove events

      if(this.$contentEl !== undefined){
        this.$contentEl.off("touchmove", ".touch-area", this._touchMoveEvent);
      }

      if(this.$targetEl !== undefined){
        this.$targetEl.off(pointerdown, this._pointerDownEvent);
        this.$targetEl.off("contextmenu", this._contextmenuEvent);
      }

      $$(document).off(pointermove, this._pointerMoveEvent);
      $$(document).off(pointerup, this._pointerUpEvent);


      this._remove();

    }


    private _remove() {


      //-- Remove modal


      if(this.$contentEl !== undefined){
        this.$contentEl.remove();
        this.$contentEl.off(pointerdown, ".touch-area", this._pointerDownEvent);
        this.$contentEl = undefined;
      }


      //-- Remove backdrop

      if(this._$fullscreenModalBackdrop !== undefined){
        this._$fullscreenModalBackdrop.off("click", this._backdropClickedEvent);
        this._$fullscreenModalBackdrop.remove();
        this._$fullscreenModalBackdrop = undefined;
      }

    }


    private _enableAnimation() {

      if(this._$fullscreenModalBackdrop !== undefined){
        this._$fullscreenModalBackdrop.css("transition", "all .15s ease-in-out");
      }

      if(this.$contentEl !== undefined){
        this.$contentEl.css("transition", "all .15s ease-in-out");
      }

    }


    private _disableAnimation() {

      if(this._$fullscreenModalBackdrop !== undefined){
        this._$fullscreenModalBackdrop.css("transition", "none");
      }

      if(this.$contentEl !== undefined){
        this.$contentEl.css("transition", "none");
      }

    }


    private _getBackdrop(): Dom7Array | undefined {

      if(this.backdrop === false){
        return;
      }

      if(this._$fullscreenModalBackdrop === undefined){

        this._$fullscreenModalBackdrop = this._app.$el.children(".fullscreenmodal-backdrop");

        if(this._$fullscreenModalBackdrop.length === 0){
          this._$fullscreenModalBackdrop = $$(`
            <div class="fullscreenmodal-backdrop"></div>
          `);
          this._app.$el.append(this._$fullscreenModalBackdrop);
        }

        this._$fullscreenModalBackdrop.on("click", this._backdropClickedEvent);

        return this._$fullscreenModalBackdrop;

      } else {
        return this._$fullscreenModalBackdrop;
      }

    }

  };
}
