import IdbKvStore from "idb-kv-store";
class Storage_ {

  private _code: string | undefined;
  private _store: any;
  private _initializing = false;
  private _initialized = false;

  constructor() {
  }


  public async initializeDB(): Promise<void> {

    return new Promise(resolve => {

      if(this._initializing === true){
        this._store.once("open", resolve);
        return;
      }

      if(this._initialized === true){
        resolve();
        return;
      }

      this._initializing = true;


      //-- Create db

      this._store = new IdbKvStore("data", () => {
        this._initialized = true;
        this._initializing = false;
        resolve();
      });

    });

  }


  public async storeCode(code: string) {

    if(typeof code !== "string"){
      return;
    }

    this._code = code;

    return await this.storeData("__CODE__", code);

  }


  public async getCode(): Promise<string | undefined> {

    if(this._code !== undefined){
      return this._code;
    }

    const code = await this.getData("__CODE__");

    if(typeof code === "string"){
      this._code = code;
    }

    return this._code;

  }


  public async deleteCode(): Promise<void> {

    if(this._store === undefined){
      return;
    }

    if(this._initialized === false){
      await this.initializeDB();
    }

    this._store.remove("__CODE__");

  }


  public async deleteData(key: string): Promise<void> {

    if(this._store === undefined){
      return;
    }

    if(this._initialized === false){
      await this.initializeDB();
    }

    this._store.removeItem(key);

  }


  public async getData(key: string): Promise<string | number | boolean | undefined> {

    if(this._store === undefined){
      return;
    }

    if(this._initialized === false){
      await this.initializeDB();
    }

    return await this._store.get(key);

  }


  public async storeData(key: string, value: string | number | boolean): Promise<void> {

    if(this._store === undefined){
      return;
    }

    if(this._initialized === false){
      await this.initializeDB();
    }

    try {

      return await this._store.set(key, value);

    } catch (err){
      console.error("transaction error: ", err);
    }

  }

}

export const Storage = new Storage_();