import { types } from "../../interfaces/types";

export class CloudStorage_ {

  public SDB: types.SDB | undefined;

  constructor() {

  }


  public getValue(name: string, deviceIdentifier: string): string | boolean | number | undefined {

    if(this.SDB === undefined){
      this.SDB = [];
    }

    deviceLoop: for(const device of this.SDB){

      if(device.identifier !== deviceIdentifier){
        continue deviceLoop;
      }

      for(const variable of device.variables){
        if(variable.name === name){
          return variable.value;
        }
      }

    }

    return undefined;

  }


  public storeValue(name: string, value: string | boolean | number, deviceIdentifier: string, time: number): void {

    if(this.SDB === undefined){
      this.SDB = [];
    }

    deviceLoop: for(let s = 0; s < this.SDB.length; s++){

      if(this.SDB[s].identifier !== deviceIdentifier){
        continue deviceLoop;
      }

      for(let v = 0; v < this.SDB[s].variables.length; v++){
        if(this.SDB[s].variables[v].time < time){
          if(this.SDB[s].variables[v].name === name){
            this.SDB[s].variables[v].value = value;
            this.SDB[s].variables[v].time = time;
            return;
          }
        } else {
          return;
        }
      }

      this.SDB[s].variables.push({
        "name": name,
        "value": value,
        "time": time
      });

      return;

    }

    this.SDB.push({
      "identifier": deviceIdentifier,
      "variables": [{
        "name": name,
        "value": value,
        "time": time
      }]
    });

    return;

  }


  public deleteValue(name: string, deviceIdentifier: string, time: number): void {

    if(this.SDB === undefined){
      this.SDB = [];
    }

    deviceLoop: for(let s = this.SDB.length - 1; s >= 0 ; s--){

      if(this.SDB[s].identifier !== deviceIdentifier){
        continue deviceLoop;
      }

      for(let v = this.SDB[s].variables.length - 1; v >= 0; v--){
        if(this.SDB[s].variables[v].time < time){
          if(this.SDB[s].variables[v].name === name){
            this.SDB[s].variables.splice(v, 1);
            return;
          }
        }
      }

      return;

    }

    return;

  }


}

export const CloudStorage = new CloudStorage_();