import { isParseableJSON } from "../../../shared/functions/functions";
import { XMLParser, XMLValidator } from "fast-xml-parser";
import { types } from "../../../shared/interfaces/types";

export default class HTTPS_REQUEST {

  private _sid: number;
  private _ip: string | undefined;
  private _host: string | undefined;
  private _url: string | undefined;
  private _responseContent: string;

  constructor(httpResponse: types.Object) {
    this._sid = httpResponse.sid;
    this._responseContent = httpResponse.response;
    this._ip = httpResponse.ip;
    this._host = httpResponse.host;
    this._url = httpResponse.url;
  }


  public get response(): string | undefined {
    return this._responseContent;
  }


  public get text(): string | undefined {
    return this._responseContent?.toString() ?? undefined;
  }


  public get sid(): number {
    return this._sid;
  }


  public get error(): string | undefined {
    if(this._sid === 0){
      return this._responseContent;
    }
    return;
  }


  public get ip(): string | undefined {
    return this._ip;
  }


  public get host(): string | undefined {
    return this._host;
  }


  public get url(): string | undefined {
    return this._url;
  }


  public get json(): any {
    if(this._sid === 5 && this._responseContent !== undefined && isParseableJSON(this._responseContent)){
      return JSON.parse(this._responseContent);
    } else if(this._sid === 5 && this._responseContent !== undefined && XMLValidator.validate(this._responseContent)){
      return new XMLParser({
        ignoreAttributes: false,
        parseAttributeValue : true,
        attributeNamePrefix : "",
        parseTagValue : true
      }).parse(this._responseContent);
    }
  }

}