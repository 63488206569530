import Websocket from "./websocket";
import * as server from "../../../shared/interfaces/server";
import { types } from "../../interfaces/types";
import TinyEventEmitter from "../tiny-event-emitter";
import * as constants from "shared-constants";

class Cloud_ extends TinyEventEmitter {

  private _lastHeartbeatReceived: number | undefined;
  private _heartbeatInterval: any | undefined;
  private _reconnect: boolean = true;

  private _callbacks: any;
  public connection: any;
  public identifier: string | undefined;
  public code: string | undefined;
  public device: server.Device | undefined;


  constructor() {

    super();

    this._callbacks = [];

    this.identifier;
    this.code;
    this.device;

    this.connection = new Websocket("wss://" + constants.URLS.SERVER_ADDRESS);

    this.connection.on("open", this._connected.bind(this));
    this.connection.on("close", this._closed.bind(this));
    this.connection.on("message", this._handleMessage.bind(this));
    this.connection.on("error", this._error.bind(this));

  }


  public connect() {
    this.connection.connect();
    this._reconnect = true;
  }


  public reconnect() {
    this.connection.close();
    setTimeout(this.connect.bind(this), 500);
  }


  private _closed() {

    console.log("Cloud: Disconnected to " + constants.URLS.SERVER_ADDRESS);

    this._stopHeartbeat();

    this.emit("disconnect");

    if(this._reconnect === true){
      setTimeout(this.connect.bind(this), 500);
    }

  }


  private _error(err) {

    console.log("Cloud: Connection error: ", err);

    if(this._reconnect === true){
      setTimeout(this.connect.bind(this), 500);
    }

  }


  private _stopHeartbeat() {

    if(this._heartbeatInterval !== undefined){
      clearInterval(this._heartbeatInterval);
    }

    this._lastHeartbeatReceived = undefined;

  }


  private _startHeartbeat() {

    if(this._heartbeatInterval !== undefined){
      clearInterval(this._heartbeatInterval);
    }

    this._lastHeartbeatReceived = Date.now();

    this._heartbeatInterval = setInterval(() => {

      this.send({ "func": "heartbeat" });

      setTimeout(() => {

        const t = Date.now();

        if(this._lastHeartbeatReceived !== undefined){
          if(this._lastHeartbeatReceived < t - constants.HEARTBEAT_TIMEOUT){
            console.log("hartbeat missed");
          }

          if(this._lastHeartbeatReceived < t - (constants.HEARTBEAT_TIMEOUT * constants.HEARTBEAT_MAX_TRIES)){
            console.log("Closed due to not sending heartbeat");
            this.reconnect();
          }
        }

      }, 3000);

    }, constants.HEARTBEAT_TIMEOUT);


  }


  private _connected() {
    console.log("Cloud: Connected to " + constants.URLS.SERVER_ADDRESS);
    this.emit("connect", null);
    this._startHeartbeat();
  }


  private _handleMessage(msg: string) {
    try {

      const data: server.Function | server.Function = JSON.parse(decodeURIComponent(msg));

      // console.log("<< ", data);


      //-- Handle callback

      if(data.func === "cb"){


        if(data.id !== undefined){

          if(this._getCallbackById(data.id)){
            this._getCallbackById(data.id).cb(data);
          }

          if(data.params === undefined){
            return;
          }

          if(data.params.sid === undefined){
            return;
          }

          if(data.params.sid >= 5 || data.params.sid === 0){
            for(let c = this._callbacks.length - 1; c >= 0; c--){
              if(this._callbacks[c].id === data.id){
                this._callbacks.splice(c, 1);
              }
            }
          }

          return;

        }
      }


      //-- Handle heartbeat

      if(data.func === "heartbeat"){
        this._lastHeartbeatReceived = Date.now();
        return;
      }

      this.emit("message", data);

    } catch (err){
      console.error("Cloud on data error: ", err);
    }
  }


  public send(msg: server.Function, callback?: Function): void {

    try {


      //-- Closed

      if(this.readyState === 3){
        this.connect();
      }


      //-- Opening

      if(this.readyState === 0){
        this.once("open", () => {
          this.send(msg, callback);
        });
        return;
      }


      //-- Not connected

      if(this.readyState !== 1){
        return;
      }


      //-- Add callback

      if(callback !== undefined && typeof callback === "function"){
        msg.id = new Date().getTime() + msg.func + "" + getUniqueString();
        this._callbacks.push({ "id": msg.id, "cb": callback });
      }


      //-- Add missing data

      if(msg.params !== undefined){

        if(msg.params.code === undefined){
          if(this.code !== undefined){
            msg.params.code = this.code;
          }
        }

        if(msg.params.src === undefined){
          if(this.identifier !== undefined){
            msg.params.src = this.identifier;
          }
        }

        if(msg.params.device === undefined){
          if(this.device !== undefined){
            msg.params.device = this.device;
          }
        }
      }

      // console.log(">> ", msg);

      this.connection.send(encodeURIComponent(JSON.stringify(msg)));

    } catch (err){
      console.error("Cloud send error: ", err);
    }

  }


  public get(msg: server.Function | string): Promise<types.Object> {

    return new Promise((resolve, reject) => {


      //-- Convert single string functions to object

      if(typeof msg === "string"){
        msg = {
          "func": "get-" + msg,
          "params": {}
        };
      } else {
        if(msg.params === undefined){
          msg.params = {};
        }
      }

      this.send(msg, (data: any) => {
        if(data.params.sid === 0){
          reject(data.params.statustext);
        }
        if(data.params.sid === 5){
          resolve(data.params.payload);
        }
      });
    });

  }


  public close() {
    this._reconnect = false;
    this.connection.close();
  }


  private _getCallbackById(id: string): any {

    for(let c = 0; c < this._callbacks.length; c++){
      if(this._callbacks[c].id === id){
        return this._callbacks[c];
      }
    }

    return;
  }


  set setIdentifier(identifier: string) {
    this.identifier = identifier;
  }


  set setCode(code: string) {
    this.code = code;
  }


  set setDevice(device: server.Device) {
    this.device = device;
  }


  get readyState(): number {

    if(this.connection.ws === undefined || this.connection.ws.readyState === undefined){
      return 0;
    }

    return this.connection.ws.readyState;

  }


}

let UNIQUE_NUMBER = 17;
function getUniqueString() {
  UNIQUE_NUMBER ++;
  return UNIQUE_NUMBER + "";
}


const Cloud = new Cloud_();

export default Cloud;