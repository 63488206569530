import creatorTranslations from "./creator-translations";
import appTranslations from "./app-translations";

export default class translations {

  translations: Array<any> | undefined;

  constructor(src: string) {

    if(src === "creator"){
      this.translations = creatorTranslations;
    }
    if(src === "app"){
      this.translations = appTranslations;
    }

  }


  public getText(text: string, values?: Array<string>, fallback?: string)
  public getText(text: string, fallback?: string)
  public getText(text: string, valuesOrFallback?: Array<string>| string | undefined, fallback?: string | undefined): string {

    try {

      let values: Array<string> = [];

      if(typeof valuesOrFallback === "object"){
        values = valuesOrFallback;
      }

      if(typeof valuesOrFallback === "string"){
        fallback = valuesOrFallback;
      }


      if(this.translations === undefined){
        return "Could not get text";
      }

      //@ts-ignore
      let language = navigator.languages && navigator.languages[0] ||
          navigator.language ||
          //@ts-ignore
          navigator.userLanguage ||
          //@ts-ignore
          navigator.systemLanguage ||
          //@ts-ignore
          navigator.browserLanguage || "en";

      //@ts-ignore
      // console.log(navigator.languages, navigator.language, navigator.userLanguage, navigator.systemLanguage, navigator.browserLanguage);

      if(fallback !== undefined){
        language = fallback;
      }

      for(let c = 0; c < this.translations.length; c++){
        let isCurrentLanguage = false;
        for(let l = 0; l < this.translations[c].language.length; l++){
          if(this.translations[c].language[l] === language.toLowerCase()){
            isCurrentLanguage = true;
          }
        }
        if(isCurrentLanguage){
          if(this.translations[c].text[text] !== undefined){
            let string = this.translations[c].text[text];
            for(let v = 0, n = 1; v < values.length; v++, n++){
              string = string.replace(new RegExp("\\{" + n + "\\}", "g"), values[v]);
            }
            return string;
          } else {
            return this.getText(text, values, this.translations[c].fallback);
          }
        }
      }
      return this.getText(text, values, "en");
    } catch (err){
      return "Text not found";
    }

  }


  public translatePage(): void {

    if(this.translations === undefined){
      return;
    }

    for(const property in this.translations[0].text){
      const elements = document.getElementsByClassName(property);
      if(elements){
        for(let e = 0; e < elements.length; e++){
          elements[e].textContent = this.getText(property);
        }
      }
    }

  }

}