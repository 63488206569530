import Framework7 from "framework7";
import { isParseableJSON, mapNumber } from "../../../shared/functions/functions";
import FullscreenModal from "../fullscreen-modal/f7.fullscreen-modal.class";
import "./f7.hue-saturation-wheel.css";

export default function(Framework7Class) {
  return class HueSaturationWheel extends FullscreenModal(Framework7Class) {

    private params;

    public min: number = 0;
    public radius: number = 100;
    public releaseOnly: boolean = false;
    public stopOnly: boolean = true;
    public value: { h: number, s: number, l: number } = { h: 0, s: 100, l: 50 };

    private _stopTimeout: any;


    constructor(app: Framework7, params) {

      super(app, params);

      this.params = params;


      //-- Set parameters

      this.radius = this.params.radius || this.radius;
      this.releaseOnly = this.params.releaseOnly || this.params.releaseonly || this.releaseOnly;
      this.stopOnly = this.params.stopOnly || this.params.stoponly || this.stopOnly;


      //-- Set initial value

      let value = this.params.value || this.value;

      if(typeof value === "string"){
        value = decodeURIComponent(value);
        if(isParseableJSON(value)){
          value = JSON.parse(value);
        }
      }

      this.value = value;


      //-- Set radius

      const phoneRadius = this.params.phoneRadius || this.params.phoneradius || this.radius;

      if(app.device.ios === true && app.device.iphone === true){
        this.radius = phoneRadius;
      }
      if(app.width < 768 && (app.device.desktop !== true && app.theme !== "aurora")){
        this.radius = phoneRadius;
      }

      this.init();


      if(this.$contentEl === undefined){
        return;
      }

      //@ts-ignore
      this.$contentEl[0].f7HueSaturationWheel = this;
      //@ts-ignore
      this.$contentEl[0].hueSaturationWheel = this;

    }


    public pointerDown(ev): void {

    }


    public pointerUp(ev): void {
      if(this.releaseOnly === true){
        this.emit("local::change hueSaturationWheelChange", this.value);
        if(this.$contentEl !== undefined){
          this.$contentEl.trigger("change hueSaturationWheelChange", this.value);
        }
      }
    }


    public pointerMove(ev): void {

      if(this.$contentEl === undefined){
        return;
      }

      const svg = this.$contentEl.find(".hue-saturation-wheel-svg-container");

      if(svg.length <= 0){
        return;
      }

      const svgRect = svg[0].getBoundingClientRect();

      const x = 1 - (((ev.clientX - svgRect.left) / (this.radius * 2)) * 2);
      const y = 1 - (((ev.clientY - svgRect.top) / (this.radius * 2)) * 2);


      //-- Calculate hue

      const angleInRadians = Math.atan2(-y, -x);
      const angleInDegrees = (180 / Math.PI) * angleInRadians;


      //-- Calculate stauration

      const c = Math.sqrt(x ** 2 + y ** 2);

      const saturation = c * 100;

      let saturationValue = 150 - (50 / 100 * saturation + 50);
      saturationValue = saturationValue > 100 ? 100 : saturationValue;
      saturationValue = saturationValue < 50 ? 50 : saturationValue;

      const h = Math.round(this._normalizeDegrees(angleInDegrees));
      const s = 100;
      const l = Math.round(saturationValue);

      this.value = { h, s, l };

      this._updateCurrentColorCircle(h, s, l);


      //-- Emit hueSaturationWheelChange events

      if(this.releaseOnly === false && this.stopOnly === false){

        this.emit("local::change hueSaturationWheelChange", this.value);
        if(this.$contentEl !== undefined){
          this.$contentEl.trigger("change hueSaturationWheelChange", this.value);
        }

      }

      if(this.stopOnly === true && this.releaseOnly === false){

        if(this._stopTimeout !== undefined){
          clearTimeout(this._stopTimeout);
        }

        this._stopTimeout = setTimeout(() => {

          this.emit("local::change hueSaturationWheelChange", this.value);
          if(this.$contentEl !== undefined){
            this.$contentEl.trigger("change hueSaturationWheelChange", this.value);
          }

        }, 150);
      }

    }


    private _updateCurrentColorCircle(h: number, s: number, l: number) {

      if(this.$contentEl === undefined){
        return;
      }


      //-- Update current color circle

      const svg = this.$contentEl.find(".hue-saturation-wheel-svg-container");
      const currentColorCircle = svg[0].getElementsByClassName("current-color-circle")[0];

      if(currentColorCircle !== undefined){


        const { x, y } = this._getCoordinatesFromHSL(h, s, l);


        // //-- Restrict positions within circle

        // if(Math.sqrt((this.radius - cx)**2 + (this.radius - cy)**2) > this.radius){

        //   const radians = Math.atan2(cy - this.radius, cx - this.radius);

        //   cx = Math.cos(radians) * this.radius + this.radius;
        //   cy = Math.sin(radians) * this.radius + this.radius;

        // }

        // const h = Math.round(this._normalizeDegrees(angleInDegrees));
        // const s = 100;
        // const l = Math.round(saturationValue);

        const hsl = `hsl(${h}, ${s}%, ${l}%)`;

        currentColorCircle.setAttribute("fill", hsl);
        currentColorCircle.setAttribute("cx", x + "");
        currentColorCircle.setAttribute("cy", y + "");
        currentColorCircle.classList.remove("not-set");

      }
    }


    private _getCoordinatesFromHSL(h: number, s: number, l: number): { x: number, y: number } {

      const c = this.radius - mapNumber(l, 50, 100, 0, this.radius);

      const theta = h * Math.PI / 180;
      const x = c * Math.cos(theta) + this.radius;
      const y = c * Math.sin(theta) + this.radius;

      return { x, y };

    }


    private _getSvgArcPath(cx: number, cy: number, radius: number, startAngle: number, endAngle: number): string {

      const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
      startAngle *= Math.PI / 180;
      endAngle *= Math.PI / 180;
      const x1 = cx + radius * Math.cos(endAngle);
      const y1 = cy + radius * Math.sin(endAngle);
      const x2 = cx + radius * Math.cos(startAngle);
      const y2 = cy + radius * Math.sin(startAngle);
      return `M ${x1} ${y1} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${x2} ${y2}`;

    }


    private _normalizeDegrees(degrees: number): number {
      return ((degrees % 360) + 360) % 360;
    }


    public render() {

      let container = "";
      let icon = "";
      let title = "";
      let content = "";

      if(this.params.icon !== undefined){
        icon = this.params.icon;
      }

      if(this.params.title !== undefined){
        title = this.params.title;
      }

      if(this.params.content !== undefined){
        content = this.params.content;
      }


      //-- Create SVG

      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.style.setProperty("width", this.radius * 2 + "px");
      svg.style.setProperty("height", this.radius * 2 + "px");
      svg.style.setProperty("display", "block");
      svg.style.setProperty("overflow", "visible");

      svg.setAttribute("width", this.radius * 2 + "");
      svg.setAttribute("height", this.radius * 2 + "");


      //-- Create defs

      const defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");


      //-- Create radial gradient

      const radialGradient = document.createElementNS("http://www.w3.org/2000/svg", "radialGradient");
      radialGradient.setAttribute("id", "saturation");
      const stop1 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
      stop1.setAttribute("offset", "0%");
      stop1.setAttribute("stop-color", "#ffffff");
      const stop2 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
      stop2.setAttribute("offset", "100%");
      stop2.setAttribute("stop-color", "#ffffff");
      stop2.setAttribute("stop-opacity", "0");

      radialGradient.appendChild(stop1);
      radialGradient.appendChild(stop2);

      defs.appendChild(radialGradient);


      //-- Add defs

      svg.appendChild(defs);


      //-- Add group

      const group = document.createElementNS("http://www.w3.org/2000/svg", "g");
      group.setAttribute("stroke-width", this.radius + "");
      group.setAttribute("fill", "none");


      //-- Create hues

      for(let i = 0; i < 360; i++){

        const angle = i;
        const cx = this.radius;
        const cy = this.radius;

        const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        path.setAttribute("stroke", `hsl(${i}, 100%, 50%)`);
        const d = this._getSvgArcPath(cx, cy, this.radius / 2, angle, angle + 1.5);
        path.setAttribute("d", d);


        group.appendChild(path);

      }


      //-- Add saturation overlay

      const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      circle.setAttribute("fill", "url(#saturation)");
      circle.setAttribute("stroke", "#000");
      circle.setAttribute("stroke-width", "1px");
      circle.setAttribute("r", this.radius + "");
      circle.setAttribute("cx", this.radius + "");
      circle.setAttribute("cy", this.radius + "");
      group.appendChild(circle);

      svg.appendChild(group);


      //-- Add currentColor circle

      const currentColorCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
      currentColorCircle.setAttribute("class", "current-color-circle not-set");
      currentColorCircle.setAttribute("stroke", "#000");
      currentColorCircle.setAttribute("stroke-width", "1px");
      currentColorCircle.setAttribute("r", 12 + "");
      currentColorCircle.setAttribute("cx", 0 + "");
      currentColorCircle.setAttribute("cy", 0 + "");


      //-- Set initial value

      if(typeof this.value === "object"){
        if(typeof this.value.h !== "undefined" && !isNaN(this.value.h) &&
            typeof this.value.s !== "undefined" && !isNaN(this.value.s) &&
            typeof this.value.l !== "undefined" && !isNaN(this.value.l)){

          const { x, y } = this._getCoordinatesFromHSL(this.value.h, this.value.s, this.value.l);

          currentColorCircle.setAttribute("fill", `hsl(${this.value.h}, ${this.value.s}%, ${this.value.l}%)`);
          currentColorCircle.setAttribute("cx", x + "");
          currentColorCircle.setAttribute("cy", y + "");
          currentColorCircle.classList.remove("not-set");

        }
      }

      svg.appendChild(currentColorCircle);

      const additionalContent = content === undefined ? "" : `
        <div class="hue-saturation-wheel-content fullscreen-modal-content flexbox">
          ${content}
        </div>
      `;

      container = `
        <div class="hue-saturation-wheel-container">
          <i class="f7-icons">${icon}</i>
          <div class="fullscreen-modal-title">${title}</div>
          <div class="hue-saturation-wheel-svg-container touch-area">
            ${svg.outerHTML}
          </div>
          ${additionalContent}
        </div>
      `;

      return container;

    }

  };
}
